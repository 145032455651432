import React from 'react';
import { Modal } from 'react-bootstrap';

const NewWalletModal = ({ show, onHide }) => (
  <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
    <Modal.Body className="modal-body p-40">
      <p className="c-gray tt-u">First step</p>
      <h3 className="mB-30"><b className="fw-n">Create Wallet Address</b></h3>
      <form action>
        <div className="form-group">
          <label className="c-grey">Select Currency</label>
          <div className="dropdown select-coin">
            <button className="form_control btn-transparent dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true">
              <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                  Bitcoin
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
              <ul className="lis-n p-0 m-0">
                <li>
                  <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                      Bitcoin
                </li>
                <li>
                  <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                      Ethereum
                </li>
                <li>
                  <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                      Bitcoin
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="c-grey">Name of the wallet</label>
          <input type="text" className="form-control form_control" placeholder="Type the name" />
        </div>
        <div className="form-group">
          <label className="c-grey">Select Group</label>
          <select className="form-control form_control">
            <option value>Wallet Group</option>
          </select>
        </div>
        <div className="text-center mT-30">
          <button className="btn btn-primary btn-lg btn-around">Create Wallet</button>
        </div>
      </form>
    </Modal.Body>
  </Modal>
);

export default NewWalletModal;
