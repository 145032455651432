import React from 'react';
import Slider from 'react-slick';

const settings = {
  dots: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SliderBlogTop = () => (
  <div className="slider-blog-top custom-container-right">
    <Slider className="slider" id="slider-blog-top" {...settings}>
      <div className="item">
        <div className="items img" style={{ backgroundImage: 'url(/images/image-blog-sm-1.jpg)' }}>
          <a className="item-blog-slider-top" href="/">
            <h4 className="c-white mb-0">Paybit debuts mPOS product</h4>
          </a>
        </div>
      </div>
      <div className="item">
        <div className="items img" style={{ backgroundImage: 'url(/images/image-blog-sm-2.jpg)' }}>
          <a className="item-blog-slider-top" href="/">
            <h4 className="c-white mb-0">Amazon eyes NYC for next Go store</h4>
          </a>
        </div>
      </div>
      <div className="item">
        <div className="items img" style={{ backgroundImage: 'url(/images/image-blog-sm-1.jpg)' }}>
          <a className="item-blog-slider-top" href="/">
            <h4 className="c-white mb-0">Glance Pay integrates with 15 POS systems</h4>
          </a>
        </div>
      </div>
      <div className="item">
        <div className="items img" style={{ backgroundImage: 'url(/images/image-blog-sm-2.jpg)' }}>
          <a className="item-blog-slider-top" href="/">
            <h4 className="c-white mb-0">Speedpay inks pact with Denver Water</h4>
          </a>
        </div>
      </div>
      <div className="item">
        <div className="items img" style={{ backgroundImage: 'url(/images/image-blog-sm-1.jpg)' }}>
          <a className="item-blog-slider-top" href="/">
            <h4 className="c-white mb-0">Amazon eyes NYC for next Go store</h4>
          </a>
        </div>
      </div>
      <div className="item">
        <div className="items img" style={{ backgroundImage: 'url(/images/image-blog-sm-2.jpg)' }}>
          <a className="item-blog-slider-top" href="/">
            <h4 className="c-white mb-0">Speedpay inks pact with Denver Water</h4>
          </a>
        </div>
      </div>
    </Slider>
  </div>
);

export default SliderBlogTop;
