import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { compose, mapProps } from 'recompose';

import { withAuth } from '../../../hocs/withAuth';

const RedirectToLogin = () => (
  <Redirect to={{ pathname: '/sign-in' }} />
);

export default compose(
  withAuth,
  mapProps(({ component, isAuth, ...props }) => ({
    component: isAuth ? component : RedirectToLogin,
    ...props,
  })),
)(Route);
