import React from 'react';
import {
  AreaChart, Area,
  XAxis, YAxis,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const mockData = [
  {
    xCategory: '0', value: 116, time: '01:00 AM', day: 'Jan 17',
  },
  { xCategory: '1', value: 180 },
  { xCategory: '2', value: 72 },
  { xCategory: '3', value: 156 },
  { xCategory: '4', value: 2 },
  { xCategory: '5', value: 137 },
  {
    xCategory: '6', value: 51, time: '02:00 AM', day: 'Feb 17',
  },
  { xCategory: '7', value: 119 },
  { xCategory: '8', value: 86 },
  { xCategory: '9', value: 139 },
  {
    xCategory: '10', value: 194, time: '03:00 AM', day: 'Mar 17',
  },
  { xCategory: '11', value: 90 },
  { xCategory: '12', value: 189 },
  { xCategory: '13', value: 59 },
  { xCategory: '14', value: 156 },
  { xCategory: '15', value: 19 },
  {
    xCategory: '16', value: 148, time: '04:00 AM', day: 'Apr 17',
  },
  { xCategory: '17', value: 64 },
  { xCategory: '18', value: 127 },
  { xCategory: '19', value: 4 },
  { xCategory: '20', value: 144 },
  { xCategory: '21', value: 66 },
  {
    xCategory: '22', value: 93, time: '05:00 AM', day: 'May 17',
  },
  { xCategory: '23', value: 41 },
  { xCategory: '24', value: 30 },
  { xCategory: '25', value: 179 },
  { xCategory: '26', value: 108 },
  { xCategory: '27', value: 189 },
  {
    xCategory: '28', value: 145, time: '06:00 AM', day: 'Jun 17',
  },
  { xCategory: '29', value: 74 },
  { xCategory: '30', value: 125, keepShowing: true },
  { xCategory: '31', value: 35 },
  { xCategory: '32', value: 122 },
  { xCategory: '33', value: 80 },
  {
    xCategory: '34', value: 42, time: '07:00 AM', day: 'Jul 17',
  },
  { xCategory: '35', value: 35 },
  { xCategory: '36', value: 74 },
  { xCategory: '37', value: 178 },
  { xCategory: '38', value: 113 },
  { xCategory: '39', value: 162 },
  {
    xCategory: '40', value: 185, time: '08:00 AM', day: 'Aug 17',
  },
  { xCategory: '39', value: 162 },
  { xCategory: '39', value: 162 },
];

const CustomTick = (props) => {
  const { x, y, payload: { value } } = props;
  const { time } = mockData.find(payload => payload.xCategory === value);
  return (
    <g transform={`translate(${x + 30},${y})`} {...props}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">{time}</text>
    </g>
  );
};

const CustomDot = (props) => {
  const { cx, cy, payload: { keepShowing = false, value } } = props;
  const radius = 8;
  const diameter = radius * 2;
  return keepShowing && (
    <svg width={diameter} height={diameter} style={{ overflow: 'visible' }}>
      <circle cx={cx} cy={cy} r={radius} fill="#3486C2" stroke="#FFF" strokeWidth="3" />
      <text x={cx - 10} y={cy - 10} fill="#3486C2" fontSize="16" fontWeight="bold">{value}</text>
    </svg>
  );
};

const StatsChart = () => (
  <ResponsiveContainer>
    <AreaChart width={1534} height={370} data={mockData}>
      <defs>
        <linearGradient id="statsColor" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#3486C2" stopOpacity={0.4} />
          <stop offset="50%" stopColor="#3486C2" stopOpacity={0.1} />
          <stop offset="90%" stopColor="#3486C2" stopOpacity={0.01} />
        </linearGradient>
      </defs>
      <Tooltip
        viewBox={{
          x: -1,
          y: -1,
          width: 400,
          height: 400,
        }}
      />
      <XAxis dataKey="xCategory" tickLine={false} tick={CustomTick} />
      <YAxis tickLine={false} axisLine={false} />
      <Area type="linear" dataKey="value" stroke="#0084FF" fill="url(#statsColor)" fillOpacity={1} dot={CustomDot} />
      {mockData.filter(value => value.time).map((value) => {
        const { xCategory, day } = value;
        const customLabel = (props) => {
          const { viewBox: { x, y } } = props;
          return (
            <g transform={`translate(${x + 50},${y})`} {...props}>
              <text x={0} y={0} dy={16} textAnchor="end" fill="#666">{day}</text>
            </g>
          );
        };
        return <ReferenceLine x={xCategory} label={customLabel} key={value.xCategory} />;
      })}
    </AreaChart>
  </ResponsiveContainer>
);

export default StatsChart;
