import React from 'react';
import classNames from 'classnames';
import ReactWOW from 'react-wow';

import { withTranslation } from 'react-i18next';
import container from './container';
import FAQ from './FAQ';

const faqs = [
  {
    q: 'q4',
    a: 'a4',
  },
  {
    q: 'q5',
    a: 'a5',
  },
  {
    q: 'q6',
    a: 'a6',
  },
];

const SecurityNPrivacy = ({
  setCollapse, collapseIndex, className, t,
}) => (
  <ReactWOW animation="fadeInUp">
    <div className={classNames('bd bgc-white faq-horizonal-box-item', className)}>
      <div className="d-flex align-items-center mB-15 faq-horizonal-box-item-header">
        <div className="mR-60 fsz-40 c-grey">
          <i className="icon-security" />
        </div>
        <div>
          <h5><b className="fw-n">{t('title')}</b></h5>
          <p className="fsz-def c-grey mb-0">{t('sub')}</p>
        </div>
      </div>
      <div className="faq-horizonal-item-body">
        <div id="accordion5">
          {faqs.map((faq, index) => (
            <FAQ
              {...faq}
              index={index}
              key={`faq-${index}`}
              setCollapse={setCollapse}
              collapseIndex={collapseIndex}
            />
          ))}
        </div>
      </div>
    </div>
  </ReactWOW>
);

export default withTranslation('SecurityNPrivacy')(container(SecurityNPrivacy));
