import React from 'react';
import Slider from 'react-slick';
import Countup from 'react-countup';
import ReactWOW from 'react-wow';
import { compose, lifecycle, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { map, take } from 'lodash';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { pricesSelector } from '../../../selectors/coinsSelector';
import { fetchPrices } from '../../../actions/coinsAction';
import { cssURLCoinImg } from '../../../utils/apiDataHelper';
import CrypoTinyLineChart from '../Charts/CrypoTinyLineChart';

const settings = {
  dots: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Block = compose(
  withRouter,
  mapProps(({ price = {}, ...props }) => {
    const { chartData = [] } = price;
    return ({
      ...props,
      price,
      chartData: map(chartData, (value, key) => ({ value, key })),
    });
  }),
)(
  ({
    history,
    price: {
      // id,
      coinName,
      price,
      change,
      slug,
      symbol,
    },
    chartData,
  }) => (
    <ReactWOW animation="bounceIn" duration="1s">
      <div className="item" onClick={() => history.push(`/crypto-details-page/${symbol}`)}>
        <div className="bd p-15" style={{ cursor: 'pointer' }}>
          <div className="d-flex justify-content-between mB-10">
            <div className="d-flex align-items-center">
              <div className="img size-25" style={{ backgroundImage: cssURLCoinImg(slug) }} />
              <span className="mL-5">{coinName}</span>
            </div>
            <span className="btn btn-gray">24h</span>
          </div>
          <div className="d-flex justify-content-between mB-25">
            <h5 className="mb-0">
              <b className="fw-n">
                USD
                <br />
                <Countup end={price} separator="," decimal="." duration={2.75} decimals={3} />
              </b>
            </h5>
            <span className="c-grey">
              <Countup prefix="USD " end={change} decimal="." duration={1.1} delay={0.5} decimals={3} suffix=" %" start={-100} />
            </span>
          </div>
          <div className="chart-line chart-line--normal">
            <CrypoTinyLineChart data={chartData} />
          </div>
        </div>
      </div>
    </ReactWOW>
  ),
);
const CurrencySlider = ({ t, prices = [] }) => (
  <ReactWOW animation="fadeInUp">
    <section className="pY-70">
      <div className="container">
        <h4><b className="fw-n">{t('Virtual')}</b></h4>
        <p className="fsz-def">
          {t('Last updated')}
        </p>
        <div className="five-block-currency mT-40">
          <Slider className="slider currency-slider" id="five-block-currency-slider" {...settings}>
            {map(take(prices, 10), price => <Block price={price} key={price.id} />)}
          </Slider>
        </div>
      </div>
    </section>
  </ReactWOW>
);

export default compose(
  withTranslation('CurrencySlider'),
  connect(pricesSelector, { fetchPrices }),
  lifecycle({
    componentDidMount() {
      this.props.fetchPrices();
    },
  }),
)(CurrencySlider);
