import React from 'react';
import ReactWOW from 'react-wow';

const BannerText = () => (
  <div className="col-md-12 col-lg-5 col-xl-4">
    <div className="pT-70">
      <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.25s">
        <p className="fsz-def c-white-60">Paybit for Personal</p>
      </ReactWOW>
      <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.5s">
        <h1 className="fsz-50 fw-n">PayBit Business App: Manage your business on the go.</h1>
      </ReactWOW>
      <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.75s">
        <p className="c-text-gray">
            Send and receive money across the table or around the world
            to PayBit account holders using just their phone number or email.
        </p>
      </ReactWOW>
      <ReactWOW animation="slideInUp" duration="0.5s" delay="1s">
        <div className="mT-20">
          <a href="/" className="button_androidstore186x54">{' '}</a>
        </div>
      </ReactWOW>
    </div>
  </div>
);

export default BannerText;
