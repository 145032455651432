import React from 'react';
import {
  LineChart, Line, ResponsiveContainer, YAxis,
} from 'recharts';
import { sample } from 'lodash';

const colors = [
  '#FF7831',
  '#00C300',
  '#8385A5',
  '#A4A4A4',
  '#A3E5D1',
];

const CrypoTinyLineChart = ({ color = sample(colors), data }) => (
  <div className="tiny-line-chart">
    <ResponsiveContainer>
      <LineChart data={data}>
        <Line type="monotone" dataKey="value" stroke={color} strokeWidth={2} dot={() => null} />
        <YAxis hide domain={['dataMin', 'dataMax']} />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default CrypoTinyLineChart;
