import React from 'react';
import classNames from 'classnames';
import { compose, withState, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import ReactWOW from 'react-wow';
import Countup from 'react-countup';

import IntroSlider from './components/IntroSlider';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import WalletPortfolio from './components/WalletPortfolio';
import DashboardRouter from './DashboardRouter';
import { DaskModeWrapper } from '../../hocs/withDashMode';
import '../../styles/main.scss';
import 'font-awesome/css/font-awesome.css';

const Dashboard = (props) => {
  const {
    activeSibar, toggleActiveSibar, daskMode, toggleDaskMode, history,
  } = props;
  const redirectToWalletAddresses = () => history.push('/dashboard_new/wallets-address');
  return (
    <DaskModeWrapper {...{ daskMode, toggleDaskMode }}>
      <div className={classNames({ 'dark-mode': daskMode })}>
        <div className={classNames('dashboard', { activeSibar, 'collapse-sidebar': activeSibar })}>
          <Sidebar
            toggleActive={toggleActiveSibar}
            active={activeSibar}
            {...{ daskMode, toggleDaskMode }}
          />
          <div className="dashboard-container">
            <Header />
            <div className="dashboard-content">
              <ReactWOW animation="fadeInUp">
                <section className="bgc-white bdB">
                  <div className="row no-gutters">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                      <div className="wallet-overview">
                        <h3 className="section-title">Welcome Jason Straightham!</h3>
                        <div className="d-inline-block w-100 mT-20">
                          <div className="wallet-portfolio">
                            <p className="ta-c c-gray">Wallet Portfolio</p>
                            <div className="wallet-portfolio-chart">
                              <WalletPortfolio />
                            </div>
                          </div>
                          <div className="wallet-balance">
                            <div className="d-flex align-items-center mB-20">
                              <div className="wallet-balance-icon">
                                <i className="icon-dollar" />
                              </div>
                              <div className="wallet-balance-value mR-20">
                                <p className="c-gray">Wallet Balance</p>
                                <div className="wallet-number">
                                  <Countup end={4687.11} separator="," decimal="." duration={2.75} decimals={2} />
                                  <sup className="c-gray">$</sup>
                                </div>
                              </div>
                              <div className="fsz-sm c-gray mL-10">
                                <i className="c-green fas fa-check-circle mR-5" />
                                Status:
                                <a href="/" className="c-green td-u">Verified</a>
                              </div>
                            </div>
                            <div className="user-infomation mT-20">
                              <ul className="lis-n p-0 d-inline-block w-100">
                                <li className="c-gray">
                                  <p className="mB-5">
                                    <i className="icon-user mR-5" />
                                    Account Type:
                                  </p>
                                  <span className="c-black mL-20">Personal</span>
                                </li>
                                <li className="c-gray">
                                  <p className="mB-5">
                                    <i className="icon-user mR-5" />
                                    Member since:
                                  </p>
                                  <span className="c-black mL-20">30 Jan 2013</span>
                                </li>
                                <li className="c-gray">
                                  <p className="mB-5">
                                    <i className="icon-time mR-5" />
                                    Last Login:
                                  </p>
                                  <span className="c-black mL-20">24.11.2018</span>
                                </li>
                                <li className="c-gray">
                                  <p className="mB-5">
                                    <i className="icon-transactions mR-5" />
                                    Last transaction:
                                  </p>
                                  <span className="c-black mL-20">26.11.2018</span>
                                </li>
                                <li className="c-gray">
                                  <p className="mB-5">
                                    <i className="icon-moneys mR-5" />
                                    Transactions Today:
                                  </p>
                                  <span className="c-black mL-20">0</span>
                                </li>
                                <li
                                  className="c-gray"
                                  onClick={redirectToWalletAddresses}
                                  role="presentation"
                                >
                                  <p
                                    className="mB-5"
                                  >
                                    <i className="icon-wallet_address mR-5" />
                                    Wallet Addresses:
                                  </p>
                                  <span className="c-black mL-20">6</span>
                                </li>
                              </ul>
                            </div>
                            <div className="list-coin">
                              <ul className="lis-n p-0 d-inline-block w-100 mB-0">
                                <li>
                                  <p className="c-black mB-0">
                                    <span className="coin-color bitcoin" />
                                    Bitcoins
                                  </p>
                                  <span className="mL-20 fsz-xs c-gray">17%, 12 Coins</span>
                                </li>
                                <li>
                                  <p className="c-black mB-0">
                                    <span className="coin-color ethereum" />
                                    Ethereum
                                  </p>
                                  <span className="mL-20 fsz-xs c-gray">3%, 7 Coins</span>
                                </li>
                                <li>
                                  <p className="c-black mB-0">
                                    <span className="coin-color litecoin" />
                                    Litecoin
                                  </p>
                                  <span className="mL-20 fsz-xs c-gray">22%, 0.3 Coins</span>
                                </li>
                                <li>
                                  <p className="c-black mB-0">
                                    <span className="coin-color ripple" />
                                    Ripple
                                  </p>
                                  <span className="mL-20 fsz-xs c-gray">15%, 4 Coins</span>
                                </li>
                                <li>
                                  <p className="c-black mB-0">
                                    <span className="coin-color paybit" />
                                    Paybit
                                  </p>
                                  <span className="mL-20 fsz-xs c-gray">22%, 0.3 Coins</span>
                                </li>
                                <li>
                                  <p className="c-black mB-0">
                                    <span className="coin-color neo" />
                                    Neo
                                  </p>
                                  <span className="mL-20 fsz-xs c-gray">15%, 4 Coins</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <IntroSlider />
                  </div>
                </section>
              </ReactWOW>
              <DashboardRouter />
            </div>
          </div>
        </div>
      </div>
    </DaskModeWrapper>
  );
};

const Container = compose(
  withRouter,
  withState('activeSibar', 'setActiveSibar', false),
  withState('daskMode', 'setDaskMode', localStorage.getItem('daskMode') === 'true'),
  withHandlers({
    toggleActiveSibar: ({ setActiveSibar, activeSibar }) => () => {
      setActiveSibar(!activeSibar);
    },
    toggleDaskMode: ({ daskMode, setDaskMode }) => () => {
      localStorage.setItem('daskMode', !daskMode);
      setDaskMode(!daskMode);
    },
  }),
);

export default Container(Dashboard);
