import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import {
  AreaChart, Area,
  XAxis, YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { reduce } from 'lodash';
import { compose, mapProps } from 'recompose';

import { currencyFormater } from '../../../utils/currencyFormater';

const CryptoPriceGrapht = ({ data, historical: { coinName } = {} }) => {
  const CustomTooltip = (props) => {
    const {
      payload,
    } = props;
    const {
      payload: {
        value,
        text,
        // high, low, coin, up,
      },
    } = (payload && payload[0]) || { payload: {} };
    return (
      <div className="chart-price--tooltip chart-tooltip">
        <div className="chart-tooltip__header">
          <span>{coinName}</span>
          <p className="c-green fsz-def pL-15 mB-0">
            {/* <i className="fas fa-sort-up" /> */}
            {/* {up} % */}
          </p>
        </div>
        <div className="chart-tooltip__break-line mB-5 mT-5" />
        <div className="chart-tooltip__body">
          <div className="chart-tooltip--value">
            {/* <span className="c-gray mb-0">Value: </span> */}
            <span>
              {currencyFormater(value)}
            </span>
          </div>
          <div className="chart-tooltip--value">
            {/* <span className="c-gray mb-0">Low: </span> */}
            <span>
              {text}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const CustomTick = (props) => {
    const { x, y, payload: { value } } = props;
    return (
      <g transform={`translate(${x + 30},${y})`} {...props}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666">{data[value].displayText}</text>
      </g>
    );
  };

  return (
    <div className="chart-price">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={1534} height={370} data={Object.values(data)}>
          <defs>
            <linearGradient id="statsColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3486C2" stopOpacity={0.4} />
              <stop offset="50%" stopColor="#3486C2" stopOpacity={0.1} />
              <stop offset="90%" stopColor="#3486C2" stopOpacity={0.01} />
            </linearGradient>
          </defs>
          <Tooltip
            viewBox={{
              x: -1,
              y: -1,
              width: 400,
              height: 400,
            }}
            content={<CustomTooltip />}
          />
          <XAxis dataKey="time" tickLine={false} tick={CustomTick} />
          <YAxis tickLine={false} axisLine={false} domain={['dataMin', 'dataMax']} tickFormatter={value => value && numeral(value).format('0 a').toUpperCase()} />
          <Area type="linear" dataKey="value" stroke="#0084FF" fill="url(#statsColor)" fillOpacity={1} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

const container = compose(
  mapProps(({
    chartData, chartDuration, durationSettings, ...props
  }) => {
    const { deltaTime, formatTime } = durationSettings;
    let nextDot = null;
    const caculateNextDot = (time) => {
      const dotTime = moment(parseInt(time, 10));
      const text = dotTime.format(formatTime);
      let displayText = null;
      if (!nextDot || nextDot <= moment().valueOf(dotTime)) {
        nextDot = moment().valueOf(dotTime.add(...deltaTime));
        displayText = text;
      }
      return {
        displayText,
        text: dotTime.format(),
        time,
      };
    };
    const data = reduce(chartData, (result, value, time) => ({
      ...result,
      [time]: {
        value,
        ...caculateNextDot(time),
      },
    }), {});
    return {
      ...props,
      data,
      chartData,
    };
  }),
);

export default container(CryptoPriceGrapht);
