import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import Overview from './Overview';
import NotificationMain from '../../NotificationMain';

const HomePage = () => (
  <section>
    <Helmet>
      <title>Paybit - Dashboard</title>
    </Helmet>
    <Tab.Container defaultActiveKey="tab_overview">
      <div className="main-content">
        <ReactWOW animation="fadeInUp">
          <div className="tab-common pX-25 bgc-white bdB">
            <Nav variant="pills" className="nav nav-tabs tab-lg">
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="tab_overview">
                  <i className="icon-overview" />
                    Overview
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="tab_stats">
                  <i className="icon-stats" />
                    Stats
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="tab_last_activity">
                  <i className="icon-menu" />
                    Last Activity
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="tab_contacts">
                  <i className="icon-contacts" />
                    Contacts
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="tab_notifications">
                  <i className="icon-notifications" />
                    Notifications
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </ReactWOW>
        <div className="p-20 bgc-grey main-content-container">
          <Tab.Content id="mainTabContent">
            <Tab.Pane eventKey="tab_overview">
              <Overview />
            </Tab.Pane>
            <Tab.Pane eventKey="tab_stats">.2..</Tab.Pane>
            <Tab.Pane eventKey="tab_last_activity">.3..</Tab.Pane>
            <Tab.Pane eventKey="tab_contacts">.4..</Tab.Pane>
            <Tab.Pane eventKey="tab_notifications"><NotificationMain /></Tab.Pane>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  </section>
);

export default HomePage;
