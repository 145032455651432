import React from 'react';
import ReactWOW from 'react-wow';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { compose } from 'recompose';
import SectionPartner from '../common/SectionPartner';
import PartnerItems from '../common/PartnerItems';
import { withAuth } from '../../hocs/withAuth';

const BusinessTour = ({ isAuth }) => (
  <>
    <Helmet>
      <title>Paybit - Business Tour</title>
    </Helmet>
    <section className="top-banner-common" style={{ backgroundImage: 'url(/images/business-tour-banner.jpg)' }}>
      <div className="top-banner-common-inner c-white">
        <div className="container">
          <ul className="breadcrumb breadcrumbs breadcrumbs-white">
            <li><a href="/"><i className="icon-house" /></a></li>
            <li>
              <i className="fas fa-chevron-right" />
              <a href="/">Business Tour</a>
            </li>
          </ul>
          <div className="top-banner-text">
            <ReactWOW animation="slideInUp">
              <p className="fsz-def c-white-60">Paybit for business</p>
            </ReactWOW>
            <ReactWOW animation="slideInUp">
              <h1 className="fsz-50 fw-n mB-20">
                Let paybit help you
                <br />
                grow your business
              </h1>
            </ReactWOW>
            <ReactWOW animation="slideInUp">
              <p className="c-white-60 fsz-def mB-30">Built-in fraud prevention, personalized support, free subscription billing options and much more.</p>
            </ReactWOW>
            <div className="mT-20">
              {!isAuth && (
                <>
                  <ReactWOW animation="slideInLeft">
                    <a href="/sign-up-business" className="btn btn-lg btn-primary mR-10">Join now</a>
                  </ReactWOW>
                  <ReactWOW animation="slideInLeft">
                    <a href="/" className="btn btn-lg btn-light">More Information</a>
                  </ReactWOW>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="pY-50 bgc-white section-why-paybit" style={{ backgroundImage: 'url(/images/main-background-business-tour.png)' }}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-12 col-lg-9">
            <h2 className="fw-n fsz-lg">Why Paybit?</h2>
            <h2 className="mT-50 text-before-decor">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</h2>
          </div>
        </div>
        <div className="mB-60">
          <PartnerItems />
        </div>
        <div className="mY-50">
          <div className="text-center mB-70">
            <h4 className="fw-n">Get Started</h4>
            <p className="c-text-gray">Looking for help or consulting? Fill the form below or email us. We will respond you as soon as possible.</p>
          </div>
          <div className="row mY-50 justify-content-center">
            <ReactWOW animation="fadeInUp">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="main-feature-item box-hover-zoom box-shadow-mb">
                  <div className="main-feature-item-icon">
                    <i className="icon-security" />
                  </div>
                  <h5>
                    <b className="fw-n">Ultra Fast &amp; Secure</b>
                    <p className="mT-15 c-grey fsz-def">Instant Private Transactions</p>
                  </h5>
                  <div className="main-feature-item-text">
                    <p className="c-text-gray">
                      The Smart Asset Paybit is smarter &amp;
                      more open with Open Coin, the highly Cusomizable Coin
                    </p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="main-feature-item box-hover-zoom box-shadow-mb">
                  <div className="main-feature-item-icon">
                    <i className="icon-request_money" />
                  </div>
                  <h5>
                    <b className="fw-n">Highly Scalable</b>
                    <p className="mT-15 c-grey fsz-def">Limitless Applications</p>
                  </h5>
                  <div className="main-feature-item-text">
                    <p className="c-text-gray">
                      The Smart Asset Paybit is smarter &amp;
                      more open with OpenCoin, the highly Cusomizable Coin
                    </p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="main-feature-item box-hover-zoom box-shadow-mb">
                  <div className="main-feature-item-icon">
                    <i className="icon-infomation" />
                  </div>
                  <h5>
                    <b className="fw-n">Reliable &amp; Low Cost</b>
                    <p className="mT-15 c-grey fsz-def">Instant Private Transactions</p>
                  </h5>
                  <div className="main-feature-item-text">
                    <p className="c-text-gray">
                      The Smart Asset Paybit is smarter &amp;
                      more open with OpenCoin, the highly Customizable Coin
                    </p>
                  </div>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
      <div>
        <div className="our-product-item-50 our-product-item-img-right">
          <ReactWOW animation="fadeInRight">
            <div className="our-product-item-img">
              <div style={{ backgroundImage: 'url(/images/tablet-mobile-mockup.png)' }} />
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInLeft">
            <div className="our-product-item-text">
              <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
              <h3 className="mB-15">Download the PayBit Application for your favorite platform</h3>
              <p className="c-text-gray mB-15">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</p>
              <div className="d-inline-block mT-40 w-100 list-os">
                <a href="/" className="box-hover-zoom float-left circle-center-104 c-blue bgc-o-blue mR-15 mB-15"><i className="fsz-30 fab fa-apple" /></a>
                <a href="/" className="box-hover-zoom float-left circle-center-104 c-green bgc-o-green mR-15 mB-15"><i className="fsz-30 fab fa-android" /></a>
                <a href="/" className="box-hover-zoom float-left circle-center-104 c-4d bgc-o-black mR-15 mB-15"><i className="fsz-30 fab fa-windows" /></a>
                <a href="/" className="box-hover-zoom float-left circle-center-104 c-orange bgc-o-orange mR-15 mB-15"><i className="fsz-30 fab fa-linux" /></a>
              </div>
            </div>
          </ReactWOW>
        </div>
        <ReactWOW animation="fadeInUp">
          <Tab.Container defaultActiveKey="tab_payment_button">
            <div className="container">
              <div className="tabs-sup">
                <Nav className="nav nav-tabs row no-gutters" id="paymentTab" role="tablist" as="ul">
                  <Nav.Item as="li" className="nav-item col-3 col-md-3 text-center">
                    <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_payment_button" role="tab" aria-selected="true">
                      <i className="icon-payment-button" />
                      <h5 className="fw-n">Payment Buttons</h5>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="nav-item col-3 col-md-3 text-center">
                    <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_shopping" role="tab" aria-selected="false">
                      <i className="icon-shopping-bag" />
                      <h5 className="fw-n">Shopping Cart Integration</h5>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="nav-item col-3 col-md-3 text-center">
                    <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_multiple_payment" role="tab" aria-selected="false">
                      <i className="icon-multi-payments" />
                      <h5 className="fw-n">Multiple Payment</h5>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="nav-item col-3 col-md-3 text-center">
                    <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_get_paid" role="tab" aria-selected="false">
                      <i className="icon-paid" />
                      <h5 className="fw-n">Get Paid Your Way</h5>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="tab-content mY-50" id="`paymentTabContent`">
                  <Tab.Pane as="div" eventKey="tab_payment_button" role="tabpanel">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <h4 className="fw-n">PayBit Payment Buttons make it easy to accept online</h4>
                        <div className="c-gray line-height-2">
                          <p>
                            Great for single payments or subscriptions,
                            payments or donations, there&lsquo;s
                            a Paybit Payment Button to meet your needs.
                          </p>
                          <p>
                            Choose the button that&lsquo;s right for you and we&lsquo;ll
                            automatically generate the code for easy integration.
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="box-shadow bd bdrs-6 p-30 mB-25">
                          <h5 className="fw-n">SAMPLE CODE</h5>
                          <xmp className="whs-pw wob-ba m-0">
                            &lt;-- Pay Now --&gt;
                            &lt;form method=&quot;POST&quot;
                            action=&quot;https://secure.paybit.com/checkout&quot;&gt;
                            &lt;input type=&quot;hidden&quot;
                            name=&quot;ap_productid&quot; value=&quot;XXXXXXXXXXXXXX&quot; /&gt;
                            &lt;input type=&quot;hidden&quot;
                            name=&quot;ap_quantity&quot; value=&quot;1&quot; /&gt;
                            &lt;input type=&quot;image&quot;
                            name=&quot;ap_image&quot; src=&quot;XXXXXXXXXX&quot; /&gt;
                            &lt;/form&gt;
                          </xmp>
                        </div>
                        <a href="/" className="btn btn-paynow">
                          <i className="icon-paybit" />
                          <b className="fw-n">Pay Now</b>
                        </a>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane as="div" eventKey="tab_shopping" role="tabpanel">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-sm-12">
                        <h4 className="fw-n">Hassle-free Shopping Cart Integration</h4>
                        <div className="c-gray line-height-2">
                          <p>There are tons of third-party shopping cart modules already supported by Paybit making it easy to accept secure online payments.</p>
                          <p>PayBit works seamlessly with the Shopping Carts you know and love. From PrestaShop to ZenCart and everything in between.</p>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane as="div" eventKey="tab_multiple_payment" role="tabpanel">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-sm-12">
                        <h4 className="fw-n">Simplifying your payroll – one click is all it takes</h4>
                        <div className="c-gray line-height-2">
                          <p>With Mass Pay, you can complete your corporate disbursements with just one click. Upload your payroll spreadsheet and send money to just a few people, or a few hundred!</p>
                          <p>Use Mass Pay to send money to employees, freelancers, contractors, affiliates, suppliers and much more. Keep track of your payments with PayBit&lsquo;s detailed Transaction History.</p>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane as="div" eventKey="tab_get_paid" role="tabpanel">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-sm-12">
                        <h4 className="fw-n">Get paid your way with PayBit’s online payment solutions!</h4>
                        <div className="c-gray line-height-2">
                          <p><strong>More choice means better conversions</strong></p>
                          <p>Your customers can pay you instantly using their e-wallet, their credit card, or a variety of local payment solutions tailored to their country. When you give your customers choice, you’ll keep them happy and improve your bottom line.</p>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </ReactWOW>
      </div>
    </section>
    <section className="pY-70 bgc-grey">
      <div className="container">
        <div className="text-center mB-60">
          <h4 className="fw-n">Digital Wallet Checkout</h4>
          <p className="c-text-gray">As one of the world&lsquo;s largest digital wallet providers, PayBit makes it easy for consumers to pay quickly with just an email address and password.</p>
        </div>
        <ReactWOW animation="fadeInUp">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3 text-center mB-20 box-hover box-shadow-mb box-shadow-mb">
              <div className="text-center mB-20">
                <div className="circle-107 m-auto hover-boxshadow">
                  <i className="fsz-25 c-blue icon-e-mail" />
                  <span>1</span>
                </div>
              </div>
              <h5 className="fw-n">E - Mail &amp; Password</h5>
              <p className="c-text-gray">Consumers pay quickly with just an email and password</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 text-center mB-20 box-hover box-shadow-mb box-shadow-mb">
              <div className="text-center mB-20">
                <div className="circle-107 m-auto hover-boxshadow">
                  <i className="fsz-25 c-blue icon-tap-payment" />
                  <span>2</span>
                </div>
              </div>
              <h5 className="fw-n">1 - Tap Payments</h5>
              <p className="c-text-gray">Mobile optimised payments ideal for in play and micro-payments.</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 text-center mB-20 box-hover box-shadow-mb box-shadow-mb">
              <div className="text-center mB-20">
                <div className="circle-107 m-auto hover-boxshadow">
                  <i className="fsz-25 c-blue icon-mapline" />
                  <span>3</span>
                </div>
              </div>
              <h5 className="fw-n">Local Payments</h5>
              <p className="c-text-gray">100+ local payment methods</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 text-center mB-20 box-hover box-shadow-mb box-shadow-mb">
              <div className="text-center mB-20">
                <div className="circle-107 m-auto hover-boxshadow">
                  <i className="fsz-25 c-blue icon-banks" />
                  <span>4</span>
                </div>
              </div>
              <h5 className="fw-n">Banks</h5>
              <p className="c-text-gray">Integrated with over 170 banks worldwide</p>
            </div>
          </div>
        </ReactWOW>
        <p className="text-center mT-50 c-text-gray">Not sure what you need? Sign up and your own personal Account Manager will help you.</p>
      </div>
    </section>
    <section className="pY-60 bgc-white">
      <div className="container">
        <ReactWOW animation="fadeInUp">
          <div className="row no-gutters">
            <div className="col-sm-12 col-md-6 p-40 bd bgc-white">
              <div className="d-flex d-md-inline-block w-100 box-hover ">
                <div className="circle-107 mB-10 mR-25 mX-md-auto hover-boxshadow">
                  <i className="fsz-25 c-gray icon-visa" />
                </div>
                <div className="width-100-135 w-md-100">
                  <h5 className="fw-n">Find out More</h5>
                  <p className="c-text-gray line-height-2">
  One simple integration gives you
                    <br />
                    {' '}
  access to 100+ payment methods,
                    <br />
                    {' '}
  globally.
                  </p>
                  <a href="/" className="btn btn-lg btn-primary">
  Find out More
                    {' '}
                    <i className="mL-25 icon-arrow-right-circle" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 p-40 bd bgc-white">
              <div className="d-flex d-md-inline-block w-100 box-hover">
                <div className="circle-107 mB-10 mR-25 mX-md-auto hover-boxshadow">
                  <i className="fsz-25 c-gray icon-development" />
                </div>
                <div className="width-100-135 w-md-100">
                  <h5 className="fw-n">Developer Resources</h5>
                  <p className="c-text-gray line-height-2">There’s a public API to test against, comprehensive documentation and our integration team to help get you up and running.</p>
                  <a href="/" className="btn btn-lg btn-primary">
                    More on Integration
                    <i className="mL-25 icon-arrow-right-circle" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 p-40 bd bgc-white">
              <div className="d-flex d-md-inline-block w-100 box-hover">
                <div className="circle-107 mB-10 mR-25 mX-md-auto hover-boxshadow">
                  <i className="fsz-25 c-gray icon-get-paid" />
                </div>
                <div className="width-100-135 w-md-100">
                  <h5 className="fw-n">Get Paid for Referrals</h5>
                  <p className="c-text-gray line-height-2">Become a Paybit Affiliate and earn revenue from bringing new customers to us. That way everyone benefits.</p>
                  <a href="/" className="btn btn-lg btn-primary">
                    More on Paybit Affiliate
                    <i className="mL-25 icon-arrow-right-circle" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 p-40 bd bgc-white">
              <div className="d-flex d-md-inline-block w-100 box-hover">
                <div className="circle-107 mB-10 mR-25 mX-md-auto hover-boxshadow">
                  <i className="fsz-25 c-gray icon-developments" />
                </div>
                <div className="width-100-135 w-md-100">
                  <h5 className="fw-n">Our Logo and Brand</h5>
                  <p className="c-text-gray line-height-2">We’ve got all the latest resources you need for your site in embeddable HTML code. Just log in or sign up to a Skrill account to access them.</p>
                  <a href="/" className="btn btn-lg btn-primary">
                    Visit the Brand Centre
                    <i className="mL-25 icon-arrow-right-circle" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
      <div className="our-product-item-50">
        <ReactWOW animation="fadeInLeft">
          <div className="our-product-item-img">
            <div style={{ backgroundImage: 'url(/images/laptop-mobile-mockup.png)' }} />
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInRight">
          <div className="our-product-item-text">
            <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
            <h3 className="mB-15 fw-n">Get started with Paybit.</h3>
            <p className="c-text-gray mB-15">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</p>
            <div className="d-inline-block mT-20 w-100">
              <div className="mY-20 d-flex align-items-center">
                <div className="circle-center-50 c-blue bgc-o-blue mR-15">1</div>
                <div className="c-4d fsz-md w-100-65">Complete the short online application form.</div>
              </div>
              <div className="mY-20 d-flex align-items-center">
                <div className="circle-center-50 c-orange bgc-o-black mR-15">2</div>
                <div className="c-4d fsz-md w-100-65">If approved and you accpet, you’ll have a credit limit attached to your PayBit account almost immediately. </div>
              </div>
              <div className="mY-20 d-flex align-items-center">
                <div className="circle-center-50 c-4d bgc-o-black mR-15">3</div>
                <div className="c-4d fsz-md w-100-65">Start spending straigtaway at thousands of online stores with PayBit Credit.</div>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
    </section>
    <ReactWOW animation="fadeInUp">
      <section className="pY-60 bgc-dark2 c-white">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-6">
              <h2 className="fsz-30 mB-20">No hidden costs, no surprises.</h2>
              <p className="fsz-def c-text-gray line-height-2">When you purchase with PayBit there are no hidden costs or processing fees, so you can enjoy your shopping. Currency conversion fees may apply.</p>
              <a href="/" className="c-white fsz-def"><b className="fw-n">More about fees</b></a>
            </div>
          </div>
        </div>
      </section>
    </ReactWOW>
    <SectionPartner backgroundImage="/images/testimonials-bg2.jpg" />
  </>
);

export default compose(
  withAuth,
)(BusinessTour);
