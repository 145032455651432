import { compose, lifecycle } from 'recompose';

export default compose(
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    },
  }),
)(({ children }) => children);
