import { compose, withHandlers, withState } from 'recompose';
import upperFirst from 'lodash/upperFirst';

export default ({
  dropdownName = 'value',
  defaultGetter = () => null,
  setter = () => () => null,
}) => {
  const name = upperFirst(dropdownName);
  const setterName = `setSelected${name}`;
  return compose(
    withState(`selected${name}`, setterName, defaultGetter()),
    withHandlers({
      [`change${name}`]: props => (selectedValue) => {
        const { [setterName]: stateSetter } = props;
        setter(props)(selectedValue);
        stateSetter(selectedValue);
      },
    }),
  );
};
