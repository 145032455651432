export default {
  header: {
    'Paybit Products': 'Paybit Products',
    Products: 'Products',
    Services: 'Services',
    Apps: 'Apps',
    Wallet: 'Wallet',
    Marketcap: 'Marketcap',
    Business: 'Business',
    'Fraud Detection': 'Fraud Detection',
    Explorer: 'Explorer',
    Careers: 'Careers',
    'Crypto Market': 'Crypto Market',
    Marketplace: 'Marketplace',
    Referral: 'Referral',
    'Personal Tour': 'Personal Tour',
    'HOW WE WORK': 'HOW WE WORK',
    'Paybit Services': 'Paybit Services',
    'Pricing & Packaging': 'Pricing & Packaging',
    'About Us': 'About Us',
    'Invoice & Billing': 'Invoice & Billing',
    'Paybit Status': 'Paybit Status',
    Merchants: 'Merchants',
    'Business solutions': 'Business solutions',
    Common: 'Common',
    'Message Center': 'Message Center',
    'Paybit APPS': 'Paybit APPS',
    'Android Phone': 'Android Phone',
    'Android Tablet': 'Android Tablet',
    Desktop: 'Desktop',
    'Apple Mac': 'Apple Mac',
    'Apple Ipad': 'Apple Ipad',
    'Apple Iphone': 'Apple Iphone',
    'Send, Receive, and Trade': 'Send, Receive, and Trade',
    'Institutional Portal': 'Institutional Portal',
    'Get your money faster, easier and cheaper': 'Get your money faster, easier and cheaper',
    'Manage your business on the go.': 'Manage your business on the go.',
    'Get your money faster, easier and cheaper with PayBit.': 'Get your money faster, easier and cheaper with PayBit.',
    Pricing: 'Pricing',
    'Transparent pricing for all businesses': 'Transparent pricing for all businesses',
    Company: 'Company',
    Support: 'Support',
    Blog: 'Blog',
    'Help & Support': 'Help & Support',
    'Contact Us': 'Contact Us',
    Solutions: 'Solutions',
  },
  footer: {
    Wallet: 'Wallet',
    Business: 'Business',
    Products: 'Products',
    Solutions: 'Solutions',
    Apps: 'Apps',
    'Help & Support': 'Help & Support',
    'Pricing & Packaging': 'Pricing & Packaging',
    'Contact Us': 'Contact Us',
    'Software Companies': 'Software Companies',
    'SaaS Companies': 'SaaS Companies',
    Startups: 'Startups',
    Games: 'Games',
    Application: 'Application',
    Ecommerce: 'Ecommerce',
    About: 'About',
    Company: 'Company',
    Features: 'Features',
    Platform: 'Platform',
    'Commerce Resources': 'Commerce Resources',
    'Payment System': 'Payment System',
    Multiple: 'Multiple',
    Merchants: 'Merchants',
    Micropayments: 'Micropayments',
    'Invoice & Billing': 'Invoice & Billing',
    'Fraud Detection': 'Fraud Detection',
    Fees: 'Fees',
    'Paybit for Business': 'Paybit for Business',
    'Login to Paybit': 'Login to Paybit',
    'Paybit Status': 'Paybit Status',
    Marketplace: 'Marketplace',
    Tour: 'Tour',
    'Become Merchant': 'Become Merchant',
    'Market Cap': 'Market Cap',
    'Product Datasheets': 'Product Datasheets',
    'Customer Case studies': 'Customer Case studies',
    'Developer Portal': 'Developer Portal',
    'Knowledge Center': 'Knowledge Center',
    'Privacy Policy': 'Privacy Policy',
    'Terms & Conditions': 'Terms & Conditions',
    'Refund Policy': 'Refund Policy',
    Sitemap: 'Sitemap',
    'Cookie Policy': 'Cookie Policy',
  },
  'langding page': {
    title: 'Paybit — online payments made easy',
    'Paybit Solutions for Online, Mobile and In-Store': 'Paybit Solutions for Online, Mobile and In-Store',
    'Available Currencies': 'Available Currencies',
    'Transfer in Secound': 'Transfer in Secound',
    'Mighty Protection': 'Mighty Protection',
    'Easy to Use': 'Easy to Use',
    '24/7 Support': '24/7 Support',
    'Organized Payments': 'Organized Payments',
    'Start Selling ': 'Start Selling ',
    'Start Shopping ': 'Start Shopping ',
    'Download our Tools': 'Download our Tools',
    'Download for App Store': 'Download for App Store',
    'Buyers make purchases': 'Get started now with your bitcoin wallet. Monetize your virtual currency payments',
    'Add Paybit to Your Mobile Site and App': 'Add Paybit to Your Mobile Site and App',
    'Add Paybit to Your Website': 'Add Paybit to Your Website',
    'Accept Paybit in Your Store': 'Accept Paybit in Your Store',
    'Conditions apply': 'Shop with peace of mind, knowing we protect your eligible purchases. If an eligible item doesn’t show up, or turns out to be different than described, we’ll help sort things out with the seller. Conditions apply.',
    'We offer': 'We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold. Nobody needs to worry to get problems, read our Buyer and Protection details for more information.',
    'Apps with Paybit': `Buyers make purchases on merchants’ websites and in merchants’ Apps with Paybit. Paybit will deduct the amount of a payment from the buyer's Paybit account in real-time in CNY and settle the payment to the merchant in a Crypto
    currency.`,
    benefits: 'benefits',
    simple: 'simple',
    Get: 'Get everything you need',
    got: 'We got you covered',
    support: 'We support every crypto currency possible. From BTC to ETH, from EON to NEO. Currencys are converted automaticly.',
    better: "Payments can't get better. Pay anyone with just one simple click. Everything is done in lightspeed.",
    'dont worry': 'You dont need to worry. With our PayBit Buyer Protection. you have not to worry about any problems.',
    'PayBit is simple': 'PayBit is simple. You can pay with just a bit. After signup, a short verification is needed. After that you can start paying and selling.',
    'Our large staff': 'Our large staff is always there when needed. No matter how big or small your problem is we will do our best to solve everything.',
    'With our simple': 'With our simple but yet mighty Dashboard organizing payments has reached a new level Everything needed for both business and normal clients',
    fast: 'fast',
    'Payment in a few clicks': 'Payment in a few clicks',
    'Millions of customers': 'Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.',
    Pay: 'Pay on Websites',
    'Pay multiple': 'Pay multiple Clients/Customers',
    'Great sucess': 'Great sucess you made',
    pp: 'the payment!',
    'Send money': 'Send money online or with your mobile.',
    'Send money de': "Send money to almost anyone with an email address. You can send all around the world and they'sll be delighted to get your message. Currency conversion fees may apply.",
    'Shop around the world.': 'Shop around the world.',
    'Shop around': 'Shop around the world.',
    'Shop around de': 'With PayBit you can shop at thousands of stores around the world in just a few easy clicks, knowing your card details are never shared with the seller. Currency conversion fees may apply.',
    '#1 Wallet': '#1 Wallet',
    '#1 Wallet de': 'With over 28 million wallets, $200B transacted, and the lowest fees in the industry, it’s no wonder we’re the most trusted cryptocurrency wallet.',
    Security: 'Security',
    'Send & Receive': 'Send & Receive',
    'Buy, Sell, & Exchange': 'Buy, Sell, & Exchange',
    'We have': 'We have more stuff and features to offer for you',
    'Send multiple Payments': 'Send multiple Payments',
    'Tap into all your money.': 'Tap into all your money.',
    'Multiple developers APIs': 'Multiple developers APIs',
    'You are secured': 'You are secured',
    'Transfer in Secounds': 'Transfer in Secounds',
    'Send and request money fast.': 'Send and request money fast.',
    'Manage your money easily.': 'Manage your money easily.',
    'Collect money from groups.': 'Collect money from groups.',
    'Payments can not get simpler': 'Payments can not get simpler! You can pay multiply clients, affiliates or customers at once with one simple click.',
    'Use our mobile tools': 'Use our mobile tools and panels to have always a overview of your business and money.',
    'We have solutions': ' We have solutions for a big variety of ecommerce systems and many developer APIs for each possible solution.',
    'it free': 'It’s free to send money to friends and family in the U.S.',
    'The improved Paybit': 'The improved Paybit app makes it simple to send and request money, manage credit, pool cash from peers.',
    Whether: "Whether you're collecting money for group gifts, travel, or special events.",
    'Click`': 'Click on the link bellow and start using',
    'Save unlimited': 'Save unlimited web images, videos, and text from the web with Cloud.co Save',
    'Download Toolsbars for browser': 'Download Toolsbars for browser',
    'Download for Google Play': 'Download for Google Play',
    'Access all': 'Access all your photos, videos and documents anywhere, at any time, from any device',
    'Your money': 'Your money is on its way in a few clicks.',
    'Sign Up': 'Sign Up',
    'Our Apps': 'Our Apps',
  },
  YourMoney: {
    'Your money': 'Your money is on its way in a few clicks.',
    'We offer': 'We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold. Nobody needs to worry to get problems, read our Buyer and Protection details for more information.',
    'Millions of payments processed': 'Millions of payments processed',
    'Get Started': 'Milion of customer around the world use us for one simple reason',
    'And even more clients': 'And even more clients',
    'We’ll help': 'We’ll help sort things out with the seller. Conditions apply.',
    'About 5000': 'About 5000 API integrations',
    'Over 500.000': 'Over 500.000 API Integrations',
    Monitoring: 'Monitoring',
    'With a few clicks you': 'With a few clicks you can generate and view hundreads of payments.',
  },
  CurrencySlider: {
    Virtual: 'Virtual & Crypto Currency payments',
    'Last updated': 'Last updated market information',
  },
  WeGotCoveredSlider: {
    'We got you covered.': 'We got you covered.',
    'Shop with peace': 'of mind, knowing we protect your eligible purchases. If an eligible item doesn&lsquo;t show up, or turns out to be different than described, we’ll help sort things out with the seller. Conditions apply.',
    'More about PayBit': 'More about PayBit Buyer Protection',
    'Learn More': 'Learn More',
  },
  SectionPartner: {
    'We offer': '“We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold. Nobody needs to worry.”',
  },
  ProductTabs: {
    'Personal Product': 'Personal Product',
    Merchants: 'Merchants',
    Partners: 'Partners',
    'Collect payments': 'Collect payments on web & mobile from Chinese consumers.',
    'Paybit transaction': 'Paybit transaction declaration interface for China customs.',
    'Cross-border Online Payment': 'Cross-border Online Payment',
    'Customs Declaration': 'Customs Declaration',
    'Cross-border In-Store Payment In-Store': 'Cross-border In-Store Payment In-Store',
    Mobile: 'Mobile',
    'In - Store': 'In - Store',
    'Go see more': 'Go see more',
  },
  Sidebar: {
    Dashboard: 'Dashboard',
    Wallets: 'Wallets',
    Transactions: 'Transactions',
    Statistics: 'Statistics',
    Settings: 'Settings',
    Help: 'Help',
  },
  ToggleMenu: {
    Index: 'Index',
    'Send Money': 'Send Money',
    Notifications: 'Notifications',
    Settings: 'Settings',
    Messages: 'Messages',
    Contacts: 'Contacts',
  },
  UserStep: {
    'Your next steps': 'Your next steps',
    finished: '{{step}} finished',
    Address: 'Address',
    'Connect Bank Account': 'Connect Bank Account',
    'Upload Tax Documents': 'Upload Tax Documents',
    'Deposit Money': 'Deposit Money',
    'Fill your address and contact info': 'Fill your address and contact info',
    'You have connected 2 accounts': 'You have connected 2 accounts',
    'You uploaded W-2 and 1099': 'You uploaded W-2 and 1099',
    'You can deposit from your bank': 'You can deposit from your bank',
    'Server time': 'Server time: {{time}}',
    Action: 'Action',
    'Another action': 'Another action',
    'Something else here': 'Something else here',
    'Separated link': 'Separated link',
    PayBit: '© 2018 Pay`Bit All rights reserved.',
    Security: 'Security',
    'Terms & Conditions': 'Terms & Conditions',
  },
  UserDropdown: {
    Message: 'Message',
    Profile: 'Profile',
    'Account Settings': 'Account Settings',
    Notifications: 'Notifications',
    Contacts: 'Contacts',
    Security: 'Security',
    Merchants: 'Merchants',
    Developers: 'Developers',
    'Lock Account': 'Lock Account',
    'Sign Out': 'Sign Out',
    'Paybit Balance': 'Paybit Balance',
  },
  DownloadAppleMac: {
    title: 'Paybit - Download Apple Mac',
    Millions: 'Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.',
    free: 'It’s free to send money to friends and family in the U.S. using your bank account or Paybit balance. Pay your share of the bill or request money from friends with ease.',
    find: 'Find our more',
    'Send and request money fast.': 'Send and request money fast.',
    'Manage your money easily.': 'Manage your money easily.',
    'Collect money from groups.': 'Collect money from groups.',
    Message: 'The improved Paybit app makes it simple to send and request money, manage credit, pool cash from peers, and create savings goals. Plus, you can easily track and monitor every transaction you make.',
    Whether: "Whether you're collecting money for group gifts, travel, or special events, Money Pools make it easy to create a custom page that allows family, friends, and coworkers to chip in for big-ticket items.",
    'Get up to speed on getting paid.': 'Get up to speed on getting paid.',
    'Stay on top of your business': 'Stay on top of your business',
    'Mobilize how you do business.': 'Mobilize how you do business.',
    'Create and send invoices in the moment, so customers can pay right away.': 'Create and send invoices in the moment, so customers can pay right away.',
    'Whether you need to transfer funds, view transaction details.': 'Whether you need to transfer funds, view transaction details.',
    'Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S.': 'Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S.',
  },
  OurProdcut: {
    'Getting started is easy.': 'Getting started is easy.',
    'Main Features': 'Main Features',
    'Key-in': '*Key-in transactions and sales over $500 in a 7-day period made with the Mobile Card Reader are subject to an automatic 30-day reserve where funds are held in your Paybit account to cover the high risk associated with these types of transactions. For increased protection from fraudulent transactions, we recommend using a chip card reader.',
    'All Paybit': 'All Paybit accounts are subject to policies that can lead to account restrictions in the form of holds, limitations, or reserves.',
    Millions: '“Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.',
    Looking: 'Looking for help or consulting? Fill the form below or email us. We will respond you as soon as possible.',
    Security: 'Security',
    Rest: 'Rest easy knowing your crypto is secured with the world’s most trusted crypto wallet. We give you full control, back up your funds, and protect them from unauthorized access.',
    'Send & Receive': 'Send & Receive',
    'Buy, Sell, & Exchange': 'Buy, Sell, & Exchange',
    'Access Anywhere': 'Access Anywhere',
    'Simplified disputes resolution': 'Simplified disputes resolution',
    'All information on one screen': 'All information on one screen',
    Whether: 'Whether you’re sending to a friend or transacting for business, PayBit allows you to send and receive cryptocurrencies with the lowest fees.',
    Easily: 'Easily exchange crypto-to-crypto within your PayBit Wallet. We now offer buy and sell for users in many countries looking to invest in crypto without leaving your secured wallet experience.',
    Transform: 'Transform the financial system right from your pocket! Your Paybit Wallet is available anywhere - ready to be on the go, just like you are.',
    'No need to worry': 'No need to worry about market volatility — you always get what you asked for.',
    'This makes our integration': 'This makes our integration risk and hassle-free for merchants and their business.',
  },
  Acccount: {
    title: 'Acccount',
    sub: 'Get familiar with our account system.',
  },
  SecurityNPrivacy: {
    title: 'Security & Privacy',
    sub: 'We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold.',
  },
  FAQ: {
    q1: 'How do I register a PayBit Account?',
    a1: 'Its easy as nothing. Just enter your email, signup for free. For real money transaction we need you to do a verification.',
    q2: 'I represent a company. Can I register a business account?',
    a2: 'If have access to your account, go to Dashboard, check the list of sessions for suspicious activity. If something is wrong you can always eliminate any session and change password. ',
    q3: 'I still have not found a solution to my problem. Can you help me?',
    a3: 'Check our Help Center or contact our Live Support for more help',
    q4: 'I think my account was hacked. What should I do?',
    a4: 'Security is one of our priorities. If you think your security was breached, please contact our support.',
    q5: 'I want to change account\'s password. How do I do this?',
    a5: 'If have access to your account, go to Dashboard, check the list of sessions for suspicious activity. If something is wrong you can always eliminate any session and change password.<br /> If you have lost acess to you account please immediately contact with our Support Team. We will help you restore access and prevent any suspicious actions.',
    q6: 'I still have not found a solution to my problem. Can you help me?',
    a6: 'Check our Help Center or contact our Live Support for more help',
  },
};
