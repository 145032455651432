import React from 'react';
import { compose } from 'recompose';
import Countup from 'react-countup';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import TinyBarChart from '../components/TinyBarChart';
import TransactionCharts from '../components/TransactionCharts';
import TinyLineChart from '../../common/Charts/TinyLineChart';
import ModalTransactionDetail from './ModalTransactionDetail';
import withModal from '../../../hocs/withModal';

const Transactions = ({ isOpenModal, openModal, closeModal }) => (
  <section>
    <Helmet>
      <title>Paybit - Transactions</title>
    </Helmet>
    <div className="p-20 bgc-grey main-content-container">
      <div className="main-content">
        <div className="row">
          <div className="col-md-12 col-lg-3 col-xl-3">
            <div className="mB-20">
              <div className="d-flex justify-content-between align-items-center mB-20">
                <h5 className="pY-10 mB-5"><b className="fw-n">Your Turnover</b></h5>
                <a href="/" className="c-gray fsz-sm">See More</a>
              </div>
              <div className="bg-white bd bdrs-3">
                <div className="p-20 bdB text-center ">
                  <div className="d-flex justify-content-center mB-10">
                    <span className="c-gray fsz-sm mR-10">Your Turnover</span>
                    <a href="/" className="size-20 bd bdrs-50p d-flex justify-content-center align-items-center"><i className="icon-down-arrow fsz-xxs c-black" /></a>
                  </div>
                  <h1 className="mB-15"><b className="fw-n">$3022.00</b></h1>
                  <div className="mB-20">
                    <span className="c-gray mR-10">This Month</span>
                    <b className="fw-n c-green">$1245 Earned</b>
                  </div>
                  <div className="turnover-chart">
                    <TinyBarChart />
                  </div>
                </div>
                <div className="pY-25 pX-20">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="mb-10"><b className="fw-n">$522</b></h5>
                      <span className="c-gray fsz-sm">Expenses</span>
                      <div className="progress mT-10">
                        <div className="progress-bar" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '80%' }} />
                      </div>
                    </div>
                    <div className="col-6">
                      <h5 className="mb-10"><b className="fw-n">$800</b></h5>
                      <span className="c-gray fsz-sm">Earned</span>
                      <div className="progress mT-10">
                        <div className="progress-bar bgc-green" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '80%' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6">
            <div className="mB-20">
              <div className="d-flex justify-content-between align-items-center mB-20">
                <h5><b className="fw-n"> Transaction</b></h5>
                <div className="input_group">
                  <i className="mL-10 far fa-calendar-check" />
                  <input type="date" placeholder="Select Date" className="form-control bdn" />
                  <button className="btn btn-primary">Search</button>
                </div>
              </div>
              <div className="bg-white bd p-15 bdrs-3">
                <div className="d-flex justify-content-between">
                  <div className="dropdown dropdown-coin">
                    <div className="coin-header">
                      <span className="coin-icon coin-icon-20" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                      <span>Bitcoin = </span>
                      <span className="coin-value"><Countup end={1.1549} prefix="" suffix=" USD" separator="," decimal="." duration={3} decimals={4} /></span>
                    </div>
                    <button className="btn dropdown-toggle no-after" type="button" id="dropdownBTC" data-toggle="dropdown">
                      <i className="fas fa-chevron-down" />
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownBTC">
                      <a href="/" className="dropdown-item">Bitcoin</a>
                    </div>
                  </div>
                  <ul className="lis-n p-0 mL-10 mb-0 list-tab-history">
                    <li className="float-left"><a href="/" className="btn btn-white btn-around">Today</a></li>
                    <li className="float-left active"><a href="/" className="btn btn-white btn-around">7 Days</a></li>
                    <li className="float-left"><a href="/" className="btn btn-white btn-around">14 Days</a></li>
                    <li className="float-left"><a href="/" className="btn btn-white btn-around">Last Month</a></li>
                  </ul>
                </div>
                <div className="transactions-chart">
                  <TransactionCharts />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-3 col-xl-3">
            <div className="mB-20">
              <div className="d-flex justify-content-between align-items-center mB-20">
                <h5 className="pY-10 mB-5"><b className="fw-n"> Wallet Coins</b></h5>
                <a href="/" className="c-gray fsz-sm td-u">See the list of your wallets</a>
              </div>
              <div>
                <div className="wallet-coin-item mB-5">
                  <div className="bd bgc-white bdrs-3">
                    <div className="pY-10 pL-25 d-flex align-items-center justify-content-between">
                      <div>
                        <div className="d-flex mB-10">
                          <span className="coin-icon coin-icon-38 mR-20" style={{ backgroundImage: 'url(/images/coins/bitcoin-38.png)' }} />
                          <span className="whs-nw">
                            <b className="fw-n">BTC</b>
                            <br />
                            <span className="c-gray"><Countup end={5255.15} prefix="$ " separator="," decimal="." duration={2.75} decimals={2} /></span>
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="coin-icon-38 text-center c-green fsz-xl mR-20"><i className="icon-arrow_up" /></span>
                          <span className="whs-nw">
                            <b className="fw-n"><Countup end={2624.9} prefix="$ " separator="," decimal="." duration={2.75} decimals={1} /></b>
                            <br />
                            <span className="c-gray"><Countup end={0.567} suffix=" coin" separator="," decimal="." duration={3} decimals={3} /></span>
                          </span>
                        </div>
                      </div>
                      <div className="chart-line mL-30 chart-line--lg">
                        <TinyLineChart color="#00C300" />
                      </div>
                    </div>
                    <div className="pX-20 pY-10 d-flex">
                      <div className="mR-15">
                        <p className="c-gray mb-0">Wallet Addresses</p>
                        <small><b>12 </b></small>
                      </div>
                      <div>
                        <p className="c-gray mb-0">Last Transaction</p>
                        <small><b>12.12.2018 - 22.22 </b></small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wallet-coin-item">
                  <div className="bd bgc-white bdrs-3">
                    <div className="pY-10 pL-25 d-flex align-items-center justify-content-between">
                      <div>
                        <div className="d-flex mB-10">
                          <span className="coin-icon coin-icon-38 mR-20" style={{ backgroundImage: 'url(/images/coins/ripple-38.png)' }} />
                          <span className="whs-nw">
                            <b className="fw-n">XRP</b>
                            <br />
                            <span className="c-gray"><Countup end={5255.15} prefix="$ " separator="," decimal="." duration={2.75} decimals={2} /></span>
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="coin-icon-38 text-center c-red fsz-xl mR-20"><i className="icon-arrow_down" /></span>
                          <span className="whs-nw">
                            <b className="fw-n"><Countup end={2624.9} prefix="$ " separator="," decimal="." duration={2.75} decimals={1} /></b>
                            <br />
                            <span className="c-gray"><Countup end={0.567} suffix=" coin" separator="," decimal="." duration={3} decimals={3} /></span>
                          </span>
                        </div>
                      </div>
                      <div className="chart-line mL-30 chart-line--lg">
                        <TinyLineChart color="#FF000A" />
                      </div>
                    </div>
                    <div className="pX-20 pY-10 d-flex">
                      <div className="mR-15">
                        <p className="c-gray mb-0">Wallet Addresses</p>
                        <small><b>12 </b></small>
                      </div>
                      <div>
                        <p className="c-gray mb-0">Last Transaction</p>
                        <small><b>12.12.2018 - 22.22 </b></small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-4 mB-20">
            <div className="bdrs-6 bd pY-30 pX-40 bg-white">
              <div className="d-flex justify-content-between mB-30">
                <div>
                  <h5><b className="fw-n">Transactions</b></h5>
                  <p className="c-gray fsz-sm">Since Last Week</p>
                </div>
                <div>
                  <a href="/" className="btn btn-o-green btn-around">
                    <i className="fas fa-caret-up mR-10" />
15%
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <span className="mR-30 c-blue fsz-sup">
                  <i className="icon-requests" />
                </span>
                <span className="fsz-sup">+125</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 mB-20">
            <div className="bdrs-6 bd pY-30 pX-40 bg-white">
              <div className="d-flex justify-content-between mB-30">
                <div>
                  <h5><b className="fw-n">Impressions</b></h5>
                  <p className="c-gray fsz-sm">Since Last Week</p>
                </div>
                <div>
                  <a href="/" className="btn btn-o-green btn-around">
                    <i className="fas fa-caret-up mR-10" />
18%
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <span className="mR-30 c-orange fsz-sup">
                  <i className="icon-users" />
                </span>
                <span className="fsz-sup">621</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 mB-20">
            <div className="bdrs-6 bd pY-30 pX-40 bg-white">
              <div className="d-flex justify-content-between mB-30">
                <div>
                  <h5><b className="fw-n">Income</b></h5>
                  <p className="c-gray fsz-sm">Since Last Week</p>
                </div>
                <div>
                  <a href="/" className="btn btn-o-red btn-around">
                    <i className="fas fa-caret-down mR-10" />
22%
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <span className="mR-30 c-green fsz-sup">
                  <i className="icon-money-withdraw" />
                </span>
                <span className="fsz-sup">4.001</span>
              </div>
            </div>
          </div>
        </div>
        <div className="box-style bd bgc-white mB-20 bdrs-6">
          <div className="box-header box-last-activity-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center box-last-activity-header-left">
                <h5>
                  <b className="fw-n mR-10">Last activity </b>
                  <span className="fsz-sm c-gray">Year 2018</span>
                </h5>
                <div className="mL-30">
                  <a className="c-gray" href="/">(Show All)</a>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="mR-15">
                  <div className="input_group">
                    <i className="mL-10 icon-search" />
                    <input type="text" placeholder="Search now" className="form-control bdn" />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
                <div>
                  <div className="input_group">
                    <i className="mL-10 far fa-calendar-check" />
                    <input type="date" placeholder="Select Date" className="form-control bdn" />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Tab.Container defaultActiveKey="tab_alltransaction" className="box-body pY-20">
            <div className="tab-common pX-25 bgc-white">
              <div className="d-flex align-items-center justify-content-between">
                <Nav className="nav nav-tabs tab-md" id="last_activityTab" role="tablist">
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction" role="tab">All Transaction </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_transactions_1" role="tab" aria-selected="false">Recieved </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_transactions_2" role="tab" aria-selected="false">Sent </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_transactions_3" role="tab" aria-selected="false">Withdrawn </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_transactions_4" role="tab" aria-selected="false">Logins </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_transactions_5" role="tab" aria-selected="false">Exchanges </Nav.Link>
                  </Nav.Item>
                </Nav>
                <div>
                  <button className="btn btn-transparent mR-20">
                    <i className="c-green icon-export mR-10" />
                    Export Table
                  </button>
                  <button className="btn btn-transparent">
                    <i className="c-yellow icon-print mR-10" />
                    Print
                  </button>
                </div>
              </div>
            </div>
            <div className="tab-content p-0" id="last_activityTabContent">
              <Tab.Pane className="tab-pane" eventKey="tab_alltransaction" role="tabpanel">
                <div className="table-responsive scrollable max-h-730">
                  <table className="table table-common table-hover table-refferal">
                    <thead>
                      <tr>
                        <th>Name/Email</th>
                        <th>Date</th>
                        <th>Action</th>
                        <th>Status</th>
                        <th>Details</th>
                        <th>Amount</th>
                        <th>Transaction ID</th>
                        <th className="pX-10">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="pX-20">
                      <tr>
                        <td>
                          <a className="name-email c-black d-flex align-items-center" href="#transaction-detail-modal" onClick={openModal} data-toggle="modal" data-target="#transaction-detail-modal">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                            <div className="mL-20">
                              <span>
                                Bruce Springesteen
                                <i className="icon-contacts mL-10 c-blue" />
                              </span>
                              <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                            </div>
                          </a>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                            <span>
Sent to
                              <br />
                              <span className="fsz-xs c-grey">Bitcoin Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-green fsz-md fas fa-check-circle mR-5" />
Complete
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-red">
                            {' '}
                            <Countup end={-800} suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                      <tr>
                        <td>
                          <a className="name-email c-black d-flex align-items-center" href="#transaction-detail-modal" onClick={openModal} data-toggle="modal" data-target="#transaction-detail-modal">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                            <div className="mL-20">
                              <span>James Blunt</span>
                              <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                            </div>
                          </a>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/ethereum.png)' }} />
                            <span>
Received
                              <br />
                              <span className="fsz-xs c-grey">From Ethereum Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-green fsz-md fas fa-check-circle mR-5" />
Complete
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-green">
                            {' '}
                            <Countup end={1800.00} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                      <tr>
                        <td>
                          <a className="name-email c-black d-flex align-items-center" href="#transaction-detail-modal" onClick={openModal} data-toggle="modal" data-target="#transaction-detail-modal">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                            <div className="mL-20">
                              <span>Cristopter Smith</span>
                              <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                            </div>
                          </a>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/monero.png)' }} />
                            <span>
Received
                              <br />
                              <span className="fsz-xs c-grey">From Monero Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-red fsz-md fas fa-times-circle mR-5" />
Cancelled
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-green">
                            {' '}
                            <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                      <tr>
                        <td>
                          <a className="name-email c-black d-flex align-items-center" href="#transaction-detail-modal" onClick={openModal} data-toggle="modal" data-target="#transaction-detail-modal">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                            <div className="mL-20">
                              <span>James Blunt</span>
                              <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                            </div>
                          </a>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/litecoin.png)' }} />
                            <span>
Send to
                              <br />
                              <span className="fsz-xs c-grey">From Litecoin Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-green fsz-md fas fa-check-circle mR-5" />
Complete
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-red">
                            {' '}
                            <Countup end={1800.00} prefix="-" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                      <tr>
                        <td>
                          <a className="name-email c-black d-flex align-items-center" href="#transaction-detail-modal" onClick={openModal} data-toggle="modal" data-target="#transaction-detail-modal">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                            <div className="mL-20">
                              <span>James Blunt</span>
                              <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                            </div>
                          </a>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/ethereum.png)' }} />
                            <span>
Received
                              <br />
                              <span className="fsz-xs c-grey">From Ethereum Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-yellow fsz-md fas fa-exclamation-circle mR-5" />
Pending
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-green">
                            {' '}
                            <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                      <tr>
                        <td>
                          <a className="name-email c-black d-flex align-items-center" href="#transaction-detail-modal" onClick={openModal} data-toggle="modal" data-target="#transaction-detail-modal">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                            <div className="mL-20">
                              <span>Cristopter Smith</span>
                              <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                            </div>
                          </a>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/monero.png)' }} />
                            <span>
Received
                              <br />
                              <span className="fsz-xs c-grey">From Monero Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-red fsz-md fas fa-times-circle mR-5" />
Cancelled
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-green">
                            {' '}
                            <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_transactions_1" role="tabpanel">
                Recieved
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_transactions_2" role="tabpanel">
                Sent
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_transactions_3" role="tabpanel">
                Withdrawn
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_transactions_4" role="tabpanel">
                Logins
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_transactions_5" role="tabpanel">
                Exchanges
              </Tab.Pane>
            </div>
          </Tab.Container>
        </div>
      </div>
    </div>
    <ModalTransactionDetail {...{ isOpenModal, openModal, closeModal }} />
  </section>
);

const TransactionsContainer = compose(
  withModal(),
)(Transactions);

export default TransactionsContainer;
