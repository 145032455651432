import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis,
  CartesianGrid, Tooltip,
} from 'recharts';

const mockData = [
  {
    name: '25 Sep', uv: 40000, pv: 24000, amt: 24000,
  },
  {
    name: '26 Sep', uv: 30000, pv: 13908, amt: 22010,
  },
  {
    name: '27 Sep', uv: 20000, pv: 68000, amt: 22090,
  },
  {
    name: '28 Sep', uv: 27800, pv: 39008, amt: 20000,
  },
  {
    name: '29 Sep', uv: 18900, pv: 48000, amt: 21081,
  },
  {
    name: '30 Sep', uv: 23900, pv: 38000, amt: 25000,
  },
  {
    name: '31 Sep', uv: 34900, pv: 43000, amt: 21000,
  },
  {
    name: '1 Oct', uv: 48621, pv: 12345, amt: 21000,
  },
  {
    name: '2 Oct', uv: 10234, pv: 21234, amt: 21000,
  },
  {
    name: '3 Oct', uv: 32323, pv: 32214, amt: 21000,
  },
  {
    name: '4 Oct', uv: 13456, pv: 37890, amt: 21000,
  },
  {
    name: '5 Oct', uv: 25234, pv: 2768, amt: 21000,
  },
];

const IncomeChart = ({ width = 1460, height = 595, data = mockData }) => (
  <BarChart
    width={width}
    height={height}
    data={data}
    margin={{
      top: 30, right: 30, left: 20, bottom: 5,
    }}
  >
    <CartesianGrid strokeDasharray="3" vertical={false} />
    <XAxis dataKey="name" tickLine={false} axisLine={false} padding={{ left: 30 }} />
    <YAxis tickLine={false} axisLine={false} textAnchor="insideLeft" tick={{ transform: 'translate(5, -10)' }} />
    <Tooltip />
    <Bar dataKey="pv" fill="#FAAB1A" barSize={5} />
    <Bar dataKey="uv" fill="#BABABA" barSize={5} />
  </BarChart>
);

export default IncomeChart;
