import React from 'react';
import { compose, withState, mapProps } from 'recompose';
import { Nav, Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

const ApiDocumentation = ({ isOpenSpreadsheets, setCollapse }) => (
  <section className="bdT bgc-grey pY-30">
    <Helmet>
      <title>Paybit - Api Documentation</title>
    </Helmet>
    <div className="container">
      <div className="mB-25">
        <ul className="breadcrumb breadcrumbs">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
            Api Documentation
          </li>
        </ul>
      </div>
      <div className="pX-15 pY-40 bd bgc-white api-documentation-container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <div className="input-has-icon">
                <i className="icon-search" />
                <input type="text" className="form-control form_control" placeholder="Search Api Documentation…" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-3">
            <Nav className="api-documentation-sidebar-left">
              <Nav as="ul" navbar className="flex-column sidebar-menu">
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link href="/" className="sidebar-link">
                    <span className="title">Authentication</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link href="/" className="sidebar-link">
                    <span className="title">Request / Response Formats</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link href="/" className="sidebar-link">
                    <span className="title">Rate Limiting</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link href="/" className="sidebar-link">
                    <span className="title">Organization Metrics</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link href="/" className="sidebar-link">
                    <span className="title">Domains</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link href="/" className="sidebar-link">
                    <span className="title">Deeplink Metrics</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className={classNames('nav-item has-submenu', { active: isOpenSpreadsheets })}>
                  <Nav.Link
                    onClick={setCollapse(!isOpenSpreadsheets && 'Spreadsheets')}
                    // aria-expanded={isOpenSpreadsheets}
                    aria-controls="spreadsheets-navbar-nav"
                    className="sidebar-link"
                  >
                    <span className="title">Spreadsheets</span>
                    <span className="arrow"><i className="fas fa-chevron-down" /></span>
                  </Nav.Link>
                  <Collapse in={isOpenSpreadsheets} id="spreadsheets-navbar-nav">
                    <ul className="sub-menu">
                      <li><a href="/">What is Postman Pro</a></li>
                      <li className="active"><a href="/">Managing Postman Pro</a></li>
                      <li><a href="/">Integrations</a></li>
                      <li><a href="/">API Search</a></li>
                    </ul>
                  </Collapse>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link href="/" className="sidebar-link">
                    <span className="title">Deprecated</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Nav>
          </div>
          <div className="col-md-12 col-lg-9">
            <div className="api-documentation-content">
              <div className="pY-30 bdB fsz-def line-height-2">
                <h4 className="fw-n">Intro to API documentation</h4>
                <p className="c-text-gray">
                  API Documentation feature lets
                  you view private API documentation or
                  share public API documentation in a beautifully formatted web page.
                  {' '}
                  <br />
                  <br />
                    Postman generates and hosts browser-based API
                    documentation for your collections automatically in real-time.
                    Each collection has a private and public documentation view that
                    Postman generates from synced data in the servers.
                </p>
              </div>
              <div className="pY-30 bdB fsz-def line-height-2">
                <h4 className="fw-n">Private documentation view</h4>
                <p className="c-text-gray">
                  To access the private view of your team documentation,
                  go to the sidebar in the Postman app and click the &gt;
                  icon in the collection you want to view. Then click “View in web”
                  to view the documentation for the collection.
                </p>
                <div className="img img-1-2" style={{ backgroundImage: 'url(/images/about-banner.jpg)' }} />
              </div>
              <div className="pY-30 fsz-def line-height-2">
                <h4 className="fw-n">What gets automatically generated?</h4>
                <p className="c-text-gray">
                      Documentation for your API includes:
                </p>
                <ul className="c-black">
                  <li>Sample requests, headers, and other metadata</li>
                  <li>Descriptions associated with requests, folders, and collections</li>
                  <li>Generated code snippets in some of the most popular programming languages</li>
                </ul>
                <p>
                  Postman uses ordered requests and folders to
                   organize documentation in sections to reflect
                   the structure of your collection.
                  <br />
                  <br />
                  You can customize descriptions
                  using Markdown styling with embedded graphics to complement your documentation.
                  <br />
                  <br />
                  We support GitHub flavored markdown so you can include tables.
                  When including block elements, make sure you leave an empty line
                  before and after to avoid any rendering issues.
                </p>
                <div className="img img-1-2" style={{ backgroundImage: 'url(/images/about-banner.jpg)' }} />
              </div>
              <div className="fsz-def line-height-2">
                <h4 className="fw-n">Free documentation views with your Paybit account</h4>
                <p className="c-text-gray">
                  Public and private documentation each receive 1000 free views per month.
                  You can check your usage limits through the
                  <a href="/">PayBit API</a>
                  or the
                  <a href="/">account usage page.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ApiDocumentationContainer = compose(
  withState('collapse', 'setCollapse', 'Spreadsheets'),
  mapProps(({ collapse, setCollapse, ...props }) => ({
    isOpenSpreadsheets: collapse === 'Spreadsheets',
    setCollapse: newCollapse => (e) => {
      if (e && e.preventdefault) {
        e.preventdefault();
        e.stoppropagation();
      }
      setCollapse(newCollapse);
    },
    ...props,
  })),
)(ApiDocumentation);

export default ApiDocumentationContainer;
