import React from 'react';
import { Dropdown } from 'react-bootstrap';
import ReactWOW from 'react-wow';
import Countup from 'react-countup';

import StatsChart from '../../../components/StatsChart';

const Stats = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp">
    <div className="box-style bd bgc-white mB-20 col-12" ref={dragRef}>
      <span className="box-close-btn"><i className="icon-drag" /></span>
      <div className="box-header chart-stats-header">
        <div className="chart-stats-header-inner d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center chart-stats-header-left">
            <h5>
              <b className="fw-n mR-10">Stats </b>
              <span className="fsz-sm c-gray">Year 2018</span>
            </h5>
            <div className="mL-30">
              <Dropdown
                as="span"
                className="c-gray dropdown dropdown-monthly"
                role="group"
              >
                <Dropdown.Toggle as="span" id="dropdown-dropdown-monthly" className="btn-transparent dropdown-toggle" data-toggle="dropdown" aria-haspopup="true">
                  Monthly
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu" aria-labelledby="dropdown-dropdown-monthly">
                  <Dropdown.Item as="a" className="dropdown-item" href="/">Monthly</Dropdown.Item>
                  <Dropdown.Item as="a" className="dropdown-item" href="/">Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <ul className="lis-n p-0 mL-10 mb-0">
              <li className="float-left ml-4">
                <i className="c-red fas fa-circle" />
                <span className="pL-5">Monero</span>
              </li>
              <li className="float-left ml-4">
                <i className="c-orange fas fa-circle" />
                <span className="pL-5">Bitcoin</span>
              </li>
              <li className="float-left ml-4">
                <i className="c-green fas fa-circle" />
                <span className="pL-5">Riplle</span>
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center chart-stats-header-right">
            <div className="mr-5">
              <span className="c-grey fsz-xs">Total</span>
              <br />
              <span>
                <Countup end={81000} prefix="$" separator="" decimal="." duration={3} />
              </span>
            </div>
            <div className="mr-5">
              <span className="c-grey fsz-xs">Monthly</span>
              <br />
              <span><Countup end={95500} prefix="$" separator="" decimal="." duration={3} /></span>
            </div>
            <div className="mr-5">
              <span className="c-grey fsz-xs">Summart</span>
              <br />
              <span><Countup end={93528} prefix="$" separator="" decimal="." duration={3} /></span>
            </div>
            <div className="mr-5">
              <span className="c-grey fsz-xs">Cashflow</span>
              <br />
              <span><Countup end={74583} prefix="$" separator="" decimal="." duration={3} /></span>
            </div>
            <div className="mR-15">
              <button className="btn-gray-gradient"><i className="fas fa-chevron-left" /></button>
            </div>
            <div className="mR-20">
              <button className="btn-gray-gradient"><i className="fas fa-chevron-right" /></button>
            </div>
            <div>
              <button className="btn-transparent">
                <i className="c-gray fas fa-ellipsis-h" />
              </button>
            </div>
            <div className="mL-10">
              <button className="btn-transparent">
                <i className="c-gray fas fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="box-body pY-20 start-chart-wrapper">
        <StatsChart />
      </div>
    </div>
  </ReactWOW>
);

export default Stats;
