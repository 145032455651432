import {
  compose, withState, lifecycle, withHandlers,
} from 'recompose';

import HeaderComponent from './HeaderComponent';

const unregisterListener = ({ scrollListener }) => window.removeEventListener('scroll', scrollListener);
const registerListener = ({ scrollListener }) => window.addEventListener('scroll', scrollListener);

const Header = compose(
  withState('active', 'setActive', ({ activeHeader = false }) => activeHeader),
  withHandlers({
    scrollListener: ({ active, setActive, activeHeader }) => () => {
      const position = window.scrollY;
      if (position >= 60) {
        if (!active) setActive(true);
      } else if (active) setActive(activeHeader);
    },
  }),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line global-require
      require('../../../styles/landing.scss');
      if (!this.props.activeHeader) registerListener(this.props);
    },
    componentDidUpdate(preProps) {
      if (!preProps.activeHeader) unregisterListener(preProps);
      if (!this.props.activeHeader) registerListener(this.props);
    },
    componentWilUnmount() {
      if (!this.props.activeHeader) unregisterListener(this.props);
    },
  }),
)(HeaderComponent);

export default Header;
