import React from 'react';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const data = [
  {
    name: 'Page A', value: 400,
  },
  {
    name: 'Page C', value: 200,
  },
  {
    name: 'Page E', value: 189,
  },
  {
    name: 'Page F', value: 239,
  },
  {
    name: 'Page B', value: 80,
  },
  {
    name: 'Page G', value: 349,
  },
  {
    name: 'Page D', value: 278,
  },
  {
    name: 'Page G', value: 349,
  },

];

const TinyBarChart = ({
  barSize = 5, color = '#00C300', radius = 10,
}) => (
  <ResponsiveContainer
    width="100%"
    height="100%"
  >
    <BarChart data={data}>
      <Tooltip
        viewBox={{
          width: 400,
          height: 400,
        }}
      />
      <Bar dataKey="value" fill={color} radius={radius} barSize={barSize} />
    </BarChart>
  </ResponsiveContainer>
);

export default TinyBarChart;
