import React from 'react';
import ReactWOW from 'react-wow';
import Countup from 'react-countup';

import TinyLineChart from '../../../../common/Charts/TinyLineChart';
import SortableArea from '../../../../common/SortableArea';

const BTC = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp" delay="0.2s">
    <div className="wallet-coin-item" ref={dragRef}>
      <div className="bd bgc-white bdrs-3">
        <div className="pY-10 pL-25 d-flex align-items-center justify-content-between">
          <div>
            <div className="d-flex mB-10">
              <span className="coin-icon coin-icon-38 mR-20" style={{ backgroundImage: 'url(/images/coins/bitcoin-38.png)' }} />
              <span>
                <b className="fw-n">BTC</b>
                <br />
                <span className="c-gray"><Countup end={5255.15} prefix="$ " separator="," decimal="." duration={2.75} decimals={2} /></span>
              </span>
            </div>
            <div className="d-flex">
              <span className="coin-icon-38 text-center c-green fsz-xl mR-20"><i className="icon-arrow_up" /></span>
              <span>
                <b className="fw-n"><Countup end={2624.9} prefix="$ " separator="," decimal="." duration={2.75} decimals={1} /></b>
                <br />
                <span className="c-gray"><Countup end={0.567} suffix=" coin" separator="," decimal="." duration={3} decimals={3} /></span>
              </span>
            </div>
          </div>
          <div className="chart-line">
            <TinyLineChart color="#00C300" />
          </div>
        </div>
        <div className="pX-20 pY-10 d-flex justify-content-between">
          <div>
            <p className="c-gray mb-0">Wallet Addresses</p>
            <small><b>12 (24254 PBT - 7442424 USD)</b></small>
          </div>
          <div>
            <p className="c-gray mb-0">Market Cap</p>
            <small><b>$6,2321,232,2323</b></small>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);

const XRP = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp" delay="0.4s">
    <div className="wallet-coin-item" ref={dragRef}>
      <div className="bd bgc-white bdrs-3">
        <div className="pY-10 pL-25 d-flex align-items-center justify-content-between">
          <div>
            <div className="d-flex mB-10">
              <span className="coin-icon coin-icon-38 mR-20" style={{ backgroundImage: 'url(/images/coins/ripple-38.png)' }} />
              <span>
                <b className="fw-n">XRP</b>
                <br />
                <span className="c-gray"><Countup end={5255.15} prefix="$ " separator="," decimal="." duration={2.75} decimals={2} /></span>
              </span>
            </div>
            <div className="d-flex">
              <span className="coin-icon-38 text-center c-red fsz-xl mR-20"><i className="icon-arrow_down" /></span>
              <span>
                <b className="fw-n"><Countup end={2624.9} prefix="$ " separator="," decimal="." duration={2.75} decimals={1} /></b>
                <br />
                <span className="c-gray"><Countup end={0.567} suffix=" coin" separator="," decimal="." duration={3} decimals={3} /></span>
              </span>
            </div>
          </div>
          <div className="chart-line">
            <TinyLineChart color="#FF000A" />
          </div>
        </div>
        <div className="pX-20 pY-10 d-flex justify-content-between">
          <div>
            <p className="c-gray mb-0">Wallet Addresses</p>
            <small><b>12 (24254 PBT - 7442424 USD)</b></small>
          </div>
          <div>
            <p className="c-gray mb-0">Market Cap</p>
            <small><b>$6,2321,232,2323</b></small>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);

const ETH = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp" delay="0.6s">
    <div className="wallet-coin-item" ref={dragRef}>
      <div className="bd bgc-white bdrs-3">
        <div className="pY-10 pL-25 d-flex align-items-center justify-content-between">
          <div>
            <div className="d-flex mB-10">
              <span className="coin-icon coin-icon-38 mR-20" style={{ backgroundImage: 'url(/images/coins/eth-38.png)' }} />
              <span>
                <b className="fw-n">ETH</b>
                <br />
                <span className="c-gray"><Countup end={5255.15} prefix="$ " separator="," decimal="." duration={2.75} decimals={2} /></span>
              </span>
            </div>
            <div className="d-flex">
              <span className="coin-icon-38 text-center c-green fsz-xl mR-20"><i className="icon-arrow_up" /></span>
              <span>
                <b className="fw-n"><Countup end={2624.9} prefix="$ " separator="," decimal="." duration={2.75} decimals={1} /></b>
                <br />
                <span className="c-gray"><Countup end={0.567} suffix=" coin" separator="," decimal="." duration={3} decimals={3} /></span>
              </span>
            </div>
          </div>
          <div className="chart-line">
            <TinyLineChart color="#00C300" />
          </div>
        </div>
        <div className="pX-20 pY-10 d-flex justify-content-between">
          <div>
            <p className="c-gray mb-0">Wallet Addresses</p>
            <small><b>12 (24254 PBT - 7442424 USD)</b></small>
          </div>
          <div>
            <p className="c-gray mb-0">Market Cap</p>
            <small><b>$6,2321,232,2323</b></small>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);

const PBT = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp" delay="0.8s">
    <div className="wallet-coin-item" ref={dragRef}>
      <div className="bd bgc-white bdrs-3">
        <div className="pY-10 pL-25 d-flex align-items-center justify-content-between">
          <div>
            <div className="d-flex mB-10">
              <span className="coin-icon coin-icon-38 mR-20" style={{ backgroundImage: 'url(/images/coins/paybit-38.png)' }} />
              <span>
                <b className="fw-n">PBT</b>
                <br />
                <span className="c-gray"><Countup end={5255.15} prefix="$ " separator="," decimal="." duration={2.75} decimals={2} /></span>
              </span>
            </div>
            <div className="d-flex">
              <span className="coin-icon-38 text-center c-red fsz-xl mR-20"><i className="icon-arrow_down" /></span>
              <span>
                <b className="fw-n"><Countup end={2624.9} prefix="$ " separator="," decimal="." duration={2.75} decimals={1} /></b>
                <br />
                <span className="c-gray"><Countup end={0.567} suffix=" coin" separator="," decimal="." duration={3} decimals={3} /></span>
              </span>
            </div>
          </div>
          <div className="chart-line">
            <TinyLineChart color="#FF000A" />
          </div>
        </div>
        <div className="pX-20 pY-10 d-flex justify-content-between">
          <div>
            <p className="c-gray mb-0">Wallet Addresses</p>
            <small><b>12 (24254 PBT - 7442424 USD)</b></small>
          </div>
          <div>
            <p className="c-gray mb-0">Market Cap</p>
            <small><b>$6,2321,232,2323</b></small>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);
const WalletCoins = ({ dragRef }) => (
  <div ref={dragRef} className="col-12">
    <div className="row">
      <div className="col-12">
        <div className="pY-15">
          <h5>
            <b className="fw-n">Wallet Coins</b>
            <span className="fsz-xs c-gray mL-15">See the list of your wallets</span>
          </h5>
        </div>
      </div>
    </div>
    <div className="list-wallet-coin mB-5">
      <SortableArea
        components={[
          BTC,
          XRP,
          ETH,
          PBT,
        ]}
        type="coins"
      />
      <ReactWOW animation="fadeInUp" delay="1s">
        <div className="add-new-wallet">
          <div><a href="/" className="c-black">+ Add new wallet</a></div>
        </div>
      </ReactWOW>
    </div>
  </div>
);

export default WalletCoins;
