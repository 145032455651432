import React from 'react';
import { compose } from 'redux';
import { Modal } from 'react-bootstrap';
import SignUpTabs from './SignUpTabs';

export const SignUpModal = ({
  isOpenModalSignUp, closeModalSignUp, toggleModalSignUp, openModalSignIn,
}) => (
  <Modal id="modalSignup" tabIndex={-1} role="dialog" aria-hidden="true" show={isOpenModalSignUp} onHide={closeModalSignUp} toggle={toggleModalSignUp}>
    <Modal.Header closeButton className="react-custom-modal-header" />
    <div className="modal-signup">
      <Modal.Body className="sign-up-container">
        <SignUpTabs toggle={(event) => {
          event.persist();
          event.nativeEvent.stopImmediatePropagation();
          event.stopPropagation();
          event.preventDefault();
          closeModalSignUp();
          openModalSignIn();
        }}
        />
      </Modal.Body>
    </div>
  </Modal>
);
export default compose(
)(SignUpModal);
