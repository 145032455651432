import React from 'react';
import Countup from 'react-countup';
import { Tab, Nav } from 'react-bootstrap';


const LastActivityTable = () => (
  <Tab.Container defaultActiveKey="tab_alltransaction">
    <div className="tab-common pX-25 bgc-white">
      <div className="d-flex align-items-center justify-content-between">
        <Nav className="nav nav-tabs tab-md" id="last_activityTab" role="tablist">
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction" role="tab">All Transaction </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction-1" role="tab" aria-selected="false">Recieved </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction-2" role="tab" aria-selected="false">Sent </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction-3" role="tab" aria-selected="false">Withdrawn </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction-4" role="tab" aria-selected="false">Logins </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction-5" role="tab" aria-selected="false">Exchanges </Nav.Link>
          </Nav.Item>
        </Nav>
        <div>
          <button className="btn btn-transparent mR-20">
            <i className="c-green icon-export mR-10" />
                      Export Table
          </button>
          <button className="btn btn-transparent">
            <i className="c-yellow icon-print mR-10" />
                      Print
          </button>
        </div>
      </div>
    </div>
    <Tab.Content as="div" className="tab-content p-0" id="last_activityTabContent">
      <Tab.Pane className="tab-pane" eventKey="tab_alltransaction" role="tabpanel">
        <div className="table-responsive scrollable max-h-730">
          <table className="table table-common table-hover">
            <thead>
              <tr>
                <th>Name/Email</th>
                <th>Date</th>
                <th>Action</th>
                <th>Status</th>
                <th>Details</th>
                <th>Amount</th>
                <th>Transaction ID</th>
                <th className="pX-10">&nbsp;</th>
              </tr>
            </thead>
            <tbody className="pX-20">
              <tr>
                <td>
                  <div className="name-email d-flex align-items-center">
                    <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                    <div className="mL-20">
                      <span>
                                  Bruce Springesteen
                        <i className="icon-contacts mL-10 c-blue" />
                      </span>
                      <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                    </div>
                  </div>
                </td>
                <td><span className="c-gray">25.2.2018</span></td>
                <td>
                  <span className="coin-currency">
                    <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                    <span>
                            Sent to
                      <br />
                      <span className="fsz-xs c-grey">Bitcoin Wallet</span>
                    </span>
                  </span>

                </td>
                <td>
                  <span>
                    <i className="c-green fsz-md fas fa-check-circle mR-5" />
                              Complete
                  </span>
                </td>
                <td>
                  <a href="/" className="c-gray">View</a>
                </td>
                <td>
                  <span className="c-red">
                    <Countup end={-800} suffix="$" separator="," decimal="." duration={3} decimals={2} />
                  </span>

                </td>
                <td><span className="c-gray">5123123</span></td>
                <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
              </tr>
              <tr>
                <td>
                  <div className="name-email d-flex align-items-center">
                    <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                    <div className="mL-20">
                      <span>James Blunt</span>
                      <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                    </div>
                  </div>
                </td>
                <td><span className="c-gray">25.2.2018</span></td>
                <td>
                  <span className="coin-currency">
                    <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/ethereum.png)' }} />
                    <span>
                                Received
                      <br />
                      <span className="fsz-xs c-grey">From Ethereum Wallet</span>
                    </span>
                  </span>

                </td>
                <td>
                  <span>
                    <i className="c-green fsz-md fas fa-check-circle mR-5" />
                                Complete
                  </span>
                </td>
                <td>
                  <a href="/" className="c-gray">View</a>
                </td>
                <td>
                  <span className="c-green">
                    <Countup end={1800.00} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                  </span>
                </td>
                <td><span className="c-gray">5123123</span></td>
                <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
              </tr>
              <tr>
                <td>
                  <div className="name-email d-flex align-items-center">
                    <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                    <div className="mL-20">
                      <span>Cristopter Smith</span>
                      <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                    </div>
                  </div>
                </td>
                <td><span className="c-gray">25.2.2018</span></td>
                <td>
                  <span className="coin-currency">
                    <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/monero.png)' }} />
                    <span>
                                Received
                      <br />
                      <span className="fsz-xs c-grey">From Monero Wallet</span>
                    </span>
                  </span>

                </td>
                <td>
                  <span>
                    <i className="c-red fsz-md fas fa-times-circle mR-5" />
                              Cancelled
                  </span>
                </td>
                <td>
                  <a href="/" className="c-gray">View</a>
                </td>
                <td>
                  <span className="c-green">
                    <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                  </span>
                </td>
                <td><span className="c-gray">5123123</span></td>
                <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
              </tr>
              <tr>
                <td>
                  <div className="name-email d-flex align-items-center">
                    <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                    <div className="mL-20">
                      <span>James Blunt</span>
                      <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                    </div>
                  </div>
                </td>
                <td><span className="c-gray">25.2.2018</span></td>
                <td>
                  <span className="coin-currency">
                    <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/litecoin.png)' }} />
                    <span>
                                Send to
                      <br />
                      <span className="fsz-xs c-grey">From Litecoin Wallet</span>
                    </span>
                  </span>

                </td>
                <td>
                  <span>
                    <i className="c-green fsz-md fas fa-check-circle mR-5" />
                              Complete
                  </span>
                </td>
                <td>
                  <a href="/" className="c-gray">View</a>
                </td>
                <td>
                  <span className="c-red">
                    <Countup end={1800.00} prefix="-" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                  </span>
                </td>
                <td><span className="c-gray">5123123</span></td>
                <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
              </tr>
              <tr>
                <td>
                  <div className="name-email d-flex align-items-center">
                    <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                    <div className="mL-20">
                      <span>James Blunt</span>
                      <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                    </div>
                  </div>
                </td>
                <td><span className="c-gray">25.2.2018</span></td>
                <td>
                  <span className="coin-currency">
                    <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/ethereum.png)' }} />
                    <span>
                                Received
                      <br />
                      <span className="fsz-xs c-grey">From Ethereum Wallet</span>
                    </span>
                  </span>

                </td>
                <td>
                  <span>
                    <i className="c-yellow fsz-md fas fa-exclamation-circle mR-5" />
                              Pending
                  </span>
                </td>
                <td>
                  <a href="/" className="c-gray">View</a>
                </td>
                <td>
                  <span className="c-green">
                    <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                  </span>
                </td>
                <td><span className="c-gray">5123123</span></td>
                <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
              </tr>
              <tr>
                <td>
                  <div className="name-email d-flex align-items-center">
                    <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                    <div className="mL-20">
                      <span>Cristopter Smith</span>
                      <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                    </div>
                  </div>
                </td>
                <td><span className="c-gray">25.2.2018</span></td>
                <td>
                  <span className="coin-currency">
                    <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/monero.png)' }} />
                    <span>
                                Received
                      <br />
                      <span className="fsz-xs c-grey">From Monero Wallet</span>
                    </span>
                  </span>

                </td>
                <td>
                  <span>
                    <i className="c-red fsz-md fas fa-times-circle mR-5" />
                              Cancelled
                  </span>
                </td>
                <td>
                  <a href="/" className="c-gray">View</a>
                </td>
                <td>
                  <span className="c-green">
                    <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                  </span>
                </td>
                <td><span className="c-gray">5123123</span></td>
                <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="tab_alltransaction-1">1</Tab.Pane>
      <Tab.Pane eventKey="tab_alltransaction-2">2</Tab.Pane>
      <Tab.Pane eventKey="tab_alltransaction-3">3</Tab.Pane>
      <Tab.Pane eventKey="tab_alltransaction-4">4</Tab.Pane>
      <Tab.Pane eventKey="tab_alltransaction-5">5</Tab.Pane>
    </Tab.Content>
  </Tab.Container>
);

export default LastActivityTable;
