import React from 'react';
import { Modal } from 'react-bootstrap';

const VerificationsForm = ({ show, onHide }) => (
  <Modal className="modal fade modal-md" id="modal-verify-form" show={show} onHide={onHide}>
    <div className="modal-dialog" role="document">
      <div className="modal-content bdrs-0 p-30">
        <div className="mB-20">
          <p className="c-gray tt-u">paybit verification point</p>
          <h4><b className="fw-n">Fill up the info and wait for our verifications</b></h4>
        </div>
        <div className="mT-20">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className="c-grey">First Name</label>
                <div className="input-has-icon">
                  <i className="icon-profile" />
                  <input type="text" className="form-control form_control" placeholder="First Name" />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className="c-grey">Last Name</label>
                <div className="input-has-icon">
                  <i className="icon-profile" />
                  <input type="text" className="form-control form_control" placeholder="Last Name" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
              <label className="c-grey">Date of Birth</label>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-4">
              <div className="form-group">
                <input type="number" className="form-control form_control" placeholder="Day" />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-4">
              <div className="form-group">
                <input type="number" className="form-control form_control" placeholder="Month" />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-4">
              <div className="form-group">
                <input type="number" className="form-control form_control" placeholder="Year" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className="c-grey">Document Series and Number</label>
                <input type="text" className="form-control form_control" placeholder="Document Series and Number" />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className="c-grey">Document country of issue</label>
                <input type="text" className="form-control form_control" placeholder="Document country of issue" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
              <label className="c-grey">ID Document Expiration Date</label>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-4">
              <div className="form-group">
                <input type="number" className="form-control form_control" placeholder="Day" />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-4">
              <div className="form-group">
                <input type="number" className="form-control form_control" placeholder="Month" />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-4">
              <div className="form-group">
                <input type="number" className="form-control form_control" placeholder="Year" />
              </div>
            </div>
          </div>
          <div className="mT-20 mB-30">
            <div className="box-colour-scan">
              <div className="mB-30"><i className="c-blue icon-scan-machine" /></div>
              <h5 className="fw-n">Colour scan copy of the document</h5>
              <p className="c-grey">
                <a href="/" className="td-u">Choose the file to upload </a>
or drag it into this field
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <i className="c-blue fsz-lg icon-jpg" />
            <div className="mL-10 d-inline-block w-100">
              <span>jonathandocument.jpg</span>
              <div className="progress">
                <div className="progress-bar bgc-green" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '80%' }} />
              </div>
            </div>
          </div>
          <div className="mT-30 text-center">
            <button className="btn btn-xl btn-primary btn-around">Send for Verification</button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default VerificationsForm;
