import React from 'react';
import { Helmet } from 'react-helmet';
import { Tab, Nav } from 'react-bootstrap';

import SingleLineChart from './SingleLineChart';

const Overview = () => (
  <>
    <Helmet>
      <title>Paybit - Overview</title>
    </Helmet>
    <section>
      <Tab.Container defaultActiveKey="tab_overview">
        <div className="tab-common pX-25 bgc-white bdB">
          <Nav className="nav nav-tabs tab-lg" id="apiTab" variant="pills">
            <Nav.Item className="nav-item">
              <Nav.Link className="nav-link" eventKey="tab_overview">API Overview</Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item">
              <Nav.Link className="nav-link" eventKey="tab_logs">API Logs</Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item">
              <Nav.Link className="nav-link" eventKey="tab_settings">API Settings</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content className="tab-content">
          <Tab.Pane eventKey="tab_overview">
            <div className="pT-20 pX-20 bgc-grey main-content-container">
              <div className="main-content">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="box-style bd bgc-white bdrs-3 mB-10">
                      <div className="d-flex align-items-center p-30">
                        <span className="fsz-sup c-red"><i className="icon-error" /></span>
                        <div className="mL-30">
                          <span className="c-red">Last Transfer</span>
                          <div>
                            <span className="fsz-50">32%</span>
                          </div>
                          <p className="c-gray mb-0">% of executions w/ errors</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="box-style bd bgc-white bdrs-3 mB-10">
                      <div className="d-flex align-items-center p-30">
                        <span className="fsz-sup c-green"><i className="icon-executions" /></span>
                        <div className="mL-30">
                          <span className="c-green">Executions</span>
                          <div>
                            <span className="fsz-50">167.2k</span>
                          </div>
                          <p className="c-gray mb-0">Of times your scripts ran</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="box-style bd bgc-white bdrs-3 mB-10">
                      <div className="d-flex align-items-center p-30">
                        <span className="fsz-sup c-yellow"><i className="icon-user_circle_bold" /></span>
                        <div className="mL-30">
                          <span className="c-yellow">Users</span>
                          <div>
                            <span className="fsz-50">53</span>
                          </div>
                          <p className="c-gray mb-0">Avarage of unique users</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-style bd bgc-white mB-20 bdrs-3">
                  <span className="box-close-btn"><i className="icon-drag" /></span>
                  <div className="box-header d-flex align-items-center">
                    <h5 className="mR-20">
                      <b className="fw-n mR-30">Errors </b>
                      <span className="c-gray fsz-def">Year 2018</span>
                    </h5>
                    <select className="bdn bgn">
                      <option value>Monthly</option>
                      <option value>Daily</option>
                    </select>
                  </div>
                  <div className="box-body p-20">
                    <div className="single-line-chart-wrapper">
                      <SingleLineChart />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="box-style bd bgc-white mB-20 bdrs-3">
                      <span className="box-close-btn"><i className="icon-drag" /></span>
                      <div className="box-header d-flex align-items-center">
                        <h5 className="mR-20">
                          <b className="fw-n mR-30">Executions </b>
                          <span className="c-gray fsz-def">Year 2018</span>
                        </h5>
                        <select className="bdn bgn">
                          <option value>Monthly</option>
                          <option value>Daily</option>
                        </select>
                      </div>
                      <div className="box-body p-20">
                        <div className="single-line-chart-wrapper">
                          <SingleLineChart color="#00C300" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="box-style bd bgc-white mB-20 bdrs-3">
                      <span className="box-close-btn"><i className="icon-drag" /></span>
                      <div className="box-header d-flex align-items-center">
                        <h5 className="mR-20">
                          <b className="fw-n mR-30">Users </b>
                          <span className="c-gray fsz-def">Year 2018</span>
                        </h5>
                        <select className="bdn bgn">
                          <option value>Monthly</option>
                          <option value>Daily</option>
                        </select>
                      </div>
                      <div className="box-body p-20">
                        <div className="single-line-chart-wrapper">
                          <SingleLineChart color="#FAAB1A" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="tab_logs">
            <div className="pT-20 pX-20 bgc-grey main-content-container">
              <div className="main-content">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="box-style bd bgc-white bdrs-3 mB-10">
                      <div className="d-flex align-items-center p-30">
                        <span className="fsz-sup c-gray"><i className="icon-api" /></span>
                        <div className="mL-30">
                          <b className="fsz-md fw-n">Current Request</b>
                          <div>
                            <span className="fsz-50">32%</span>
                          </div>
                          <p className="c-gray mb-0">Requests</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="box-style bd bgc-white bdrs-3 mB-10">
                      <div className="d-flex align-items-center p-30">
                        <span className="fsz-sup c-gray"><i className="icon-api" /></span>
                        <div className="mL-30">
                          <b className="fsz-md fw-n">Last Requests</b>
                          <div>
                            <span className="fsz-50">167.2k</span>
                          </div>
                          <p className="c-gray mb-0">Requests</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="box-style bd bgc-white bdrs-3 mB-10">
                      <div className="d-flex align-items-center p-30">
                        <span className="fsz-sup c-gray"><i className="icon-api" /></span>
                        <div className="mL-30">
                          <b className="fsz-md fw-n">Highest Request Today</b>
                          <div>
                            <span className="fsz-50">22</span>
                          </div>
                          <p className="c-gray mb-0">Requests</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="tab_settings">
            <div className="pT-20 pX-20 bgc-grey main-content-container">
              <div className="main-content">
                <div className="box-style pX-40 pY-20 bd bgc-white mB-20">
                  <div className="pY-20">
                    <h5><b className="fw-n mR-10">Buy Widgets</b></h5>
                    <p className="c-gray mb-0">If you forget you password or cannot acces your account, we willl use this information to .</p>
                  </div>
                  <div className="p-20 bd d-flex align-items-center verify-box">
                    <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/buy.png)' }} />
                    <div className>
                      <h4><b className="fw-n">You dont have any buy widgets set up yet.</b></h4>
                      <p className="c-gray fsz-def">Add a recovery phone to help secure your account ,You can enable 2FA for your  account on single settings page, so your new </p>
                      <a href="/" className="c-blue tt-u">+ new buy widget</a>
                    </div>
                  </div>
                  <div className="pY-20">
                    <h5><b className="fw-n mR-10">API Keys</b></h5>
                    <p className="c-gray mb-0">If you forget you password or cannot acces your account, we willl use this information to .</p>
                  </div>
                  <div className="p-20 bd d-flex align-items-center verify-box">
                    <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/API_Keys.png)' }} />
                    <div className>
                      <h4><b className="fw-n">You haven&lsquo;t created any API keys yet.</b></h4>
                      <p className="c-gray fsz-def">API keys allow you to perform automated actions on your account with your own software. Learn More. </p>
                      <a href="/" className="c-blue tt-u">+ new API key</a>
                    </div>
                  </div>
                  <div className="pY-20">
                    <h5><b className="fw-n mR-10">My OAuth2 Applications</b></h5>
                    <p className="c-gray mb-0">If you forget you password or cannot acces your account, we willl use this information to .</p>
                  </div>
                  <div className="p-20 bd d-flex align-items-center verify-box">
                    <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/buy.png)' }} />
                    <div className>
                      <h4><b className="fw-n">You haven&lsquo;t created any OAuth2 applications yet.</b></h4>
                      <p className="c-gray fsz-def">Build applications for others using the Paybit API. Learn More.</p>
                      <a href="/" className="c-blue tt-u">+ new API key</a>
                    </div>
                  </div>
                  <div className="pT-20">
                    <h5><b className="fw-n mR-10">API Version and Notifications</b></h5>
                    <p className="c-gray">
                      API version is generally passed as a
                      {' '}
                      <a href="/" className="fw-b">CB-VERSION</a>
                      {' '}
                      header. If the API version is omitted,
                      the version displayed below will be used.
                      Learn more about API versioning. Learn more about API versioning.
                      <br />
                      <br />
                      Notifications allow you to subscribe to
                      updates for your OAuth application or API key.
                      Since notifications are delivered via webhooks,
                      they always correspond to the API version displayed below.
                      Before upgrading your service, ensure that your
                      application is ready to accept the latest notification version.
                      {' '}
                      <a href="/" className="fw-b">Learn more about notifications.</a>

                    </p>
                    <p><b className="fw-n">API Version: 2018-10-11    YOU&lsquo;RE UP-TO-DATE</b></p>
                  </div>
                </div>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </section>
    <section className="pX-20 bgc-grey main-content-container">
      <div className="main-content">
        <div className="box-style bd bgc-white mB-20 bdrs-6">
          <span className="box-close-btn"><i className="icon-drag" /></span>
          <div className="box-header box-last-activity-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center box-last-activity-header-left">
                <h5><b className="fw-n mR-10">Log History</b></h5>
              </div>
              <div className="d-flex align-items-center">
                <div className="mR-15">
                  <div className="input_group">
                    <i className="mL-10 icon-search" />
                    <input type="text" placeholder="Search now" className="form-control bdn" />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
                <div>
                  <div className="input_group">
                    <i className="mL-10 far fa-calendar-check" />
                    <input type="date" placeholder="Select Date" className="form-control bdn" />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
                <div className="mL-20">
                  <button className="btn btn-transparent mR-20">
                    <i className="c-green icon-export mR-10" />
Export Table
                  </button>
                  <button className="btn btn-transparent">
                    <i className="c-yellow icon-print mR-10" />
Print
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="box-body pY-20">
            <div className="table-responsive">
              <table className="table table-common table-hover table-common-overview">
                <thead>
                  <tr>
                    <th>Projects</th>
                    <th>Owner</th>
                    <th>Description</th>
                    <th>Error Rate</th>
                    <th>Date</th>
                    <th>Executions</th>
                    <th>Connection</th>
                    <th>Application</th>
                  </tr>
                </thead>
                <tbody className="c-grey-500">
                  <tr>
                    <td>
                      <div>
                        <span className="fsz-lg c-green mR-15"><i className="icon-wifi" /></span>
                        <span>API Operation </span>
                      </div>
                    </td>
                    <td>Me</td>
                    <td>Update a client</td>
                    <td>98%</td>
                    <td>4 minutes ago</td>
                    <td>321</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span className="fsz-lg c-green mR-15"><i className="icon-wifi" /></span>
                        <span>API Operation </span>
                      </div>
                    </td>
                    <td>Me</td>
                    <td>Create a client</td>
                    <td>98%</td>
                    <td>4 minutes ago</td>
                    <td>321</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span className="fsz-lg c-yellow mR-15"><i className="icon-mail" /></span>
                        <span>Failded Change Email</span>
                      </div>
                    </td>
                    <td>User</td>
                    <td>Create a user</td>
                    <td>98%</td>
                    <td>4 minutes ago</td>
                    <td>321</td>
                    <td>Username-Password… </td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span className="fsz-lg c-red mR-15"><i className="icon-lock" /></span>
                        <span>Failded Change Password</span>
                      </div>
                    </td>
                    <td>User</td>
                    <td>Create a user</td>
                    <td>98%</td>
                    <td>4 minutes ago</td>
                    <td>321</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span className="fsz-lg c-green mR-15"><i className="icon-wifi" /></span>
                        <span>API Operation </span>
                      </div>
                    </td>
                    <td>Me</td>
                    <td>Update a client</td>
                    <td>98%</td>
                    <td>4 minutes ago</td>
                    <td>321</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span className="fsz-lg c-green mR-15"><i className="icon-wifi" /></span>
                        <span>API Operation </span>
                      </div>
                    </td>
                    <td>Me</td>
                    <td>Update a client</td>
                    <td>98%</td>
                    <td>4 minutes ago</td>
                    <td>321</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Overview;
