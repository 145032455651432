import React from 'react';
import ReactWOW from 'react-wow';
import LastActivityTable from './LastActivityTable';


const LastActivity = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp">
    <div className="box-style bd bgc-white mB-20 col-12" ref={dragRef}>
      <span className="box-close-btn"><i className="icon-drag" /></span>
      <div className="box-header box-last-activity-header">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center box-last-activity-header-left">
            <h5>
              <b className="fw-n mR-10">Last activity</b>
              <span className="fsz-sm c-gray">Year 2018</span>
            </h5>
            <div className="mL-30">
              <span className="c-gray">(Show All)</span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="mR-15">
              <div className="input_group">
                <i className="mL-10 icon-search" />
                <input type="text" placeholder="Search now" className="form-control bdn" />
                <button className="btn btn-primary">Search</button>
              </div>
            </div>
            <div>
              <div className="input_group">
                <i className="mL-10 far fa-calendar-check" />
                <input type="date" placeholder="Select Date" className="form-control bdn" />
                <button className="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-body pY-20">
        <LastActivityTable />
      </div>
    </div>
  </ReactWOW>
);

export default LastActivity;
