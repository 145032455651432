import React from 'react';

import {
  fromRenderProps, compose, withState, withHandlers,
} from 'recompose';

const isAuth = localStorage.getItem('isAuth') === 'true';

export const AuthContext = React.createContext({
  isAuth,
  setIsAuth: (auth) => { localStorage.setItem('isAuth', auth); },
});

export const withAuth = compose(
  fromRenderProps(AuthContext.Consumer, ({ isAuth: auth, setIsAuth }) => ({
    isAuth: auth,
    setIsAuth,
  })),
);

export const AuthWrapper = ({ children, ...props }) => (
  <AuthContext.Provider
    value={props}
  >
    {children}
  </AuthContext.Provider>
);

export const withLogin = compose(
  withState('account', 'setAccount', { email: '', password: '' }),
  withHandlers({
    login: ({ setIsAuth, account: { email, password } }) => (e) => {
      // eslint-disable-next-line no-unused-expressions
      e && e.preventdefault && e.preventdefault();
      if (email === process.env.REACT_APP_FAKE_LOGIN_EMAIL && password === process.env.REACT_APP_FAKE_LOGIN_PASSWORD) {
        window.location.reload();
        setIsAuth(true);
      }
    },
    // forceLogin: ({ setIsAuth }) => (e) => {
    //   // eslint-disable-next-line no-unused-expressions
    //   e && e.preventdefault && e.preventdefault();
    //   setIsAuth(true);
    //   window.location.reload();
    // },
    logout: ({ setIsAuth }) => () => {
      setIsAuth(false);
    },
  }),
);
