import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import DownloadCustomerSlider from './DownloadCustomerSlider';
import SectionPartner from '../common/SectionPartner';
import PartnerItems from '../common/PartnerItems';

const DownloadDesktopLinux = () => (
  <div>
    <Helmet>
      <title>Paybit - Download the Desktop App App</title>
    </Helmet>
    <section className="section-download-top pY-50 bdT">
      <div className="container">
        <ul className="breadcrumb breadcrumbs mB-10">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
            <a href="download-iphone-phone">Apps</a>
          </li>
          <li>
            <i className="fas fa-chevron-right" />
            <span>Desktop</span>
          </li>
        </ul>
      </div>
      <div className="section-download-top-monitor">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-5">
              <div className="section-download-left">
                <div className="mB-40">
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.25s">
                    <p className="c-grey line-height-2 mB-15">Paybit for personal</p>
                  </ReactWOW>
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.5s">
                    <h1 className="fw-n fsz-50">Get your money faster, easier and cheaper with PayBit.</h1>
                  </ReactWOW>
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.75s">
                    <p className="c-grey line-height-2 mB-15">The new PayBit Business mobile app makes it easy to stay on top of your account activity, send invoices and view customer information wherever business takes you.</p>
                  </ReactWOW>
                </div>
                <div>
                  <ReactWOW animation="slideInUp" duration="0.5s" delay="1s">
                    <a href="/" className="btn btn-download btn-download-window mB-10">Download Windows</a>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp" duration="0.5s" delay="1s">
                    <a href="/" className="btn btn-download btn-download-linux mB-10">Download Linux</a>
                  </ReactWOW>
                </div>
              </div>
              <div className="mT-30">
                <ReactWOW animation="slideInUp" duration="0.5s" delay="1.25s">
                  <DownloadCustomerSlider />
                </ReactWOW>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pT-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9">
              <h2 className="mY-50 text-center text-before-decor">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</h2>
            </div>
          </div>
          <div className="row mY-50">
            <ReactWOW animation="fadeInUp" delay="0.2s">
              <div className="col-md-12 col-lg-4">
                <div className="pR-30">
                  <div className="c-blue fsz-50 mB-15">
                    <i className="icon-cv" />
                  </div>
                  <h5 className="fw-n">Submit Resume</h5>
                  <p className="c-gray">Apply online or send a resume to your recruiter. Note that the most successful candidates possess the required skills and experience for the role they’ve applied for</p>
                  <a href="/" className="c-black">
                    <b className="fw-n">
  Find our more
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp" delay="0.4s">
              <div className="col-md-12 col-lg-4">
                <div className="pR-30">
                  <div className="c-blue fsz-50 mB-15">
                    <i className="icon-light-up" />
                  </div>
                  <h5 className="fw-n">Have a Conversation</h5>
                  <p className="c-gray">If selected to advance in the process, you&lsquo;ll speak with someone on our recruiting team to learn more about the role you&lsquo;ve applied for and what it&lsquo;s like to work at Paybit. </p>
                  <a href="/" className="c-black">
                    <b className="fw-n">
  Find our more
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp" delay="0.6s">
              <div className="col-md-12 col-lg-4">
                <div className="pR-30">
                  <div className="c-blue fsz-50 mB-15">
                    <i className="icon-interview" />
                  </div>
                  <h5 className="fw-n">Interviews</h5>
                  <p className="c-gray">Next comes a remote interview with a hiring manager or potential teammate. If selected to move forward, you’ll come on-site to interview with people you’ll be working with, after which a recruiter will contact you to discuss any next steps. </p>
                  <a href="/" className="c-black">
                    <b className="fw-n">
  Find our more
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="clearfix" />
          <div className="mT-30">
            <PartnerItems />
          </div>
        </div>
      </div>
    </section>
    <section className="section-our-product">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-5">
            <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
            <h4 className="fw-n">A New Vision of Connectedness</h4>
            <p className="c-text-gray">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</p>
          </div>
        </div>
      </div>
      <div className="our-product-item">
        <ReactWOW animation="fadeInLeft">
          <div className="our-product-item-img">
            <div style={{ backgroundImage: 'url(/images/mockup-dashboard.jpg)' }} />
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInRight">
          <div className="our-product-item-text">
            <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
            <h4 className="fw-n mB-15">A New Vision of Connectedness</h4>
            <p className="c-text-gray mB-15">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</p>
            <a href="/business-tour" className="btn btn-primary btn-lg">Learn More</a>
          </div>
        </ReactWOW>
      </div>
      <div className="our-product-item our-product-item-img-right">
        <ReactWOW animation="fadeInLef">
          <div className="our-product-item-img">
            <div style={{ backgroundImage: 'url(/images/mockup-dashboard-2.jpg)' }} />
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInRight">
          <div className="our-product-item-text">
            <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
            <h4 className="fw-n mB-15">A New Vision of Connectedness</h4>
            <p className="c-text-gray mB-15">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</p>
            <a href="/business-tour" className="btn btn-primary btn-lg">Learn More</a>
          </div>
        </ReactWOW>
      </div>
      <div className="our-product-item our-product-item-2">
        <ReactWOW animation="fadeInLeft">
          <div className="our-product-item-img">
            <div style={{ backgroundImage: 'url(/images/save-money.png)' }} />
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInRight">
          <div className="our-product-item-text">
            <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
            <h4 className="fw-n mB-15">A New Vision of Connectedness</h4>
            <p className="c-text-gray mB-15">
              Millions of customers around the world use us for one simple reason:
              it’s simple. Just an email address and password will get you through
              to checkout before you can reach for your wallet.
              <br />
              <br />
                  Expand the Paybit network and earn money!
              <br />
              <br />
                Refer your friends, family, clients and contacts
                to Paybit and qualify for cash rewards. It’s simple:
                refer someone to use Paybit, and if they meet the conditions
                (read more) then you’ll get paid! All you have to do is copy
                and paste your personalized link or banner code into an email
                or your website to get started!
              <br />
              <br />
            </p>
            <div className="bd bgc-white p-30">
              <div className="d-flex">
                <div className="mR-25">
                  <div className="circle-80">
                    <span className="c-blue">
                      <i className="icon-headphone" />
                    </span>
                  </div>
                </div>
                <div>
                  <h5 className="fw-n mb-10">We offer the best support 24/7</h5>
                  <p className="c-text-gray">Send money to almost anyone with an email address. You can send all around the world and they&lsquo;ll be delighted to get your message. Currency conversion fees may apply.</p>
                </div>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
    </section>
    <section className="pY-60 bgc-white">
      <div className="container">
        <div className="text-center mB-70">
          <h4 className="fw-n">Main Features</h4>
          <p className="c-text-gray">Looking for help or consulting? Fill the form below or email us. We will respond you as soon as possible.</p>
        </div>
        <div className="row">
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-security" />
                </div>
                <h5 className="fw-n">Security</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    Rest easy knowing your crypto is secured with the world’s
                    most trusted crypto wallet. We give you full control,
                    back up your funds, and protect them from unauthorizeds access.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-money-withdraw" />
                </div>
                <h5 className="fw-n">Send &amp; Receive</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    Whether you’re sending to a friend or transacting for business,
                    PayBit allows you to send and receive cryptocurrencies with the
                    lowest fees.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-money-3" />
                </div>
                <h5 className="fw-n">Buy, Sell, &amp; Exchange</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    Easily exchange crypto-to-crypto within your PayBit Wallet.
                    We now offer buy and sell for users in many countries looking
                    to invest in crypto without leaving your secured wallet experience.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-access" />
                </div>
                <h5 className="fw-n">Access Anywhere</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    Transform the financial system right from your pocket!
                    Your Paybit Wallet is available anywhere -
                    ready to be on the go, just like you are.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-support-light" />
                </div>
                <h5 className="fw-n">Simplified disputes resolution</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    No need to worry about market volatility —
                    you always get what you asked for.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-infomation" />
                </div>
                <h5 className="fw-n">All information on one screen</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    This makes our integration
                    risk and hassle-free for merchants and their business.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
        <div className="row align-items-center justify-content-between mT-50">
          <ReactWOW animation="fadeInLeft">
            <div className="col-md-12 col-lg-6">
              <div className="getting-started-boxs">
                <div className="bdrs-6 bd bg-white getting-started-box box-shadow">
                  <div className="d-flex justify-content-between mB-20">
                    <div>
                      <h5 className="fw-n">Transactions</h5>
                      <p className="c-gray fsz-sm mb-0">Since Last Week</p>
                    </div>
                    <div>
                      <a href="/" className="btn btn-o-green btn-around fw-b">
                        <i className="fas fa-caret-up mR-10" />
  15%
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <span className="mR-30 c-blue fsz-50">
                      <i className="icon-collect-money-from-group" />
                    </span>
                    <span className="fsz-60 mL-20">+125</span>
                  </div>
                </div>
                <div className="bdrs-6 bd bg-white getting-started-box box-shadow">
                  <div className="d-flex justify-content-between mB-20">
                    <div>
                      <h5 className="fw-n">Impressions</h5>
                      <p className="c-gray fsz-sm mb-0">Since Last Week</p>
                    </div>
                    <div>
                      <a href="/" className="btn btn-o-green btn-around fw-b">
                        <i className="fas fa-caret-up mR-10" />
  18%
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <span className="mR-30 c-orange fsz-50">
                      <i className="icon-users" />
                    </span>
                    <span className="fsz-60 mL-20">621</span>
                  </div>
                </div>
                <div className="bdrs-6 bd bg-white getting-started-box box-shadow">
                  <div className="d-flex justify-content-between mB-20">
                    <div>
                      <h5 className="fw-n">Income</h5>
                      <p className="c-gray fsz-sm mb-0">Since Last Week</p>
                    </div>
                    <div>
                      <a href="/" className="btn btn-o-red btn-around fw-b">
                        <i className="fas fa-caret-up mR-10" />
  22%
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <span className="mR-30 c-green fsz-50">
                      <i className="icon-money-withdraw" />
                    </span>
                    <span className="fsz-60 mL-20">4.001</span>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInRight">
            <div className="col-md-12 col-lg-5">
              <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
              <h4 className="fw-n">Getting started is easy.</h4>
              <p className="c-text-gray">
                *Key-in transactions and sales over $500 in a 7-day
                period made with the Mobile Card Reader are subject
                to an automatic 30-day reserve where funds are held in
                your Paybit account to cover the high risk associated with
                these types of transactions. For increased protection from
                fraudulent transactions, we recommend using a chip card reader.
                {' '}
                <br />
                <br />
                All Paybit accounts are subject to policies that can lead to account
                restrictions in the form of holds, limitations, or reserves.
              </p>
              <hr className="hr-gray" />
              <div className="mT-30">
                <DownloadCustomerSlider />
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
    <SectionPartner backgroundImage="/images/testimonials-bg2.jpg" />
  </div>
);

export default DownloadDesktopLinux;
