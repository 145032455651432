import React from 'react';
import Countup from 'react-countup';
import ReactWOW from 'react-wow';
import { Dropdown, Tab, Nav } from 'react-bootstrap';

import WalletBalance from './WalletBalance';
import ProductSoldChart from './ProductSoldChart';
import NewCustomer from './NewCustomer';
import WalletCoins from './WalletCoins';
import Stats from './Stats';
import SalesCharts from './SalesCharts';


export const History = ({ dragRef }) => (
  <ReactWOW animation="fadeInRight">
    <div className="col-md-12 col-lg-12 col-xl-7 mB-20" ref={dragRef}>
      <div className="box-style bd bgc-white">
        <span className="box-close-btn"><i className="icon-drag" /></span>
        <div className="box-header box-header-history">
          <div className="d-flex justify-content-between align-items-center box-header-history-inner">
            <div className="d-flex box-header-history-left align-items-center">
              <h5><b className="fw-n">History </b></h5>
              <ul className="lis-n p-0 mL-10 mb-0 list-tab-history">
                <li className="float-left"><a href="/" className="btn btn-white btn-around">Today</a></li>
                <li className="float-left active"><a href="/" className="btn btn-white btn-around">7 Days</a></li>
                <li className="float-left"><a href="/" className="btn btn-white btn-around">14 Days</a></li>
                <li className="float-left"><a href="/" className="btn btn-white btn-around">Last Month</a></li>
              </ul>
            </div>
            <div className="box-header-history-right">
              <span>Bitcoin 0.00098095 </span>
              <Dropdown className="c-gray dropdown dropdown-bitcoin-history d-inline-block" role="group">
                <Dropdown.Toggle
                  as="span"
                  id="dropdown-bitcoin-history"
                  className="btn-transparent dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"

                >
                  - $2.04
                </Dropdown.Toggle>
                <Dropdown.Menu as="div" className="dropdown-menu" aria-labelledby="dropdown-bitcoin-history">
                  <Dropdown.Item as="a" className="dropdown-item" href="/">Dropdown link</Dropdown.Item>
                  <Dropdown.Item as="a" className="dropdown-item" href="/">Dropdown link</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="box-body">
          <div className="table-responsive scrollable max-h-730">
            <table className="table table-common table-common-ct  table-hover">
              <thead>
                <tr>
                  <th>Currency</th>
                  <th>Date</th>
                  <th>Address</th>
                  <th>Transaction ID</th>
                  <th>Sum</th>
                </tr>
              </thead>
              <tbody className="pX-20">
                <tr>
                  <td>
                    <span className="coin-currency">
                      <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                      <span>Bitcoin</span>
                    </span>
                  </td>
                  <td className="c-gray">25.2.2018</td>
                  <td className="c-gray">aBN34234u7f...</td>
                  <td className="c-gray">#245283</td>
                  <td>
                    <span className="sum">
                      <span className="mR-15">&nbsp;</span>
                      <span>
                        <span className="c-black"><Countup end={0.0024} prefix="" suffix=" BTC" separator="," decimal="." duration={3} decimals={4} delay={1} /></span>
                        <br />
                        <span className="c-gray"><Countup end={6.06} prefix="USD " suffix="" separator="," decimal="." duration={3} decimals={2} delay={1} /></span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="coin-currency">
                      <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bytecoin.png)' }} />
                      <span>Bytecoin</span>
                    </span>
                  </td>
                  <td className="c-gray">25.2.2018</td>
                  <td className="c-gray">aBN34234u7f...</td>
                  <td className="c-gray">#245283</td>
                  <td>
                    <span className="sum">
                      <span className="mR-15">+</span>
                      <span>
                        <span className="c-black"><Countup end={0.0024} prefix="" suffix=" BTC" separator="," decimal="." duration={3} decimals={4} delay={1} /></span>
                        <br />
                        <span className="c-green"><Countup end={6.06} prefix="USD " suffix="" separator="," decimal="." duration={3} decimals={2} delay={1} /></span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="coin-currency">
                      <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/dashcoin.png)' }} />
                      <span>Dashcoin</span>
                    </span>
                  </td>
                  <td className="c-gray">25.2.2018</td>
                  <td className="c-gray">litecoin@paybit.com</td>
                  <td className="c-gray">#245283</td>
                  <td>
                    <span className="sum">
                      <span className="mR-15">-</span>
                      <span>
                        <span className="c-black"><Countup end={0.0024} prefix="" suffix=" BTC" separator="," decimal="." duration={3} decimals={4} delay={1} /></span>
                        <br />
                        <span className="c-red"><Countup end={6.06} prefix="USD " suffix="" separator="," decimal="." duration={3} decimals={2} delay={1} /></span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="coin-currency">
                      <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/litecoin.png)' }} />
                      <span>Litecoin</span>
                    </span>
                  </td>
                  <td className="c-gray">25.2.2018</td>
                  <td className="c-gray">litecoin@paybit.com</td>
                  <td className="c-gray">#245283</td>
                  <td>
                    <span className="sum">
                      <span className="mR-15">+</span>
                      <span>
                        <span className="c-black"><Countup end={0.0024} prefix="" suffix=" BTC" separator="," decimal="." duration={3} decimals={4} delay={1} /></span>
                        <br />
                        <span className="c-green"><Countup end={6.06} prefix="USD " suffix="" separator="," decimal="." duration={3} decimals={2} delay={1} /></span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="coin-currency">
                      <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/monero.png)' }} />
                      <span>Monero</span>
                    </span>
                  </td>
                  <td className="c-gray">25.2.2018</td>
                  <td className="c-gray">monero@paybit.com</td>
                  <td className="c-gray">#245283</td>
                  <td>
                    <span className="sum">
                      <span className="mR-15">-</span>
                      <span>
                        <span className="c-black"><Countup end={0.0024} prefix="" suffix=" BTC" separator="," decimal="." duration={3} decimals={4} delay={1} /></span>
                        <br />
                        <span className="c-red"><Countup end={6.06} prefix="USD " suffix="" separator="," decimal="." duration={3} decimals={2} delay={1} /></span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="coin-currency">
                      <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                      <span>Bitcoin</span>
                    </span>
                  </td>
                  <td className="c-gray">25.2.2018</td>
                  <td className="c-gray">aBN34234u7f...</td>
                  <td className="c-gray">#245283</td>
                  <td>
                    <span className="sum">
                      <span className="mR-15">-</span>
                      <span>
                        <span className="c-black"><Countup end={0.0024} prefix="" suffix=" BTC" separator="," decimal="." duration={3} decimals={4} delay={1} /></span>
                        <br />
                        <span className="c-red"><Countup end={6.06} prefix="USD " suffix="" separator="," decimal="." duration={3} decimals={2} delay={1} /></span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="coin-currency">
                      <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bytecoin.png)' }} />
                      <span>Bytecoin</span>
                    </span>
                  </td>
                  <td className="c-gray">25.2.2018</td>
                  <td className="c-gray">aBN34234u7f...</td>
                  <td className="c-gray">#245283</td>
                  <td>
                    <span className="sum">
                      <span className="mR-15">+</span>
                      <span>
                        <span className="c-black"><Countup end={0.0024} prefix="" suffix=" BTC" separator="," decimal="." duration={3} decimals={4} delay={1} /></span>
                        <br />
                        <span className="c-green"><Countup end={6.06} prefix="USD " suffix="" separator="," decimal="." duration={3} decimals={2} delay={1} /></span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="coin-currency">
                      <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/dashcoin.png)' }} />
                      <span>Dashcoin</span>
                    </span>
                  </td>
                  <td className="c-gray">25.2.2018</td>
                  <td className="c-gray">litecoin@paybit.com</td>
                  <td className="c-gray">#245283</td>
                  <td>
                    <span className="sum">
                      <span className="mR-15">-</span>
                      <span>
                        <span className="c-black"><Countup end={0.0024} prefix="" suffix=" BTC" separator="," decimal="." duration={3} decimals={4} delay={1} /></span>
                        <br />
                        <span className="c-red"><Countup end={6.06} prefix="USD " suffix="" separator="," decimal="." duration={3} decimals={2} delay={1} /></span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="coin-currency">
                      <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/litecoin.png)' }} />
                      <span>Litecoin</span>
                    </span>
                  </td>
                  <td className="c-gray">25.2.2018</td>
                  <td className="c-gray">litecoin@paybit.com</td>
                  <td className="c-gray">#245283</td>
                  <td>
                    <span className="sum">
                      <span className="mR-15">+</span>
                      <span>
                        <span className="c-black"><Countup end={0.0024} prefix="" suffix=" BTC" separator="," decimal="." duration={3} decimals={4} delay={1} /></span>
                        <br />
                        <span className="c-green"><Countup end={6.06} prefix="USD " suffix="" separator="," decimal="." duration={3} decimals={2} delay={1} /></span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="coin-currency">
                      <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/monero.png)' }} />
                      <span>Monero</span>
                    </span>
                  </td>
                  <td className="c-gray">25.2.2018</td>
                  <td className="c-gray">monero@paybit.com</td>
                  <td className="c-gray">#245283</td>
                  <td>
                    <span className="sum">
                      <span className="mR-15">-</span>
                      <span>
                        <span className="c-black"><Countup end={0.0024} prefix="" suffix=" BTC" separator="," decimal="." duration={3} decimals={4} delay={1} /></span>
                        <br />
                        <span className="c-red"><Countup end={6.06} prefix="USD " suffix="" separator="," decimal="." duration={3} decimals={2} delay={1} /></span>
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);

export const ProductSold = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp" delay="0.2s">
    <div className="col-md-12 col-lg-6 col-xl-3 mB-20" ref={dragRef}>
      <div className="box-style bd bgc-white bdrs-3">
        <span className="box-close-btn"><i className="icon-drag" /></span>
        <div className="chart-small bdrs-3">
          <ProductSoldChart />
          <div className="d-flex align-items-center p-30 product-sold-info">
            <span className="fsz-sup c-grey"><i className="icon-cubesvg" /></span>
            <div className="mL-20">
              <span className="fsz-xs c-grey">PRODUCTS SOLD</span>
              <div>
                <b className="fsz-sup fw-n"><Countup end={125} prefix="" suffix="" separator="," decimal="." duration={3} delay={1} /></b>
                <span className="c-green pL-5">
                  12%
                  <i className="fas fa-caret-up" />
                </span>
              </div>
            </div>
          </div>
          <span className="chart-button-up"><i className="fas fa-chevron-up" /></span>
        </div>
      </div>
    </div>
  </ReactWOW>
);

export const NewCustomerBox = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp" delay="0.6s">
    <div className="col-md-12 col-lg-6 col-xl-3 mB-20" ref={dragRef}>
      <div className="box-style bd bgc-white bdrs-3">
        <span className="box-close-btn"><i className="icon-drag" /></span>
        <div className="chart-small bdrs-3">
          <NewCustomer />
          <div className="d-flex align-items-center p-30 new-customer-info ">
            <span className="fsz-sup c-grey"><i className="icon-headphones" /></span>
            <div className="mL-20">
              <span className="fsz-xs c-grey">NEW CUSTOMERS</span>
              <div>
                <b className="fsz-sup fw-n"><Countup end={125} prefix="" suffix="" separator="," decimal="." duration={3} delay={1} /></b>
                <span className="c-red pL-5">
                  12%
                  <i className="fas fa-caret-down" />
                </span>
              </div>
            </div>
          </div>
          <span className="chart-button-up"><i className="fas fa-chevron-up" /></span>
        </div>
      </div>
    </div>
  </ReactWOW>
);

export const RefundsProcessed = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp" delay="0.6s">
    <div className="col-md-12 col-lg-6 col-xl-3 mB-20" ref={dragRef}>
      <div className="box-style bd bgc-white bdrs-3">
        <span className="box-close-btn"><i className="icon-drag" /></span>
        <div>
          <div className="d-flex align-items-center p-30">
            <span className="fsz-sup c-grey"><i className="icon-exchange_money" /></span>
            <div className="mL-20">
              <span className="fsz-xs c-grey">REFUNDS PROCESSED</span>
              <div>
                <b className="fsz-sup fw-n"><Countup end={125} prefix="" suffix="" separator="," decimal="." duration={3} delay={1} /></b>
                <span className="c-green pL-5">
                  12%
                  <i className="fas fa-caret-up" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);

export const GrossProfit = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp" delay="0.4s">
    <div className="col-md-12 col-lg-6 col-xl-3 mB-20" ref={dragRef}>
      <div className="box-style bd bgc-white bdrs-3">
        <span className="box-close-btn"><i className="icon-drag" /></span>
        <div>
          <div className="d-flex align-items-center p-30">
            <span className="fsz-sup c-grey"><i className="icon-stats" /></span>
            <div className="mL-20">
              <span className="fsz-xs c-grey">GROSS PROFIT</span>
              <div>
                <b className="fsz-sup fw-n"><Countup end={125} prefix="" suffix="" separator="," decimal="." duration={3} delay={1} /></b>
                <span className="c-red pL-5">
                  12%
                  <i className="fas fa-caret-down" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);

export const ReferFriends = ({ dragRef }) => (
  <ReactWOW animation="fadeInLeft">
    <div className="col-md-12 col-lg-12 col-xl-5 mB-20" ref={dragRef}>
      <div className="form-refer-friend">
        <div className="c-white form-refer-text">
          <h4><b className="fw-n">Refer Friends. Get Rewarded</b></h4>
          <p>
            You can earn: 15,000
            Membership Rewards points for
            each approved referral - up to 55,000 Membership
            Rewards points per calendar year.
          </p>
        </div>
        <form action="#">
          <div className="input_group bdn">
            <input type="text" placeholder="Enter your emaill address…" className="form-control bdn" />
            <button className="btn btn-primary fw-n">Send</button>
          </div>
        </form>
      </div>
    </div>
  </ReactWOW>
);

export const UniqueVisitors = ({ dragRef }) => (
  <ReactWOW animation="fadeInRight">
    <Tab.Container defaultActiveKey="tab_os_overview">
      <div className="col-md-12 col-lg-12 col-xl-7 mB-20" ref={dragRef}>
        <div className="box-style bd bgc-white bdrs-6">
          <span className="box-close-btn"><i className="icon-drag" /></span>
          <div className="box-header">
            <div className="tab-common bgc-white bdB">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Nav className="nav nav-tabs tab-md" id="overview_saleTab" role="tablist">
                    <Nav.Item className="nav-item">
                      <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_os_overview" role="tab">Overview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_sales" role="tab" aria-selected="false">Sales </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div>
                  <ul className="lis-n p-0 mL-10 mb-0 list-tab-history">
                    <li className="float-left"><a href="/" className="btn btn-white btn-around">Today</a></li>
                    <li className="float-left active"><a href="/" className="btn btn-white btn-around">7 Days</a></li>
                    <li className="float-left"><a href="/" className="btn btn-white btn-around">14 Days</a></li>
                    <li className="float-left"><a href="/" className="btn btn-white btn-around">Last Month</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="box-body pY-10 pX-30">
            <div className="chart-unique-visitors">
              <div className="chart-unique-visitors-header">
                <h3 className="fsz-sup">
                  <b className="fw-n"><Countup end={12537} prefix="$" separator="," decimal="." duration={5} /></b>
                  <span className="mL-10 fsz-sm c-gray">Unique Visitors</span>
                </h3>
              </div>
              <div className="chart-unique-visitors-body">
                <SalesCharts />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tab.Container>
  </ReactWOW>
);

export const Contact = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp">
    <div className="col-md-12 col-lg-4 col-xl-4 mB-20" ref={dragRef}>
      <div className="box-style bd bgc-white">
        <span className="box-close-btn"><i className="icon-drag" /></span>
        <div className="box-header">
          <div className="d-flex justify-content-between">
            <h6><b className="fw-n">Contact</b></h6>
            <a href="/" className="c-grey">View All</a>
          </div>
        </div>
        <div className="box-body bdT">
          <div className="scrollable-sm  pY-10 pX-20 list-contact-box">
            <ul className="lis-n p-0 m-0">
              <li className="pY-10 d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                  <b className="fw-n">Bruce Springesteen</b>
                </span>
                <span className="c-gray">11 mutual contacts</span>
              </li>
              <li className="pY-10 d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                  <b className="fw-n">Bruce Springesteen</b>
                </span>
                <span className="c-gray">11 mutual contacts</span>
              </li>
              <li className="pY-10 d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                  <b className="fw-n">Bruce Springesteen</b>
                </span>
                <span className="c-gray">11 mutual contacts</span>
              </li>
              <li className="pY-10 d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                  <b className="fw-n">Bruce Springesteen</b>
                </span>
                <span className="c-gray">11 mutual contacts</span>
              </li>
              <li className="pY-10 d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                  <b className="fw-n">Bruce Springesteen</b>
                </span>
                <span className="c-gray">11 mutual contacts</span>
              </li>
              <li className="pY-10 d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                  <b className="fw-n">Bruce Springesteen</b>
                </span>
                <span className="c-gray">11 mutual contacts</span>
              </li>
              <li className="pY-10 d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                  <b className="fw-n">Bruce Springesteen</b>
                </span>
                <span className="c-gray">11 mutual contacts</span>
              </li>
              <li className="pY-10 d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                  <b className="fw-n">Bruce Springesteen</b>
                </span>
                <span className="c-gray">11 mutual contacts</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);

export const ChatBox = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp">
    <div className="col-md-12 col-lg-4 col-xl-4 mB-20" ref={dragRef}>
      <div className="box-style bd bgc-white">
        <span className="box-close-btn"><i className="icon-drag" /></span>
        <div className="box-header">
          <div className="d-flex justify-content-between">
            <h6><b className="fw-n">Chat box</b></h6>
            <a href="/" className="c-grey">Expand Chat</a>
          </div>
        </div>
        <div className="box-body bdT">
          <div className="scrollable">
            <div className="box-chat-box">
              <div className="list-chat-box scrollable-sm p-20">
                <div className="chat-item">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                  <div className="chat-item-text">
                    <div className="chat-item-username"><b className="fsz-def">Bruce Springesteen</b></div>
                    <p className="mb-0">User typing interaction I designed for a chat app</p>
                  </div>
                </div>
                <div className="chat-item active">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                  <div className="chat-item-text">
                    <div className="chat-item-username"><b className="fsz-def">Jovana Gvero</b></div>
                    <p className="mb-0">Another concept for a speech and teletherapy logo with some soft blue colors. The T and the S are part of the vocal wavelength.</p>
                  </div>
                </div>
                <div className="chat-item">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                  <div className="chat-item-text">
                    <div className="chat-item-username"><b className="fsz-def">Nikola Maric</b></div>
                    <p className="mb-0">Ultimate InBox - final project of messaging app. Take all your messaging under one roof.</p>
                  </div>
                </div>
                <div className="chat-item">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                  <div className="chat-item-text">
                    <div className="chat-item-username"><b className="fsz-def">Bruce Springesteen</b></div>
                    <p className="mb-0">User typing interaction I designed for a chat app</p>
                  </div>
                </div>
                <div className="chat-item active">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                  <div className="chat-item-text">
                    <div className="chat-item-username"><b className="fsz-def">Jovana Gvero</b></div>
                    <p className="mb-0">Another concept for a speech and teletherapy logo with some soft blue colors. The T and the S are part of the vocal wavelength.</p>
                  </div>
                </div>
                <div className="chat-item">
                  <span className="size-32 img bdrs-50p d-block mR-15" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                  <div className="chat-item-text">
                    <div className="chat-item-username"><b className="fsz-def">Nikola Maric</b></div>
                    <p className="mb-0">Ultimate InBox - final project of messaging app. Take all your messaging under one roof.</p>
                  </div>
                </div>
              </div>
              <form className="form-chat-box p-20 bdT">
                <div className="d-flex justify-content-between">
                  <button className="btn btn-primary circle size-36"><i className="icon-camera" /></button>
                  <input className="form-control bdn fcs-n input-sendmessage" type="text" placeholder="Say something..." />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);

export const RecentMessages = ({ dragRef }) => (
  <ReactWOW animation="fadeInUp">
    <div className="col-md-12 col-lg-4 col-xl-4 mB-20" ref={dragRef}>
      <div className="box-style bd bgc-white">
        <span className="box-close-btn"><i className="icon-drag" /></span>
        <div className="box-header">
          <div className="d-flex justify-content-between">
            <h6>
              <b className="fw-n mR-15">Recent Messages</b>
              <span className="badge badge-red">3</span>
            </h6>
            <a href="/" className="c-grey">View All</a>
          </div>
        </div>
        <div className="box-body bdT pT-20">
          <p className="c-gray text-center">232 message unseen</p>
          <div className="list-recent-messages scrollable-sm">
            <div className="recent-messages-item">
              <span className="size-32 img bdrs-50p d-block mR-20" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              <div className="recent-messages-text">
                <div className="recent-messages-item-username">
                  <b className="fsz-def fw-n">Nikola Maric</b>
                  <span className="c-grey fsz-xs">12 minutes</span>
                </div>
                <p className="mb-0">Working on a Dashboard UI and Here is the piece of Chat Window. Let me know your feedba…..</p>
              </div>
            </div>
            <div className="recent-messages-item">
              <span className="size-32 img bdrs-50p d-block mR-20" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              <div className="recent-messages-text">
                <div className="recent-messages-item-username">
                  <b className="fsz-def fw-n">Nikola Maric</b>
                  <span className="c-grey fsz-xs">12 minutes</span>
                </div>
                <p className="mb-0">Working on a Dashboard UI and Here is the piece of Chat Window. Let me know your feedba…..</p>
              </div>
            </div>
            <div className="recent-messages-item">
              <span className="size-32 img bdrs-50p d-block mR-20" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              <div className="recent-messages-text">
                <div className="recent-messages-item-username">
                  <b className="fsz-def fw-n">Nikola Maric</b>
                  <span className="c-grey fsz-xs">12 minutes</span>
                </div>
                <p className="mb-0">Working on a Dashboard UI and Here is the piece of Chat Window. Let me know your feedba…..</p>
              </div>
            </div>
            <div className="recent-messages-item">
              <span className="size-32 img bdrs-50p d-block mR-20" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              <div className="recent-messages-text">
                <div className="recent-messages-item-username">
                  <b className="fsz-def fw-n">Nikola Maric</b>
                  <span className="c-grey fsz-xs">12 minutes</span>
                </div>
                <p className="mb-0">Working on a Dashboard UI and Here is the piece of Chat Window. Let me know your feedba…..</p>
              </div>
            </div>
            <div className="recent-messages-item">
              <span className="size-32 img bdrs-50p d-block mR-20" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              <div className="recent-messages-text">
                <div className="recent-messages-item-username">
                  <b className="fsz-def fw-n">Nikola Maric</b>
                  <span className="c-grey fsz-xs">12 minutes</span>
                </div>
                <p className="mb-0">Working on a Dashboard UI and Here is the piece of Chat Window. Let me know your feedba…..</p>
              </div>
            </div>
            <div className="recent-messages-item">
              <span className="size-32 img bdrs-50p d-block mR-20" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              <div className="recent-messages-text">
                <div className="recent-messages-item-username">
                  <b className="fsz-def fw-n">Nikola Maric</b>
                  <span className="c-grey fsz-xs">12 minutes</span>
                </div>
                <p className="mb-0">Working on a Dashboard UI and Here is the piece of Chat Window. Let me know your feedba…..</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);

export default {
  WalletBalance,
  History,
  ProductSold,
  GrossProfit,
  WalletCoins,
  ReferFriends,
  UniqueVisitors,
  Stats,
};
