import React from 'react';
import { Helmet } from 'react-helmet';

const NotificationMain = () => (
  <section className="p-20 bgc-grey main-content-container">
    <Helmet>
      <title>Paybit - Notification Main</title>
    </Helmet>
    <div className="main-content">
      <div className="box-style bd bgc-white mB-20">
        <div className="box-header box-last-activity-header">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center box-last-activity-header-left">
              <h5>
                <b className="fw-n mR-10">Today’s Notifications:24</b>
                {' '}
                <span className="fsz-sm c-gray"> Yesterday Notifications:20</span>
              </h5>
            </div>
            <div className="d-flex align-items-center">
              <div className="mL-15">
                <div className="input_group">
                  <i className="mL-10 far fa-calendar-check" />
                  <input type="date" placeholder="Select Date" className="form-control bdn" />
                  <button className="btn btn-primary">Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-body">
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 mR-20" style={{ backgroundImage: 'url(/images/prep.png)' }} />
              <div>
                <b className="fw-n">Jonathan Banks</b>
                <p className="c-gray mb-0">Preparation for bio crypto business meeting this week - weekly meeting with </p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 bdrs-50p mR-20" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              <div>
                <b className="fw-n">Tamara Nikcevic send you private message</b>
                <p className="c-gray mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&lsquo;s</p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 mR-20" style={{ backgroundImage: 'url(/images/Payment-icon.png)' }} />
              <div>
                <b className="fw-n">Jonathan Smith</b>
                <p className="c-gray mb-0">Prepayment payment received from the user jonathan</p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
        </div>
      </div>
      <div className="box-style bd bgc-white mB-20">
        <div className="box-header box-last-activity-header">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center box-last-activity-header-left">
              <h5>
                <b className="fw-n mR-10">Yesterday</b>
                {' '}
              </h5>
            </div>
          </div>
        </div>
        <div className="box-body">
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 mR-20" style={{ backgroundImage: 'url(/images/iphone-orange.png)' }} />
              <div>
                <b className="fw-n">Jonathan Banks</b>
                <p className="c-gray mb-0">Deleted the device iphone from the last few days</p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 mR-20" style={{ backgroundImage: 'url(/images/support.png)' }} />
              <div>
                <b className="fw-n">Paybit Support Center</b>
                <p className="c-gray mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&lsquo;s</p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 mR-20" style={{ backgroundImage: 'url(/images/prep.png)' }} />
              <div>
                <b className="fw-n">Jonathan Banks</b>
                <p className="c-gray mb-0">Preparation for bio crypto business meeting this week - weekly meeting with </p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 bdrs-50p mR-20" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
              <div>
                <b className="fw-n">Tamara Nikcevic send you private message</b>
                <p className="c-gray mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&lsquo;s</p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 mR-20" style={{ backgroundImage: 'url(/images/Payment-icon.png)' }} />
              <div>
                <b className="fw-n">Jonathan Smith</b>
                <p className="c-gray mb-0">Prepayment payment received from the user jonathan</p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 mR-20" style={{ backgroundImage: 'url(/images/prep.png)' }} />
              <div>
                <b className="fw-n">Jonathan Banks</b>
                <p className="c-gray mb-0">Preparation for bio crypto business meeting this week - weekly meeting with </p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 bdrs-50p mR-20" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
              <div>
                <b className="fw-n">Tamara Nikcevic send you private message</b>
                <p className="c-gray mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&lsquo;s</p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 mR-20" style={{ backgroundImage: 'url(/images/Payment-icon.png)' }} />
              <div>
                <b className="fw-n">Jonathan Smith</b>
                <p className="c-gray mb-0">Prepayment payment received from the user jonathan</p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <button className="btn btn-primary btn-xl btn-around">Load More Notifications</button>
      </div>
    </div>
  </section>
);

export default NotificationMain;
