import React, { useState, useCallback } from 'react';
import update from 'immutability-helper';
import { reduce } from 'lodash';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import Card from './Card';

const Area = ({ components, areaName = 'SortableArea', type = 'Card' }) => {
  {
    const [cards, setCards] = useState(
      reduce(components, (data, component, initialIndex) => ([
        ...data,
        {
          initialIndex,
          id: `${areaName}-${initialIndex}`,
          component,
          type,
        },
      ]), []),
    );
    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        setCards(
          update(cards, {
            $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
          }),
        );
      },
      [cards],
    );
    const renderCard = (card, index) => (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        moveCard={moveCard}
        {...card}
      />
    );
    return cards.map((card, i) => renderCard(card, i));
  }
};

const SortableArea = props => (
  <DndProvider backend={HTML5Backend}>
    <Area {...props} />
  </DndProvider>
);
export default SortableArea;
