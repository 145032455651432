import {
  compose, lifecycle, mapProps, withState,
} from 'recompose';
import { connect } from 'react-redux';

import generatePaginateAttributes from '../../../utils/paginateHelper';
import { pricesSelector } from '../../../selectors/coinsSelector';
import { fetchPrices } from '../../../actions/coinsAction';

export default (perPage = 100) => compose(
  connect(pricesSelector, { fetchPrices }),
  lifecycle({
    componentDidMount() {
      this.props.fetchPrices();
    },
  }),
  withState('page', 'changePage', 1),
  withState('isShowAll', 'showAll', false),
  mapProps(({
    prices, page, isShowAll, ...props
  }) => {
    const pagination = generatePaginateAttributes(prices, page, perPage, isShowAll);
    return {
      ...props,
      pagination,
      prices,
      isShowAll,
    };
  }),
);
