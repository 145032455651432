import React from 'react';
import { Helmet } from 'react-helmet';

import { compose } from 'recompose';
import TableCryptoMartket from './TableCryptoMartket';
import Paginate from '../common/Paginate';
import CryptoMarketContainer from './containers/CryptoMarketContainer';

const CryptoMarket = (props) => {
  const {
    pagination: {
      data: prices,
      page,
      isLast,
    },
    isShowAll,
    changePage,
    showAll,
  } = props;
  return (
    <>
      <Helmet>
        <title>Paybit - Crypto Market</title>
      </Helmet>
      <section className="bdT bgc-grey pY-30">
        <div className="container">
          <div className="mB-30">
            <ul className="breadcrumb breadcrumbs">
              <li><a href="/"><i className="icon-house" /></a></li>
              <li>
                <i className="fas fa-chevron-right" />
              Crypto Market
              </li>
            </ul>
          </div>
          <div className="crypto-market-search mB-40">
            <div className="input-group">
              <div className="input-group-prepend">
                <div><i className="icon-search" /></div>
              </div>
              <input type="text" className="form-control form_control" placeholder="Search Crypto Currenies..." />
              <div className="input-group-append">
                <button className="btn-primary btn">Search</button>
              </div>
            </div>
          </div>
          <div className="box-style bd bgc-white mB-20">
            <div className="box-header d-flex d-md-inline-block justify-content-between w-100">
              <div className="mR-10 d-flex d-md-inline-block w-100">
                <select className="form-control form_control mR-10 mB-5">
                  <option value>All</option>
                </select>
                <select className="form-control form_control mR-10 mB-5">
                  <option value>Coins</option>
                </select>
                <select className="form-control form_control mR-10 mB-5">
                  <option value>Tokens</option>
                </select>
              </div>
              <div className="d-flex d-md-inline-block w-100 justify-content-end">
                {(isLast
                  ? (
                    !isShowAll && (
                      <a onClick={() => changePage(page - 1)} className="btn btn-lg btn-outline-light bd c-black mR-10 mB-5">
                        <i className="fas fa-chevron-left mR-20" />
                    previous 100
                      </a>
                    )
                  ) : (
                    !isShowAll && (
                      <a onClick={e => changePage(page + 1)} className="btn btn-lg btn-outline-light bd c-black mR-10 mB-5">
                  Next 100
                        <i className="fas fa-chevron-right mL-20" />
                      </a>
                    )
                  ))}
                <a onClick={() => showAll(!isShowAll)} className="btn btn-lg btn-outline-light bd c-black mR-10 mB-5">
                  {isShowAll ? 'Per Page 100' : 'View All'}
                </a>
              </div>
            </div>
            <div className="box-body">
              <TableCryptoMartket prices={prices} />
            </div>
          </div>
          <Paginate {...props} />
        </div>
      </section>
      <section className="section-imgs section-subscribe" style={{ backgroundImage: 'url(/images/subscribe-bg.jpg)' }}>
        <div className="inner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-10 col-lg-6">
                <div className="c-white text-center">
                  <h2 className="fsz-sup fw-n mB-10">Subscribe Our Newsletter!</h2>
                  <p className="c-white-90 mB-20 fsz-md">For get all of our latest updates and promotion</p>
                  <div className="form-subscribe">
                    <div className="input-group">
                      <input type="email" className="form-control form_control" placeholder="Enter your email address" />
                      <div className="input-group-append">
                        <button className="btn-primary btn"><i className="icon-right-arrow" /></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default compose(
  CryptoMarketContainer(100),
)(CryptoMarket);
