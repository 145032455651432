import React from 'react';
import { compose, mapProps } from 'recompose';
import { get, min, max } from 'lodash';

import { DetailCryptoChart } from '../../CryptoMarket/DetailCryptoChart';
import { withDetailCryto } from '../../CryptoMarket/containers/CryptoDetailContainer';
import { significantDigits } from '../../../utils/currencyFormater';

export const CurrentCryptoPriceGrapht = ({
  maxValue, minValue,
  durationTypes,
  chartDuration, setChartDuration,
  chartData, historical,
  durationSettings, symbol, price: {
    marketCap,
    price,
    circulatingSupply,
    volume,
    change,
  } = {},
}) => (
  <div className="bd bgc-white mB-10">
    <div className="pT-10 pX-20">
      <div className="d-flex d-md-inline-block w-100 justify-content-between align-items-center bdB">
        <div className="d-flex d-md-inline-block float-left">
          <div className="mR-30">
            <h4 className="fw-n mb-0">
              $
              {significantDigits(price)}
            </h4>
            <p className="c-green fsz-def">
              <i className="fas fa-sort-up" />
              {significantDigits(change)}
%
            </p>
          </div>
        </div>
        <div className="d-flex d-md-inline-block float-left">
          <div className="d-flex">
            <div className="mR-40">
              <p className="c-gray mb-0">Market Cap</p>
              <h5 className="fw-n">
                {significantDigits(marketCap)}
%
              </h5>
            </div>
            <div className="mR-40">
              <p className="c-gray mb-0">Circ Sply</p>
              <h5 className="fw-n">
                {significantDigits(circulatingSupply)}
                {' '}
                {symbol}
              </h5>
            </div>
            <div className="mR-40">
              <p className="c-gray mb-0">24H Vol</p>
              <h5 className="fw-n">
                $
                {significantDigits(volume)}
              </h5>
            </div>
            <div>
              <p className="c-gray mb-0">24h High/Low</p>
              <h5 className="fw-n">
                $
                {significantDigits(maxValue)}
                {' '}
                / $
                {' '}
                {significantDigits(minValue)}

              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="pX-10">
      <DetailCryptoChart
        {...{
          durationTypes, chartDuration, setChartDuration, chartData, historical, durationSettings, symbol,
        }}
      />
    </div>
  </div>
);

export default compose(
  withDetailCryto,
  mapProps(props => ({
    ...props,
    price: (props.prices || []).find(price => price.symbol === props.symbol),
    maxValue: max(Object.values(get(props, 'historical.durationType.day', {}))) || 0,
    minValue: min(Object.values(get(props, 'historical.durationType.day', {}))) || 0,
  })),
)(CurrentCryptoPriceGrapht);
