import { compose, withState, mapProps } from 'recompose';

export default compose(
  withState('collapseIndex', 'setCollapse', 0),
  mapProps(({ collapse, setCollapse, ...props }) => ({
    setCollapse: index => (e) => {
      if (e && e.preventdefault) {
        e.preventdefault();
        e.stoppropagation();
      }
      setCollapse(index);
    },
    ...props,
  })),
);
