/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

export const NoneRedirectA = ({
  href,
  disableWidth,
  onClick,
  stopEventHandler = (e) => {
    if (((disableWidth && window.innerWidth <= disableWidth) || !href) && e) {
      if (e.preventDefault) e.preventDefault();
      if (e.stopPropagation) e.stopPropagation();
    }
    if (onClick) onClick(e);
  },
  ...props
}) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    onClick={stopEventHandler}
    {...props}
    href={disableWidth ? href : null}
  />
);

export default NoneRedirectA;
