import React from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';

import { withTranslation } from 'react-i18next';
import withModal from '../../../../hocs/withModal';

const ToggleMenu = ({
  isOpenModal: active,
  toggleModal: toggleMenu,
  name,
  href,
  iconName,
  counter,
  hasSubmenu = true,
  t,
}) => (
  <li className={classNames('nav-item', { active, 'has-submenu': hasSubmenu })}>
    <a
      href={href}
      className="sidebar-link"
      onClick={(e) => {
        if (hasSubmenu) {
          e.preventDefault();
          e.stopPropagation();
          toggleMenu();
        }
      }}
    >
      <span className="icon-holder">
        <i className={iconName} />
      </span>
      <span className="title">{name}</span>
      { counter && <span className="counter">{counter}</span> }
      {hasSubmenu && <span className="arrow"><i className="fas fa-chevron-down opentoggleMenu" /></span>}
    </a>
    {hasSubmenu && (
      <ul className="sub-menu">
        <li className="active"><a href={href}>{t('Index')}</a></li>
        <li><a href="/dashboard_new/send-money-dedicated">{t('Send Money')}</a></li>
        <li><a href="/dashboard_new/notification-main">{t('Notifications')}</a></li>
        <li><a href="/dashboard_new/main-settings">{t('Settings')}</a></li>
        <li><a href="/dashboard_new/message-center">{t('Messages')}</a></li>
        <li><a href="/dashboard_new/contact">{t('Contacts')}</a></li>
      </ul>
    )}
  </li>
);

export default compose(
  withModal(),
  withTranslation('ToggleMenu'),
)(ToggleMenu);
