import React from 'react';

import Account from '../common/FAQS/Account';
import SecurityNPrivacy from '../common/FAQS/SecurityNPrivacy';
import PartnerItems from '../common/PartnerItems';

const DownloadFooter = () => (
  <>
    <section className="bgc-grey pT-50 section-job">
      <div className="container">
        <div className="mT-30 row">
          <Account className="mB-15" />
          <SecurityNPrivacy className="mB-15" />
        </div>
        <div className="mY-40 text-center"><a href="/" className="btn btn-primary btn-around btn-lg">Find out More</a></div>
      </div>
    </section>
    <section className="bgc-white pB-50 section-partner-carrer">
      <div className="container">
        <div className="row justify-content-center video-iframe-carrer">
          <div className="col-md-11 col-lg-10">
            <iframe
              title="this is title"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/3MKj2qVk2yQ?controls=0"
              frameBorder={0}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
        <div className="text-center mB-30">
          <div className="mB-20 fsz-xl">Do the Most Meaningful Work of Your Career</div>
          <p className="c-text-gray fsz-def line-height-2">
People are at the heart of every connection we build. We design products and deliver
            {' '}
            <br />
services that bring the world closer together — one connection at a time.
          </p>
        </div>
        <PartnerItems />
      </div>
    </section>
    <section className="section-imgs section-subscribe" style={{ backgroundImage: 'url(/images/subscribe-bg.jpg)' }}>
      <div className="inner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-6">
              <div className="c-white text-center">
                <h2 className="fsz-sup fw-n mB-10">Subscribe Our Newsletter!</h2>
                <p className="c-white-90 mB-20 fsz-md">For get all of our latest updates and promotion</p>
                <div className="form-subscribe">
                  <div className="input-group">
                    <input type="email" className="form-control form_control" placeholder="Enter your email address" />
                    <div className="input-group-append">
                      <button className="btn-primary btn"><i className="icon-right-arrow" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default DownloadFooter;
