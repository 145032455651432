/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';

import { withTranslation } from 'react-i18next';
import ToggleMenu from './ToggleMenu';
import UserStep from './UserStep';
import { withAuth, withLogin } from '../../../../hocs/withAuth';

const Sidebar = ({
  toggleActive, active, logout, t, ...props
}) => (
  <div className="sidebar">
    <div className="sidebar-inner">
      <div className="sidebar-logo">
        <a href="/">
          <div className="logo" style={{ backgroundImage: 'url(/images/paybit-logo.png)' }} />
        </a>
        <button className={classNames('sidebar-toggle btn-transparent', { active })} onClick={toggleActive}>
          <span />
          <span />
          <span />
        </button>
      </div>
      <div
        className="sidebar-list scrollable"
        onScroll={(e) => { e.target.classList.add('scrolled'); }}
        onMouseOut={(e) => { e.target.classList.remove('scrolled'); }}
        role="presentation"
      >
        <ul className="sidebar-menu">
          <ToggleMenu
            iconName="icon-dashboard"
            name={t('Dashboard')}
            href="/dashboard_new"
          />
          <ToggleMenu
            iconName="icon-wallet"
            name={t('Wallets')}
            href="/dashboard_new/wallets-address"
            hasSubmenu={false}
          />
          <ToggleMenu
            iconName="icon-transactions"
            name={t('Transactions')}
            href="/dashboard_new/transactions"
            hasSubmenu={false}
          />
          <ToggleMenu
            iconName="icon-notifications"
            counter={3}
            name="Notifications"
            href="/dashboard_new/notification-main"
            hasSubmenu={false}
          />
          <ToggleMenu
            iconName="icon-statistics"
            name={t('Statistics')}
            href="/dashboard_new/reports"
            hasSubmenu={false}
          />
          <ToggleMenu
            iconName="icon-settings"
            name={t('Settings')}
            href="/dashboard_new/main-settings"
            hasSubmenu={false}
          />
          <li className="nav-item">
            <a href="/dashboard_new/referral" className="sidebar-link">
              <span className="icon-holder">
                <i className="icon-referrals" />
              </span>
              <span className="title">Referrals</span>
            </a>
          </li>
          <ToggleMenu
            iconName="icon-wallet"
            name={t('Help')}
            href="/help-center"
            hasSubmenu={false}
          />
          <li className="nav-item">
            <a href="/api-documentation" className="sidebar-link">
              <span className="icon-holder">
                <i className="icon-api" />
              </span>
              <span className="title">API</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/" className="sidebar-link" onClick={logout}>
              <span className="icon-holder">
                <i className="icon-logout" />
              </span>
              <span className="title">Log Out</span>
            </a>
          </li>
        </ul>
        <div className="sidebar-footer">
          <UserStep {...props} />
        </div>
      </div>
    </div>
  </div>
);

export default compose(
  withAuth,
  withLogin,
  withTranslation('Sidebar'),
)(Sidebar);
