import React from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';

import withModal from '../../../../hocs/withModal';

const HeaderSearchForm = ({ isOpenModal: active, toggleModal }) => (
  <div className={classNames({ active }, 'header-search-form')}>
    <input type="text" className="form-control" />
    <button className="btn-transparent button-search" onClick={toggleModal}><i className="icon-search" /></button>
  </div>
);

export default compose(
  withModal(),
)(HeaderSearchForm);
