import React from 'react';
import { mapProps, compose } from 'recompose';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import NoneRedirectA from '../NoneRedirectA';

const FAQ = ({
  q, a, key, active, onClick,
}) => (
  <div className="faq-horizonal-item" key={key}>
    <div className="faq-horizonal-item-header" id="headingOne">
      <NoneRedirectA
        className={classNames(
          'btn-toggle-faq',
          {
            collapsed: !active,
          },
        )}
        href="/"
        aria-controls={key}
        // aria-expanded={active}
        onClick={onClick}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: q }}
      />
    </div>
    <Collapse in={active}>
      <div
        className="faq-horizonal-item-body"
        id={key}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: a }}
      />
    </Collapse>
  </div>
);

export default compose(
  withTranslation('FAQ'),
  mapProps(
    ({
      index, collapseIndex, setCollapse, t, a, q, ...props
    }) => ({
      index,
      active: index === collapseIndex,
      onClick: setCollapse(index === collapseIndex ? -1 : index),
      q: t(q),
      a: t(a),
      ...props,
    }),
  ),
)(FAQ);
