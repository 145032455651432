import React from 'react';
import Countup from 'react-countup';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const SendMoneyDedicated = () => (
  <section>
    <Helmet>
      <title>Paybit - Send Money Dedicated</title>
    </Helmet>
    <Tab.Container defaultActiveKey="tab_send_money">
      <div className="tab-common pX-25 bgc-white bdB">
        <Nav className="nav nav-tabs tab-lg" id="mainTab" role="tablist">
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="tab_send_money">
              <i className="icon-overview" />
              Send Money
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="tab_send_to_wallet">
              <i className="icon-wallet" />
              Send to Wallet
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="tab_send_multiple_payments">
              <i className="icon-multi-payment" />
              Send Multiple Payments
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="tab_exchange_money">
              <i className="icon-shuffle" />
              Exchange Money
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Tab.Content className="tab-content" id="mainTabContent">
        <Tab.Pane className="tab-pane" eventKey="tab_send_money">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="pY-20 pX-30 bd bgc-white">
                <p className="mB-20 c-gray">Shopping online, paying for good or services, or splitting expenses? Use PayBit to securely pay for your online purchases or send a personal payment.</p>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-6">
                    <div className="form-group input-has-icon-right">
                      <label className="c-grey">Recipient</label>
                      <i className="fas fa-check-circle c-green" />
                      <input type="text" className="form-control form_control" defaultValue="124LSFsad24ih42ih42FLLsddss1SSD2ds23ss2" />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-3">
                    <div className="form-group">
                      <label className="c-grey">Sum</label>
                      <input type="text" className="form-control form_control" placeholder="Enter Sum" />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-3">
                    <div className="form-group">
                      <label className="c-grey">Currency</label>
                      <div className="dropdown select-coin">
                        <button className="form_control btn-transparent dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true">
                          <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
Bitcoin
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                          <ul className="lis-n p-0 m-0">
                            <li>
                              <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
Bitcoin
                            </li>
                            <li>
                              <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
Ethereum
                            </li>
                            <li>
                              <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
Bitcoin
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label className="c-grey">Note</label>
                      <textarea className="form-control form_control" cols={30} rows={5} placeholder="Write an optional message" defaultValue="" />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end">
                  <div className="col-12 col-md-6 col-lg-3">
                    <button className="btn btn-primary btn-xl w-100">Send</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane" eventKey="tab_send_to_wallet">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="pY-20 pX-30 bd bgc-white">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3">
                    <div className="form-group">
                      <label className="c-grey">Currency</label>
                      <div className="dropdown select-coin">
                        <button className="form_control btn-transparent dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true">
                          <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
Bitcoin
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                          <ul className="lis-n p-0 m-0">
                            <li>
                              <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
Bitcoin
                            </li>
                            <li>
                              <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
Ethereum
                            </li>
                            <li>
                              <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
Bitcoin
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-5">
                    <div className="form-group">
                      <label className="c-grey">To</label>
                      <div className="input-group">
                        <input type="text" className="form-control form_control" placeholder="Paste or scan an address, or select a destination" />
                        <div className="input-group-append">
                          <button className="btn btn-outline-secondary bd c-gray pX-15" type="button"><i className="icon-qr-code" /></button>
                          <button className="btn btn-outline-secondary bd c-gray pX-15" type="button"><i className="fas fa-caret-down" /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="form-group">
                      <label className="c-grey">From</label>
                      <select className="form-control form_control">
                        <option value>My Bitcoin Wallet</option>
                        <option value>My ETH Wallet</option>
                        <option value>My Paybit Wallet</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-5 col-lg-5 col-xl-4">
                    <div className="form-group">
                      <label className="c-grey">Enter Amount</label>
                      <div className="input-group">
                        <input type="number" className="form-control form_control" placeholder="Enter Amount" />
                        <div className="input-group-append">
                          <div className="dropdown select-coin btn bd d-flex align-items-center">
                            <div className="btn-transparent dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true">
                              <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                              <span className="c-black">BTC</span>
                            </div>
                            <div className="dropdown-menu">
                              <ul className="lis-n p-0 m-0">
                                <li>
                                  <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                  <span className="c-black">BTC</span>
                                </li>
                                <li>
                                  <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                                  <span className="c-black">ETH</span>
                                </li>
                                <li>
                                  <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                  <span className="c-black">BTC</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2 col-xl-1">
                    <div className="form-group">
                      <label className="c-grey" />
                      <div className="d-flex justify-content-center align-items-center p-15"><i className="fas fa-arrows-alt-h fsz-lg c-grey" /></div>
                    </div>
                  </div>
                  <div className="col-12 col-md-5 col-lg-5 col-xl-3">
                    <div className="form-group">
                      <label className="c-grey" />
                      <div className="input-group">
                        <input type="text" className="form-control form_control" defaultValue="1.523123" />
                        <div className="input-group-append">
                          <button className="btn btn-outline-secondary bd c-gray pX-15" type="button"><b className="fw-n c-black">USD</b></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-2">
                    <div className="form-group">
                      <label className="c-grey">Transaction Fee</label>
                      <select className="form-control form_control">
                        <option value>Regular</option>
                        <option value>Regular</option>
                        <option value>Regular</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-2">
                    <div className="form-group">
                      <label className="c-grey" />
                      <div>
                        <p className="mb-0"><b className="fw-n">0 BTC ($0.00)</b></p>
                        <a href="/" className="c-blue">Customize Fee</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label className="c-grey">Description</label>
                      <textarea className="form-control form_control" cols={30} rows={5} placeholder="What’s this transaction for?" defaultValue="" />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-12 col-md-12 col-lg-8 mB-10">
                    <p className="mb-0 c-gray">Estimated confirmation time 1+ hour</p>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4 mB-10">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-4">
                        <p className="c-gray mB-5">Continue</p>
                        <b className="fw-n fsz-def">$3.24522</b>
                      </div>
                      <div className="col-12 col-md-12 col-lg-8">
                        <button className="btn btn-primary btn-xl w-100">Continue</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane" eventKey="tab_send_multiple_payments">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="pY-20 pX-30 bd bgc-white">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="c-grey">Currency</label>
                      <div className="dropdown select-coin">
                        <button className="form_control btn-transparent dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true">
                          <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
Bitcoin
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                          <ul className="lis-n p-0 m-0">
                            <li>
                              <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
Bitcoin
                            </li>
                            <li>
                              <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
Ethereum
                            </li>
                            <li>
                              <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
Bitcoin
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="c-grey">Recipient</label>
                      <input type="text" className="form-control form_control" defaultValue="1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-2">
                    <div className="form-group">
                      <label className="c-grey">Enter Amount</label>
                      <div className="input-group">
                        <input type="text" className="form-control form_control" defaultValue={24} />
                        <div className="input-group-append">
                          <button className="btn btn-outline-secondary bd c-gray pX-15" type="button"><b className="fw-n c-black">BTC</b></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="c-grey d-flex align-items-center justify-content-between">
                        <span>Upload Document </span>
                        <small className="float-right">We only accept txt/.csv file</small>
                      </label>
                      <input type="text" className="form-control form_control" defaultValue="1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX" />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-1">
                    <div className="form-group">
                      <label className="c-grey" />
                      <div className>
                        <button className="p-15 btn btn-transparent fsz-md"><i className="icon-add" /></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="c-grey">Currency</label>
                      <select className="form-control form_control">
                        <option value>Select Currency</option>
                        <option value>BTC</option>
                        <option value>ETH</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="c-grey">Recipient</label>
                      <input type="text" className="form-control form_control" placeholder="Enter his name or wallet address" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-2">
                    <div className="form-group">
                      <label className="c-grey">Enter Amount</label>
                      <div className="input-group">
                        <input type="text" className="form-control form_control" placeholder={0} />
                        <div className="input-group-append">
                          <button className="btn btn-outline-secondary bd c-gray pX-15" type="button"><b className="fw-n c-black">BTC</b></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="c-grey d-flex align-items-center justify-content-between">
                        <span>Upload Document </span>
                        <small className="float-right">We only accept txt/.csv file</small>
                      </label>
                      <input type="text" className="form-control form_control" defaultValue="1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX" />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-1">
                    <div className="form-group">
                      <label className="c-grey" />
                      <div className>
                        <button className="p-15 btn btn-transparent fsz-md"><i className="icon-add" /></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-12 col-md-12 col-lg-7 mB-10">
                    <p className="mB-15 c-gray">Please enter your paymentsi n the following format</p>
                    <p className="mb-0 c-gray">
                      <span className="mR-20">
Recepient:
                        <b className="fw-n c-black">XXX</b>
                      </span>
                      <span className="mR-20">
Sum:
                        <b className="fw-n c-black">XXX</b>
                      </span>
                      <span className="mR-20">
Currency:
                        <b className="fw-n c-black">BTC</b>
                      </span>
                      <span className="mR-20">
Comment:
                        <b className="fw-n c-black">XXX</b>
                      </span>
                    </p>
                  </div>
                  <div className="col-12 col-md-12 col-lg-5 mB-10">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6">
                        <button className="btn btn-warning btn-xl w-100">Mass Payments</button>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <button className="btn btn-primary btn-xl w-100">Continue</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane div className="tab-pane" eventKey="tab_exchange_money">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="p-30 bd bgc-white">
                <div className="row justify-content-between">
                  <div className="col-md-12 col-lg-3">
                    <h5 className="mB-15"><b className="fw-n">Simple. Seamless. Secure.</b></h5>
                    <p className="c-gray">You can exchange between bitcoin, ether, and bitcoin cash directly from your PayBit wallet. In a few simple steps, your exchange will be in progress. Note: exchanges usually take between twenty minutes and two hours. Need help? Contact Support</p>
                  </div>
                  <div className="col-md-12 col-lg-8">
                    <div className="row">
                      <div className="col-12 col-md-5">
                        <div className="form-group">
                          <label className="c-grey">Exchange</label>
                          <div className="dropdown select-coin">
                            <button className="form_control btn-transparent dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true">
                              <i className="icon-home mR-10" />
                              My Bitcoin Wallet
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenu1"
                              x-placement="bottom-start"
                              style={{
                                position: 'absolute', transform: 'translate3d(0px, 51px, 0px)', top: '0px', left: '0px', willChange: 'transform',
                              }}
                            >
                              <ul className="lis-n p-0 m-0">
                                <li>
                                  <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                  Bitcoin
                                </li>
                                <li>
                                  <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                                  Ethereum
                                </li>
                                <li>
                                  <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                  Bitcoin
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-2">
                        <div className="form-group">
                          <label className="c-grey" />
                          <div className="p-15 w-100 text-center"><i className="icon-double-arrow c-gray fsz-lg" /></div>
                        </div>
                      </div>
                      <div className="col-12 col-md-5">
                        <div className="form-group">
                          <label className="c-grey">Receive</label>
                          <div className="dropdown select-coin">
                            <button className="form_control btn-transparent dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true">
                              <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                              Ethereum Wallet
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                              <ul className="lis-n p-0 m-0">
                                <li>
                                  <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                  Bitcoin Wallet
                                </li>
                                <li>
                                  <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                                  Ethereum Wallet
                                </li>
                                <li>
                                  <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                  Bitcoin Wallet
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="c-grey">Exchange</label>
                      <div className="row align-items-center">
                        <div className="col-12 col-md-5">
                          <div className="input-group">
                            <input type="text" className="form-control form_control bdrs-0" defaultValue={24} />
                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary bd c-gray pX-15 bdrs-0" type="button">
                                <span className="coin-20 float-left mR-10" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                <b className="fw-n c-black">BTC</b>
                              </button>
                            </div>
                          </div>
                          <div className="input-group">
                            <input type="text" className="form-control form_control bdrs-0" defaultValue={12551244} />
                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary bd c-gray pX-15 bdrs-0" type="button">
                                <span className="coin-20 float-left mR-10"><i className="fas fa-dollar-sign" /></span>
                                <b className="fw-n c-black">BTC</b>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-2">
                          <div className="p-15 w-100 text-center"><i className="icon-right-arrow c-gray fsz-lg" /></div>
                        </div>
                        <div className="col-12 col-md-5">
                          <div className="input-group">
                            <input type="text" className="form-control form_control bdrs-0" defaultValue={24} />
                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary bd c-gray pX-15 bdrs-0" type="button">
                                <span className="coin-20 float-left mR-10" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                                <b className="fw-n c-black">ETH</b>
                              </button>
                            </div>
                          </div>
                          <div className="input-group">
                            <input type="text" className="form-control form_control bdrs-0" defaultValue={12551244} />
                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary bd c-gray pX-15 bdrs-0" type="button">
                                <span className="coin-20 float-left mR-10"><i className="fas fa-dollar-sign" /></span>
                                <b className="fw-n c-black">BTC</b>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-12 col-md-12 col-lg-9 mB-10">
                        <p className="mb-0 c-gray">Estimated confirmation time 1+ hour</p>
                      </div>
                      <div className="col-12 col-md-12 col-lg-3 mB-10">
                        <button className="btn btn-primary btn-xl w-100" data-toggle="modal" data-target="#successModal">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
    <div className="pB-20 pX-20 bgc-grey main-content-container">
      <div className="box-style bd bgc-white mB-20">
        <span className="box-close-btn"><i className="icon-drag" /></span>
        <div className="box-header box-last-activity-header">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center box-last-activity-header-left">
              <h5>
                <b className="fw-n mR-10">Last activity </b>
                {' '}
                <span className="fsz-sm c-gray">Year 2018</span>
              </h5>
              <div className="mL-30">
                <a className="c-gray" href="/">(Show All)</a>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="mR-15">
                <div className="input_group">
                  <i className="mL-10 icon-search" />
                  <input type="text" placeholder="Search now" className="form-control bdn" />
                  <button className="btn btn-primary">Search</button>
                </div>
              </div>
              <div>
                <div className="input_group">
                  <i className="mL-10 far fa-calendar-check" />
                  <input type="date" placeholder="Select Date" className="form-control bdn" />
                  <button className="btn btn-primary">Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-body pY-20">
          <Tab.Container defaultActiveKey="tab_alltransaction">
            <div className="tab-common pX-25 bgc-white">
              <div className="d-flex align-items-center justify-content-between">
                <Nav className="nav nav-tabs tab-md" id="last_activityTab" role="tablist">
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" eventKey="tab_alltransaction">All Transaction </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" eventKey="last_activityTab1">Recieved </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" eventKey="last_activityTab2">Sent </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" eventKey="last_activityTab3">Withdrawn </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" eventKey="last_activityTab4">Logins </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" eventKey="last_activityTab5">Exchanges </Nav.Link>
                  </Nav.Item>
                </Nav>
                <div>
                  <button className="btn btn-transparent mR-20">
                    <i className="c-green icon-export mR-10" />
                    Export Table
                  </button>
                  <button className="btn btn-transparent">
                    <i className="c-yellow icon-print mR-10" />
                    Print
                  </button>
                </div>
              </div>
            </div>
            <Tab.Content className="tab-content p-0" id="last_activityTabContent">
              <Tab.Pane className="tab-pane" eventKey="tab_alltransaction" role="tabpanel">
                <div className="table-responsive scrollable max-h-730">
                  <table className="table table-common table-hover table-refferal">
                    <thead>
                      <tr>
                        <th>Name/Email</th>
                        <th>Date</th>
                        <th>Action</th>
                        <th>Status</th>
                        <th>Details</th>
                        <th>Amount</th>
                        <th>Transaction ID</th>
                        <th className="pX-10">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="pX-20">
                      <tr>
                        <td>
                          <div className="name-email d-flex align-items-center">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                            <div className="mL-20">
                              <span>
                                Bruce Springesteen
                                <i className="icon-contacts mL-10 c-blue" />
                              </span>
                              <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                            </div>
                          </div>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                            <span>
                              Sent to
                              <br />
                              {' '}
                              <span className="fsz-xs c-grey">Bitcoin Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-green fsz-md fas fa-check-circle mR-5" />
                              Complete
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-red">
                            {' '}
                            <Countup end={-800} suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="name-email d-flex align-items-center">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                            <div className="mL-20">
                              <span>James Blunt</span>
                              <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                            </div>
                          </div>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/ethereum.png)' }} />
                            <span>
                              Received
                              <br />
                              {' '}
                              <span className="fsz-xs c-grey">From Ethereum Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-green fsz-md fas fa-check-circle mR-5" />
                            Complete
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-green">
                            {' '}
                            <Countup end={1800.00} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="name-email d-flex align-items-center">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                            <div className="mL-20">
                              <span>Cristopter Smith</span>
                              <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                            </div>
                          </div>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/monero.png)' }} />
                            <span>
                              Received
                              <br />
                              {' '}
                              <span className="fsz-xs c-grey">From Monero Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-red fsz-md fas fa-times-circle mR-5" />
                            Cancelled
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-green">
                            {' '}
                            <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="name-email d-flex align-items-center">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                            <div className="mL-20">
                              <span>James Blunt</span>
                              <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                            </div>
                          </div>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/litecoin.png)' }} />
                            <span>
                              Send to
                              <br />
                              <span className="fsz-xs c-grey">From Litecoin Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-green fsz-md fas fa-check-circle mR-5" />
                            Complete
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-red">
                            {' '}
                            <Countup end={1800.00} prefix="-" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="name-email d-flex align-items-center">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                            <div className="mL-20">
                              <span>James Blunt</span>
                              <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                            </div>
                          </div>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/ethereum.png)' }} />
                            <span>
                              Received
                              <br />
                              {' '}
                              <span className="fsz-xs c-grey">From Ethereum Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-yellow fsz-md fas fa-exclamation-circle mR-5" />
                            Pending
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-green">
                            {' '}
                            <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="name-email d-flex align-items-center">
                            <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                            <div className="mL-20">
                              <span>Cristopter Smith</span>
                              <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                            </div>
                          </div>
                        </td>
                        <td><span className="c-gray">25.2.2018</span></td>
                        <td>
                          <span className="coin-currency">
                            <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/monero.png)' }} />
                            <span>
                              Received
                              <br />
                              <span className="fsz-xs c-grey">From Monero Wallet</span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <i className="c-red fsz-md fas fa-times-circle mR-5" />
                            Cancelled
                          </span>
                        </td>
                        <td>
                          <a href="/" className="c-gray">View</a>
                        </td>
                        <td>
                          <span className="c-green">
                            {' '}
                            <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                          </span>
                        </td>
                        <td><span className="c-gray">5123123</span></td>
                        <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  </section>
);

export default SendMoneyDedicated;
