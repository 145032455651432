import React from 'react';
import classNames from 'classnames';

import { compose } from 'recompose';
import withModal from '../../../hocs/withModal';
import CrypoTinyLineChart from '../../common/Charts/CrypoTinyLineChart';
import DetailCryptoChart from '../DetailCryptoChart';


const ChartExpand = ({
  isOpenModal, toggleModal, chartData, symbol,
}) => (
  <td className="pr-0">
    <div
      className="chart-line"
      onClick={toggleModal}
      role="presentation"
    >
      <CrypoTinyLineChart data={chartData} color="#FF7831" />
    </div>
    <div className={classNames(
      'chart-expand',
      {
        'chart-expand--expand': isOpenModal,
      },
    )}
    >
      {isOpenModal && <DetailCryptoChart symbol={symbol} />
      }
    </div>
  </td>
);

export default compose(
  withModal(),
)(ChartExpand);
