import React from 'react';
import { Helmet } from 'react-helmet';

const ContactUs = () => (
  <>
    <Helmet>
      <title>Paybit - ContactUs</title>
    </Helmet>
    <section className="bgc-white pY-20 bdT">
      <div className="container">
        <ul className="breadcrumb breadcrumbs">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
            <a href="/contact-us">Contact Us</a>
          </li>
        </ul>
      </div>
    </section>
    <section className="pY-70 bgc-grey bg-contact" style={{ backgroundImage: 'url(/images/contact-background.jpg)' }}>
      <div className="container">
        <div className="text-center mB-70">
          <h4 className="fw-n">Contact Us</h4>
          <p className="fsz-def c-text-gray mb-0">Looking for help or consulting? Fill the form below or email us. We will respond you as soon as possible.</p>
        </div>
        <div className="row no-gutters">
          <div className="col-md-12 col-lg-4 bg contact-form-left pc" />
          <div className="col-md-12 col-lg-8 bd bgc-white">
            <div className="p-50">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <div className="form-group mB-20">
                    <label className="c-text-gray fsz-sm">First Name</label>
                    <input type="text" className="form-control bdrs-0 bdB" placeholder="Enter your first name" />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group mB-20">
                    <label className="c-text-gray fsz-sm">Last Name</label>
                    <input type="text" className="form-control bdrs-0 bdB" placeholder="Enter your last name" />
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group mB-20">
                    <label className="c-text-gray fsz-sm">Email Address</label>
                    <input type="email" className="form-control bdrs-0 bdB" placeholder="Enter you email address" />
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group mB-20">
                    <label className="c-text-gray fsz-sm">Email Address</label>
                    <textarea className="form-control bdrs-0 bdB" rows={4} placeholder="Enter your message here..." defaultValue="" />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between d-md-inline-block w-100">
                <div className="d-flex">
                  <div className="mR-20 custom-radio-blue">
                    <input type="radio" name="type-contact-radio" id="contact-help-support" />
                    <label htmlFor="contact-help-support">
                      <p className="mb-0"><b className="fw-n fsz-sm">Help &amp; Support</b></p>
                      <small className="c-gray">Contact us if you have any question</small>
                    </label>
                  </div>
                  <div className="custom-radio-blue">
                    <input type="radio" name="type-contact-radio" id="contact-sales" />
                    <label htmlFor="contact-sales">
                      <p className="mb-0"><b className="fw-n fsz-sm">Sales</b></p>
                      <small className="c-gray">For Business Inquiries</small>
                    </label>
                  </div>
                </div>
                <div className="text-right">
                  <button className="btn btn-lg btn-primary">Send Message</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mT-40 bd bgc-white pY-50">
          <div className="row no-gutters">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="contact-info-item pX-50 bdR bdr-md-n">
                <div className="d-flex">
                  <div className="c-blue mR-20 fsz-lg">
                    <i className="icon-headphone" />
                  </div>
                  <div>
                    <h5 className="fw-n">Call Us</h5>
                    <p className="line-height-2 c-text-gray">
Office Phone: +52 222 3211
                      <br />
Mobile Phone: 222 124 23266
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="contact-info-item pX-50 bdR bdr-md-n">
                <div className="d-flex">
                  <div className="c-blue mR-20 fsz-lg">
                    <i className="fas fa-map-marker-alt" />
                  </div>
                  <div>
                    <h5 className="fw-n">Where to find us</h5>
                    <p className="line-height-2 c-text-gray">
287 Natalie Glens Apt. 214
                      <br />
San Francisco, CA
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="contact-info-item pX-50">
                <div className="d-flex">
                  <div className="c-blue mR-20 fsz-lg">
                    <i className="icon-mail" />
                  </div>
                  <div>
                    <h5 className="fw-n">Write to us</h5>
                    <p className="line-height-2 c-text-gray">support@paybit.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default ContactUs;
