import React from 'react';
import {
  BarChart, Bar, XAxis,
  ResponsiveContainer,
} from 'recharts';

const mockData = [
  {
    name: 'Mon', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Tue', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Wed', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Thu', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Fri', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Sat', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Sun', uv: 3490, pv: 4300, amt: 2100,
  },
];

const ChartTotalEarnings = ({ data = mockData }) => (
  <ResponsiveContainer width="100%" height="100%">
    <BarChart
      data={data}
      margin={{
        top: 5, right: 30, left: 20, bottom: 30,
      }}
    >
      <XAxis tickLine={false} axisLine={false} dataKey="name" tick={{ transform: 'translate(0, 20)' }} />
      <Bar dataKey="pv" fill="#0084FF" barSize={5} radius={10} />
      <Bar dataKey="uv" fill="#A4A4A4" barSize={5} radius={10} />
    </BarChart>
  </ResponsiveContainer>
);

export default ChartTotalEarnings;
