import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import TopSlider from './TopSlide';
import YourMoney from '../LandingPageBest/YourMoney';
import CurrencySlider from '../common/Sliders/CurrencySlider';
import WeGotCoveredSlider from '../common/Sliders/WeGotCoveredSlider';
import SectionPartner from '../common/SectionPartner';
import ProductTabs from '../common/ProductTabs';
import { withAuth } from '../../hocs/withAuth';

const LandingPage = ({ isAuth }) => {
  const { t } = useTranslation('langding page');

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta data-react-helmet="true" name="description" content="PayBit is the best wallet to buy, sell, and store cryptocurrency like Bitcoin, Ethereum, and more. Accept bitcoins or etherum using PayBit, it is simple and free."/>
        <meta data-react-helmet="true" property="og:description" name="og:description" content="PayBit is the best wallet to buy, sell, and store cryptocurrency like Bitcoin, Ethereum, and more. Accept bitcoins or etherum using PayBit, it is simple and free."/>
        <meta data-react-helmet="true" name="twitter:description" content="PayBit is the best wallet to buy, sell, and store cryptocurrency like Bitcoin, Ethereum and more. Accept payments using PayBit.com, it is simple and free."/>
        <meta property="og:image" name="og:image" content="logo"/>
      </Helmet>
      <TopSlider />
      <CurrencySlider />
      <section className="pY-70 section-solution bg-has-icon">
        <ReactWOW animation="fadeInLeft">
          <div className="shape shape-1" />
        </ReactWOW>
        <ReactWOW animation="fadeInRight">
          <div className="shape shape-2" />
        </ReactWOW>
        <ReactWOW animation="fadeInLeft">
          <div className="icons icons-1" />
        </ReactWOW>
        <ReactWOW animation="fadeInUp" delay="0.5s">
          <div className="icons icons-5" />
        </ReactWOW>
        <div className="container">
          <div className="text-center mB-40">
            <h4>

              <b className="fw-n">{t('Paybit Solutions for Online, Mobile and In-Store')}</b>
            </h4>
            <p className="fsz-def">{t('Buyers make purchases')}</p>
          </div>
          <div className="row justify-content-center">
            <ReactWOW animation="bounceInLeft" duration="1s" delay="0.2s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-mobile" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5>
                      <b className="fw-n">Mobile</b>

                    </h5>
                    <p className="fsz-def">{t('Add Paybit to Your Mobile Site and App')}</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="bounceInUp" duration="1s" delay="0.4s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-pc" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5>
                      <b className="fw-n">PC</b>

                    </h5>
                    <p className="fsz-def">{t('Add Paybit to Your Website')}</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="bounceInRight" duration="1s" delay="0.6s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-shop" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5>
                      <b className="fw-n">Store</b>

                    </h5>
                    <p className="fsz-def">{t('Accept Paybit in Your Store')}</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>
      <section className="bg-wave-blue-2">
        <ReactWOW animation="fadeInUp" delay="0.5s">
          <div className="icons icons-1" />
        </ReactWOW>
        <ReactWOW animation="fadeInUp" delay="1s">
          <div className="icons icons-2" />
        </ReactWOW>
        <ReactWOW animation="fadeInUp" delay="1.5s">
          <div className="icons icons-3" />
        </ReactWOW>
        <ReactWOW animation="fadeInUp" delay="1s">
          <div className="icons icons-4" />
        </ReactWOW>
        <ReactWOW animation="fadeInUp" delay="0.5s">
          <div className="icons icons-5" />
        </ReactWOW>
        <ReactWOW animation="fadeInUp" delay="0.5s">
          <div className="icons icons-6" />
        </ReactWOW>
        <div className="container">
          <div className="mY-50 c-white">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 box-shadow-mb-blue">
                    <ReactWOW animation="slideInUp">
                      <div className="mB-50 box-hover">
                        <p className="c-white text-uppercase">{t('benefits')}</p>
                        <h3 className="mB-20">
                          <b className="fw-n">{t('Get')}</b>

                        </h3>
                        <div className="line-height-2 c-white-60 fsz-def">
                          <p>{t('Conditions apply')}</p>
                          <br className="pc" />
                          <p className="pc">{t('We offer')}</p>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 box-shadow-mb-blue">
                    <ReactWOW animation="slideInUp" delay="0.1s">
                      <div className="mB-40 box-hover">
                        <p className="c-white text-uppercase">{t('simple')}</p>
                        <h3 className="mB-20">
                          <b className="fw-n">{t('got')}</b>

                        </h3>
                        <div className="line-height-2 c-white-60 fsz-def">
                          <p>{t('Apps with Paybit')}</p>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 ">
                <div className="row">
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb-blue">
                      <div className="mB-25">
                        <div className="circle-white-80">
                          <i className="fas fa-dollar-sign" />
                        </div>
                      </div>
                      <h4>

                        <b className="fw-n">{t('Available Currencies')}</b>

                      </h4>
                      <p className="fsz-def c-white-60 line-height-2">{t('support')}</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp" />
                  <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb-blue">
                    <div className="mB-25">
                      <div className="circle-white-80">
                        <i className="icon-transfer-in-second" />
                      </div>
                    </div>
                    <h4>

                      <b className="fw-n">{t('Transfer in Secound')}</b>

                    </h4>
                    <p className="fsz-def c-white-60 line-height-2">{t('better')}</p>
                  </div>
                  <ReactWOW animation="slideInUp" />
                  <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb-blue">
                    <div className="mB-25">
                      <div className="circle-white-80">
                        <i className="icon-security" />
                      </div>
                    </div>
                    <h4>

                      <b className="fw-n">{t('Mighty Protection')}</b>

                    </h4>
                    <p className="fsz-def c-white-60 line-height-2">{t('dont worry')}</p>
                  </div>
                  <ReactWOW animation="slideInUp" />
                  <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb-blue">
                    <div className="mB-25">
                      <div className="circle-white-80">
                        <i className="icon-hand-pointer" />
                      </div>
                    </div>
                    <h4>

                      <b className="fw-n">{t('Easy to Use')}</b>

                    </h4>
                    <p className="fsz-def c-white-60 line-height-2">{t('PayBit is simple')}</p>
                  </div>
                  <ReactWOW animation="slideInUp" />
                  <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb-blue">
                    <div className="mB-25">
                      <div className="circle-white-80">
                        <i className="icon-headphone" />
                      </div>
                    </div>
                    <h4>

                      <b className="fw-n">{t('24/7 Support')}</b>

                    </h4>
                    <p className="fsz-def c-white-60 line-height-2">{t('Our large staff')}</p>
                  </div>
                  <ReactWOW animation="slideInUp" />
                  <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb-blue-last">
                    <div className="mB-25">
                      <div className="circle-white-80">
                        <i className="icon-organized-payments" />
                      </div>
                    </div>
                    <h4>

                      <b className="fw-n">{t('Organized Payments')}</b>

                    </h4>
                    <p className="fsz-def c-white-60 line-height-2">{t('With our simple')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="great-success">
          <div className="container">
            <div className="row justify-content-between">
              <ReactWOW animation="slideInUp" duration="0.5s">
                <div className="col-md-12 col-lg-5">
                  <div className="mT-50">
                    <p className="c-gray text-uppercase">{t('fast')}</p>
                    <h3 className="mB-20">
                      <b className="fw-n">{t('Payment in a few clicks')}</b>

                    </h3>
                    <div className="line-height-2 c-text-gray fsz-def mB-40">
                      <p>{t('Millions of customers')}</p>
                      <br />
                      <div>
                        <a href="/" className="c-black">{t('Pay')}</a>
                        <a href="/" className="c-black">{t('Pay multiple')}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </ReactWOW>
              <div className="col-md-12 col-lg-6">
                <div className="row no-gutters">
                  <ReactWOW animation="bounceInLeft">
                    <div className="col-4 col-md-4 mT-50">
                      <div className="circle-163 m-auto mB-15">
                        <span className="c-blue">
                          <i className="icon-hand-pointer" />
                        </span>
                      </div>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="bounceIn">
                    <div className="col-3 col-md-3 mT-50 text-center">
                      <div className="dot-line">
                        <i className="fas fa-circle" />
                        <i className="fas fa-circle" />
                        <i className="fas fa-circle" />
                        <i className="fas fa-circle" />
                        <i className="fas fa-circle" />
                        <i className="fas fa-circle" />
                      </div>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="bounceInRight">
                    <div className="col-4 col-md-5">
                      <div className="circle-163 active m-auto">
                        <span className="c-blue">
                          <i className="icon-paybit" />
                        </span>
                        <i className="fas fa-check-circle c-green" />
                      </div>
                      <p className="text-center mT-20 c-white">
                        {t('Great sucess')}
                        <br />
                        {t('pp')}
                      </p>
                    </div>
                  </ReactWOW>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProductTabs />
      <WeGotCoveredSlider />
      <section className="pT-70 bgc-grey">
        <div className="container">
          <div className="row">
            <ReactWOW animation="fadeInLeft">
              <div className="col-md-12 col-lg-6 box-hover text-center-md">
                <div className="d-flex d-mb-inline-block mB-40 box-shadow-mb">
                  <div className="mR-30 mX-auto-md mB-40">
                    <div className="circle-80 m-auto">
                      <span className="c-blue">
                        <i className="icon-moneys1" />
                      </span>
                    </div>
                  </div>
                  <div>
                    <h5 className="mB-15">
                      <b className="fw-n">{t('Send money')}</b>

                    </h5>
                    <p className="fsz-def c-text-gray">
                      {t('Send money de')}
                    </p>
                    <br />
                    <a href="/" className="c-black">

                      <b className="fw-n">{t('Start Selling ')}</b>
                      <i className="icon-right-arrow mL-10" />
                    </a>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInRight">
              <div className="col-md-12 col-lg-6 box-hover text-center-md">
                <div className="d-flex d-mb-inline-block mB-40 box-shadow-mb">
                  <div className="mR-30 mX-auto-md mB-40">
                    <div className="circle-80 m-auto">
                      <span className="c-blue">
                        <i className="icon-cart1" />
                      </span>
                    </div>
                  </div>
                  <div>
                    <h5 className="mB-15">
                      <b className="fw-n">{t('Shop around')}</b>

                    </h5>
                    <p className="fsz-def c-text-gray">{t('Shop around de')}</p>
                    <br />
                    <a href="/" className="c-black">

                      <b className="fw-n">{t('Start Shopping ')}</b>
                      <i className="icon-right-arrow mL-10" />
                    </a>
                  </div>
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="text-center mT-30">
            <ReactWOW animation="bounceInUp">
              <div className="m-auto mockup-mobile" style={{ backgroundImage: 'url(/images/mockup-phone-paybit.png)' }} />
            </ReactWOW>
          </div>
        </div>
      </section>
      <section className="bg-dark-wave">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-11 col-xl-10">
              <div className="d-flex d-md-inline-block">
                <div className="d-flex">
                  <ReactWOW animation="bounceInUp">
                    <div className="mockup-mobile-login" style={{ backgroundImage: 'url(/images/mockup-phone-paybit-login.png)' }} />
                  </ReactWOW>
                  <ReactWOW animation="fadeInRight">
                    <div className="c-white wallet-right pY-70 pL-50">
                      <div>
                        <h1 className="mB-20">{t('#1 Wallet')}</h1>
                        <p className="fsz-def c-text-gray line-height-2 mB-20">
                          {t('#1 Wallet de')}
                        </p>
                        <ul className="d-flex list-unstyled d-md-inline-block mB-0 w-100">
                          <li className="mR-20 mB-10 d-flex align-items-center float-left box-hover">
                            <div className="circle-40 bgc-white mR-15">
                              <i className="c-blue fsz-md icon-security" />
                            </div>
                            {t('Security')}
                          </li>
                          <li className="mR-20 mB-10 d-flex align-items-center float-left box-hover">
                            <div className="circle-40 bgc-white mR-15">
                              <i className="c-blue fsz-sm icon-arrow-double" />
                            </div>
                            {t('Send & Receive')}
                          </li>
                          <li className="mR-20 mB-10 d-flex align-items-center float-left box-hover">
                            <div className="circle-40 bgc-white mR-15">
                              <i className="c-blue fsz-md fas fa-dollar-sign" />
                            </div>
                            {t('Buy, Sell, & Exchange')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ReactWOW>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <YourMoney />
      <section className="pB-25 section-features">
        <div className="container">
          <div className="bd bgc-grey p-50">
            <div className="text-center mB-50">
              <h3 className="mB-20">
                <b className="fw-n">{t('We have')}</b>

              </h3>
              <p className="c-text-gray fsz-def">{t('We offer')}</p>
            </div>
            <div className="row">
              <ReactWOW animation="slideInUp">
                <div className="col-12 col-md-6 col-lg-4 hover-opacity">
                  <div className="d-flex mT-40">
                    <div className="c-blue fsz-lg mR-20">
                      <i className="icon-multiple-payments" />
                    </div>
                    <div>
                      <h6 className="mB-20 fsz-def">
                        <b className="fw-n">{t('Send multiple Payments')}</b>
                      </h6>
                      <p className="c-text-gray">
                        {t('Payments can not get simpler!')}
                      </p>
                    </div>
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="slideInUp">
                <div className="col-12 col-md-6 col-lg-4 hover-opacity">
                  <div className="d-flex mT-40">
                    <div className="c-blue fsz-lg mR-20">
                      <i className="icon-all-money" />
                    </div>
                    <div>
                      <h6 className="mB-20 fsz-def">
                        <b className="fw-n">{t('Tap into all your money.')}</b>
                      </h6>
                      <p className="c-text-gray">
                        {t('Use our mobile tools!')}
                      </p>
                    </div>
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="slideInUp">
                <div className="col-12 col-md-6 col-lg-4 hover-opacity">
                  <div className="d-flex mT-40">
                    <div className="c-blue fsz-lg mR-20">
                      <i className="icon-developers-api" />
                    </div>
                    <div>
                      <h6 className="mB-20 fsz-def">
                        <b className="fw-n">{t('Multiple developers APIs')}</b>
                      </h6>
                      <p className="c-text-gray">
                        {t('We have solutions')}
                      </p>
                    </div>
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="slideInUp">
                <div className="col-12 col-md-6 col-lg-4 hover-opacity">
                  <div className="d-flex mT-40">
                    <div className="c-blue fsz-lg mR-20">
                      <i className="icon-security" />
                    </div>
                    <div>
                      <h6 className="mB-20 fsz-def">
                        <b className="fw-n">{t('You are secured')}</b>
                      </h6>
                      <p className="c-text-gray">{t('We offer')}</p>
                    </div>
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="slideInUp">
                <div className="col-12 col-md-6 col-lg-4 hover-opacity">
                  <div className="d-flex mT-40">
                    <div className="c-blue fsz-lg mR-20">
                      <i className="icon-overview" />
                    </div>
                    <div>
                      <h6 className="mB-20 fsz-def">
                        <b className="fw-n">{t('Send money')}</b>
                      </h6>
                      <p className="c-text-gray">
                        {t('Use our mobile tools!')}
                      </p>
                    </div>
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="slideInUp">
                <div className="col-12 col-md-6 col-lg-4 hover-opacity">
                  <div className="d-flex mT-40">
                    <div className="c-blue fsz-lg mR-20">
                      <i className="icon-time" />
                    </div>
                    <div>
                      <h6 className="mB-20 fsz-def">
                        <b className="fw-n">{t('Transfer in Secounds')}</b>
                      </h6>
                      <p className="c-text-gray">
                        {t('better')}
                      </p>
                    </div>
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="slideInUp">
                <div className="col-12 col-md-6 col-lg-4 hover-opacity">
                  <div className="d-flex mT-40">
                    <div className="c-blue fsz-lg mR-20">
                      <i className="icon-money-10 fsz-def" />
                    </div>
                    <div>
                      <h6 className="mB-20 fsz-def">
                        <b className="fw-n">{t('Send and request money fast.')}</b>
                      </h6>
                      <p className="c-text-gray">
                        {t('it free')}
                      </p>
                    </div>
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="slideInUp">
                <div className="col-12 col-md-6 col-lg-4 hover-opacity">
                  <div className="d-flex mT-40">
                    <div className="c-blue fsz-lg mR-20">
                      <i className="icon-manage-money-easily" />
                    </div>
                    <div>
                      <h6 className="mB-20 fsz-def">
                        <b className="fw-n">{t('Manage your money easily.')}</b>
                      </h6>
                      <p className="c-text-gray">
                        {t('The improved Paybit')}
                      </p>
                    </div>
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="slideInUp">
                <div className="col-12 col-md-6 col-lg-4 hover-opacity">
                  <div className="d-flex mT-40">
                    <div className="c-blue fsz-lg mR-20">
                      <i className="icon-collect-money-from-group" />
                    </div>
                    <div>
                      <h6 className="mB-20 fsz-def">
                        <b className="fw-n">{t('Collect money from groups.')}</b>
                      </h6>
                      <p className="c-text-gray">
                        {t('Whether')}
                      </p>
                    </div>
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </section>
      <section className="bdT bdB section-download-browser">
        <div className="container">
          <div className="row">
            <ReactWOW animation="fadeInLeft">
              <div className="col-sm-12 col-md-6 col-lg-6 bdR bdR-sm-n">
                <div className="pY-60">
                  <p className="c-text-gray">{t('Click')}</p>
                  <h3 className="mB-30">
                    <b className="fw-n">{t('Download Toolsbars for browser')}</b>
                  </h3>
                  <div className="d-flex">
                    <div className="img size-32 mR-20 hover-opacity-onhover" style={{ backgroundImage: 'url(/images/firefox_64.png)' }} />
                    <div className="img size-32 mR-20 hover-opacity-onhover" style={{ backgroundImage: 'url(/images/chrome_64.png)' }} />
                    <div className="img size-32 mR-20 hover-opacity-onhover" style={{ backgroundImage: 'url(/images/safari_64.png)' }} />
                    <div className="img size-32 mR-20 hover-opacity-onhover" style={{ backgroundImage: 'url(/images/edge_64.png)' }} />
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInRight">
              <div className="col-sm-12 col-md-6 col-lg-6 bdT-sm box-hover">
                <div className="pY-60 pL-50 pL-md-0">
                  <p className="c-text-gray">
                    {t('Click')}
                  </p>
                  <h3 className="mB-30">
                    <b className="fw-n">{t('Download our Tools')}</b>
                  </h3>
                  <div className="mT-60">
                    <a href="/" className="fsz-def c-black">

                      <b className="fw-n">{t('Download our Tools')}</b>
                      <i className="icon-right-arrow mL-10" />
                    </a>
                  </div>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>
      <section className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-lg-6 bgc-blue">
            <ReactWOW animation="slideInUp">
              <div className="pX-60 pB-40 text-right pX-sm-0">
                <div className="text-center download-app-ios  ml-auto">
                  <div className="mockup-ios-mobile mB-50" style={{ backgroundImage: 'url(/images/download-app-mockup.png)' }} />
                  <h3 className="c-white mB-20">
                    <b className="fw-n">{t('Download for App Store')}</b>
                  </h3>
                  <p className="c-white-60 mB-40">{t('Save unlimited')}</p>
                  <a href="/" className="button_appstore">{' '}</a>
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="col-md-12 col-lg-6">
            <ReactWOW animation="slideInUp">
              <div className="pX-60 pT-50 pX-sm-0">
                <div className="text-center download-app-ios  mr-auto">
                  <h3 className="mB-30">

                    <b className="fw-n">{t('Download for Google Play')}</b>
                  </h3>
                  <p className="fsz-def c-text-gray mB-25">{t('Access all')}</p>
                  <a href="/" className="button_androidstore">{' '}</a>
                  <div className="mockup-ios-mobile mT-50" style={{ backgroundImage: 'url(/images/download-app-android-mockup.png)' }} />
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>
      <SectionPartner />
      <ReactWOW animation="fadeInUp">
        <section className="section-download-app">
          <div className="container text-center c-white">
            <h2 className="mB-20">
              <b className="fw-n">{t('Your money')}</b>
            </h2>
            <div className="mB-30">
              {!isAuth && (
                <a href="/sign-up-personal" className="btn-light btn-lg bdrs-0 btn-signup">
                  <b className="fw-n">{t('Sign Up')}</b>
                </a>
              )}
            </div>
            <h2 className="fsz-xl mB-30">
              <b className="fw-n">{t('Sign Up')}</b>
            </h2>
            <div>
              <a href="/" className="button_appstore m-5">{' '}</a>
              <a href="/" className="button_androidstore m-5">{' '}</a>
            </div>
          </div>
        </section>
      </ReactWOW>
    </>
  );
};

export default compose(
  withAuth,
)(LandingPage);
