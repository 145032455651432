import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EN from './en';
import VI from './vi';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: EN,
      vi: VI,
    },
    lng: (localStorage.getItem('usedLanguage') || 'en'),
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  });

export default i18n;
