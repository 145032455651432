import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

const PaybitStatusPage = () => (
  <>
    <Helmet>
      <title>Paybit - Paybit Status Page</title>
    </Helmet>
    <section className="banner-status-page">
      <div className="banner-status-page-inner c-white">
        <div className="container">
          <ul className="breadcrumb breadcrumbs breadcrumbs-white mT-40">
            <li><a href="/"><i className="icon-house" /></a></li>
            <li>
              <i className="fas fa-chevron-right" />
              <a href="/">What is Bitcoin</a>
            </li>
          </ul>
          <ReactWOW animation="fadeInUp">
            <div className="banner-status-page-text">
              <h1 className="fsz-50 fw-n">Paybit Status Page</h1>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
    <div className="bgc-green pY-20 c-white fsz-def"><div className="container">All Systems Operational</div></div>
    <section className="bgc-grey pT-35 pB-50">
      <div className="container">
        <div className="bd bgc-white">
          <div className="d-flex justify-content-between pY-25 pL-40 pR-20 bdB">
            <h5 className="fw-n mb-0">Explorer and API</h5>
            <span className="c-text-gray fsz-def">Operational</span>
          </div>
          <div className="d-flex justify-content-between pY-25 pL-40 pR-20 bdB">
            <h5 className="fw-n mb-0">Wallet</h5>
            <span className="c-text-gray fsz-def">Operational</span>
          </div>
          <div className="d-flex justify-content-between pY-25 pL-40 pR-20 bdB">
            <h5 className="fw-n mb-0">Wallet</h5>
            <span className="c-text-gray fsz-def">Operational</span>
          </div>
          <div className="d-flex justify-content-between pY-25 pL-40 pR-20 bdB">
            <h5 className="fw-n mb-0">Wallet - Ether</h5>
            <span className="c-text-gray fsz-def">Operational</span>
          </div>
          <div className="d-flex justify-content-between pY-25 pL-40 pR-20">
            <h5 className="fw-n mb-0">Wallet - Bitcoin Cash</h5>
            <span className="c-text-gray fsz-def">Operational</span>
          </div>
        </div>
        <div className="mT-30 mB-25">
          <div className="d-flex justify-content-between system-metrics-title">
            <h4 className="fw-n mb-0">System Metrics</h4>
            <ul className="list-unstyled">
              <li className="active"><a href="/">Day</a></li>
              <li><a href="/">Week</a></li>
              <li><a href="/">Month</a></li>
            </ul>
          </div>
        </div>
        <div className="bd bgc-white pY-25 pX-40">
          <div className="d-flex justify-content-between mB-20">
            <h5 className="fw-n">Paybit.info Uptime</h5>
            {' '}
            <span className="c-grey fsz-md">100%</span>
          </div>
          <div className="chart-paybit-info-uptime" style={{ backgroundImage: 'url(/images/chart-paybit-info-uptime.png)' }} />
        </div>
        <div className="bd bdT-n bgc-white pY-25 pX-40">
          <div className="d-flex justify-content-between mB-20">
            <h5 className="fw-n">Paybit.info Wallet Uptime</h5>
            {' '}
            <span className="c-grey fsz-md">100%</span>
          </div>
          <div className="chart-paybit-info-uptime" style={{ backgroundImage: 'url(/images/chart-paybit-info-uptime.png)' }} />
        </div>
        <div className="mT-30 mB-25">
          <h4 className="fw-n mb-0">Past Incidents</h4>
        </div>
        <div className="bd bgc-white">
          <div className="pY-25 pL-40 pR-20 bdB">
            <h5 className="fw-n mb-0">Dec 12, 2018</h5>
            <span className="c-text-gray fsz-md">No incidents reported today.</span>
          </div>
          <div className="pY-25 pL-40 pR-20 bdB">
            <h5 className="fw-n mb-0">Dec 11, 2018</h5>
            <span className="c-text-gray fsz-md">No incidents reported today.</span>
          </div>
          <div className="pY-25 pL-40 pR-20 bdB">
            <h5 className="fw-n mb-0">Dec 10, 2018</h5>
            <span className="c-text-gray fsz-md">No incidents reported today.</span>
          </div>
          <div className="pY-25 pL-40 pR-20 bdB">
            <h5 className="fw-n mb-0">Dec 9, 2018</h5>
            <span className="c-text-gray fsz-md">No incidents reported today.</span>
          </div>
        </div>
      </div>
    </section>
    <section className="bgc-white pT-40 pB-50">
      <div className="container">
        <div className="single-blog-related">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="blog-items blog-items-1 mB-25">
                <div className="blog-text p-25 bgc-white">
                  <a href="/" className="btn-category btn btn-around bgc-orange1 c-white">Statistics</a>
                  <a href="/" className="c-black"><h4 className="fw-n title mY-10">Study: Gen Z prefers shopping over online</h4></a>
                  <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                </div>
                <div className="blog-image">
                  <a href="/">
                    <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-12.jpg)' }} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-8">
              <div className="blog-items blog-items-2 mB-25">
                <div className="blog-image">
                  <a href="/">
                    <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-11.jpg)' }} />
                  </a>
                </div>
                <div className="blog-text p-25 bgc-white">
                  <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                  <a href="/" className="c-black"><h4 className="fw-n title mY-10">PredictSpring intros &lsquo;modern&lsquo; mobile commerce platform for brick-and-mortar</h4></a>
                  <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="blog-items blog-items-3 mB-25">
                <div className="blog-image">
                  <a href="/">
                    <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-13.jpg)' }} />
                  </a>
                </div>
                <div className="blog-text">
                  <div className="d-flex">
                    <div className="mR-25">
                      <div className="circle-136" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                    </div>
                    <div>
                      <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                      <a href="/" className="c-black"><h4 className="fw-n title mY-10">Ripple expands network worldwide for easier transfers</h4></a>
                      <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default PaybitStatusPage;
