import React from 'react';
import Slider from 'react-slick';
import ReactWOW from 'react-wow';

const settings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: true,
};

const IllustrationSlider = () => (
  <section className="top-slider illustration-slider">
    <div className="top-slider-inner">
      <div className="slideshow">
        <Slider {...settings} className="slider main-slider " id="illustration-slider">
          <div className="item">
            <div className="slider-content c-white">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-5">
                    <ReactWOW animation="fadeInUp">
                      <p className="mB-30 text-uppercase">YOUR PERSONAL WALLET</p>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp" delay="0.3s">
                      <h2 className="fsz-sup mB-30 fw-n">Online Payments Made Easy</h2>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp" delay="0.6s">
                      <p className="fsz-def mB-30">No matter where and who.Join now the simple life of Paybit</p>
                    </ReactWOW>
                    <ReactWOW animation="fadeInLeft" delay="1s">
                      <div>
                        <a href="/sign-up-personal" className="btn btn-lg btn-light btn-around btn-light--light-hover mR-15">Sign Up</a>
                        <a href="/business-tour" className="btn btn-lg btn-primary btn-around">Learn More</a>
                      </div>
                    </ReactWOW>

                  </div>
                  <ReactWOW animation="fadeInRight">
                    <div className="col-md-12 col-lg-7 hidden-md">
                      <img src="/images/mockup-iphone-ipad.png" alt="" />
                    </div>
                  </ReactWOW>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="slider-content c-white">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-5">
                    <ReactWOW animation="fadeInUp">
                      <p className="mB-30 text-uppercase">YOUR PERSONAL WALLET</p>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp" delay="0.3s">
                      <h2 className="fsz-sup mB-30 fw-n">Online Payments Made Easy</h2>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp" delay="0.6s">
                      <p className="fsz-def mB-30">No matter where and who.Join now the simple life of Paybit</p>
                    </ReactWOW>
                    <ReactWOW animation="fadeInLeft" delay="1s">
                      <div>
                        <a href="/sign-up-personal" className="btn btn-lg btn-primary btn-around mR-15">Sign Up</a>
                        <a href="/business-tour" className="btn btn-lg btn-outline-light btn-around">Learn More</a>
                      </div>
                    </ReactWOW>
                  </div>
                  <ReactWOW animation="fadeInRight">
                    <div className="col-md-12 col-lg-7 hidden-md">
                      <img src="/images/mockup-dashboard-tablet.png" alt="" />
                    </div>
                  </ReactWOW>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="slider-content c-white">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-5">
                    <ReactWOW animation="fadeInUp">
                      <p className="mB-30 text-uppercase">YOUR PERSONAL WALLET</p>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp" delay="0.3s">
                      <h2 className="fsz-sup mB-30 fw-n">Online Payments Made Easy</h2>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp" delay="0.6s">
                      <p className="fsz-def mB-30">No matter where and who.Join now the simple life of Paybit</p>
                    </ReactWOW>
                    <ReactWOW animation="fadeInLeft" delay="1s">
                      <div>
                        <a href="/sign-up-personal" className="btn btn-lg btn-light btn-around mR-15">Sign Up</a>
                        <a href="/business-tour" className="btn btn-lg btn-primary btn-around">Learn More</a>
                      </div>
                    </ReactWOW>
                  </div>
                  <ReactWOW animation="bounceInRight">
                    <div className="col-md-12 col-lg-7 hidden-md">
                      <img src="/images/mockup-iphone-ipad.png" alt="" />
                    </div>
                  </ReactWOW>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  </section>
);

export default IllustrationSlider;
