import React from 'react';
import ReactWOW from 'react-wow';
import { withTranslation } from 'react-i18next';

const OurProdcut = ({ t }) => (
  <section className="pB-60">
    <div className="container">
      <div className="row align-items-center justify-content-between mT-50">
        <ReactWOW animation="fadeInLeft">
          <div className="col-md-12 col-lg-6">
            <div className="user-friend-boxs">
              <ReactWOW animation="fadeInLeft">
                <div className="user-friend-boxs-row">
                  <div className="bdrs-3 bd bg-white user-friend-boxs-item box-shadow">
                    <div className="text-center">
                      <div className="circle-50 m-auto" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                    </div>
                    <div className="text-center mT-20">
                      <b className="fw-n fsz-def">Tanja Biberdzic</b>
                      <p className="c-gray">tanjabiberdzic@gmail.com</p>
                      <div className="c-blue fsz-md"><i className="icon-contacts" /></div>
                    </div>
                  </div>
                  <div className="bdrs-3 bd bg-white user-friend-boxs-item box-shadow">
                    <div className="text-center">
                      <div className="circle-50 m-auto" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                    </div>
                    <div className="text-center mT-20">
                      <b className="fw-n fsz-def">Tanja Biberdzic</b>
                      <p className="c-gray">tanjabiberdzic@gmail.com</p>
                      <div className="c-blue fsz-md"><i className="icon-mail" /></div>
                    </div>
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="fadeInRight">
                <div className="user-friend-boxs-row">
                  <div className="bdrs-3 bd bg-white user-friend-boxs-item box-shadow">
                    <div className="text-center">
                      <div className="circle-50 m-auto" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                    </div>
                    <div className="text-center mT-20">
                      <b className="fw-n fsz-def">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX</b>
                      <div className="c-green fsz-md"><b className="fw-n">$100</b></div>
                    </div>
                  </div>
                  <div className="bdrs-3 bd bg-white user-friend-boxs-item box-shadow">
                    <div className="text-center">
                      <div className="circle-50 m-auto" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                    </div>
                    <div className="text-center mT-20">
                      <b className="fw-n fsz-def">Tanja Biberdzic</b>
                      <p className="c-gray">tanjabiberdzic@gmail.com</p>
                      <div className="c-blue fsz-md"><i className="icon-mail" /></div>
                    </div>
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInRight">
          <div className="col-md-12 col-lg-5">
            <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
            <h4 className="fw-n">{t('Getting started is easy.')}</h4>
            <p className="c-text-gray">
              {t('Key-in')}
              {' '}
              <br />
              <br />
              {t('All Paybit')}
            </p>
            <hr className="hr-gray" />
            <div className="mT-30">
              <div className="slider download-customer-slider">
                <div className="item">
                  <div className="d-flex">
                    <div className="mR-20">
                      <div className="circle-50" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                    </div>
                    <div>
                      <p className="c-gray">
                        <i>
                          {t('Millions')}
                        </i>
                      </p>
                      <p className="c-gray">
                        <b className="fw-n c-black">Bill Johnson</b>
                        {' '}
                            - Customer
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="d-flex">
                    <div className="mR-20">
                      <div className="circle-50" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                    </div>
                    <div>
                      <p className="c-gray">
                        <i>
                          {t('Millions')}
                        </i>

                      </p>
                      <p className="c-gray">
                        <b className="fw-n c-black">Tom Glato</b>
                        {' '}
                            - Customer
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="d-flex">
                    <div className="mR-20">
                      <div className="circle-50" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                    </div>
                    <div>
                      <p className="c-gray">
                        <i>
                          {t('Millions')}
                        </i>

                      </p>
                      <p className="c-gray">
                        <b className="fw-n c-black">Jonathan Banks</b>
                        {' '}
                            - Customer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
      <div className="text-center mB-70 mT-70">
        <h4 className="fw-n">{t('Main Features')}</h4>
        <p className="c-text-gray">
          {t('Looking')}
        </p>
      </div>
      <div className="row">
        <ReactWOW animation="fadeInUp">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="main-feature-item box-hover-zoom box-shadow-mb">
              <div className="main-feature-item-icon">
                <i className="icon-security" />
              </div>
              <h5 className="fw-n">{t('Security')}</h5>
              <div className="main-feature-item-text">
                <p className="c-text-gray">
                  {t('Rest')}
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInUp">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="main-feature-item box-hover-zoom box-shadow-mb">
              <div className="main-feature-item-icon">
                <i className="icon-money-withdraw" />
              </div>
              <h5 className="fw-n">{t('Send & Receive')}</h5>
              <div className="main-feature-item-text">
                <p className="c-text-gray">
                  {t('Whether')}
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInUp">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="main-feature-item box-hover-zoom box-shadow-mb">
              <div className="main-feature-item-icon">
                <i className="icon-money-3" />
              </div>
              <h5 className="fw-n">{t('Buy, Sell, & Exchange')}</h5>
              <div className="main-feature-item-text">
                <p className="c-text-gray">
                  {t('Easily')}
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInUp">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="main-feature-item box-hover-zoom box-shadow-mb">
              <div className="main-feature-item-icon">
                <i className="icon-access" />
              </div>
              <h5 className="fw-n">{t('Access Anywhere')}</h5>
              <div className="main-feature-item-text">
                <p className="c-text-gray">
                  {t('Transform')}
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInUp">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="main-feature-item box-hover-zoom box-shadow-mb">
              <div className="main-feature-item-icon">
                <i className="icon-support-light" />
              </div>
              <h5 className="fw-n">{t('Simplified disputes resolution')}</h5>
              <div className="main-feature-item-text">
                <p className="c-text-gray">
                  {t('No need to worry')}
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInUp">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="main-feature-item box-hover-zoom box-shadow-mb">
              <div className="main-feature-item-icon">
                <i className="icon-infomation" />
              </div>
              <h5 className="fw-n">{t('All information on one screen')}</h5>
              <div className="main-feature-item-text">
                <p className="c-text-gray">
                  {t('This makes our integration')}
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
    </div>
  </section>
);

export default withTranslation('OurProdcut')(OurProdcut);
