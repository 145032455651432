import React from 'react';
import { Dropdown } from 'react-bootstrap';
import map from 'lodash/map';
import {
  compose, withState, withHandlers,
} from 'recompose';
import { useTranslation } from 'react-i18next';

import i18n from '../../locales/PaybitAppI18n';

const WithRefsCustomToggle = ({
  children, fixedClassName, onClick, ...props
}) => (
  <div
    role="presentation"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    {...props}
    className={fixedClassName}
  >
    {children}
  </div>
);
const CustomToggle = React.forwardRef(WithRefsCustomToggle);


export const coins = {
  Bitcoin: ['Bitcoin', '/images/bitcoin.png'],
  Neo: ['Neo', '/images/neo.png'],
  Ethereum: ['Ethereum', '/images/ethereum.png'],
};
export const languages = {
  us: ['English (US) ', '/images/united-states-of-america.png'],
  vi: ['Tiếng Việt', '/images/vietnam.png'],
  en: ['English (UK)', '/images/england.jpg'],
  // ja: ['Japan', '/images/japan.png'],
};

const Footer = ({
  changeCoinEvent, coin, changeLanguageEvent, language,
}) => {
  const { t } = useTranslation('footer');
  return (
    <footer className="p-footer">
      <div className="footer-top fsz-def">
        <div className="container">
          <div className="d-flex d-xl-inline-block align-items-center w-100">
            <a href="/" className="footer-logo mR-30"><div className="logo-paybit-white" style={{ backgroundImage: 'url(/images/logo-white.png)' }} /></a>
            <div className="footer-top-right d-flex align-items-center">
              <ul className="list-unstyled menu-footer-top mb-0 d-flex">
                <li><a href="/personal-tour">{t('Wallet')}</a></li>
                <li><a href="/business-tour">{t('Business')}</a></li>
                <li><a href="/landing-page-illustration-2">{t('Products')}</a></li>
                <li><a href="/personal-tour">{t('Solutions')}</a></li>
                <li><a href="/download-iphone-phone">{t('Apps')}</a></li>
                <li><a href="/help-center">{t('Help & Support')}</a></li>
              </ul>
              <ul className="list-unstyled mb-0 ml-auto list-web-info d-flex">
                <li className="c-white">
                  <i className="icon-mail" />
                  support@paybit.com
                </li>
                <li className="c-white">
                  <i className="icon-iphone-device" />
                  +0800 522 21 22
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-second">
        <div className="container">
          <div className="footer-menu">
            <div className="footer-item">
              <h5 className="c-white mB-10"><b className="fw-n">{t('About')}</b></h5>
              <ul className="list-unstyled">
                <li><a href="/pricing-fees">{t('Pricing & Packaging')}</a></li>
                <li><a href="/contact-us">{t('Contact Us')}</a></li>
                <li><a href="/">{t('Software Companies')}</a></li>
                <li><a href="/">{t('SaaS Companies')}</a></li>
                <li><a href="/">{t('Startups')}</a></li>
                <li><a href="/">{t('Games')}</a></li>
                <li><a href="/">{t('Application')}</a></li>
                <li><a href="/">{t('Ecommerce')}</a></li>
              </ul>
            </div>
            <div className="footer-item">
              <h5 className="c-white mB-10"><b className="fw-n">{t('Company')}</b></h5>
              <ul className="list-unstyled">
                <li><a href="/help-center">Help</a></li>
                <li><a href="/contact-us">Contact Us</a></li>
                <li><a href="/pricing-fees">Privacy</a></li>
                <li><a href="/about-us">Check Our Story</a></li>
                <li><a href="/careers">Work with us</a></li>
                <li><a href="/">Press</a></li>
                <li><a href="/download-iphone-phone">Apps</a></li>
                <li><a href="/blog">Blog</a></li>
              </ul>
            </div>
            <div className="footer-item">
              <h5 className="c-white mB-10"><b className="fw-n">{t('Features')}</b></h5>
              <ul className="list-unstyled">
                <li><a href="/personal-tour">{t('Wallet')}</a></li>
                <li><a href="/cryptop-currency-converting">{t('Payment System')}</a></li>
                <li><a href="/marketplace">{t('Multiple')}</a></li>
                <li><a href="/business-tour">{t('Business')}</a></li>
                <li><a href="/merchant-tour">{t('Merchants')}</a></li>
                <li><a href="/cryptocurrency-calculato">{t('Micropayments')}</a></li>
                <li><a href="/business-tour">{t('Invoice & Billing')}</a></li>
                <li><a href="/ico-listing-application">{t('Fraud Detection')}</a></li>
              </ul>
            </div>
            <div className="footer-item">
              <h5 className="c-white mB-10"><b className="fw-n">{t('Platform')}</b></h5>
              <ul className="list-unstyled">
                <li><a href="/pricing-fees">{t('Fees')}</a></li>
                <li><a href="/sign-up-business">{t('Paybit for Business')}</a></li>
                <li><a href="/sign-in">{t('Login to Paybit')}</a></li>
                <li><a href="/paybit-status-page">{t('Paybit Status')}</a></li>
                <li><a href="/marketplace">{t('Marketplace')}</a></li>
                <li><a href="/personal-tour">{t('Tour')}</a></li>
                <li><a href="/sign-up-business">{t('Become Merchant')}</a></li>
                <li><a href="/marketplace">{t('Market Cap')}</a></li>
              </ul>
            </div>
            <div className="footer-item">
              <h5 className="c-white mB-10"><b className="fw-n">{t('Commerce Resources')}</b></h5>
              <ul className="list-unstyled">
                <li><a href="/blog">{t('Blog')}</a></li>
                <li><a href="/what-is-bitcoin">{t('Whitepapers')}</a></li>
                <li><a href="/">{t('Webinars')}</a></li>
                <li><a href="/">{t('Product Datasheets')}</a></li>
                <li><a href="/">{t('Customer Case studies')}</a></li>
                <li><a href="/">{t('Developer Portal')}</a></li>
                <li><a href="/">{t('Knowledge Center')}</a></li>
                <li><a href="/">{t('Sandbox')}</a></li>
              </ul>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row justify-content-between">
            <div className="col-md-12 col-lg-6 mB-15">
              <h5 className="c-white mB-15"><b className="fw-n">Subscribe to our Newsletter</b></h5>
              <form action="#" className="form-subscribe">
                <div className="input-group">
                  <i className="icon-mail" />
                  <input type="text" className="form-control" placeholder="Enter your email address here" />
                  <div className="input-group-append">
                    <button className="btn-primary btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-12 col-lg-5 mB-15">
              <h5 className="c-white mB-15"><b className="fw-n">Follow us</b></h5>
              <ul className="list-unstyled list-social-footer">
                <li><a href="/"><i className="fab fa-facebook-f" /></a></li>
                <li><a href="https://twitter.com/paybitcom" target="_blank"><i className="fab fa-twitter" /></a></li>
                <li><a href="https://www.instagram.com/paybitcom" target="_blank"><i className="fab fa-instagram" /></a></li>
                <li><a href="/"><i className="fab fa-linkedin-in" /></a></li>
                <li><a href="/"><i className="fab fa-youtube" /></a></li>
                <li><a href="/"><i className="fab fa-pinterest-p" /></a></li>
                <li><a href="/"><i className="fas fa-hospital-symbol" /></a></li>
                <li><a href="/"><i className="fab fa-reddit-alien" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="d-flex align-items-center d-xl-inline-block w-100">
            <div className="c-a6 mR-30 text-copyright">{`© 2013–${new Date().getFullYear()} Paybit, Inc. All rights reserved.`}</div>
            <ul className="mb-0 d-flex list-unstyled footer-copyright-menu">
              <li className="mR-20"><a href="/" className="c-a6">{t('Privacy Policy')}</a></li>
              <li className="mR-20"><a href="/" className="c-a6">{t('Terms & Conditions')}</a></li>
              <li className="mR-20"><a href="/" className="c-a6">{t('Refund Policy')}</a></li>
              <li className="mR-20"><a href="/" className="c-a6">{t('Sitemap')}</a></li>
              <li className="mR-20"><a href="/" className="c-a6">{t('Cookie Policy')}</a></li>
            </ul>
            <div className="ml-auto d-flex footer-copyright-right">
              <Dropdown className="dropdown dropdown-coin">
                <Dropdown.Toggle as={CustomToggle} fixedClassName="coin-header">
                  <span className="circle size-20" style={{ backgroundImage: `url(${coins[coin][1]})` }} />
                  <span>{coins[coin][0]}</span>
                </Dropdown.Toggle>
                <Dropdown.Toggle className="btn dropdown-toggle no-after" type="button" id="dropdownBTC" data-toggle="dropdown">
                  <i className="fas fa-chevron-down" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu" aria-labelledby="dropdownBTC">
                  {map(coins, (value, key) => (
                    <Dropdown.Item className="dropdown-item" key={key} onClick={changeCoinEvent(key)}>
                      <span className="circle coin-icon-20" style={{ backgroundImage: `url(${value[1]})` }} />
                      {value[0]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="dropdown dropdown-language">
                <Dropdown.Toggle as={CustomToggle} fixedClassName="language-header">
                  <span className="circle size-20" style={{ backgroundImage: `url(${languages[language][1]})` }} />
                  <span>{languages[language][0]}</span>
                </Dropdown.Toggle>
                <Dropdown.Toggle className="btn dropdown-toggle no-after" type="button" id="dropdownLanguage" data-toggle="dropdown">
                  <i className="fas fa-chevron-down" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-language" aria-labelledby="dropdownLanguage">
                  {map(languages, (value, key) => (
                    <Dropdown.Item className="dropdown-item" key={key} onClick={changeLanguageEvent(key)}>
                      <span className="circle coin-icon-20" style={{ backgroundImage: `url(${value[1]})` }} />
                      {value[0]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};


export default compose(
  withState('coin', 'setCoin', (localStorage.getItem('usedCoin') || 'Bitcoin')),
  withState('language', 'setLanguage', (localStorage.getItem('usedLanguage') || 'en')),
  withHandlers({
    changeCoinEvent: ({ setCoin }) => coin => () => {
      localStorage.setItem('usedCoin', coin);
      setCoin(coin);
    },
    changeLanguageEvent: ({ setLanguage }) => language => () => {
      localStorage.setItem('usedLanguage', language);
      i18n.changeLanguage(language);
      setLanguage(language);
    },
  }),
)(Footer);
