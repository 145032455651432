import React from 'react';

import { fromRenderProps, compose } from 'recompose';

const isDaskMode = localStorage.getItem('daskMode') === 'true';

export const DaskModeContext = React.createContext({
  daskMode: isDaskMode,
  toggleDaskMode: () => { localStorage.setItem('daskMode', !(localStorage.getItem('daskMode') === 'true')); },
});

export const withDaskMode = compose(
  fromRenderProps(DaskModeContext.Consumer, ({ daskMode, toggleDaskMode }) => ({
    daskMode,
    toggleDaskMode,
  })),
);

export const DaskModeWrapper = ({ children, ...props }) => (
  <DaskModeContext.Provider
    value={props}
  >
    {children}
  </DaskModeContext.Provider>
);
