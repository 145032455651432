import { createActions } from 'redux-actions';
import ActionTypes from '../constants';

export const {
  fetchPrices,
  fetchHistorical,
  updatePrices,
  updateHistorical,
} = createActions({
  [ActionTypes.FETCH_PRICES]: payload => payload,
  [ActionTypes.FETCH_HISTORICAL]: payload => payload,
  [ActionTypes.UPDATE_PRICES]: payload => payload,
  [ActionTypes.UPDATE_HISTORICAL]: payload => payload,

});
