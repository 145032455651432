import React from 'react';
import { Modal } from 'react-bootstrap';
import Countup from 'react-countup';

const ModalTransactionDetail = ({ isOpenModal, closeModal }) => (
  <Modal
    className="modal fade right modal-transaction-detail"
    id="transaction-detail-modal"
    tabIndex={-1}
    show={isOpenModal}
    onHide={closeModal}
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header bdn mY-20 ">
          <div className="btn btn-lg btn-around">
            <span className="c-gray">Transaction ID:</span>
            #21313131
          </div>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <Modal.Body className="modal-body">
          <div className="bd bg-white">
            <div className="curency-detail bdB p-20 text-center">
              <div className="curency-logo"><div className="img img-104" style={{ backgroundImage: 'url(/images/bc-logo.png)' }} /></div>
              <h3><b className="fw-n"><Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} /></b></h3>
              <p className="fsz-def c-grey">Sent to Bitcoin Wallet</p>
              <div>
                <span className="btn btn-o-green btn-around">Complete</span>
              </div>
            </div>
            <div className="p-20 bdB">
              <div className="d-flex align-items-center">
                <div className="circle img size-45" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                <div className="mL-20">
                  <b className="fw-n fsz-md">Bruce Springesteen</b>
                  <p className="m-0 c-gray">brucespringesteen@yahoo.com</p>
                </div>
              </div>
            </div>
            <div className="p-20 bdB">
              <div className="d-flex justify-content-between pY-5 fsz-def">
                <div>
                  <p className="c-gray whs-nw mb-0 pY-5">Wallet Address:</p>
                  <p className="c-gray whs-nw mb-0 pY-5">Date:</p>
                  <p className="c-gray whs-nw mb-0 pY-5">Refence:</p>
                </div>
                <div className="pL-20 w-100">
                  <p className="mb-0 pY-5"><b className="fw-n">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</b></p>
                  <p className="mb-0 pY-5"><b className="fw-n">October 10,2018</b></p>
                  <p className="mb-0 pY-5"><b className="fw-n">Client</b></p>
                </div>
              </div>
            </div>
            <div className="p-20 bdB">
              <a href="/" className="c-black fsz-def d-flex justify-content-center">
                <i className="mR-15 c-green fsz-lg icon-download" />
Download proof of transfer
              </a>
            </div>
            <div className="p-20 bdB">
              <div className="pB-10 mB-20 bdB">
                <h5><b className="fw-n">Add a comment</b></h5>
                <input type="text" className="form-control bdn bdB pl-0" placeholder="Type here to put a note..." />
              </div>
              <h5 className="mB-15"><b className="fw-n">Rest of Information</b></h5>
              <div className="d-flex justify-content-between pY-5 fsz-def">
                <div>
                  <p className="c-gray whs-nw mb-0 pY-5">ID:</p>
                  <p className="c-gray whs-nw mb-0 pY-5">Status:</p>
                  <p className="c-gray whs-nw mb-0 pY-5">Sum:</p>
                  <p className="c-gray whs-nw mb-0 pY-5">Fees:</p>
                  <p className="c-gray whs-nw mb-0 pY-5">Time:</p>
                  <p className="c-gray whs-nw mb-0 pY-5">Transaction Type:</p>
                  <p className="c-gray whs-nw mb-0 pY-5">Account Email Address:</p>
                </div>
                <div className="pL-20 w-100">
                  <p className="mb-0 pY-5"><b className="fw-n">21313155</b></p>
                  <p className="mb-0 pY-5"><b className="fw-n">Complete</b></p>
                  <p className="mb-0 pY-5"><b className="fw-n">$23,42</b></p>
                  <p className="mb-0 pY-5"><b className="fw-n">$244,422</b></p>
                  <p className="mb-0 pY-5"><b className="fw-n">08:44 PM</b></p>
                  <p className="mb-0 pY-5"><b className="fw-n">Web</b></p>
                  <p className="mb-0 pY-5"><b className="fw-n">brucespringesteen@yahoo.com</b></p>
                </div>
              </div>
            </div>
            <div className="p-20 text-center">
              <a href="/transactions-detail" className="c-gray fsz-def">More Details</a>
            </div>
          </div>
        </Modal.Body>
      </div>
    </div>
  </Modal>
);

export default ModalTransactionDetail;
