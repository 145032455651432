export default {
  header: {
    'Paybit Products': 'Sản phẩm Paybit',
    Products: 'Sản phẩm',
    Services: 'Dịch Vụ',
    Apps: 'Ứng dụng',
    Wallet: 'Ví',
    Marketcap: 'Marketcap',
    Business: 'Kinh doanh',
    'Fraud Detection': 'Phát hiện gian lận',
    Explorer: 'Nhà thám hiểm',
    Careers: 'Nghề nghiệp',
    'Crypto Market': 'Thị trường tiền điện tử',
    Marketplace: 'Thị trường',
    Referral: 'Giới thiệu',
    'Personal Tour': 'Chuyến tham quan cá nhân',
    'Paybit Benefits': 'CÁCH CHÚNG TÔI LÀM VIỆC',
    'Paybit Services': 'Dịch vụ Paybit',
    'Pricing & Packaging': 'Giá & Gói',
    'Check Our Story': 'Về chúng tôi',
    'Invoice & Billing': 'Hóa đơn & Thanh toán',
    'Paybit Status': 'Trạng thái Paybit',
    Merchants: 'Thương nhân',
    Common: 'Chung',
    'Message Center': 'Trung tâm tin nhắn',
    'Paybit APPS': 'Paybit APPS',
    'Android Phone': 'Android Phone',
    'Android Tablet': 'Android Tablet',
    Desktop: 'Desktop',
    'Apple Mac': 'Apple Mac',
    'Apple Ipad': 'Apple Ipad',
    'Apple Iphone': 'Apple Iphone',
    'Send, Receive, and Trade': 'Gửi, nhận và giao dịch',
    'Institutional Portal': 'Cổng thông tin thể chế',
    'Get your money faster, easier and cheaper': 'Nhận tiền của bạn nhanh hơn, dễ dàng hơn và rẻ hơn',
    'Download the Android Phone App.': 'Quản lý doanh nghiệp của bạn khi đang di chuyển.',
    'Uptime and status': 'Thời gian hoạt động và trạng thái',
    'Business solutions': 'Giải pháp kinh doanh',
    'Download the Android Tablet App.': 'Download the Android Tablet App.',
    'Download the Mac App.': 'Download the Mac App.',
    'Download the Ipad App.': 'Download the Ipad App.',
    'Download the Iphone App.': 'Download the Iphone App.',
    'Download the Desktop App': 'Nhận tiền của bạn nhanh hơn, dễ dàng hơn và rẻ hơn với PayBit.',
    Pricing: 'Giá cả',
    'Transparent pricing for all businesses': 'Định giá minh bạch cho tất cả các doanh nghiệp',
    Company: 'Công ty',
    Support: 'Hỗ trợ',
    Blog: 'Blog',
    'Help & Support': 'Trợ giúp & Hỗ trợ',
    'Contact Us': 'Liên hệ với chúng tôi',
    Solutions: 'Giải pháp',
    'Paybit Solutions': 'Giải pháp Paybit',
    'Toolbar Download': 'Thanh công cụ Download',
    'Get your money faster, easier and cheaper with PayBit.': 'Nhận tiền của bạn nhanh hơn, dễ dàng hơn và rẻ hơn với PayBit.',
    'Training Portal': 'Training Portal',
    'Manage your business on the go.': 'Quản lý doanh nghiệp của bạn khi đang di chuyển.',
    'CO Listing Application': 'Ứng dụng niêm yết CO',
  },
  footer: {
    Wallet: 'Ví',
    Business: 'Kinh Doanh',
    Products: 'Sản phẩm',
    Solutions: 'Giải pháp',
    Apps: 'Ứng dụng',
    'Help & Support': 'Trợ giúp & Hỗ trợ',
    'Pricing & Packaging': 'Pricing & Packaging',
    'Contact Us': 'Liên hệ với chúng tôi',
    'Software Companies': 'Software Companies',
    'SaaS Companies': 'SaaS Companies',
    Startups: 'Khởi nghiệp',
    Games: 'Games',
    Application: 'Application',
    Ecommerce: 'Thương mại điện tử',
    About: 'Giới thiệu',
    Company: 'Công ty',
    Features: 'Tính năng',
    Platform: 'Nền tảng',
    'Commerce Resources': 'Tài nguyên thương mại',
    'Payment System': 'Hệ thống thanh toán',
    Multiple: 'Multiple',
    Merchants: 'Thương gia',
    Micropayments: 'Micropayments',
    'Invoice & Billing': 'Hóa đơn & Thanh toán',
    'Fraud Detection': 'Phát hiện gian lận',
    Fees: 'Phí',
    'Paybit for Business': 'Paybit for Business',
    'Login to Paybit': 'Đăng nhập vào Paybit',
    'Paybit Status': 'Paybit Status',
    Marketplace: 'Thị trường',
    Tour: 'Tham quan',
    'Become Merchant': 'Trở thành thương gia',
    'Market Cap': 'Market Cap',
    'Product Datasheets': 'Dữ liệu sản phẩm',
    'Customer Case studies': 'Nghiên cứu trường hợp khách hàng',
    'Developer Portal': 'Cổng thông tin dành cho nhà phát triển',
    'Knowledge Center': 'Trung tâm kiến ​​thức',
    'Privacy Policy': 'Chính sách bảo mật',
    'Terms & Conditions': 'Điều khoản & Điều kiện',
    'Refund Policy': 'Refund Policy',
    Sitemap: 'Sitemap',
    'Cookie Policy': 'Cookie Policy',
  },
  'langding page': {
    title: 'Paybit — thanh toán trực tuyến được thực hiện dễ dàng',
    'Paybit Solutions for Online, Mobile and In-Store': 'Paybit giải pháp thanh toán cho trực tuyến, di động và tại cửa hàng',
    'Available Currencies': 'Nhiều tiền tệ khả dụng',
    'Transfer in Secound': 'Chuyển trong vài giây',
    'Mighty Protection': 'Bảo vệ mạnh mẽ',
    'Easy to Use': 'Dễ sử dụng',
    '24/7 Support': 'Hỗ trợ 24/7',
    'Organized Payments': 'Thanh toán có tổ chức',
    'Start Selling ': 'Start Selling ',
    'Start Shopping ': 'Start Shopping ',
    'Download our Tools': 'Tải xuống Công cụ của chúng tôi',
    'Download for App Store': 'Tải xuống cho App Store',
    'Buyers make purchases': 'Người mua thực hiện mua hàng trên các trang web của thương nhân và các thương nhân',
    'Add Paybit to Your Mobile Site and App': 'Thêm Paybit vào trang web và ứng dụng di động của bạn',
    'Add Paybit to Your Website': 'Thêm Paybit vào trang web của bạn',
    'Accept Paybit in Your Store': 'Chấp nhận thanh toán trong cửa hàng của bạn',
    'Conditions apply': 'Mua sắm yên tâm, biết rằng chúng tôi bảo vệ giao dịch mua hàng đủ điều kiện của bạn. Nếu một mặt hàng đủ điều kiện không xuất hiện, hoặc hóa ra khác với mô tả, chúng tôi sẽ giúp sắp xếp mọi thứ với người bán. Điều kiện áp dụng.',
    'We offer': 'Chúng tôi cung cấp hệ thống Bảo vệ Người mua và Người bán. Thanh toán có thể được hoàn lại lên tới 180 ngày, dựa trên những gì được bán. Không ai cần phải lo lắng khi gặp sự cố, hãy đọc chi tiết về Người mua và Bảo vệ của chúng tôi để biết thêm thông tin.',
    'Apps with Paybit': 'Người mua thực hiện mua hàng trên các trang web của thương nhân và trong các thương nhân Các ứng dụng với Paybit. Paybit sẽ khấu trừ số tiền thanh toán từ tài khoản Paybit của người mua theo thời gian thực bằng CNY và thanh toán cho người bán bằng tiền điện tử  tiền tệ.',
    benefits: 'lợi ích',
    simple: 'đơn giản',
    Get: 'Get everything you need',
    got: 'Nhận mọi thứ bạn cần',
    support: 'Chúng tôi hỗ trợ mọi loại tiền điện tử có thể. Từ BTC đến ETH, từ EON đến NEO. Currencys được chuyển đổi tự động.',
    better: 'Thanh toán không thể trở nên tốt hơn. Thanh toán cho bất kỳ ai chỉ bằng một cú nhấp chuột đơn giản. Mọi thứ đều được thực hiện trong lightpeed.',
    'dont worry': 'Bạn không cần phải lo lắng. Với Bảo vệ người mua PayBit ​​của chúng tôi. bạn không phải lo lắng về bất kỳ vấn đề nào.',
    'PayBit is simple': 'PayBit ​​rất đơn giản. Bạn có thể trả tiền chỉ với một chút. Sau khi đăng ký, cần xác minh ngắn. Sau đó, bạn có thể bắt đầu trả tiền và bán.',
    'Our large staff': 'Đội ngũ nhân viên lớn của chúng tôi luôn có mặt khi cần thiết. Bất kể vấn đề của bạn lớn hay nhỏ, chúng tôi sẽ cố gắng hết sức để giải quyết mọi thứ.',
    'With our simple': 'Với các khoản thanh toán tổ chức Bảng điều khiển đơn giản nhưng mạnh mẽ của chúng tôi đã đạt đến một cấp độ mới Mọi thứ cần thiết cho cả khách hàng doanh nghiệp và khách hàng bình thường',
    fast: 'nhanh',
    'Payment in a few clicks': 'Thanh toán trong một vài lần nhấp',
    'Millions of customers': 'Hàng triệu khách hàng trên khắp thế giới sử dụng chúng tôi vì một lý do đơn giản: nó đơn giản. Chỉ cần một địa chỉ email và mật khẩu sẽ giúp bạn kiểm tra trước khi bạn có thể lấy ví của mình.',
    'Pay multiple': 'Thanh toán trên trang web',
    'Great sucess': 'Trả nhiều khách hàng / khách hàng',
    pp: 'thành công',
    'Send money': 'Gửi tiền trực tuyến hoặc bằng điện thoại di động của bạn.',
    'Send money de': 'Gửi tiền cho hầu hết mọi người có địa chỉ email. Bạn có thể gửi tất cả mọi nơi trên thế giới và họ sẽ rất vui mừng khi nhận được tin nhắn của bạn. Phí chuyển đổi tiền tệ có thể được áp dụng.',
    'Shop around the world.': 'Mua sắm trên toàn thế giới.',
    'Shop around': 'Cửa hàng trên toàn thế giới',
    'Shop around de': 'Với PayBit, bạn có thể mua sắm tại hàng ngàn cửa hàng trên khắp thế giới chỉ bằng vài cú nhấp chuột dễ dàng, biết rằng chi tiết thẻ của bạn sẽ không bao giờ được chia sẻ với người bán. Phí chuyển đổi tiền tệ có thể được áp dụng.',
    '#1 Wallet': '#1 Ví điện tử',
    '#1 Wallet de': 'Với hơn 28 triệu ví, giao dịch $ 200B và phí thấp nhất trong ngành, không có gì lạ khi chúng tôi là ví tiền điện tử đáng tin cậy nhất.',
    Security: 'Bảo mật',
    'Send & Receive': 'Gửi và nhận',
    'Buy, Sell, & Exchange': 'Mua, bán và trao đổi',
    'We have': 'Chúng tôi có nhiều thứ và tính năng hơn để cung cấp cho bạn',
    'Send multiple Payments': 'Gửi nhiều khoản thanh toán',
    'Tap into all your money.': 'Chạm vào tất cả tiền của bạn.',
    'Multiple developers APIs': 'Nhiều API nhà phát triển',
    'You are secured': 'Bạn được bảo mật',
    'Transfer in Secounds': 'Chuyển trong giây',
    'Send and request money fast.': 'Gửi và yêu cầu tiền nhanh.',
    'Manage your money easily.': 'Quản lý tiền của bạn một cách dễ dàng.',
    'Collect money from groups.': 'Thu thập tiền từ các nhóm.',
    'Payments can not get simpler': 'Thanh toán không thể đơn giản hơn! Bạn có thể thanh toán nhiều khách hàng, chi nhánh hoặc khách hàng cùng một lúc chỉ bằng một cú nhấp chuột đơn giản.',
    'Use our mobile tools': 'Sử dụng các công cụ và bảng điều khiển di động của chúng tôi để luôn có cái nhìn tổng quan về doanh nghiệp và tiền bạc của bạn.',
    'We have solutions': 'Chúng tôi có giải pháp cho nhiều hệ thống thương mại điện tử lớn và nhiều API dành cho nhà phát triển cho từng giải pháp có thể.',
    'it free': 'Nó miễn phí gửi tiền cho bạn bè và gia đình ở Hoa Kỳ.',
    'The improved Paybit': 'Ứng dụng Paybit được cải tiến giúp việc gửi và yêu cầu tiền, quản lý tín dụng, tiền mặt từ các đồng nghiệp trở nên đơn giản.',
    Whether: 'Cho dù bạn đang thu tiền cho quà tặng nhóm, du lịch hoặc các sự kiện đặc biệt.',
    Click: 'Nhấp vào liên kết dưới đây và bắt đầu sử dụng',
    'Save unlimited': 'Lưu hình ảnh, video và văn bản không giới hạn từ web bằng Lưu trữ Cloud.co',
    'Download Toolsbars for browser': 'Tải xuống Thanh công cụ cho trình duyệt',
    'Download for Google Play': 'Tải xuống cho Google Play',
    'Access all': 'Truy cập tất cả ảnh, video và tài liệu của bạn ở mọi nơi, mọi lúc, từ mọi thiết bị',
    'Your money': 'Tiền của bạn đang trên đường trong một vài cú nhấp chuột.',
    'Sign Up': 'Đăng ký',
    'Our Apps': 'Ứng dụng của chúng tôi',
  },
  YourMoney: {
    'Your money': 'Tiền của bạn đang trên đường trong một vài cú nhấp chuột.',
    'We offer': 'Chúng tôi cung cấp hệ thống Bảo vệ Người mua và Người bán. Thanh toán có thể được hoàn lại lên tới 180 ngày, dựa trên những gì được bán. Không ai cần phải lo lắng khi gặp sự cố, hãy đọc chi tiết về Người mua và Bảo vệ của chúng tôi để biết thêm thông tin.',
    'Millions of payments processed': 'Hàng triệu thanh toán được xử lý',
    'Get Started': 'Hàng triệu khách hàng trên toàn thế giới sử dụng chúng tôi vì một lý do đơn giản',
    'And even more clients': 'Và thậm chí nhiều khách hàng hơn',
    'We’ll help': 'Chúng tôi sẽ giúp sắp xếp mọi thứ với người bán. Điều kiện áp dụng.',
    'About 5000': 'Khoảng 5000 tích hợp API',
    'Over 500.000': 'Hơn 500.000 tích hợp API',
    Monitoring: 'Giám sát',
    'With a few clicks you': 'Với một vài lần nhấp, bạn có thể tạo và xem vô số khoản thanh toán.',
  },
  CurrencySlider: {
    Virtual: 'Thanh toán tiền ảo & tiền điện tử ',
    'Last updated': 'Thông tin thị trường cập nhật lần cuối',
  },
  WeGotCoveredSlider: {
    'We got you covered.': 'Chúng tôi đã bảo vệ bạn.',
    'Shop with peace': 'của tâm trí, biết rằng chúng tôi bảo vệ mua hàng đủ điều kiện của bạn. Nếu một mặt hàng đủ điều kiện không xuất hiện hoặc hóa ra khác với mô tả, chúng tôi sẽ giúp sắp xếp mọi thứ với người bán. Điều kiện áp dụng.',
    'More about PayBit': 'Tìm hiểu thêm về Bảo vệ người mua PayBit',
    'Learn More': 'Tìm hiểu thêm',
  },
  SectionPartner: {
    'We offer': '“Chúng tôi cung cấp một hệ thống bảo vệ người mua và người bán. Thanh toán có thể được hoàn lại lên tới 180 ngày, dựa trên những gì được bán. Không ai cần phải lo lắng.”',
  },
  ProductTabs: {
    'Personal Product': 'Sản phẩm cá nhân',
    Merchants: 'Thương gia',
    Partners: 'Đối tác',
    'Collect payments': 'Thu thập thanh toán trên web và điện thoại di động từ người tiêu dùng Trung Quốc.',
    'Paybit transaction': 'Giao diện khai báo giao dịch thanh toán cho hải quan Trung Quốc.',
    'Cross-border Online Payment': 'Thanh toán trực tuyến xuyên biên giới',
    'Customs Declaration': 'Khai báo hải quan',
    'Cross-border In-Store Payment In-Store': 'Thanh toán tại cửa hàng xuyên biên giới',
    Mobile: 'Di động',
    'In - Store': 'In - Store',
    'Go see more': 'Đi xem thêm',
  },
  Sidebar: {
    Dashboard: 'Bảng điều khiển',
    Wallets: 'Ví',
    Transactions: 'Giao dịch',
    Statistics: 'Số liệu thống kê',
    Settings: 'Cài đặt',
    Help: 'Trợ giúp',
  },
  ToggleMenu: {
    Index: 'Index',
    'Send Money': 'Gửi tiền',
    Notifications: 'Thông báo',
    Settings: 'Cài đặt',
    Messages: 'Tin nhắn',
    Contacts: 'Liên hệ',
  },
  UserStep: {
    'Your next steps': 'Bước tiếp theo của bạn',
    finished: 'Hoàn thành {{step}} ',
    Address: 'Địa chỉ',
    'Connect Bank Account': 'Kết nối tài khoản ngân hàng',
    'Upload Tax Documents': 'Tải lên tài liệu thuế',
    'Deposit Money': 'Tiền đặt cọc',
    'Fill your address and contact info': 'Điền địa chỉ và thông tin liên lạc của bạn',
    'You have connected 2 accounts': 'Bạn đã kết nối 2 tài khoản',
    'You uploaded W-2 and 1099': 'Bạn đã tải lên W-2 và 1099',
    'You can deposit from your bank': 'Bạn có thể gửi tiền từ ngân hàng của bạn',
    'Server time': 'Server time: {{time}}',
    Action: 'Hành động',
    'Another action': 'Hành động khác ',
    'Something else here': 'Cái gì khác ở đây',
    'Separated link': 'Liên kết riêng',
    PayBit: '© 2018 Pay`Bit All rights reserved.',
    Security: 'Bảo vệ',
    'Terms & Conditions': 'Điều khoản và điều kiện',
  },
  UserDropdown: {
    Message: 'Tin nhắn',
    Profile: 'Hồ sơ',
    'Account Settings': 'Cài đặt tài khoản',
    Notifications: 'Thông báo',
    Contacts: 'Liên lạc',
    Security: 'Bảo vệ',
    Merchants: 'Thương gia',
    Developers: 'Nhà phát triển',
    'Lock Account': 'Khóa tài khoản',
    'Sign Out': 'Đăng xuất',
    'Paybit Balance': 'Số dư thanh toán',
  },
  DownloadAppleMac: {
    title: 'Paybit - Tải xuống Apple Mac',
    Millions: 'Hàng triệu khách hàng trên khắp thế giới sử dụng chúng tôi vì một lý do đơn giản: nó đơn giản. Chỉ cần một địa chỉ email và mật khẩu sẽ giúp bạn kiểm tra trước khi bạn có thể lấy ví của mình.',
    free: 'Nó miễn phí gửi tiền cho bạn bè và gia đình ở Hoa Kỳ bằng tài khoản ngân hàng hoặc số dư Paybit của bạn. Thanh toán chia sẻ hóa đơn của bạn hoặc yêu cầu tiền từ bạn bè một cách dễ dàng.',
    find: 'Tìm thêm của chúng tôi',
    'Send and request money fast.': 'Gửi và yêu cầu tiền nhanh.',
    'Manage your money easily.': 'Quản lý tiền của bạn một cách dễ dàng.',
    'Collect money from groups.': 'Thu thập tiền từ các nhóm.',
    Message: 'Ứng dụng Paybit được cải tiến giúp gửi và yêu cầu tiền đơn giản, quản lý tín dụng, gom tiền từ các đồng nghiệp và tạo mục tiêu tiết kiệm. Ngoài ra, bạn có thể dễ dàng theo dõi và giám sát mọi giao dịch bạn thực hiện. ',
    Whether: 'Cho dù bạn đang thu tiền cho quà tặng theo nhóm, du lịch hoặc các sự kiện đặc biệt, Money Pools giúp dễ dàng tạo một trang tùy chỉnh cho phép gia đình, bạn bè và đồng nghiệp đóng góp cho các mặt hàng có giá trị lớn.',
    'Get up to speed on getting paid.': 'Hãy tăng tốc để được trả tiền.',
    'Stay on top of your business': 'Luôn đứng đầu doanh nghiệp của bạn',
    'Mobilize how you do business.': 'Huy động cách bạn kinh doanh.',
    'Create and send invoices in the moment, so customers can pay right away.': 'Tạo và gửi hóa đơn trong thời điểm này, để khách hàng có thể thanh toán ngay.',
    'Whether you need to transfer funds, view transaction details.': 'Bạn có cần chuyển tiền hay không, xem chi tiết giao dịch.',
    'Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S.': 'Apple và logo Apple là các nhãn hiệu của Apple Inc., được đăng ký tại Hoa Kỳ',
  },
  OurProdcut: {
    'Getting started is easy.': 'Bắt đầu thật dễ dàng.',
    'Main Features': 'Những đặc điểm chính',
    'Key-in': '* Các giao dịch và doanh số chính trên 500 đô la trong thời gian 7 ngày được thực hiện với Đầu đọc thẻ di động phải chịu khoản dự trữ 30 ngày tự động trong đó tiền được giữ trong tài khoản Paybit của bạn để bảo hiểm rủi ro cao liên quan đến các loại giao dịch này. Để tăng cường bảo vệ khỏi các giao dịch gian lận, chúng tôi khuyên bạn nên sử dụng đầu đọc thẻ chip.',
    'All Paybit': 'Tất cả các tài khoản Paybit phải tuân theo các chính sách có thể dẫn đến các hạn chế tài khoản dưới dạng giữ, giới hạn hoặc dự trữ.',
    Millions: 'Hàng triệu khách hàng trên khắp thế giới sử dụng chúng tôi vì một lý do đơn giản: nó đơn giản. Chỉ cần một địa chỉ email và mật khẩu sẽ giúp bạn kiểm tra trước khi bạn có thể lấy ví của mình.',
    Looking: 'Tìm kiếm sự giúp đỡ hoặc tư vấn? Điền vào mẫu dưới đây hoặc gửi email cho chúng tôi. Chúng tôi sẽ trả lời bạn càng sớm càng tốt.',
    Security: 'Bảo m',
    Rest: 'Hãy yên tâm khi biết tiền điện tử của bạn được bảo mật bằng ví tiền điện tử đáng tin cậy nhất thế giới. Chúng tôi cung cấp cho bạn toàn quyền kiểm soát, sao lưu tiền của bạn và bảo vệ chúng khỏi sự truy cập trái phép.',
    'Send & Receive': 'Gửi và nhận',
    'Buy, Sell, & Exchange': 'Mua, bán và trao đổi',
    'Access Anywhere': 'Truy cập mọi nơi',
    'Simplified disputes resolution': 'Giải quyết tranh chấp đơn giản hóa',
    'All information on one screen': 'Tất cả thông tin trên một màn hình',
    Whether: 'Dù bạn gửi cho bạn bè hay giao dịch cho doanh nghiệp, PayBit ​​cho phép bạn gửi và nhận tiền điện tử với mức phí thấp nhất.',
    Easily: 'Dễ dàng trao đổi tiền điện tử thành tiền điện tử trong Ví PayBit ​​của bạn. Hiện tại chúng tôi cung cấp mua và bán cho người dùng ở nhiều quốc gia muốn đầu tư vào tiền điện tử mà không để lại trải nghiệm ví an toàn của bạn.',
    Transform: 'Chuyển đổi hệ thống tài chính ngay từ túi của bạn! Ví Paybit của bạn có sẵn ở bất cứ đâu - sẵn sàng để di chuyển, giống như bạn vậy.',
    'No need to worry': 'Không cần phải lo lắng về sự biến động của thị trường - bạn luôn nhận được những gì bạn yêu cầu.',
    'This makes our integration': 'Điều này làm cho rủi ro hội nhập của chúng tôi và không gặp rắc rối cho các thương nhân và doanh nghiệp của họ.',
  },
  Acccount: {
    title: 'Tài khoản',
    sub: 'Làm quen với hệ thống tài khoản của chúng tôi.',
  },
  SecurityNPrivacy: {
    title: 'Bảo mật & quyền riêng tư',
    sub: 'Chúng tôi cung cấp một hệ thống bảo vệ người mua và người bán. Thanh toán có thể được hoàn lại lên tới 180 ngày, dựa trên những gì được bán. ',
  },
  FAQ: {
    q1: 'Làm cách nào để tôi đăng ký Tài khoản PayBit?',
    a1: 'Its easy as nothing. Just enter your email, signup for free. For real money transaction we need you to do a verification.',
    q2: 'Tôi đại diện cho một công ty. Tôi có thể đăng ký tài khoản doanh nghiệp không?',
    a2: 'Nếu có quyền truy cập vào tài khoản của bạn, hãy truy cập Bảng điều khiển, kiểm tra danh sách các phiên để biết hoạt động đáng ngờ. Nếu có gì đó không ổn, bạn luôn có thể loại bỏ bất kỳ phiên nào và thay đổi mật khẩu. ',
    q3: 'Tôi vẫn chưa tìm được giải pháp cho vấn đề của mình. Bạn có thể giúp tôi được không?',
    a3: 'Liên hệ chúng tôi để có thêm thông tin chi tiết',
    q4: 'Tôi nghĩ rằng tài khoản của tôi đã bị hack. Tôi nên làm gì?',
    a4: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque ab, voluptatibus cum error quidem in tempore molestiae! Commodi minima voluptatem vel accusamus, ipsa id similique officia illum autem nulla soluta!',
    q5: 'Tôi muốn thay đổi mật khẩu của tài khoản. Làm thế nào để tôi làm điều này?',
    a5: 'Nếu có quyền truy cập vào tài khoản của bạn, hãy truy cập Bảng điều khiển, kiểm tra danh sách các phiên để biết hoạt động đáng ngờ. Nếu có điều gì đó không ổn, bạn luôn có thể loại bỏ bất kỳ phiên nào và thay đổi mật khẩu. <br /> Nếu bạn bị mất tài khoản, vui lòng liên hệ ngay với Nhóm hỗ trợ của chúng tôi. Chúng tôi sẽ giúp bạn khôi phục quyền truy cập và ngăn chặn mọi hành động đáng ngờ. ',
    q6: 'Tôi vẫn chưa tìm được giải pháp cho vấn đề của mình. Bạn có thể giúp tôi được không?',
    a6: 'Liên hệ chúng tôi để có thêm thông tin chi tiết',
  },
};
