import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import ReactWOW from 'react-wow';
import { withTranslation } from 'react-i18next';

const Item = ({
  t, name = 'Jonathan Smith', position, text,
}) => (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-12 col-lg-10">
        <div className="text-center c-white">
          <h1 className="mB-40">
            {text}
          </h1>
          <h5 className="mB-20">
            <b className="fw-n">
              {name}
            </b>
            {' '}
            {position}
          </h5>
          {/* <div className="logo-aliexpress m-auto" style={{ backgroundImage: 'url(/images/logo-aliexpress.png)' }} /> */}
        </div>
      </div>
    </div>
  </div>
);

const SectionPartner = ({ backgroundImage = 'images/testimonials-bg2.jpg', t }) => (
  <ReactWOW animation="fadeInUp">
    <section className="section-partner" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Tab.Container defaultActiveKey="tab_partners_1">
        <div className="section-partner-inner pT-30">
          <div className="pY-80 tab-content">
            <Tab.Pane className="tab-pane fade" eventKey="tab_partners_1">
              <Item
                t={t}
                name="Hamid S"
                position="Customer Alixpress"
                text="We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold. Nobody needs to worry to get problems, read our Buyer and Protection details for more information."
              />
              <div className="logo-aliexpress m-auto" style={{ backgroundImage: 'url(/images/logo-aliexpress.png)' }} />
            </Tab.Pane>
            <Tab.Pane className="tab-pane fade" eventKey="tab_partners_2">
              <Item
                t={t}
                name="Jason C"
                position="Customer DHGATE"
                text="Thanks to the payment speed ordering things online is finally not a problem any more. Paybit is truly amazing."
              />
            </Tab.Pane>
            <Tab.Pane className="tab-pane fade" eventKey="tab_partners_3">
              <Item
                t={t}
                name="Harris L"
                position="Customer Paymentgate"
                text="Our platform offers a wide range of local payment methods. Paybit is the ideal partner for virtual currency payments."
              />
            </Tab.Pane>
            <Tab.Pane className="tab-pane fade" eventKey="tab_partners_4">
              <Item
                t={t}
                name="Kevin P"
                position="Customer Herby"
                text="I purchased things and didnt realize how much i liked it. Keep up this great work."
              />
            </Tab.Pane>
            <Tab.Pane className="tab-pane fade" eventKey="tab_partners_5">
              <Item
                t={t}
                name="Savas Y"
                position="Customer AYDE"
                text="The payment was finished the moment it started. I can not imagine a faster experience, Thanks Paybit"
              />
            </Tab.Pane>
          </div>
          <div className="tab-partner">
            <div className="container">
              <Nav className="nav nav-tabs nav-pills nav-fill" role="tablist">
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_partners_1" role="tab" aria-selected="true">ALIEXPRESS</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link " data-toggle="tab" eventKey="tab_partners_2" role="tab" aria-selected="false">DHGATE.COM</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_partners_3" role="tab" aria-selected="false">GOOGLE.COM</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_partners_4" role="tab" aria-selected="false">YAHOO.COM</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_partners_5" role="tab" aria-selected="false">AYDE</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
        </div>
      </Tab.Container>
    </section>
  </ReactWOW>
);

export default withTranslation('SectionPartner')(SectionPartner);
