import { drop } from 'lodash';

export default (collection, page, per = 100, isShowAll) => {
  const total = collection.length;
  if (isShowAll) {
    return {
      page: 1,
      pageSize: total,
      total,
      totalPages: 0,
      data: collection,
      isLast: true,
      showingPages: [],
    };
  }
  const pg = page || 1;
  const pgSize = per || 100;
  const offset = (pg - 1) * pgSize;
  const pagedItems = drop(collection, offset).slice(0, pgSize);
  const totalPages = Math.ceil(collection.length / pgSize);
  const isLast = pg * per >= total;
  let showingPages = [page - 1, page, page + 1];
  if (page === 1) showingPages.push(page + 2);
  showingPages = showingPages.filter(pageIndex => pageIndex > 0 && pageIndex <= totalPages);
  return {
    page: pg,
    pageSize: pgSize,
    total,
    totalPages,
    data: pagedItems,
    isLast,
    showingPages,
  };
};
