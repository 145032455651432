import {
  compose, lifecycle, mapProps, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { historicalsSelector } from '../../../selectors/coinsSelector';
import { fetchHistorical } from '../../../actions/coinsAction';

const CHART_DURATION = {
  all: {
    displayName: 'All',
    deltaTime: [3, 'm'],
    formatTime: 'MM/YYYY',
  },
  year: {
    displayName: 'Year',
    deltaTime: [1, 'M'],
    formatTime: 'MM/YYYY',
  },
  '3Month': {
    displayName: '3 Month',
    deltaTime: [1, 'W'],
    formatTime: 'DD/MM',
  },
  month: {
    displayName: 'Month',
    deltaTime: [3, 'd'],
    formatTime: 'DD/MM/YYYY',
  },
  week: {
    displayName: '7 Days',
    deltaTime: [1.001, 'd'],
    formatTime: 'dddd',
  },
  day: {
    displayName: 'Today',
    deltaTime: [10, 'm'],
    formatTime: 'hh:mm',
  },
};

export const withDetailCryto = compose(
  connect(historicalsSelector, { fetchHistorical }),
  lifecycle({
    componentDidMount() {
      const { fetchHistorical: fetch, symbol } = this.props;
      fetch({ symbol });
    },
    componentDidUpdate(preProp) {
      const { fetchHistorical: fetch, symbol } = this.props;
      if (preProp.symbol !== symbol) fetch({ symbol });
    },
  }),
  withState('chartDuration', 'setChartDuration', 'week'),
  mapProps((props) => {
    const { historical: { durationType = {} } = {}, chartDuration } = props;
    return {
      ...props,
      chartData: durationType[chartDuration] || [],
      durationSettings: CHART_DURATION[chartDuration],
      durationTypes: CHART_DURATION,
    };
  }),
);

export default compose(
  withRouter,
  mapProps(props => ({
    ...props,
    symbol: props.match.params.symbol,
  })),
  withDetailCryto,
);
