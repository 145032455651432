import React from 'react';
import {
  LineChart, Line,
  XAxis, YAxis,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const mockData = [
  {
    xCategory: '0', otherValue: 111, value: 116,
  },
  { xCategory: '1', otherValue: 18, value: 180 },
  {
    xCategory: '2', otherValue: 22, value: 72, time: '01:00 AM', day: 'Jan 17',
  },
  { xCategory: '3', otherValue: 15, value: 156 },
  { xCategory: '4', otherValue: 2, value: 23 },
  { xCategory: '5', otherValue: 13, value: 137 },
  { xCategory: '5', otherValue: 18, value: 72 },
  {
    xCategory: '6', otherValue: 23, value: 51, time: '02:00 AM', day: 'Feb 17',
  },
  { xCategory: '7', otherValue: 11, value: 119 },
  { xCategory: '8', otherValue: 65, value: 86 },
  { xCategory: '9', otherValue: 13, value: 139 },
  { xCategory: '11', otherValue: 65, value: 90 },
  {
    xCategory: '10', otherValue: 19, value: 194, time: '03:00 AM', day: 'Mar 17',
  },
  { xCategory: '12', otherValue: 18, value: 189 },
  { xCategory: '13', otherValue: 45, value: 59 },
  { xCategory: '14', otherValue: 15, value: 156 },
  { xCategory: '15', otherValue: 19, value: 89 },
  {
    xCategory: '16', otherValue: 14, value: 148, time: '04:00 AM', day: 'Apr 17',
  },
  { xCategory: '17', otherValue: 54, value: 64 },
  { xCategory: '18', otherValue: 12, value: 127 },
  { xCategory: '19', otherValue: 4, value: 24 },
  { xCategory: '20', otherValue: 14, value: 144 },
  { xCategory: '21', otherValue: 43, value: 66 },
  {
    xCategory: '22', otherValue: 77, value: 93, time: '05:00 AM', day: 'May 17',
  },
  { xCategory: '23', otherValue: 22, value: 41 },
  { xCategory: '24', otherValue: 11, value: 30 },
  { xCategory: '25', otherValue: 17, value: 179 },
  { xCategory: '26', otherValue: 10, value: 108 },
  {
    xCategory: '28', otherValue: 14, value: 145, time: '06:00 AM', day: 'Jun 17',
  },
  { xCategory: '29', otherValue: 11, value: 30 },
  { xCategory: '30', otherValue: 18, value: 189 },
];

const CustomTick = (props) => {
  const { x, y, payload: { value } } = props;
  const { time } = mockData.find(payload => payload.xCategory === value);
  return (
    <g transform={`translate(${x + 30},${y})`} {...props}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">{time}</text>
    </g>
  );
};

const EarningsChart = () => (
  <ResponsiveContainer>
    <LineChart width={1534} height={370} data={mockData}>
      <Tooltip
        viewBox={{
          x: -1,
          y: -1,
          width: 400,
          height: 400,
        }}
      />
      <XAxis dataKey="xCategory" tickLine={false} tick={CustomTick} axisLine={false} />
      <YAxis tickLine={false} orientation="right" tick={false} width="0.5" fill="#666" axisLine={{ fill: '#666', strokeWidth: 0.5 }} />
      {mockData.filter(value => value.time).map((value) => {
        const { xCategory, day } = value;
        const customLabel = (props) => {
          const { viewBox: { x, y } } = props;
          return (
            <g transform={`translate(${x + 50},${y})`} {...props}>
              <text x={0} y={0} dy={16} textAnchor="end" fill="#666">{day}</text>
            </g>
          );
        };
        return <ReferenceLine x={xCategory} label={customLabel} key={value} />;
      })}
      <Line type="linear" dataKey="value" stroke="#0084FF" fill="url(#statsColor)" fillOpacity={1} dot={false} />
      <Line type="linear" dataKey="otherValue" stroke="#FAAB1A" fill="url(#statsColor)" fillOpacity={1} dot={false} />
    </LineChart>
  </ResponsiveContainer>
);

export default EarningsChart;
