import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import { withTranslation } from 'react-i18next';
import OurProduct from '../OurProduct';
import DownloadFooter from '../DownloadFooter';
import PartnerItems from '../../common/PartnerItems';
import BannerText from '../BannerText';

const DownloadAppleMac = ({ t }) => (
  <>
    <Helmet>
      <title>{t('title')}</title>
    </Helmet>
    <section className="top-banner-download" style={{ backgroundImage: 'url(/images/download-apple-imac-unsplash.jpg)' }}>
      <div className="top-banner-download-outer">
        <div className="top-banner-download-inner c-white">
          <div className="container">
            <ul className="breadcrumb breadcrumbs breadcrumbs-white">
              <li><a href="/"><i className="icon-house" /></a></li>
              <li>
                <i className="fas fa-chevron-right" />
                <a href="download-iphone-phone">{t('Apps')}</a>
              </li>
              <li>
                <i className="fas fa-chevron-right" />
                {' '}
                <span>Apple Mac</span>
              </li>
            </ul>
            <div className="top-banner-text">
              <div className="row justify-content-between">
                <BannerText />
                <ReactWOW animation="slideInRight" duration="0.5s" delay="1.5s">
                  <div className="col-md-12 col-lg-7 col-xl-8 hidden-md">
                    <div className="img-mockup-dashboad-imac" style={{ backgroundImage: 'url(/images/mockup-dashboad-imac.png)' }} />
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row justify-content-center mB-50 ">
          <div className="col-md-12 col-lg-9">
            <h2 className="mY-50 text-center text-before-decor">{t('Millions')}</h2>
          </div>
        </div>
        <div className="row mY-50">
          <ReactWOW animation="fadeInUp" deplay="0.2s">
            <div className="col-md-12 col-lg-4">
              <div className="pR-30 mB-30 box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-40 mB-25">
                  <i className="icon-money-10" />
                </div>
                <h5 className="fw-n">{t('Send and request money fast.')}</h5>
                <p className="c-gray">{t('free')}</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
                    {t('find')}
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>
                </a>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" deplay="0.4s">
            <div className="col-md-12 col-lg-4">
              <div className="pR-30 mB-30 box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-15">
                  <i className="icon-manage-easy" />
                </div>
                <h5 className="fw-n">{t('Manage your money easily.')}</h5>
                <p className="c-gray">{t('The improved Paybit')}</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
                    {t('find')}
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" deplay="0.6s">
            <div className="col-md-12 col-lg-4">
              <div className="pR-30 mB-30 box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-15">
                  <i className="icon-collect-money-from-group" />
                </div>
                <h5 className="fw-n">{t('Collect money from groups.')}</h5>
                <p className="c-gray">{t('Whether')}</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
                    {t('find')}
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
        </div>
        <div className="clearfix" />
        <div className="mT-30">
          <PartnerItems />
        </div>
      </div>
    </section>
    <div className="bgi-gray">
      <section className="pB-70">
        <div className="container">
          <ReactWOW animation="fadeInUp">
            <div className="row align-items-center justify-content-center download-android-tablet wow fadeInUp">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-shadow download-box">
                  <div className="circle-gray-70">
                    <i className="icon-money-bag" />
                  </div>
                  <div className="mT-25">
                    <h5 className="fw-n">{t('Get up to speed on getting paid.')}</h5>
                    <p className="c-text-gray line-height mb-0">{t('Create and send invoices in the moment, so customers can pay right away.')}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-shadow download-box">
                  <div className="circle-gray-70">
                    <i className="icon-money-dollar" />
                  </div>
                  <div className="mT-25">
                    <h5 className="fw-n">{t('Stay on top of your business')}</h5>
                    <p className="c-text-gray line-height mb-0">{t('Whether you need to transfer funds, view transaction details.')}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-shadow download-box">
                  <div className="circle-gray-70">
                    <i className="icon-flag" />
                  </div>
                  <div className="mT-25">
                    <h5 className="fw-n">{t('Mobilize how you do business.')}</h5>
                    <p className="c-text-gray line-height mb-0">{t('Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S.')}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-11">
                <div className="img-mockup-dashboard-pc" style={{ backgroundImage: 'url(/images/mockup-dashboard-pc.png)' }} />
              </div>
            </div>
          </ReactWOW>
          <div className="text-center mT-20">
            <a href="/" className="button_appstore186x54">{' '}</a>
          </div>
        </div>
      </section>
      <OurProduct />
    </div>
    <DownloadFooter />
  </>
);

export default withTranslation('DownloadAppleMac')(DownloadAppleMac);
