import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import PartnerItems from '../common/PartnerItems';

const AboutUs = () => (
  <>
    <Helmet>
      <title>Paybit - Check Our Story</title>
    </Helmet>
    <section className="top-banner bg-parallax">
      <div className="top-banner-inner c-white">
        <div className="container">
          <ul className="breadcrumb breadcrumbs breadcrumbs-white mT-30">
            <li><a href="/"><i className="icon-house" /></a></li>
            <li>
              <i className="fas fa-chevron-right" />
              <a href="/about-us">Check Our Story </a>
            </li>
          </ul>
          <div className="about-banner-text ">
            <ReactWOW animation="slideInUp">
              <p className="fsz-def c-white-60">Online payments made easy.</p>
            </ReactWOW>
            <ReactWOW animation="slideInUp">
              <h1 className="fsz-50 fw-n">
                  Paybit is changing
                <br />
                  the world
              </h1>
            </ReactWOW>

          </div>
        </div>
      </div>
    </section>
    <section className="pY-70 bgc-white">
      <div className="container">
        <div className="text-center">
          <h4><b className="fw-n">Paybit by Numbers</b></h4>
          <p className="fsz-def c-text-gray">Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
          <ReactWOW animation="fadeInUp">
            <div className="mY-50 paybit-number">
              <div className="paybit-number-item text-center">
                <b className="fw-n fsz-sup">100+</b>
                <p className="c-text-gray">Sponsors</p>
              </div>
              <div className="paybit-number-item text-center">
                <b className="fw-n fsz-sup">40 million</b>
                <p className="c-text-gray">Users</p>
              </div>
              <div className="paybit-number-item text-center">
                <b className="fw-n fsz-sup">2013</b>
                <p className="c-text-gray">Year started</p>
              </div>
            </div>
          </ReactWOW>
        </div>
        <div className="section-submit-cv">
          <div className="row">
            <ReactWOW animation="fadeInLeft">
              <div className="col-md-12 col-lg-8">
                <div className="section-submit-cv-left" style={{ backgroundImage: 'url(/images/about-img1.jpg)' }} />
              </div>
            </ReactWOW>
            <div className="col-md-12 col-lg-4 section-submit-cv-right">
              <div className="section-submit-cv-right-box">
                <h4 className="mB-15 fw-b">See your job opportunity?</h4>
                <p className="c-text-gray mB-20">
                  PayBit is an online payment service.
                  Millions of customers around the world use
                  us for one simple reason: it’s simple.
                  Just an email address and password will
                  get you through to checkout before you can reach for your wallet.
                </p>
                <a href="careers" className="btn btn-primary btn-lg">Sumbit your CV</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-9">
            <h2 className="mY-50 text-center">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</h2>
            <ReactWOW animation="fadeInUp">
              <div className="col-12">
                <div className="row mB-50">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-5">
                    <div className="d-flex align-items-center">
                      <span className="fsz-sup c-grey mR-20">
                        <i className="icon-smile-face" />
                      </span>
                      <h4 className="mb-0">Stay humble</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-8 col-lg-7">
                    <p className="c-text-gray">
                      PayBit is an online payment service.
                      Millions of customers around the world use us for one simple reason:
                      it’s simple. Just an email address and password will get you through
                      to checkout before you can reach for your wallet.
                    </p>
                  </div>
                </div>
                <div className="row mB-50">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-5">
                    <div className="d-flex align-items-center">
                      <span className="fsz-sup c-grey mR-20">
                        <i className="icon-reading-book" />
                      </span>
                      <h4 className="mb-0">Never stop learning</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-8 col-lg-7">
                    <p className="c-text-gray">
                      Shop with peace of mind,
                      knowing we protect your eligible purchases.
                      If an eligible item doesn’t show up, or turns
                      out to be different than described, we’ll help
                      sort things out with the seller. Conditions apply.
                    </p>
                  </div>
                </div>
                <div className="row mB-50">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-5">
                    <div className="d-flex align-items-center">
                      <span className="fsz-sup c-grey mR-20">
                        <i className="icon-businesses" />
                      </span>
                      <h4 className="mb-0">Work hard, play hard</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-8 col-lg-7">
                    <p className="c-text-gray">
                      Send money to almost anyone with an email address.
                      You can send all around the world and they&lsquo;ll
                      be delighted to get your message. Currency conversion
                      fees may apply.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-5">
                    <div className="d-flex align-items-center">
                      <span className="fsz-sup c-grey mR-20">
                        <i className="icon-group-people" />
                      </span>
                      <h4 className="mb-0">People over profit</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-8 col-lg-7">
                    <p className="c-text-gray">
                      With PayBit you can shop at thousands of stores around
                      the world in just a few easy clicks,
                      knowing your card details are never shared with the seller.
                      Currency conversion fees may apply.
                    </p>
                    <p className="c-text-gray">
                      You are secured.
                      We offer a Buyer and Seller Protection system.
                      Payments can be refund up to 180 days, based on what is sold.
                      Nobody needs to worry to get problems,
                      read our Buyer and Protection details for more information.
                    </p>
                  </div>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </section>
    <section className="pY-70 bgc-grey">
      <div className="container">
        <div className="text-center mB-50">
          <h4><b className="fw-n">Your business has evolved - So should your Paybit</b></h4>
          <p className="fsz-def c-text-gray mb-0">Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
        </div>
        <ReactWOW animation="fadeInUp">
          <div className="row no-gutters">
            <div className="col-12 col-md-12 col-lg-6 d-flex p-30 bgc-white bd bdB-md-n box-hover-zoom d-block">
              <div className="mR-30">
                <div className="circle-76 m-auto hover-boxshadow">
                  <span className="c-blue">
                    <i className="icon-payment-lock" />
                  </span>
                </div>
              </div>
              <div>
                <h5 className="fw-n mB-15">Everything you need to accept payments</h5>
                <p className="c-text-gray">If selected to advance in the process, you&lsquo;ll speak with someone on our recruiting team to learn more about the role you&lsquo;ve applied for and what it&lsquo;s like to work at Paybit.</p>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 d-flex p-30 bgc-white bd bdB-md-n box-hover-zoom d-block">
              <div className="mR-30">
                <div className="circle-76 m-auto hover-boxshadow">
                  <span className="c-blue">
                    <i className="icon-chart-line" />
                  </span>
                </div>
              </div>
              <div>
                <h5 className="fw-n mB-15">Our frequent technology updates</h5>
                <p className="c-text-gray">You are secured. We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold. Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 d-flex p-30 bgc-white bd bdB-md-n box-hover-zoom d-block">
              <div className="mR-30">
                <div className="circle-76 m-auto hover-boxshadow">
                  <span className="c-blue">
                    <i className="icon-light" />
                  </span>
                </div>
              </div>
              <div>
                <h5 className="fw-n mB-15">We adapt to unique business needs</h5>
                <p className="c-text-gray">With PayBit you can shop at thousands of stores around the world in just a few easy clicks, knowing your card details are never shared with the seller. Currency conversion fees may apply.</p>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 d-flex p-30 bgc-white bd bdB-md-n box-hover-zoom d-block">
              <div className="mR-30">
                <div className="circle-76 m-auto">
                  <span className="c-blue">
                    <i className="icon-headphone" />
                  </span>
                </div>
              </div>
              <div>
                <h5 className="fw-n mB-15">We offer the best support 24/7</h5>
                <p className="c-text-gray">Send money to almost anyone with an email address. You can send all around the world and they&lsquo;ll be delighted to get your message. Currency conversion fees may apply.</p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <div className="mY-50">
          <div className="row">
            <div className="col-12 col-sm-4 col-md-4 col-lg-6">
              <h4 className="fw-n">Meet our team</h4>
            </div>
            <div className="col-12 col-sm-4 col-md-4 col-lg-6">
              <p className="c-text-gray fsz-def">Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
            </div>
          </div>
        </div>
        <ReactWOW animation="fadeInUp">
          <div className="row row-sm">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="hover-boxshadow bd bgc-white d-md-inline-block w-100 text-center d-flex align-items-center justify-content-center p-50 mB-15 mB-sm-5">
                <div className="mR-40 mX-md-auto text-center">
                  <div className="circle-95 m-auto mB-md-20" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                </div>
                <div>
                  <h5 className="fw-n mB-10">Joshua Vyce</h5>
                  <p className="mb-0 c-text-gray">Human Resources</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="hover-boxshadow bd bgc-white d-md-inline-block w-100 text-center d-flex align-items-center justify-content-center p-50 mB-15 mB-sm-5">
                <div className="mR-40 mX-md-auto text-center">
                  <div className="circle-95 m-auto mB-md-20" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                </div>
                <div>
                  <h5 className="fw-n mB-10">Jay Cone</h5>
                  <p className="mb-0 c-text-gray">PR Consulatant</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="hover-boxshadow bd bgc-white text-center p-30 mB-15 mB-sm-5">
                <div className="mB-20">
                  <div className="circle-95 m-auto" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                </div>
                <h4 className="fw-n">Philip Koon</h4>
                <p className="mb-0 c-text-gray">Senior Designer</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="hover-boxshadow bd bgc-white text-center p-30 mB-15 mB-sm-5">
                <div className="mB-20">
                  <div className="circle-95 m-auto" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                </div>
                <h4 className="fw-n">William Norris</h4>
                <p className="mb-0 c-text-gray">Human Resources</p>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="hover-boxshadow bd bgc-white text-center p-30 mB-15 mB-sm-5">
                <div className="mB-20">
                  <div className="circle-95 bgc-blue d-flex justify-content-center align-items-center m-auto"><i className="fsz-xl fas fa-plus c-white" /></div>
                </div>
                <h4 className="fw-n">This can be you!</h4>
                <p className="mb-0 c-text-gray">Click to see job opportunityes</p>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
    </section>
    <section className="pY-70 bgc-white">
      <div className="container">
        <div className="text-center mB-50">
          <h4 className="mB-30">
            <b className="fw-n">
Our clients love us, and
              {' '}
              <br />
we love them
            </b>

          </h4>
          <p className="fsz-def c-text-gray">Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
        </div>
        <div className="mT-30">
          <PartnerItems />
        </div>
      </div>
    </section>
    <ReactWOW animation="fadeInUp">
      <div className="img section-img-about-bot" style={{ backgroundImage: 'url(/images/about-img2.jpg)' }} />
    </ReactWOW>
  </>
);

export default AboutUs;
