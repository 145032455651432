import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { map, take } from 'lodash';

import { DetailCryptoChart } from '../DetailCryptoChart';
import TableCryptoMartket from '../TableCryptoMartket';
import Paginate from '../../common/Paginate';
import CryptoMarketContainer from '../containers/CryptoMarketContainer';
import CryptoDetailContainer from '../containers/CryptoDetailContainer';
import { cssURLCoinImg } from '../../../utils/apiDataHelper';
import { significantDigits } from '../../../utils/currencyFormater';

const CryptoDetailsPage = (props) => {
  const {
    pagination: {
      data: prices,
    },
    setChartDuration,
    symbol,
    chartData,
    chartDuration,
    durationTypes,
    historical,
    durationSettings,
  } = props;
  const {
    coinName,
    slug,
    id,
    marketCap,
    price,
    change,
  } = historical;
  const coinImage = cssURLCoinImg(slug);
  return (
    <>
      <Helmet>
        <title>
          {`Paybit - ${coinName} Crypto Currency Price`}
        </title>
      </Helmet>
      <section className="bdT bgc-grey pY-30">
        <div className="container">
          <div className="crypto-details-header mB-30 d-flex d-md-inline-block w-100 align-items-center justify-content-between">
            <ul className="breadcrumb breadcrumbs mB-10">
              <li><a href="/"><i className="icon-house" /></a></li>
              <li>
                <i className="fas fa-chevron-right" />
                <a href="/">Home</a>
              </li>
              <li>
                <i className="fas fa-chevron-right" />
                <a href="/crypto-market">Crypto Market</a>
              </li>
              <li>
                <i className="fas fa-chevron-right" />
                <a href={`/crypto-details-page/${symbol}`}>{`${coinName} Details`}</a>
              </li>
            </ul>
            <div className="d-flex d-block">
              <select className="form-control mR-10 mB-5">
                <option value>Buy</option>
              </select>
              <select className="form-control mR-10 mB-5">
                <option value>Trade</option>
              </select>
              <select className="form-control mR-10 mB-5">
                <option value>Get Wallet</option>
              </select>
              <select className="form-control mB-5">
                <option value>Get Loan</option>
              </select>
            </div>
          </div>
          <div className="crypto-details-chart">
            <div className="row">
              <div className="col-12 col-lg-9">
                <div className="crypto-details-chart-left bd bgc-white mB-20">
                  <div className="d-flex d-md-inline-block w-100 justify-content-between p-20 bdB">
                    <div className="d-flex d-block">
                      <span className="coin-currency mR-25">
                        <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: coinImage }} />
                        <b className="fw-n fsz-md">{coinName}</b>
                      </span>
                      {/* <Dropdown className="dropdown dropdown-coin">
                        <div className="coin-header">
                          <span className="circle coin-icon coin-icon-20" style={{ backgroundImage: coinImage }} />
                          <span>{coinName}</span>
                        </div>
                        <Dropdown.Toggle as="button" className="btn dropdown-toggle no-after" type="button" id="dropdownBTC" data-toggle="dropdown">
                          <i className="fas fa-chevron-down" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu" aria-labelledby="dropdownBTC" x-placement="top-start">
                          {map(take(prices, 7), p => (
                            <Dropdown.Item as="a" className="dropdown-item" href={`/crypto-details-page/${p.symbol}`}>
                              <span className="circle coin-icon coin-icon-20" style={{ backgroundImage: cssURLCoinImg(p.slug) }} />
                              {p.coinName}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                    <div className="d-flex">
                      <div className="mR-30">
                        <p className="c-gray mB-5">Market Cap</p>
                        <h5 className="fw-n mb-0">
                          $
                          {significantDigits(marketCap)}
                        </h5>
                      </div>
                      <div className="mR-30">
                        <p className="c-gray mB-5">Volume 24h</p>
                        <h5 className="fw-n mb-0">
                          $
                          {significantDigits(price)}
                        </h5>
                      </div>
                      <div>
                        <p className="c-gray mB-5">Change 24h</p>
                        <h5 className="fw-n mb-0 c-red">{change}</h5>
                      </div>
                    </div>
                  </div>
                  <DetailCryptoChart {...{
                    historical, symbol, chartData, chartDuration, durationTypes, setChartDuration, durationSettings,
                  }}
                  />
                  {/* <div className="pL-10">
                    <div className="mY-20">
                      <ul className="lis-n p-0 mb-0 list-tab-history d-inline-block w-100">
                        <li className="float-left"><a href="#TODO" className="btn btn-white btn-around">Today</a></li>
                        <li className="float-left active"><a href="#TODO" className="btn btn-white btn-around">7 Days</a></li>
                        <li className="float-left"><a href="#TODO" className="btn btn-white btn-around">14 Days</a></li>
                        <li className="float-left"><a href="#TODO" className="btn btn-white btn-around">Last Month</a></li>
                      </ul>
                    </div>
                    <div className="chart-price-container">
                      <PriceGraph />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <div className="crypto-details-chart-right mB-20">
                  <div className="cdcr-header bgc-blue c-white text-center">Rank {id}</div>
                  <div className="cdcr-body bd ngc-white">
                    <ul className="list-unstyled">
                      <li>
                        <a href="https://www.bitcoin.com/">
                          <i className="fas fa-link" />
                          Website
                        </a>

                      </li>
                      <li>
                        <a href="#TODO">
                          <i className="icon-search" />
                          Explorer
                        </a>

                      </li>
                      <li>
                        <a href="#TODO">
                          <i className="icon-search" />
                          Explorer 2
                        </a>

                      </li>
                      <li>
                        <a href="#TODO">
                          <i className="icon-search" />
                          Explorer 3
                        </a>

                      </li>
                      <li>
                        <a href="#TODO">
                          <i className="icon-mail" />
                          Message Board
                        </a>

                      </li>
                      <li>
                        <a href="#TODO">
                          <i className="fas fa-code" />
                          Source Code
                        </a>

                      </li>
                      <li>
                        <a href="#TODO">
                          <i className="icon-document" />
                          Technical Documentation
                        </a>

                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-style bd bgc-white mB-20">
            <div className="box-header d-flex d-md-inline-block justify-content-between w-100">
              <h5 className="fw-n mB-10">Historical Data</h5>
            </div>
            <div className="box-body">
              <div className="table-responsive">
                <table className="table table-common">
                  <thead>
                    <tr className="whs-nw">
                      <th>Date</th>
                      <th>Open*</th>
                      <th>High</th>
                      <th>Low</th>
                      <th>Close**</th>
                      <th>Volume</th>
                      <th>Market Cap</th>
                    </tr>
                  </thead>
                  <tbody className="pX-20">
                    <tr className="c-gray">
                      <td>Oct 08, 2018</td>
                      <td>6,600.19</td>
                      <td>6,675.06</td>
                      <td>6,576.04</td>
                      <td>6,652.23</td>
                      <td>3,979,460,000 </td>
                      <td>114,251,052,224</td>
                    </tr>
                    <tr className="c-gray">
                      <td>Oct 08, 2018</td>
                      <td>6,600.19</td>
                      <td>6,675.06</td>
                      <td>6,576.04</td>
                      <td>6,652.23</td>
                      <td>3,979,460,000 </td>
                      <td>114,251,052,224</td>
                    </tr>
                    <tr className="c-gray">
                      <td>Oct 08, 2018</td>
                      <td>6,600.19</td>
                      <td>6,675.06</td>
                      <td>6,576.04</td>
                      <td>6,652.23</td>
                      <td>3,979,460,000 </td>
                      <td>114,251,052,224</td>
                    </tr>
                    <tr className="c-gray">
                      <td>Oct 08, 2018</td>
                      <td>6,600.19</td>
                      <td>6,675.06</td>
                      <td>6,576.04</td>
                      <td>6,652.23</td>
                      <td>3,979,460,000 </td>
                      <td>114,251,052,224</td>
                    </tr>
                    <tr className="c-gray">
                      <td>Oct 08, 2018</td>
                      <td>6,600.19</td>
                      <td>6,675.06</td>
                      <td>6,576.04</td>
                      <td>6,652.23</td>
                      <td>3,979,460,000 </td>
                      <td>114,251,052,224</td>
                    </tr>
                    <tr className="c-gray">
                      <td>Oct 08, 2018</td>
                      <td>6,600.19</td>
                      <td>6,675.06</td>
                      <td>6,576.04</td>
                      <td>6,652.23</td>
                      <td>3,979,460,000 </td>
                      <td>114,251,052,224</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="mY-40">
            <h5 className="fw-n">Related Currencyes</h5>
          </div>
          <div className="box-style bd bdT-n bgc-white mB-20">
            <div className="box-body">
              <TableCryptoMartket prices={prices} />
            </div>
          </div>
          <Paginate {...props} />
        </div>
      </section>
      <section className="section-imgs section-subscribe" style={{ backgroundImage: 'url(/images/subscribe-bg.jpg)' }}>
        <div className="inner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-10 col-lg-6">
                <div className="c-white text-center">
                  <h2 className="fsz-sup fw-n mB-10">Subscribe Our Newsletter!</h2>
                  <p className="c-white-90 mB-20 fsz-md">For get all of our latest updates and promotion</p>
                  <div className="form-subscribe">
                    <div className="input-group">
                      <input type="email" className="form-control form_control" placeholder="Enter your email address" />
                      <div className="input-group-append">
                        <button className="btn-primary btn"><i className="icon-right-arrow" /></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default compose(
  withRouter,
  CryptoDetailContainer,
  CryptoMarketContainer(30),
)(CryptoDetailsPage);
