import React from 'react';
import { Dropdown } from 'react-bootstrap';

const Notifications = () => (
  <Dropdown className="dropdown notifications">
    <Dropdown.Toggle className="dropdown-toggle no-after" variant="" as="a">
      <span className="counter">3</span>
      <i className="icon-notifications" />
    </Dropdown.Toggle>
    <Dropdown.Menu className="bd">
      <Dropdown.Toggle as="button" className="close-dropdown btn-transparent"><i className="icon-close" /></Dropdown.Toggle>
      <li className="pX-20 pY-15 bdB d-flex justify-content-between">
        <span>Notifications</span>
        <span className="c-gray fsz-def"><i className="icon-settings" /></span>
      </li>
      <li>
        <ul className="ovY-a pos-r scrollable lis-n p-0 m-0 fsz-sm ps">
          <li>
            <a href="/" className="peers td-n p-20 bdB bgcH-grey-100">
              <div className="peer mR-15">
                <div className="size-32 img" style={{ backgroundImage: 'url(/images/prep.png)' }} />
              </div>
              <div className="peer peer-greed">
                <span>
                  <span className="fw-500 c-text">Prep for bi - weekly meeting with </span>
                </span>
                <p className="m-0"><small className="fsz-sm c-blue">Jonathan Banks</small></p>
              </div>
            </a>
          </li>
          <li>
            <a href="/" className="peers td-n p-20 bdB cH-blue bgcH-grey-100">
              <div className="peer mR-15">
                <div className="size-32 img circle" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              </div>
              <div className="peer peer-greed">
                <span>
                  <span className="fw-500 c-text">Jonathan Banks</span>
                </span>
                <p className="m-0"><small className="fsz-sm c-grey-800">Lorem Ipsum is simply dumm dummy text of the printing and typesetting industry.</small></p>
              </div>
            </a>
          </li>
          <li>
            <a href="/" className="peers td-n p-20 bdB bgcH-grey-100">
              <div className="peer mR-15">
                <div className="size-32 img" style={{ backgroundImage: 'url(/images/payment.png)' }} />
              </div>
              <div className="peer peer-greed">
                <span>
                  <span className="fw-500 c-text">Payment received from</span>
                </span>
                <p className="m-0"><small className="fsz-sm c-green">Jonathan Smith</small></p>
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li className="pX-20 pY-15 ta-c"><span><a href className="c-text td-n">View All</a></span></li>
    </Dropdown.Menu>
  </Dropdown>
);

export default Notifications;
