import React from 'react';
import Countup from 'react-countup';
import { Helmet } from 'react-helmet';

const TransactionsDetail = () => (
  <section>
    <Helmet>
      <title>Paybit - Transactions Detail</title>
    </Helmet>
    <div className="p-20 bgc-grey main-content-container">
      <div className="main-content">
        <div className="bd bg-white">
          <div className="row bdB no-gutters">
            <div className="col-md-12 col-lg-12 col-xl-6">
              <div className="row no-gutters">
                <div className="col-md-12 col-lg-5 col-xl-4">
                  <div className="curency-detail p-20 text-center">
                    <div className="curency-logo d-flex justify-content-center mY-20"><div className="img img-104" style={{ backgroundImage: 'url(/images/bc-logo.png)' }} /></div>
                    <h3><b className="fw-n"><Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} /></b></h3>
                    <p className="fsz-def c-grey">Sent to Bitcoin Wallet</p>
                    <div className="mB-20">
                      <span className="btn btn-o-green btn-around">Complete</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-7 col-xl-8 bdL">
                  <div className="p-20">
                    <div className="d-flex align-items-center mY-20">
                      <div className="circle img size-45" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                      <div className="mL-20">
                        <b className="fw-n fsz-md">Bruce Springesteen</b>
                        <p className="m-0 c-gray wob-ba">brucespringesteen@yahoo.com</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between pY-10 fsz-def">
                      <div>
                        <p className="c-gray whs-nw mb-0 pY-5">Wallet Address:</p>
                        <p className="c-gray whs-nw mb-0 pY-5">Date:</p>
                        <p className="c-gray whs-nw mb-0 pY-5">Refence:</p>
                      </div>
                      <div className="pL-20 w-100">
                        <p className="mb-0 pY-5"><b className="fw-n wob-ba">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</b></p>
                        <p className="mb-0 pY-5"><b className="fw-n">October 10,2018</b></p>
                        <p className="mb-0 pY-5"><b className="fw-n">Client</b></p>
                      </div>
                    </div>
                    <div className="pY-20">
                      <a href="/" className="c-black fsz-def d-flex">
                        <i className="mR-15 c-green fsz-lg icon-download" />
                        Download proof of transfer
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-6 bdL">
              <div className="p-20">
                <div className="pB-10 bdB">
                  <h5><b className="fw-n">Add a comment</b></h5>
                  <input type="text" className="form-control bdn bdB pl-0" placeholder="Type here to put a note..." />
                </div>
              </div>
            </div>
          </div>
          <div className="p-30">
            <h5 className="mB-30"><b className="fw-n">Add a comment</b></h5>
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-md-12 col-lg-5 col-xl-4">
                    <div className="d-flex justify-content-between pY-10 fsz-def">
                      <div>
                        <p className="c-gray whs-nw mb-0 pY-5">ID:</p>
                        <p className="c-gray whs-nw mb-0 pY-5">Status:</p>
                        <p className="c-gray whs-nw mb-0 pY-5">Sum:</p>
                      </div>
                      <div className="pL-20 w-100">
                        <p className="mb-0 pY-5"><b className="fw-n wob-ba">21313155</b></p>
                        <p className="mb-0 pY-5"><b className="fw-n">Complete</b></p>
                        <p className="mb-0 pY-5"><b className="fw-n">$23,42</b></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-7 col-xl-8">
                    <div className="d-flex justify-content-between pY-10 fsz-def">
                      <div>
                        <p className="c-gray whs-nw mb-0 pY-5">Fees:</p>
                        <p className="c-gray whs-nw mb-0 pY-5">Time:</p>
                        <p className="c-gray whs-nw mb-0 pY-5">Transaction Type:</p>
                        <p className="c-gray whs-nw mb-0 pY-5">Account Email Address:</p>
                      </div>
                      <div className="pL-20 w-100">
                        <p className="mb-0 pY-5"><b className="fw-n wob-ba">$244,422</b></p>
                        <p className="mb-0 pY-5"><b className="fw-n">08:44 PM</b></p>
                        <p className="mb-0 pY-5"><b className="fw-n">Web</b></p>
                        <p className="mb-0 pY-5"><b className="fw-n wob-ba">brucespringesteen@yahoo.com</b></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-xl-6">
                <div className="d-flex justify-content-between pY-10 fsz-def">
                  <div>
                    <p className="c-gray whs-nw mb-0 pY-5">Total Amount:</p>
                    <p className="c-gray whs-nw mb-0 pY-5">Fee Amount:</p>
                    <p className="c-gray whs-nw mb-0 pY-5">Net Amount:</p>
                    <p className="c-gray whs-nw mb-0 pY-5">Date:</p>
                    <p className="c-gray whs-nw mb-0 pY-5">Time:</p>
                    <p className="c-gray whs-nw mb-0 pY-5">Shipping Address:</p>
                    <p className="c-gray whs-nw mb-0 pY-5">Payment Type:</p>
                  </div>
                  <div className="pL-20 w-100">
                    <p className="mb-0 pY-5"><b className="fw-n wob-ba">$0.01 USD</b></p>
                    <p className="mb-0 pY-5"><b className="fw-n">$0.01 USD</b></p>
                    <p className="mb-0 pY-5"><b className="fw-n">$0.01 USD</b></p>
                    <p className="mb-0 pY-5"><b className="fw-n">Sep. 08, 2018</b></p>
                    <p className="mb-0 pY-5"><b className="fw-n">04:42:01 CDT</b></p>
                    <p className="mb-0 pY-5"><b className="fw-n">John Doe 1 Way Street , NeverNever , CA 12522 - United States</b></p>
                    <p className="mb-0 pY-5"><b className="fw-n">Instant</b></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default TransactionsDetail;
