import React from 'react';
import Slider from 'react-slick';
import ReactWOW from 'react-wow';
import { compose } from 'recompose';
import { withAuth } from '../../hocs/withAuth';

const settings = {
  dots: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: true,
};

const TopSlider = ({ isAuth }) => (
  <section className="top-slider top-slider-landing">
    <div className="top-slider-inner">
      <div className="slideshow">
        <Slider {...settings} className="slider main-slider" id="main-slider">
          <div className="item">
            <div className="item-image" style={{ background: 'url(/images/slider2.jpg)' }} />
            <div className="slider-content c-white">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-4">
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.05s">
                    <p className="mB-30 text-uppercase">Bitcoin wallet</p>
                  </ReactWOW>
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.1s">
                    <h2 className="fsz-sup mB-30 fw-n">Its really that easy</h2>
                  </ReactWOW>
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.2s">
                    <p className="fsz-def mB-30">Just signup and see it for yourself</p>
                  </ReactWOW>
                  <ReactWOW animation="bounceInRight" duration="0.8s" delay="0.6s">
                    <div>
                      {!isAuth && <a href="/sign-up-personal" className="btn btn-lg btn-primary btn-around mR-15">Sign Up</a>}
                      <a href="/business-tour" className="btn btn-lg btn-outline-light btn-around">Learn More</a>
                    </div>
                  </ReactWOW>
                </div>
                <ReactWOW animation="fadeInRight">
                  <div className="col-md-12 col-lg-8 hidden-md">
                    <div className="main-cop-image" style={{ background: 'url(/images/mockup-dashboard-tablet.png)' }} />
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
          </div>
          <div className="item">
            <div className="item-image" style={{ background: 'url(/images/slider1.jpg)' }} />
            <div className="slider-content c-white">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-4">
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.05s">
                    <p className="mB-30 text-uppercase">The most secure</p>
                  </ReactWOW>
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.1s">
                    <h2 className="fsz-sup mB-30 fw-n">Download our Apps</h2>
                  </ReactWOW>
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.2s">
                    <p className="fsz-def mB-30">If you want to use your mobile phone</p>
                  </ReactWOW>
                  <ReactWOW animation="bounceInRight" duration="0.8s" delay="0.6s">
                    <div>
                      {!isAuth && <a href="/sign-up-personal" className="btn btn-lg btn-primary btn-around mR-15">Sign Up</a>}
                      <a href="/business-tour" className="btn btn-lg btn-outline-light btn-around">Learn More</a>
                    </div>
                  </ReactWOW>
                </div>
                <ReactWOW animation="fadeInRight">
                  <div className="col-md-12 col-lg-8 hidden-md">
                    <div className="main-cop-image" style={{ background: 'url(/images/mockup-dashboard-tablet.png)' }} />
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
          </div>
          <div className="item">
            <div className="item-image" style={{ background: 'url(/images/slider3.jpg)' }} />
            <div className="slider-content c-white">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-4">
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.05s">
                    <p className="mB-30 text-uppercase">YOUR PERSONAL WALLET</p>
                  </ReactWOW>
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.1s">
                    <h2 className="fsz-sup mB-30 fw-n">The best money wallet</h2>
                  </ReactWOW>
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.2s">
                    <p className="fsz-def mB-30">Signup and join the world of Paybit</p>
                  </ReactWOW>
                  <ReactWOW animation="bounceInRight" duration="0.8s" delay="0.6s">
                    <div>
                      {!isAuth && <a href="/sign-up-personal" className="btn btn-lg btn-primary btn-around mR-15">Sign Up</a>}
                      <a href="/business-tour" className="btn btn-lg btn-outline-light btn-around">Learn More</a>
                    </div>
                  </ReactWOW>
                </div>
                <ReactWOW animation="fadeInRight">
                  <div className="col-md-12 col-lg-8 hidden-md">
                    <div className="main-cop-image" style={{ background: 'url(/images/mockup-dashboard-tablet.png)' }} />
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
          </div>
        </Slider>
      </div>
    </div>
  </section>
);

export default compose(
  withAuth,
)(TopSlider);
