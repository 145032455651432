import React from 'react';
import ReactWOW from 'react-wow';
import { withTranslation } from 'react-i18next';

const YourMoney = ({ t }) => (
  <section className="pY-70 bgc-white">
    <div className="container">
      <div className="row mB-50">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
          <h4><b className="fw-n">{t('Your money')}</b></h4>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
          <p className="fsz-def c-text-gray">
            {t('We offer')}
          </p>
          <br />
          <a href="/" className="c-black">
            <b className="fw-n">
              {t('Get Started')}
            </b>
            <i className="icon-right-arrow mL-10" />
          </a>
        </div>
      </div>
      <div className="row">
        <ReactWOW animation="slideInUp">
          <div className="col-12 col-md-6 col-lg-3 mB-15 text-center hover-opacity box-shadow-mb">
            <div className="img-84x57 m-auto mB-10" style={{ backgroundImage: 'url(/images/millions-of-payments-processed.png)' }} />
            <h6 className="fsz-def mB-15"><b className="fw-n">{t('Millions of payments processed')}</b></h6>
            <p className="fsz-sm c-gray">{t('Milion of customer')}</p>
          </div>
        </ReactWOW>
        <ReactWOW animation="slideInUp">
          <div className="col-12 col-md-6 col-lg-3 mB-15 text-center hover-opacity box-shadow-mb">
            <div className="img-84x57 m-auto mB-10" style={{ backgroundImage: 'url(/images/and-even-more-clients.png)' }} />
            <h6 className="fsz-def mB-15"><b className="fw-n">{t('And even more clients')}</b></h6>
            <p className="fsz-sm c-gray">
              {t('We’ll help')}
            </p>
          </div>
        </ReactWOW>
        <ReactWOW animation="slideInUp">
          <div className="col-12 col-md-6 col-lg-3 mB-15 text-center hover-opacity box-shadow-mb">
            <div className="img-84x57 m-auto mB-10" style={{ backgroundImage: 'url(/images/about-5000-api-integ.png)' }} />
            <h6 className="fsz-def mB-15">
              <b className="fw-n">
                {t('About 5000')}
              </b>
            </h6>
            <p className="fsz-sm c-gray">
              {t('Over 500.000')}
            </p>
          </div>
        </ReactWOW>
        <ReactWOW animation="slideInUp">
          <div className="col-12 col-md-6 col-lg-3 mB-15 text-center hover-opacity box-shadow-mb">
            <div className="img-84x57 m-auto mB-10" style={{ backgroundImage: 'url(/images/monitoring.png)' }} />
            <h6 className="fsz-def mB-15">
              <b className="fw-n">
                {t('Monitoring')}
              </b>
            </h6>
            <p className="fsz-sm c-gray">
              {t('With a few clicks you')}
            </p>
          </div>
        </ReactWOW>
      </div>
    </div>
  </section>
);

export default withTranslation('YourMoney')(YourMoney);
