import React from 'react';
import Slider from 'react-slick';
import ReactWOW from 'react-wow';
import { withTranslation } from 'react-i18next';

const settings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
};

const WeGotCoveredSlider = ({ t }) => (
  <section>
    <Slider {...settings} className="slider we-got-covered-slider " id="we-got-covered-slider">
      <div className="item">
        <div className="bg-img section-we-got-you-covered">
          <div className="container">
            <div className="row">
              <ReactWOW animation="slideInUp" delay="0.3s" duration="0.5s">
                <div className="col-md-12 col-lg-5 col-xl-4">
                  <h1 className="mB-30">{t('We got you covered.')}</h1>
                  <p className="fsz-def c-text-gray line-height-2">
                    {t('Shop with peace')}
                  </p>
                  <br />
                  <a href="/" className="c-black">
                    {t('More about PayBit')}
                  </a>
                  <div className="mT-40">
                    <a href="/business-tour" className="btn btn-lg btn-around btn-primary">
                      {t('Learn More')}
                    </a>
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="bg-img section-we-got-you-covered">
          <div className="container">
            <div className="row">
              <ReactWOW animation="slideInUp" delay="0.3s" duration="0.5s">
                <div className="col-md-12 col-lg-5 col-xl-4">
                  <h1 className="mB-30">{t('We got you covered.')}</h1>
                  <p className="fsz-def c-text-gray line-height-2">
                    {t('Shop with peace')}
                  </p>
                  <br />
                  <a href="/" className="c-black">
                    {t('More about PayBit')}
                  </a>
                  <div className="mT-40">
                    <a href="/business-tour" className="btn btn-lg btn-around btn-primary">
                      {t('Learn More')}
                    </a>
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="bg-img section-we-got-you-covered">
          <div className="container">
            <div className="row">
              <ReactWOW animation="slideInUp" delay="0.3s" duration="0.5s">
                <div className="col-md-12 col-lg-5 col-xl-4">
                  <h1 className="mB-30">{t('We got you covered.')}</h1>
                  <p className="fsz-def c-text-gray line-height-2">
                    {t('Shop with peace')}
                  </p>
                  <br />
                  <a href="/" className="c-black">
                    {t('More about PayBit')}
                  </a>
                  <div className="mT-40">
                    <a href="/business-tour" className="btn btn-lg btn-around btn-primary">
                      {t('Learn More')}
                    </a>
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  </section>
);

export default withTranslation('WeGotCoveredSlider')(WeGotCoveredSlider);
