import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';
import PartnerItems from '../common/PartnerItems';

const Careers = () => (
  <div>
    <Helmet>
      <title>Paybit - Work with us</title>
    </Helmet>
    <section className="carres-header c-white">
      <div className="container">
        <ul className="breadcrumb breadcrumbs breadcrumbs-white">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
            <a href="/">Help Center </a>
          </li>
          <li>
            <i className="fas fa-chevron-right" />
            <span> Payment Support</span>
          </li>
        </ul>
        <p className="c-white-60 fsz-def mY-40">No matter where you are, join the simple life of Paybit</p>
        <h1 className="fsz-50">
          <b className="fw-n">
            We want everybody to love
            {' '}
            <br />
            work as much as we do.
          </b>

        </h1>
        <a href="/" className="btn btn-lg bdrs6 btn-light">Become our member</a>
      </div>
    </section>
    <section className="section-carrer-img-intro">
      <div className="sci-inner">
        <div className="d-flex align-items-center d-block">
          <ReactWOW animation="fadeInRight">
            <div className="img carrer-img-big mR-25" style={{ backgroundImage: 'url(/images/carrer-img01.jpg)' }} />
          </ReactWOW>
          <ReactWOW animation="fadeInRight">
            <div className="img carrer-img-small mR-25" style={{ backgroundImage: 'url(/images/carrer-img02.jpg)' }} />
          </ReactWOW>
          <ReactWOW animation="fadeInRight">
            <div className="img carrer-img-small" style={{ backgroundImage: 'url(/images/carrer-img03.jpg)' }} />
          </ReactWOW>
        </div>
      </div>
    </section>
    <section className="bgc-white">
      <div className="container">
        <div className="mY-40 fsz-xl">
          In order to see the real you,
          beyond just your CV,
          we are disrupting the traditional recruiting process.
          With the trivago Talent Community, the focus is on you,
          rather than on a specific role. We see this as the most
          logical step towards you finding your ideal job.
        </div>
        <ReactWOW animation="fadeInUp">
          <div className="row mT-30">
            <div className="col-md-12 col-lg-4">
              <div className="d-flex mB-30">
                <div className="c-blue fsz-sup mR-25">
                  <i className="icon-cv" />
                </div>
                <div className="pR-30">
                  <h5 className="mB-10"><b className="fw-n">Submit Resume</b></h5>
                  <p className="c-text-gray fsz-def line-height-2">
                    Apply online or send a resume to your recruiter.
                    Note that the most successful candidates possess
                    the required skills and experience for the role they’ve applied for.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="d-flex mB-30">
                <div className="c-blue fsz-sup mR-25">
                  <i className="icon-light-up" />
                </div>
                <div className="pR-30">
                  <h5 className="mB-10"><b className="fw-n">Have a Conversation</b></h5>
                  <p className="c-text-gray fsz-def line-height-2">
                    If selected to advance in the process, you&lsquo;ll speak
                    with someone on our recruiting team to learn more about the
                    role you&lsquo;ve applied for and what it&lsquo;s like to
                    work at Paybit.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="d-flex mB-30">
                <div className="c-blue fsz-sup mR-25">
                  <i className="icon-interview" />
                </div>
                <div className="pR-30">
                  <h5 className="mB-10"><b className="fw-n">Interviews</b></h5>
                  <p className="c-text-gray fsz-def line-height-2">
                    Next comes a remote interview with a hiring manager or potential teammate.
                    If selected to move forward, you’ll come on-site to interview with people
                    you’ll be working with, after which a recruiter will contact you to discuss
                    any next steps.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
    </section>
    <section className="bgc-grey pT-50 section-job">
      <div className="container">
        <div className="row mB-50">
          <div className="col-md-12 col-lg-6">
            <h4 className="mB-10"><b className="fw-n">See our job selections</b></h4>
          </div>
          <div className="col-md-12 col-lg-6">
            <p className="fsz-def c-gray mB-10">Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
          </div>
        </div>
        <ReactWOW animation="fadeInUp">
          <div className="row row-sm">
            <div className="col-md-6 col-lg-4">
              <div className="mB-15 bgc-white d-flex bd p-20 job-box">
                <div className="mR-25 fsz-sup c-grey">
                  <i className="icon-rocket" />
                </div>
                <div>
                  <p className="c-text-gray">Paybit.com</p>
                  <h5 className="mB-10"><a href="/" className="c-black"><b className="fw-n">Association of Management Consulting Firms</b></a></h5>
                  <div className="mB-20">
                    <span className="c-gray mR-10">
                      <i className="mR-10 c-blue icon-mapline" />
  San Franscisco, CA
                    </span>
                    <span className="c-gray">
                      <i className="mR-10 c-blue icon-time" />
  6 Days Left
                    </span>
                  </div>
                  <a href="/" className="c-black">
                    <b className="fw-n">
  View More
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mB-15 bgc-white d-flex bd p-20 job-box">
                <div className="mR-25 fsz-sup c-grey">
                  <i className="icon-message" />
                </div>
                <div>
                  <p className="c-text-gray">Paybit.com</p>
                  <h5 className="mB-10"><a href="/" className="c-black"><b className="fw-n">PR Careers in Consulting</b></a></h5>
                  <div className="mB-20">
                    <span className="c-gray mR-10">
                      <i className="mR-10 c-blue icon-mapline" />
  San Franscisco, CA
                    </span>
                    <span className="c-gray">
                      <i className="mR-10 c-blue icon-time" />
  6 Days Left
                    </span>
                  </div>
                  <a href="/" className="c-black">
                    <b className="fw-n">
  View More
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mB-15 bgc-white d-flex bd p-20 job-box">
                <div className="mR-25 fsz-sup c-grey">
                  <i className="icon-chart-bold" />
                </div>
                <div>
                  <p className="c-text-gray">Paybit.com</p>
                  <h5 className="mB-10"><a href="/" className="c-black"><b className="fw-n">Association for Financial Professionals</b></a></h5>
                  <div className="mB-20">
                    <span className="c-gray mR-10">
                      <i className="mR-10 c-blue icon-mapline" />
  San Franscisco, CA
                    </span>
                    <span className="c-gray">
                      <i className="mR-10 c-blue icon-time" />
  6 Days Left
                    </span>
                  </div>
                  <a href="/" className="c-black">
                    <b className="fw-n">
  View More
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mB-15 bgc-white d-flex bd p-20 job-box">
                <div className="mR-25 fsz-sup c-grey">
                  <i className="icon-chart-bold" />
                </div>
                <div>
                  <p className="c-text-gray">Paybit.com</p>
                  <h5 className="mB-10"><a href="/" className="c-black"><b className="fw-n">Association for Financial Professionals</b></a></h5>
                  <div className="mB-20">
                    <span className="c-gray mR-10">
                      <i className="mR-10 c-blue icon-mapline" />
  San Franscisco, CA
                    </span>
                    <span className="c-gray">
                      <i className="mR-10 c-blue icon-time" />
  6 Days Left
                    </span>
                  </div>
                  <a href="/" className="c-black">
                    <b className="fw-n">
  View More
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mB-15 bgc-white d-flex bd p-20 job-box">
                <div className="mR-25 fsz-sup c-grey">
                  <i className="icon-rocket" />
                </div>
                <div>
                  <p className="c-text-gray">Paybit.com</p>
                  <h5 className="mB-10"><a href="/" className="c-black"><b className="fw-n">Association of Management Consulting Firms</b></a></h5>
                  <div className="mB-20">
                    <span className="c-gray mR-10">
                      <i className="mR-10 c-blue icon-mapline" />
  San Franscisco, CA
                    </span>
                    <span className="c-gray">
                      <i className="mR-10 c-blue icon-time" />
  6 Days Left
                    </span>
                  </div>
                  <a href="/" className="c-black">
                    <b className="fw-n">
  View More
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mB-15 bgc-white d-flex bd p-20 job-box">
                <div className="mR-25 fsz-sup c-grey">
                  <i className="icon-message" />
                </div>
                <div>
                  <p className="c-text-gray">Paybit.com</p>
                  <h5 className="mB-10"><a href="/" className="c-black"><b className="fw-n">PR Careers in Consulting</b></a></h5>
                  <div className="mB-20">
                    <span className="c-gray mR-10">
                      <i className="mR-10 c-blue icon-mapline" />
  San Franscisco, CA
                    </span>
                    <span className="c-gray">
                      <i className="mR-10 c-blue icon-time" />
  6 Days Left
                    </span>
                  </div>
                  <a href="/" className="c-black">
                    <b className="fw-n">
  View More
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
            </div>
          </div>
        </ReactWOW>
        <div className="text-center mY-40">
          <span className="c-gray fsz-def d-flex align-items-center justify-content-center">
            <i className="fas fa-spinner fa-spin mR-10 fsz-lg" />
Loading More
          </span>
        </div>
      </div>
    </section>
    <section className="bgc-white pB-50 section-partner-carrer">
      <div className="container">
        <div className="row justify-content-center video-iframe-carrer">
          <div className="col-md-11 col-lg-10">
            <iframe
              title="/"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/3MKj2qVk2yQ?controls=0"
              frameBorder={0}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
        <div className="text-center mB-30">
          <div className="mB-20 fsz-xl">Do the Most Meaningful Work of Your Career</div>
          <p className="c-text-gray fsz-def line-height-2">
People are at the heart of every connection we build. We design products and deliver
            {' '}
            <br />
services that bring the world closer together — one connection at a time.
          </p>
        </div>
        <PartnerItems />
      </div>
    </section>
  </div>
);

export default Careers;
