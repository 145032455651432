import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import ReactWOW from 'react-wow';
import Countup from 'react-countup';


const WalletBalance = ({ dragRef }) => (
  <ReactWOW animation="fadeInLeft">
    <div className="col-md-12 col-lg-12 col-xl-5 mB-20" ref={dragRef}>
      <div className="box-style bd bgc-white">
        <span className="box-close-btn"><i className="icon-drag" /></span>
        <div className="box-header">
          <h5><b className="fw-n">Wallet Balance</b></h5>
        </div>
        <div className="box-body">
          <div className="tab-common pX-30 bgc-white">
            <Tab.Container defaultActiveKey="tab_sendMoney">
              <Nav variant="pills" className="nav nav-tabs tab-sm" id="walletBalanceTab">
                <Nav.Item>
                  <Nav.Link className="nav-link" eventKey="tab_sendMoney">
                    <i className="icon-overview" />
                    Send Money
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="nav-link" eventKey="tab_requestMoney">
                    <i className="icon-request_money" />
                    Request Money
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="nav-link" eventKey="tab_exchangeMoney">
                    <i className="icon-exchage" />
                    Exchange Money
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content id="walletBalanceTabContent">
                <Tab.Pane eventKey="tab_sendMoney">
                  <div className="tab-button">
                    <Tab.Container defaultActiveKey="tab_sendMoney_walletAddress">
                      <Nav variant="pills" id="sendmoneywalletaddressTab">
                        <Nav.Item>
                          <Nav.Link eventKey="tab_sendMoney_email">Email</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="tab_sendMoney_walletAddress">Wallet Address</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content id="sendmoneywalletaddressTabContent">
                        <Tab.Pane eventKey="tab_sendMoney_email">
                          <form action method="POST">
                            <div className="row">
                              <div className="col-md-12 col-lg-6">
                                <div className="form-group">
                                  <label className="c-grey">Recipient</label>
                                  <input type="text" className="form-control form_control" placeholder="Email or bitcoin address" />
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-6">
                                <div className="form-group">
                                  <label className="c-grey">Transfer to</label>
                                  <div className="form_control email-transfer-to">
                                    <input type="text" className="form-control" id="email-transfer-to" placeholder="Enter user email address" />
                                    <div className="list-email-transfer scrollable">
                                      <ul className="lis-n p-0 m-0 bdT">
                                        <li className="d-flex align-items-center">
                                          <div className="size-25 img circle mR-10" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                                          <span className="email-transfer-title fsz-xs c-grey-6">jerveointomson@coinbase.com</span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <div className="size-25 img circle mR-10" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                                          <span className="email-transfer-title fsz-xs c-grey-6">jerveointomson@coinbase.com</span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <div className="size-25 img circle mR-10" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                                          <span className="email-transfer-title fsz-xs c-grey-6">jerveointomson@coinbase.com</span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <div className="size-25 img circle mR-10" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                                          <span className="email-transfer-title fsz-xs c-grey-6">jerveointomson@coinbase.com</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="c-grey">Select Amount</label>
                              <select name id="input" className="form-control form_control">
                                <option value>
                                    Select the amount you want to transfer
                                </option>
                                <option value={1}>1</option>
                                <option value={1}>2</option>
                                <option value={1}>3</option>
                                <option value={1}>4</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label className="c-grey">Note</label>
                              <textarea rows={5} className="form-control form_control" placeholder="Notes" defaultValue="" />
                            </div>
                            <div className="mT-10 mB-20">
                              <div className="d-flex align-items-center justify-content-between pY-10">
                                <span className="fw-500 c-grey-500 fsz-def">Network Fee</span>
                                <span>0.00213213 BTC ($3.06)</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-between pY-10">
                                <span className="fw-500 c-grey-500 fsz-def">Network Fee</span>
                                <span>0.062131 BTC ($610.96)</span>
                              </div>
                            </div>
                            <hr className="hr-grey" />
                            <div className="d-flex justify-content-end align-items-center pT-15 d-md-inline-block">
                              <button className="btn btn-lg btn-gray mR-15">
                                <i className="icon-add mR-10" />
                                  Add Account
                              </button>
                              <button type="submit" className="btn btn-lg btn-primary">Transfer</button>
                            </div>
                          </form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab_sendMoney_walletAddress">
                          <form action method="POST">
                            <div className="form-group">
                              <label className="c-grey">Recipient</label>
                              <div className="form_control d-flex justify-content-between">
                                <span>12LGSD4sdbsZFS4pXSTnqeUvjfjfTVSD5cyS</span>
                                <div>
                                  <i className="c-black fsz-lg icon-qr-code mR-15" />
                                  <i className="c-green fsz-md fas fa-check-circle" />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="c-grey">Withdraw From</label>
                              <div className="form_control">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <div className="coin-20 mR-10 float-left" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                                    <span>Bitcoin Wallet</span>
                                  </div>
                                  <div className="text-right">
                                    <span>0.0724352100 BTC</span>
                                    <br />
                                    <span className="c-grey">$612.05</span>
                                  </div>
                                </div>
                                <hr className="hr-grey" />
                                <div className="row">
                                  <ul className="lis-n col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 m-0">
                                    <li className="d-flex justify-content-between pY-10">
                                      <span>
                                        <i className="icon-wallet2 mR-5" />
                                          ETH Wallet
                                      </span>
                                      <span className="c-grey">
                                        {' '}
                                        <Countup end={0.0000} prefix="" suffix=" ETH" separator="," decimal="." duration={3} decimals={4} delay={1} />
                                      </span>
                                    </li>
                                    <li className="d-flex justify-content-between pY-10">
                                      <span>
                                        <i className="icon-wallet2 mR-5" />
                                          My Wallet
                                      </span>
                                      <span className="c-grey">
                                        <Countup
                                          end={0.0000438}
                                          prefix=""
                                          suffix=" BTC"
                                          separator=","
                                          decimal="."
                                          duration={3}
                                          decimals={7}
                                          delay={1}
                                        />
                                      </span>
                                    </li>
                                    <li className="d-flex justify-content-between pY-10">
                                      <span>
                                        <i className="icon-transactions mR-5" />
                                          USD Wallet
                                      </span>
                                      <span className="c-grey">
                                        {' '}
                                        <Countup end={0.00} prefix="$" suffix=" BTC" separator="," decimal="." duration={3} decimals={2} delay={1} />
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <input type="text" className="form-control form_control" placeholder="Write an optional message" />
                            </div>
                            <div className="mT-10 mB-20">
                              <div className="d-flex align-items-center justify-content-between pY-10">
                                <span className="fw-500 c-grey-500 fsz-def">Network Fee</span>
                                <span>0.00213213 BTC ($3.06)</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-between pY-10">
                                <span className="fw-500 c-grey-500 fsz-def">Network Fee</span>
                                <span>0.062131 BTC ($610.96)</span>
                              </div>
                            </div>
                            <hr className="hr-grey" />
                            <div className="d-flex justify-content-end align-items-center pT-15 d-md-inline-block">
                              <button className="btn btn-lg btn-gray mR-15">
                                <i className="icon-add mR-10" />
                                  Add Account
                              </button>
                              <button type="submit" className="btn btn-lg btn-primary">Transfer</button>
                            </div>
                          </form>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  </ReactWOW>
);

export default WalletBalance;
