import React from 'react';
import Slider from 'react-slick';
import ReactWOW from 'react-wow';
import { compose } from 'recompose';
import { withAuth } from '../../hocs/withAuth';

const settings = {
  dots: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: true,
};

const TopSlider = ({ isAuth }) => (
  <section className="top-slider">
    <div className="top-slider-inner">
      <div className="slideshow">
        <div className="slider-content c-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-5">
                <ReactWOW animation="slideInLeft" duration="0.5s">
                  <p className="mB-30 text-uppercase">YOUR PERSONAL WALLET</p>
                </ReactWOW>
                <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.05s">
                  <h2 className="fsz-sup mB-30 fw-n">Online Payments Made Easy</h2>
                </ReactWOW>
                <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.1s">
                  <p className="fsz-def mB-30">No matter where and who.Join now the simple life of Paybit</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp" delay="0.2s">
                  <div>
                    {!isAuth && <a href="/sign-up-personal" className="btn btn-lg btn-primary btn-around mR-15">Sign Up</a>}
                    <a href="/business-tour" className="btn btn-lg btn-outline-light btn-around">Learn More</a>
                  </div>
                </ReactWOW>
              </div>
              <ReactWOW animation="bounceInRight">
                <div className="col-md-12 col-lg-7 hidden-md">
                  <div className="main-cop-image" style={{ background: 'url(/images/mockup-tablet-dashboard-full.png)' }} />
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
        <Slider {...settings} className="slider main-slider" id="main-slider">
          <div className="item">
            <div className="item-image" style={{ background: 'url(/images/slider2.jpg)' }} />
          </div>
          <div className="item">
            <div className="item-image" style={{ background: 'url(/images/slider1.jpg)' }} />
          </div>
          <div className="item">
            <div className="item-image" style={{ background: 'url(/images/slider3.jpg)' }} />
          </div>
        </Slider>
      </div>
    </div>
  </section>
);

export default compose(
  withAuth,
)(TopSlider);
