import React from 'react';
import ReactWOW from 'react-wow';

const Offer = () => (
  <section className="pB-25 section-features">
    <div className="container">
      <div className="bd bgc-grey p-50">
        <div className="text-center mB-50">
          <h3 className="mB-20"><b className="fw-n">We have more stuff and features to offer for you</b></h3>
          <p className="c-text-gray fsz-def">We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold. Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
        </div>
        <ReactWOW animation="fadeInUp">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-multiple-payments" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Send multiple Payments</b></h6>
                  <p className="c-text-gray">
                  Payments can not get simpler! You can pay multiply clients,
                  affiliates or customers at once with one simple click.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-all-money" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Tap into all your money.</b></h6>
                  <p className="c-text-gray">
                  Use our mobile tools and panels to have
                  always a overview of your business and money.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-developers-api" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Multiple developers APIs</b></h6>
                  <p className="c-text-gray">
                  We have solutions for a big variety of
                  ecommerce systems and many developer APIs for each possible solution.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-security" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">You are secured</b></h6>
                  <p className="c-text-gray">
                  We offer a Buyer and Seller Protection system.
                  Payments can be refund up to 180 days,
                  based on what is sold. Nobody needs to worry to get problems,
                  read our Buyer and Protection details.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-overview" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Send money online or with your mobile</b></h6>
                  <p className="c-text-gray">
                  Use our mobile tools and panels to have
                  always a overview of your business and money.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-time" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Transfer in Secounds</b></h6>
                  <p className="c-text-gray">
                  Payments can&lsquo;t get better.
                  Pay anyone with just one simple click.
                  Everything is done in lightspeed.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-money-10 fsz-def" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Send and request money fast.</b></h6>
                  <p className="c-text-gray">
                    It’s free to send money to friends and family in the U.S.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-manage-money-easily" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Manage your money easily.</b></h6>
                  <p className="c-text-gray">
                  The improved Paybit app makes it simple to send and request money,
                  manage credit, pool cash from peers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-collect-money-from-group" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Collect money from groups.</b></h6>
                  <p className="c-text-gray">
                  Whether you&lsquo;re collecting money for group
                  gifts, travel, or special events.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
    </div>
  </section>
);

export default Offer;
