import React from 'react';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  Tooltip,
} from 'recharts';

const mockData = [
  {
    name: '1', value: 400,
  },
  {
    name: '', value: 200,
  },
  {
    name: '', value: 189,
  },
  {
    name: '', value: 239,
  },
  {
    name: '', value: 80,
  },
  {
    name: '2', value: 349,
  },
  {
    name: '', value: 278,
  },
  {
    name: '', value: 349,
  },
  {
    name: '', value: 296,
  },
  {
    name: '', value: 195,
  },
  {
    name: '3', value: 111,
  },
  {
    name: '', value: 222,
  },
  {
    name: '', value: 349,
  },
  {
    name: '', value: 333,
  },
  {
    name: '', value: 278,
  },
  {
    name: '4', value: 245,
  },
  {
    name: '', value: 175,
  },
  {
    name: '', value: 287,
  },
  {
    name: '', value: 175,
  },
  {
    name: '', value: 127,
  },
  {
    name: '5', value: 142,
  },
  {
    name: '', value: 378,
  },
  {
    name: '', value: 183,
  },
  {
    name: '', value: 252,
  },
  {
    name: '', value: 257,
  },
  {
    name: '6', value: 257,
  },
  {
    name: '', value: 186,
  },
  {
    name: '', value: 231,
  },
  {
    name: '', value: 128,
  },
  {
    name: '', value: 123,
  },
  {
    name: '7', value: 321,
  },
];

const SalesCharts = ({ data = mockData }) => (
  <div className="sales-charts-wrapper">
    <ResponsiveContainer
      width="100%"
      height="100%"
    >
      <BarChart data={data}>
        <Tooltip
          viewBox={{
            x: -1,
            y: -1,
            width: 400,
            height: 400,
          }}
        />
        <defs>
          <linearGradient id="salesColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#41D4FF" stopOpacity={0.7} />
            <stop offset="90%" stopColor="#0084FF" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Bar dataKey="value" radius={5} barSize={5} fill="url(#salesColor)" />
        <XAxis dataKey="name" tickLine={false} axisLine={false} />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default SalesCharts;
