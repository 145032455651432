import React from 'react';
import Countup from 'react-countup';
import { Helmet } from 'react-helmet';

import { Tab, Nav } from 'react-bootstrap';

import TransactionCharts from '../components/TransactionCharts';

const ActivityHistory = () => (
  <section>
    <Helmet>
      <title>Paybit - Activity History</title>
    </Helmet>
    <div className="p-20 bgc-grey main-content-container">
      <div className="main-content">
        <div className="row">
          <div className="col-md-12 col-lg-4 mB-20">
            <div className="bdrs-6 bd pY-30 pX-40 bg-white">
              <div className="d-flex justify-content-between mB-30">
                <div>
                  <h5><b className="fw-n">Requests</b></h5>
                  <p className="c-gray fsz-sm">Since Last Week</p>
                </div>
                <div>
                  <a href="/" className="btn btn-o-green btn-around">
                    <i className="fas fa-caret-up mR-10" />
                    15%
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <span className="mR-30 c-blue fsz-sup">
                  <i className="icon-user-request" />
                </span>
                <span className="fsz-sup">1.200</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 mB-20">
            <div className="bdrs-6 bd pY-30 pX-40 bg-white">
              <div className="d-flex justify-content-between mB-30">
                <div>
                  <h5><b className="fw-n">Generated Customers</b></h5>
                  <p className="c-gray fsz-sm">Since Last Week</p>
                </div>
                <div>
                  <a href="/" className="btn btn-o-green btn-around">
                    <i className="fas fa-caret-up mR-10" />
                    18%
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <span className="mR-30 c-red fsz-sup">
                  <i className="icon-generated-customers" />
                </span>
                <span className="fsz-sup">2.521</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 mB-20">
            <div className="bdrs-6 bd pY-30 pX-40 bg-white">
              <div className="d-flex justify-content-between mB-30">
                <div>
                  <h5><b className="fw-n">Visitors Total</b></h5>
                  <p className="c-gray fsz-sm">Since Last Week</p>
                </div>
                <div>
                  <a href="/" className="btn btn-o-green btn-around">
                    <i className="fas fa-caret-down mR-10" />
                    22%
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <span className="mR-30 c-green fsz-sup">
                  <i className="icon-visitors-total" />
                </span>
                <span className="fsz-sup">4.001</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mB-15 d-flex align-items-center">
          <h5 className="mR-30"><b className="fw-n">Last Week Transacitons</b></h5>
          <a href="/" className="c-gray fsz-sm">See Full</a>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xl-6 mB-20">
            <div className="bd">
              <div className="box-body pY-20">
                <div className="transactions-chart">
                  <TransactionCharts />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-xl-6 mB-20">
            <div className="form-refer-friend">
              <div className="c-white form-refer-text">
                <h4><b className="fw-n">Refer Friends. Get Rewarded</b></h4>
                <p>
                  You can earn: 15,000 Membership Rewards points for each approved referral -
                  up to 55,000 Membership Rewards points per calendar year.
                </p>
              </div>
              <form action="#">
                <div className="input_group bdn">
                  <input type="text" placeholder="Enter your emaill address…" className="form-control bdn" />
                  <button className="btn btn-primary fw-n">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="mB-15 d-flex align-items-center justify-content-between d-sm-inline-block w-100">
          <h5 className="mR-30"><b className="fw-n">Last Week Transacitons</b></h5>
          <div className="d-flex">
            <div className="input_group mR-15 bg-white">
              <i className="mL-10 icon-search" />
              <input type="date" placeholder="Search now" className="form-control bdn" />
            </div>
            <button className="btn btn-primary">Search</button>
          </div>
        </div>
        <div className="today-notification mB-15">
          <div className="notifications-box box-flex bd mB-5 bg-white">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 mR-20" style={{ backgroundImage: 'url(/images/prep.png)' }} />
              <div>
                <b className="fw-n">Jonathan Banks</b>
                <p className="c-gray mb-0">Preparation for bio crypto business meeting this week - weekly meeting with </p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex bd mB-5 bg-white">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 bdrs-50p mR-20" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              <div>
                <b className="fw-n">Tamara Nikcevic send you private message</b>
                <p className="c-gray mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&lsquo;s</p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
          <div className="notifications-box box-flex bd mB-5 bg-white">
            <div className="d-flex align-items-center notifications-box-left">
              <div className="img-43 mR-20" style={{ backgroundImage: 'url(/images/Payment-icon.png)' }} />
              <div>
                <b className="fw-n">Jonathan Smith</b>
                <p className="c-gray mb-0">Prepayment payment received from the user jonathan</p>
              </div>
            </div>
            <div className="d-flex align-items-center notifications-box-right">1 October , 2018 at 17:30</div>
          </div>
        </div>
        <div className="box-style bd bgc-white mB-20 bdrs-6">
          <span className="box-close-btn"><i className="icon-drag" /></span>
          <div className="box-header box-last-activity-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center box-last-activity-header-left">
                <h5>
                  <b className="fw-n mR-10">Last activity </b>
                  <span className="fsz-sm c-gray">Year 2018</span>
                </h5>
                <div className="mL-30">
                  <a className="c-gray" href="/">(Show All)</a>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="mR-15">
                  <div className="input_group">
                    <i className="mL-10 icon-search" />
                    <input type="text" placeholder="Search now" className="form-control bdn" />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
                <div>
                  <div className="input_group">
                    <i className="mL-10 far fa-calendar-check" />
                    <input type="date" placeholder="Select Date" className="form-control bdn" />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-body pY-20">
            <Tab.Container defaultActiveKey="tab_alltransaction">
              <div className="tab-common pX-25 bgc-white">
                <div className="d-flex align-items-center justify-content-between">
                  <Nav className="nav nav-tabs tab-md" id="last_activityTab" role="tablist">
                    <Nav.Item className="nav-item">
                      <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction" role="tab">All Transaction </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction-1" role="tab" aria-selected="false">Recieved </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction-2" role="tab" aria-selected="false">Sent </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction-3" role="tab" aria-selected="false">Withdrawn </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction-4" role="tab" aria-selected="false">Logins </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_alltransaction-5" role="tab" aria-selected="false">Exchanges </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div>
                    <button className="btn btn-transparent mR-20">
                      <i className="c-green icon-export mR-10" />
                      Export Table
                    </button>
                    <button className="btn btn-transparent">
                      <i className="c-yellow icon-print mR-10" />
                      Print
                    </button>
                  </div>
                </div>
              </div>
              <Tab.Content as="div" className="tab-content p-0" id="last_activityTabContent">
                <Tab.Pane className="tab-pane" eventKey="tab_alltransaction" role="tabpanel">
                  <div className="table-responsive scrollable max-h-730">
                    <table className="table table-common table-hover">
                      <thead>
                        <tr>
                          <th>Name/Email</th>
                          <th>Date</th>
                          <th>Action</th>
                          <th>Status</th>
                          <th>Details</th>
                          <th>Amount</th>
                          <th>Transaction ID</th>
                          <th className="pX-10">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody className="pX-20">
                        <tr>
                          <td>
                            <div className="name-email d-flex align-items-center">
                              <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                              <div className="mL-20">
                                <span>
                                  Bruce Springesteen
                                  {' '}
                                  <i className="icon-contacts mL-10 c-blue" />
                                </span>
                                <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                              </div>
                            </div>
                          </td>
                          <td><span className="c-gray">25.2.2018</span></td>
                          <td>
                            <span className="coin-currency">
                              <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                              <span>
                                Sent to
                                <br />
                                {' '}
                                <span className="fsz-xs c-grey">Bitcoin Wallet</span>
                              </span>
                            </span>

                          </td>
                          <td>
                            <span>
                              <i className="c-green fsz-md fas fa-check-circle mR-5" />
                              Complete
                            </span>
                          </td>
                          <td>
                            <a href="/" className="c-gray">View</a>
                          </td>
                          <td>
                            <span className="c-red">
                              {' '}
                              <Countup end={-800} suffix="$" separator="," decimal="." duration={3} decimals={2} />
                            </span>

                          </td>
                          <td><span className="c-gray">5123123</span></td>
                          <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                        </tr>
                        <tr>
                          <td>
                            <div className="name-email d-flex align-items-center">
                              <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                              <div className="mL-20">
                                <span>James Blunt</span>
                                <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                              </div>
                            </div>
                          </td>
                          <td><span className="c-gray">25.2.2018</span></td>
                          <td>
                            <span className="coin-currency">
                              <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/ethereum.png)' }} />
                              <span>
                                Received
                                <br />
                                {' '}
                                <span className="fsz-xs c-grey">From Ethereum Wallet</span>
                              </span>
                            </span>

                          </td>
                          <td>
                            <span>
                              <i className="c-green fsz-md fas fa-check-circle mR-5" />
                                Complete
                            </span>
                          </td>
                          <td>
                            <a href="/" className="c-gray">View</a>
                          </td>
                          <td>
                            <span className="c-green">
                              {' '}
                              <Countup end={1800.00} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                            </span>
                          </td>
                          <td><span className="c-gray">5123123</span></td>
                          <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                        </tr>
                        <tr>
                          <td>
                            <div className="name-email d-flex align-items-center">
                              <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                              <div className="mL-20">
                                <span>Cristopter Smith</span>
                                <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                              </div>
                            </div>
                          </td>
                          <td><span className="c-gray">25.2.2018</span></td>
                          <td>
                            <span className="coin-currency">
                              <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/monero.png)' }} />
                              <span>
                                Received
                                <br />
                                {' '}
                                <span className="fsz-xs c-grey">From Monero Wallet</span>
                              </span>
                            </span>

                          </td>
                          <td>
                            <span>
                              <i className="c-red fsz-md fas fa-times-circle mR-5" />
                              Cancelled
                            </span>
                          </td>
                          <td>
                            <a href="/" className="c-gray">View</a>
                          </td>
                          <td>
                            <span className="c-green">
                              {' '}
                              <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                            </span>
                          </td>
                          <td><span className="c-gray">5123123</span></td>
                          <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                        </tr>
                        <tr>
                          <td>
                            <div className="name-email d-flex align-items-center">
                              <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                              <div className="mL-20">
                                <span>James Blunt</span>
                                <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                              </div>
                            </div>
                          </td>
                          <td><span className="c-gray">25.2.2018</span></td>
                          <td>
                            <span className="coin-currency">
                              <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/litecoin.png)' }} />
                              <span>
                                Send to
                                <br />
                                {' '}
                                <span className="fsz-xs c-grey">From Litecoin Wallet</span>
                              </span>
                            </span>

                          </td>
                          <td>
                            <span>
                              <i className="c-green fsz-md fas fa-check-circle mR-5" />
                              Complete
                            </span>
                          </td>
                          <td>
                            <a href="/" className="c-gray">View</a>
                          </td>
                          <td>
                            <span className="c-red">
                              {' '}
                              <Countup end={1800.00} prefix="-" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                            </span>
                          </td>
                          <td><span className="c-gray">5123123</span></td>
                          <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                        </tr>
                        <tr>
                          <td>
                            <div className="name-email d-flex align-items-center">
                              <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                              <div className="mL-20">
                                <span>James Blunt</span>
                                <p className="m-0 c-gray">jamesblunt@yahoo.com</p>
                              </div>
                            </div>
                          </td>
                          <td><span className="c-gray">25.2.2018</span></td>
                          <td>
                            <span className="coin-currency">
                              <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/ethereum.png)' }} />
                              <span>
                                Received
                                <br />
                                {' '}
                                <span className="fsz-xs c-grey">From Ethereum Wallet</span>
                              </span>
                            </span>

                          </td>
                          <td>
                            <span>
                              <i className="c-yellow fsz-md fas fa-exclamation-circle mR-5" />
                              Pending
                            </span>
                          </td>
                          <td>
                            <a href="/" className="c-gray">View</a>
                          </td>
                          <td>
                            <span className="c-green">
                              {' '}
                              <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                            </span>
                          </td>
                          <td><span className="c-gray">5123123</span></td>
                          <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                        </tr>
                        <tr>
                          <td>
                            <div className="name-email d-flex align-items-center">
                              <div className="circle img size-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                              <div className="mL-20">
                                <span>Cristopter Smith</span>
                                <p className="m-0 c-gray">1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn</p>
                              </div>
                            </div>
                          </td>
                          <td><span className="c-gray">25.2.2018</span></td>
                          <td>
                            <span className="coin-currency">
                              <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/monero.png)' }} />
                              <span>
                                Received
                                <br />
                                <span className="fsz-xs c-grey">From Monero Wallet</span>
                              </span>
                            </span>

                          </td>
                          <td>
                            <span>
                              <i className="c-red fsz-md fas fa-times-circle mR-5" />
                              Cancelled
                            </span>
                          </td>
                          <td>
                            <a href="/" className="c-gray">View</a>
                          </td>
                          <td>
                            <span className="c-green">
                              {' '}
                              <Countup end={1522.22} prefix="+" suffix="$" separator="," decimal="." duration={3} decimals={2} />
                            </span>
                          </td>
                          <td><span className="c-gray">5123123</span></td>
                          <td className="pX-10"><button className="btn-transparent c-grey"><i className="fas fa-ellipsis-v" /></button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab_alltransaction-1">1</Tab.Pane>
                <Tab.Pane eventKey="tab_alltransaction-2">2</Tab.Pane>
                <Tab.Pane eventKey="tab_alltransaction-3">3</Tab.Pane>
                <Tab.Pane eventKey="tab_alltransaction-4">4</Tab.Pane>
                <Tab.Pane eventKey="tab_alltransaction-5">5</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
        <div className="box-style bd bgc-white mB-5">
          <div className="box-header box-last-activity-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center box-last-activity-header-left">
                <h5><b className="fw-n mR-10">Account Login History</b></h5>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <div className="input_group">
                    <i className="mL-10 far fa-calendar-check" />
                    <input type="date" placeholder="Select Date" className="form-control bdn" />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
                <div className="pL-20 pT-5 float-left">
                  <button className="btn-transparent">
                    <i className="c-gray icon-drag" />
                  </button>
                </div>
                <div className="pL-20 pT-5 float-left">
                  <button className="btn-transparent">
                    <i className="c-gray fas fa-times" />
                  </button>
                </div>
                <div className="pL-20 pT-5 float-left">
                  <button className="btn-transparent">
                    <i className="c-gray fas fa-ellipsis-h" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="box-body pY-20">
            <div className="table-responsive">
              <table className="table table-common table-hover">
                <thead>
                  <tr>
                    <th />
                    <th>Devices</th>
                    <th>SSID</th>
                    <th>Protocol</th>
                    <th>Location</th>
                    <th>Manufacturer</th>
                    <th>Last Seen</th>
                  </tr>
                </thead>
                <tbody className="pX-20">
                  <tr>
                    <td>
                      <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                      <label htmlFor="checkbox-1" />
                    </td>
                    <td>
                      <b className="fw-n d-flex align-items-center devices-icon">
                        <i className="c-blue mR-10 fsz-md icon-iphone-device" />
                        IphoneXS
                      </b>

                    </td>
                    <td className="c-gray">f8:g2:d2:d6:55</td>
                    <td className="c-gray">Wifi</td>
                    <td className="c-gray">Novi Sad,Serbia</td>
                    <td className="c-gray">Apple, Inc</td>
                    <td className="c-gray"> Octobar 1 , 2018</td>
                  </tr>
                  <tr>
                    <td>
                      <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                      <label htmlFor="checkbox-1" />
                    </td>
                    <td>
                      <b className="fw-n d-flex align-items-center devices-icon">
                        <i className="c-blue mR-10 fsz-md icon-pc-computer-device" />
                        PC Computer
                      </b>

                    </td>
                    <td className="c-gray">f8:g2:d2:d6:55</td>
                    <td className="c-gray">Wifi</td>
                    <td className="c-gray">Novi Sad,Serbia</td>
                    <td className="c-gray">Apple, Inc</td>
                    <td className="c-gray"> Octobar 1 , 2018</td>
                  </tr>
                  <tr>
                    <td>
                      <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                      <label htmlFor="checkbox-1" />
                    </td>
                    <td>
                      <b className="fw-n d-flex align-items-center devices-icon">
                        <i className="c-blue mR-10 fsz-md far fa-question-circle" />
                        Unassigned
                      </b>

                    </td>
                    <td className="c-gray">f8:g2:d2:d6:55</td>
                    <td className="c-gray">Wifi</td>
                    <td className="c-gray">Novi Sad,Serbia</td>
                    <td className="c-gray">Apple, Inc</td>
                    <td className="c-gray"> Octobar 1 , 2018</td>
                  </tr>
                  <tr>
                    <td>
                      <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                      <label htmlFor="checkbox-1" />
                    </td>
                    <td>
                      <b className="fw-n d-flex align-items-center devices-icon">
                        <i className="c-blue mR-10 fsz-md icon-samsung-device" />
                        Samsung Galaxy
                      </b>

                    </td>
                    <td className="c-gray">f8:g2:d2:d6:55</td>
                    <td className="c-gray">Wifi</td>
                    <td className="c-gray">Novi Sad,Serbia</td>
                    <td className="c-gray">Apple, Inc</td>
                    <td className="c-gray"> Octobar 1 , 2018</td>
                  </tr>
                  <tr>
                    <td>
                      <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                      <label htmlFor="checkbox-1" />
                    </td>
                    <td>
                      <b className="fw-n d-flex align-items-center devices-icon">
                        <i className="c-blue mR-10 fsz-md icon-iphone-device" />
                        IphoneXS
                      </b>

                    </td>
                    <td className="c-gray">f8:g2:d2:d6:55</td>
                    <td className="c-gray">Wifi</td>
                    <td className="c-gray">Novi Sad,Serbia</td>
                    <td className="c-gray">Apple, Inc</td>
                    <td className="c-gray"> Octobar 1 , 2018</td>
                  </tr>
                  <tr>
                    <td>
                      <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                      <label htmlFor="checkbox-1" />
                    </td>
                    <td>
                      <b className="fw-n d-flex align-items-center devices-icon">
                        <i className="c-blue mR-10 fsz-md icon-pc-computer-device" />
                        PC Computer
                      </b>

                    </td>
                    <td className="c-gray">f8:g2:d2:d6:55</td>
                    <td className="c-gray">Wifi</td>
                    <td className="c-gray">Novi Sad,Serbia</td>
                    <td className="c-gray">Apple, Inc</td>
                    <td className="c-gray"> Octobar 1 , 2018</td>
                  </tr>
                  <tr>
                    <td>
                      <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                      <label htmlFor="checkbox-1" />
                    </td>
                    <td>
                      <b className="fw-n d-flex align-items-center devices-icon">
                        <i className="c-blue mR-10 fsz-md far fa-question-circle" />
                        Unassigned
                      </b>

                    </td>
                    <td className="c-gray">f8:g2:d2:d6:55</td>
                    <td className="c-gray">Wifi</td>
                    <td className="c-gray">Novi Sad,Serbia</td>
                    <td className="c-gray">Apple, Inc</td>
                    <td className="c-gray"> Octobar 1 , 2018</td>
                  </tr>
                  <tr>
                    <td>
                      <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                      <label htmlFor="checkbox-1" />
                    </td>
                    <td>
                      <b className="fw-n d-flex align-items-center devices-icon">
                        <i className="c-blue mR-10 fsz-md icon-iphone-device" />
                        IphoneXS
                      </b>

                    </td>
                    <td className="c-gray">f8:g2:d2:d6:55</td>
                    <td className="c-gray">Wifi</td>
                    <td className="c-gray">Novi Sad,Serbia</td>
                    <td className="c-gray">Apple, Inc</td>
                    <td className="c-gray"> Octobar 1 , 2018</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ActivityHistory;
