import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import Account from '../../common/FAQS/Account';
import SecurityNPrivacy from '../../common/FAQS/SecurityNPrivacy';
import PartnerItems from '../../common/PartnerItems';
import BannerText from '../BannerText';

const DownloadAndroidPhone = () => (
  <>
    <Helmet>
      <title>Paybit - Download Android Phone</title>
    </Helmet>
    <section className="top-banner-download" style={{ backgroundImage: 'url(/images/download-android-banner-unsplash.jpg)' }}>
      <div className="top-banner-download-outer">
        <div className="top-banner-download-inner c-white">
          <div className="container">
            <ul className="breadcrumb breadcrumbs breadcrumbs-white">
              <li><a href="/"><i className="icon-house" /></a></li>
              <li>
                <i className="fas fa-chevron-right" />
                <a href="download-iphone-phone">Apps</a>
              </li>
              <li>
                <i className="fas fa-chevron-right" />
                {' '}
                <span>Android App</span>
              </li>
            </ul>
            <div className="top-banner-text">
              <div className="row justify-content-between">
                <BannerText />
                <ReactWOW animation="slideInRight" duration="0.5s" delay="1.5s">
                  <div className="col-md-12 col-lg-6 hidden-md">
                    <div className="img-android-mockup" style={{ backgroundImage: 'url(/images/android-mockup.png)' }} />
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row justify-content-center mB-50 ">
          <div className="col-md-12 col-lg-9">
            <h2 className="mY-50 text-center text-before-decor">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</h2>
          </div>
        </div>
        <div className="row mY-50">
          <ReactWOW animation="fadeInUp" deplay="0.2s">
            <div className="col-md-12 col-lg-4 ">
              <div className="pR-30 mB-30 box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-40 mB-25">
                  <i className="icon-money-10" />
                </div>
                <h5 className="fw-n">Send and request money fast.</h5>
                <p className="c-gray">It’s free to send money to friends and family in the U.S. using your bank account or Paybit balance. Pay your share of the bill or request money from friends with ease.</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  Find our more
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" deplay="0.4s">
            <div className="col-md-12 col-lg-4 ">
              <div className="pR-30 mB-30 box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-15">
                  <i className="icon-manage-easy" />
                </div>
                <h5 className="fw-n">Manage your money easily.</h5>
                <p className="c-gray">The improved Paybit app makes it simple to send and request money, manage credit, pool cash from peers, and create savings goals. Plus, you can easily track and monitor every transaction you make.</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  Find our more
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" deplay="0.6s">
            <div className="col-md-12 col-lg-4 ">
              <div className="pR-30 mB-30 box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-15">
                  <i className="icon-collect-money-from-group" />
                </div>
                <h5 className="fw-n">Collect money from groups.</h5>
                <p className="c-gray">Whether you&lsquo;re collecting money for group gifts, travel, or special events, Money Pools make it easy to create a custom page that allows family, friends, and coworkers to chip in for big-ticket items.</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  Find our more
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
        </div>
        <div className="clearfix" />
        <div className="mT-30">
          <PartnerItems />
        </div>
      </div>
    </section>
    <section className="pB-70 bgc-grey">
      <div className="container">
        <ReactWOW animation="fadeInUp">
          <div className="row align-items-center download-android-phone">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="box-shadow download-box">
                <div className="circle-gray-70">
                  <i className="icon-money-bag" />
                </div>
                <div className="mT-25">
                  <h5 className="fw-n">Get up to speed on getting paid.</h5>
                  <p className="c-text-gray line-height mb-0">Create and send invoices in the moment, so customers can pay right away.</p>
                </div>
              </div>
              <div className="box-shadow download-box">
                <div className="circle-gray-70">
                  <i className="icon-money-dollar" />
                </div>
                <div className="mT-25">
                  <h5 className="fw-n">Stay on top of your business</h5>
                  <p className="c-text-gray line-height mb-0">Whether you need to transfer funds, view transaction details.</p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 hidden-md">
              <div className="img-mockup-dashboard-iphone" style={{ backgroundImage: 'url(/images/mockup-dashboard-iphone.png)' }} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="box-shadow download-box">
                <div className="circle-gray-70">
                  <i className="icon-flag" />
                </div>
                <div className="mT-25">
                  <h5 className="fw-n">Mobilize how you do business.</h5>
                  <p className="c-text-gray line-height mb-0">Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S.</p>
                </div>
              </div>
              <div className="box-shadow download-box">
                <div className="circle-gray-70">
                  <i className="icon-headphone" />
                </div>
                <div className="mT-25">
                  <h5 className="fw-n">One place for all of your customer</h5>
                  <p className="c-text-gray line-height mb-0">Now you can access all of your PayBit customer information in one place.</p>
                </div>
              </div>
            </div>
          </div>
        </ReactWOW>
        <div className="text-center mT-20">
          <a href="/" className="button_androidstore186x54">{' '}</a>
        </div>
      </div>
    </section>
    <section className="pY-60 bgc-white">
      <div className="container">
        <div className="text-center mB-70">
          <h4 className="fw-n">Main Features</h4>
          <p className="c-text-gray">Looking for help or consulting? Fill the form below or email us. We will respond you as soon as possible.</p>
        </div>
        <div className="row">
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-security" />
                </div>
                <h5 className="fw-n">Security</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    Rest easy knowing your crypto is secured with
                    the world’s most trusted crypto wallet.
                    We give you full control, back up your funds,
                    and protect them from unauthorized access.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-money-withdraw" />
                </div>
                <h5 className="fw-n">Send &amp; Receive</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    Whether you’re sending to a friend or
                    transacting for business,
                    PayBit allows you to send and
                    receive cryptocurrencies with the lowest fees.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-money-3" />
                </div>
                <h5 className="fw-n">Buy, Sell, &amp; Exchange</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                  Easily exchange crypto-to-crypto within your
                  PayBit Wallet. We now offer buy and sell for users
                  in many countries looking to invest in crypto without
                  leaving your secured wallet experience.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-access" />
                </div>
                <h5 className="fw-n">Access Anywhere</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    Transform the financial system right from your pocket!
                    Your Paybit Wallet is available anywhere - ready to be on the go,
                    just like you are.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-support-light" />
                </div>
                <h5 className="fw-n">Simplified disputes resolution</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    No need to worry about market volatility — you always get what you asked for.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-infomation" />
                </div>
                <h5 className="fw-n">All information on one screen</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    This makes our integration risk and
                    hassle-free for merchants and their business.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
        <div className="row align-items-center justify-content-between mT-50">
          <ReactWOW animation="fadeInLeft">
            <div className="col-md-12 col-lg-6">
              <div className="getting-started-boxs">
                <div className="bdrs-6 bd bg-white getting-started-box box-shadow">
                  <div className="d-flex justify-content-between mB-20">
                    <div>
                      <h5 className="fw-n">Transactions</h5>
                      <p className="c-gray fsz-sm mb-0">Since Last Week</p>
                    </div>
                    <div>
                      <a href="/" className="btn btn-o-green btn-around fw-b">
                        <i className="fas fa-caret-up mR-10" />
                        15%
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <span className="mR-30 c-blue fsz-50">
                      <i className="icon-collect-money-from-group" />
                    </span>
                    <span className="fsz-60 mL-20">+125</span>
                  </div>
                </div>
                <div className="bdrs-6 bd bg-white getting-started-box box-shadow">
                  <div className="d-flex justify-content-between mB-20">
                    <div>
                      <h5 className="fw-n">Impressions</h5>
                      <p className="c-gray fsz-sm mb-0">Since Last Week</p>
                    </div>
                    <div>
                      <a href="/" className="btn btn-o-green btn-around fw-b">
                        <i className="fas fa-caret-up mR-10" />
                        18%
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <span className="mR-30 c-orange fsz-50">
                      <i className="icon-users" />
                    </span>
                    <span className="fsz-60 mL-20">621</span>
                  </div>
                </div>
                <div className="bdrs-6 bd bg-white getting-started-box box-shadow">
                  <div className="d-flex justify-content-between mB-20">
                    <div>
                      <h5 className="fw-n">Income</h5>
                      <p className="c-gray fsz-sm mb-0">Since Last Week</p>
                    </div>
                    <div>
                      <a href="/" className="btn btn-o-red btn-around fw-b">
                        <i className="fas fa-caret-up mR-10" />
                        22%
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <span className="mR-30 c-green fsz-50">
                      <i className="icon-money-withdraw" />
                    </span>
                    <span className="fsz-60 mL-20">4.001</span>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInRight">
            <div className="col-md-12 col-lg-5">
              <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
              <h4 className="fw-n">Getting started is easy.</h4>
              <p className="c-text-gray">
                *Key-in transactions and sales over $500
                in a 7-day period made with the Mobile Card
                Reader are subject to an automatic 30-day
                reserve where funds are held in your Paybit
                account to cover the high risk associated with
                these types of transactions. For increased
                protection from fraudulent transactions,
                we recommend using a chip card reader.
                {' '}
                <br />
                <br />
                All Paybit accounts are subject to policies that can
                lead to account restrictions in the form of holds,
                limitations, or reserves.
              </p>
              <hr className="hr-gray" />
              <div className="mT-30">
                <div className="slider download-customer-slider">
                  <div className="item">
                    <div className="d-flex">
                      <div className="mR-20">
                        <div className="circle-50" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                      </div>
                      <div>
                        <p className="c-gray"><i>“Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</i></p>
                        <p className="c-gray">
                          <b className="fw-n c-black">Bill Johnson</b>
                          {' '}
                          - Customer
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="d-flex">
                      <div className="mR-20">
                        <div className="circle-50" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                      </div>
                      <div>
                        <p className="c-gray"><i>“Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</i></p>
                        <p className="c-gray">
                          <b className="fw-n c-black">Tom Glato</b>
                          {' '}
                          - Customer
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="d-flex">
                      <div className="mR-20">
                        <div className="circle-50" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                      </div>
                      <div>
                        <p className="c-gray"><i>“Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</i></p>
                        <p className="c-gray">
                          <b className="fw-n c-black">Jonathan Banks</b>
                          {' '}
                          - Customer
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
    <section className="bgc-grey pT-50 section-job">
      <div className="container">
        <div className="mT-30 row">
          <Account className="mB-15" />
          <SecurityNPrivacy className="mB-15" />
        </div>
        <div className="mY-40 text-center"><a href="/" className="btn btn-primary btn-around btn-lg">Find out More</a></div>
      </div>
    </section>
    <section className="bgc-white pB-50 section-partner-carrer">
      <div className="container">
        <div className="row justify-content-center video-iframe-carrer">
          <div className="col-md-11 col-lg-10">
            <iframe
              title="this is title"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/3MKj2qVk2yQ?controls=0"
              frameBorder={0}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
        <div className="text-center mB-30">
          <div className="mB-20 fsz-xl">Do the Most Meaningful Work of Your Career</div>
          <p className="c-text-gray fsz-def line-height-2">
            People are at the heart of every connection we build. We design products and deliver
            <br />
            services that bring the world closer together — one connection at a time.
          </p>
        </div>
        <PartnerItems />
      </div>
    </section>
    <section className="section-imgs section-subscribe" style={{ backgroundImage: 'url(/images/subscribe-bg.jpg)' }}>
      <div className="inner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-6">
              <div className="c-white text-center">
                <h2 className="fsz-sup fw-n mB-10">Subscribe Our Newsletter!</h2>
                <p className="c-white-90 mB-20 fsz-md">For get all of our latest updates and promotion</p>
                <div className="form-subscribe">
                  <div className="input-group">
                    <input type="email" className="form-control form_control" placeholder="Enter your email address" />
                    <div className="input-group-append">
                      <button className="btn-primary btn"><i className="icon-right-arrow" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default DownloadAndroidPhone;
