import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import { Tab, Nav } from 'react-bootstrap';
import TinyAreaChart from '../common/Charts/TinyAreaChart';


const ExplorerSearch = () => (
  <>
    <Helmet>
      <title>Paybit - Explorer Search</title>
    </Helmet>
    <section className="banner-explorer-search" style={{ backgroundImage: 'url(/images/explorer-search-banner.jpg)' }}>
      <div className="banner-explorer-inner c-white">
        <div className="container">
          <ul className="breadcrumb breadcrumbs breadcrumbs-white">
            <li><a href="/"><i className="icon-house" /></a></li>
            <li>
              <i className="fas fa-chevron-right" />
              <a href="/">Explorer Search</a>
            </li>
          </ul>
          <ReactWOW animation="fadeInUp">
            <div className="banner-explorer-text">
              <h1 className="fsz-50 fw-n mB-20">Block Explorer</h1>
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Explore Search" />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">Go</button>
                </div>
              </div>
              <p className="c-white-60 fsz-def">Enter any transaction to see the details</p>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
    <section className="bgc-grey">
      <Tab.Container defaultActiveKey="tab_bitcoin">
        <div className="tab-block-explore">
          <div className="bgc-white">
            <div className="container">
              <Nav className="nav nav-tabs tab-lg" id="mainTab" role="tablist">
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_bitcoin" role="tab" aria-selected="true">
                    <span className="circle-32" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                    Bitcoin
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_ethereum" role="tab" aria-selected="false">
                    <span className="circle-32" style={{ backgroundImage: 'url(/images/coins/eth-38.png)' }} />
                    Ethereum
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className="pY-40">
            <Tab.Content as="div" className="tab-content" id="mainTabContent">
              <Tab.Pane className="tab-pane" eventKey="tab_bitcoin" id="tab_bitcoin" role="tabpanel">
                <div className="container">
                  <div className="row row-sm">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mB-15">
                      <div className="bd pY-50 pX-20 text-center bgc-white bdrs-3 hover-boxshadow">
                        <h5 className="fw-n">Price</h5>
                        <p className="fsz-md c-gray mb-0">3.452,26 $</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mB-15">
                      <div className="bd tiny-area-chart-container bgc-white bdrs-3 hover-boxshadow">
                        <div className="chart-small">
                          <TinyAreaChart />
                          <div className="with-background-chart pY-50 pX-20 text-center">
                            <h5 className="fw-n">Price</h5>
                            <p className="fsz-md c-gray mb-0">3.452,26 $</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mB-15">
                      <div className="bd pY-50 pX-20 text-center bgc-white bdrs-3 hover-boxshadow">
                        <h5 className="fw-n">Price</h5>
                        <p className="fsz-md c-gray mb-0">3.452,26 $</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mB-15">
                      <div className="bd pY-50 pX-20 text-center bgc-white bdrs-3 hover-boxshadow">
                        <h5 className="fw-n">Price</h5>
                        <p className="fsz-md c-gray mb-0">3.452,26 $</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mB-15">
                      <div className="bd pY-50 pX-20 text-center bgc-white bdrs-3 hover-boxshadow">
                        <h5 className="fw-n">Price</h5>
                        <p className="fsz-md c-gray mb-0">3.452,26 $</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mB-15">
                      <div className="bd pY-50 pX-20 text-center bgc-white bdrs-3 hover-boxshadow">
                        <h5 className="fw-n">Price</h5>
                        <p className="fsz-md c-gray mb-0">3.452,26 $</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mB-15">
                      <div className="bd pY-50 pX-20 text-center bgc-white bdrs-3 hover-boxshadow">
                        <h5 className="fw-n">Price</h5>
                        <p className="fsz-md c-gray mb-0">3.452,26 $</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mB-15">
                      <div className="bd pY-50 pX-20 text-center bgc-white bdrs-3 hover-boxshadow">
                        <h5 className="fw-n">Price</h5>
                        <p className="fsz-md c-gray mb-0">3.452,26 $</p>
                      </div>
                    </div>
                  </div>
                  <Tab.Container defaultActiveKey="tab_blocks">
                    <div className="mT-15">
                      <div className="bd bgc-white p-50">
                        <Nav className="nav nav-tabs nav-tabs-sm" id="blocks-transactions-tab" role="tablist">
                          <Nav.Item className="nav-item">
                            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_blocks" role="tab" aria-selected="true">
                              Blocks
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="nav-item">
                            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_transactions" role="tab" aria-selected="false">
                              Transactions
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content className="tab-content">
                          <Tab.Pane className="tab-pane" eventKey="tab_blocks" role="tabpanel">
                            <div className="mT-20">
                              <div className="table-responsive">
                                <table className="table table-common table-striped fsz-def">
                                  <thead>
                                    <tr className="whs-nw">
                                      <th className="bdn"><span className="c-text-gray">Height</span></th>
                                      <th className="bdn"><span className="c-text-gray">Older</span></th>
                                      <th className="bdn"><span className="c-text-gray">Transactions</span></th>
                                      <th className="bdn"><span className="c-text-gray">Miner</span></th>
                                    </tr>
                                  </thead>
                                  <tbody className="pX-20">
                                    <tr>
                                      <td><b className="fw-n c-orange">252124</b></td>
                                      <td><b className="fw-n">11 Minutes</b></td>
                                      <td><b className="fw-n">516</b></td>
                                      <td><b className="fw-n c-orange">dpool</b></td>
                                    </tr>
                                    <tr>
                                      <td><b className="fw-n c-orange">412622</b></td>
                                      <td><b className="fw-n">22 Minutes</b></td>
                                      <td><b className="fw-n">516</b></td>
                                      <td><b className="fw-n c-orange">Unknown</b></td>
                                    </tr>
                                    <tr>
                                      <td><b className="fw-n c-orange">252124</b></td>
                                      <td><b className="fw-n">11 Minutes</b></td>
                                      <td><b className="fw-n">516</b></td>
                                      <td><b className="fw-n c-orange">AntPool</b></td>
                                    </tr>
                                    <tr>
                                      <td><b className="fw-n c-orange">252124</b></td>
                                      <td><b className="fw-n">11 Minutes</b></td>
                                      <td><b className="fw-n">516</b></td>
                                      <td><b className="fw-n c-orange">Unknown</b></td>
                                    </tr>
                                    <tr>
                                      <td><b className="fw-n c-orange">252124</b></td>
                                      <td><b className="fw-n">11 Minutes</b></td>
                                      <td><b className="fw-n">516</b></td>
                                      <td><b className="fw-n c-orange">dpool</b></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="mT-40 text-center"><a href="/" className="btn btn-primary btn-around btn-lg">See More</a></div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane className="tab-pane" eventKey="tab_transactions" role="tabpanel" />
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane fade" eventKey="tab_ethereum" role="tabpanel">.2..</Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </section>
    <section className="bgc-white pY-70">
      <div className="container">
        <h2 className="mY-50 text-center text-before-decor">Explore More With Digital Currencies</h2>
        <p className="c-text-gray">PBIT is the native cryptocurrency token of PayBit. It is an ERC-20-compatible token and can be securely stored in a regular Ethereum wallet and managed with MetaMask, Ledger, Trezor and other wallet clients. PBIT can be used to pay fees within the system and will have other applications later as well. PBIT total supply is 1,000,000,000 PBIT.</p>
        <ReactWOW animation="fadeInUp">
          <div className="row pT-50">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="explore-item box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-30">
                  <i className="icon-wallet-3" />
                </div>
                <h5 className="fw-n">Wallet</h5>
                <p className="c-gray fsz-def line-height-2">Use your Paybit Wallet to buy, sell, trade and trade Bitcoin, Ether and Bitcoin Cash..</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  Get a wallet
                    <i className="icon-right-arrow mL-10" />
                  </b>

                </a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="explore-item box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-30">
                  <i className="icon-api-1" />
                </div>
                <h5 className="fw-n">API</h5>
                <p className="c-gray fsz-def line-height-2">Build apps to accept bitcoin payments, search for bitcoin transactions, access live bitcoin data, and more..</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  Visit the API
                    <i className="icon-right-arrow mL-10" />
                  </b>

                </a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="explore-item box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-30">
                  <i className="icon-chart-pie" />
                </div>
                <h5 className="fw-n">Markets</h5>
                <p className="c-gray fsz-def line-height-2">Find the market data of the top crypto currencies, including price charts and price search, market capitalization, and return on investment.</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  Visit markets
                    <i className="icon-right-arrow mL-10" />
                  </b>

                </a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="explore-item box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-30">
                  <i className="icon-chart" />
                </div>
                <h5 className="fw-n">Charts</h5>
                <p className="c-gray fsz-def line-height-2">View bitcoin charts for historical data on unconfirmed transactions, market prices, and more.</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  Visit diagrams
                    <i className="icon-right-arrow mL-10" />
                  </b>

                </a>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
    </section>
  </>
);

export default ExplorerSearch;
