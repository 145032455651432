import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';
import { Tab, Nav } from 'react-bootstrap';


const WhatIsBitcoin = () => (
  <>
    <Helmet>
      <title>Paybit - What Is Bitcoin</title>
    </Helmet>
    <section className="banner-training-portal" style={{ backgroundImage: 'url(/images/traning-portal-banner.jpg)' }}>
      <div className="banner-training-portal-inner c-white">
        <div className="container">
          <ul className="breadcrumb breadcrumbs breadcrumbs-white mT-40">
            <li><a href="/"><i className="icon-house" /></a></li>
            <li>
              <i className="fas fa-chevron-right" />
              <a href="/what-is-bitcoin">What is Bitcoin</a>
            </li>
          </ul>
          <ReactWOW animation="fadeInUp">
            <div className="banner-training-portal-text">
              <h1 className="fsz-50 fw-n">Training Portal</h1>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
    <section className="bgc-grey">
      <Tab.Container defaultActiveKey="tab_bitcoin">
        <div className="tab-what-bitcoin">
          <div className="bgc-white">
            <div className="container">
              <Nav className="nav nav-tabs" id="blocks-transactions-tab" role="tablist">
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_bitcoin" role="tab" aria-selected="true">
                    Bitcoin for Beginners
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_1" role="tab" aria-selected="false">
                    Ether for Beginners
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_2" role="tab" aria-selected="false">
                    Get Bitcoin
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_3" role="tab" aria-selected="false">
                    Get Started Ether
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_4" role="tab" aria-selected="false">
                    Wallet
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_5" role="tab" aria-selected="false">
                    Always Safe
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_6" role="tab" aria-selected="false">
                    Techinical Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_7" role="tab" aria-selected="false">
                    Reseach
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className="pY-40">
            <Tab.Content as="div" className="tab-content" id="mainTabContent">
              <Tab.Pane as="div" className="tab-pane" eventKey="tab_bitcoin" role="tabpanel">
                <div className="container">
                  <h2 className="mY-50 text-center text-before-decor">Bitcoin for Beginners</h2>
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-lg-4 mB-20">
                      <div className="pX-40 pY-30 bd bgc-white text-center-sm">
                        <div className="c-blue fsz-50 mT-20 mB-30">
                          <i className="icon-bittcoin-circle" />
                        </div>
                        <h5 className="fw-n">Bitcoin</h5>
                        <p className="c-gray fsz-def line-height-2">
                            The decentralized network,
                            enables direct peer-to-peer
                            transactions without a balance-manageable middleman.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mB-20">
                      <div className="pX-40 pY-30 bd bgc-white text-center-sm">
                        <div className="c-blue fsz-50 mT-20 mB-30">
                          <i className="icon-shopping-bag-check" />
                        </div>
                        <h5 className="fw-n">Digital Asset</h5>
                        <p className="c-gray fsz-def line-height-2">Like other assets, is used in exchange for goods and services. Unlike traditional currencies and assets, Bitcoin is nonlocal, divisible and irreversible.</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mB-20">
                      <div className="pX-40 pY-30 bd bgc-white text-center-sm">
                        <div className="c-blue fsz-50 mT-20 mB-30">
                          <span className="d-flex align-items-center d-sm-inline-block">
                            <i className="c-grey icon-effect mR-10" />
                            <i className="icon-bittcoin-circle" />
                          </span>
                        </div>
                        <h5 className="fw-n">Bitcoin increases</h5>
                        <p className="c-gray fsz-def line-height-2">System efficiency and enables the provision of financial services at drastically reduced costs, giving users greater powers and more freedom.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_1">
                Ether for Beginners
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_2">
                Get Bitcoin
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_3">
                Get Started Ether
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_4">
                Wallet
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_5">
                Always Safe
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_6">
                Techinical Details
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="tab_7">
                Reseach
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
        <div className="box-sign-up-today-container">
          <div className="container">
            <div className="bd bgc-white d-flex justify-content-between align-items-center box-sign-up-today">
              <h2 className="m-0">Get the world&lsquo;s most popular digital currency wallet</h2>
              <div><a href="/" className="btn btn-primary btn-lg btn-around">Sign Up Today</a></div>
            </div>
          </div>
        </div>
      </Tab.Container>
    </section>
    <section className="bgc-white pY-70">
      <div className="container">
        <ReactWOW animation="fadeInUp">
          <div className="row pT-50 bitcoin-for-beginer-list">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="explore-item box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-30">
                  <i className="icon-bittcoin-circle" />
                </div>
                <h5 className="fw-n">Five Years</h5>
                <p className="c-gray fsz-def line-height-2">For the last four out of five years, Bitcoin has been awarded the highest performing currency rating</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="explore-item box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-30">
                  <i className="icon-hand-shake" />
                </div>
                <h5 className="fw-n">Since Bitcoin</h5>
                <p className="c-gray fsz-def line-height-2">
                  Since Bitcoin is an international currency,
                  it can be sent to any recipient worldwide without
                  the need to consider cross-border transfer fees.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="explore-item box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-30">
                  <i className="icon-safe-money" />
                </div>
                <h5 className="fw-n">Keeping your Bitcoin</h5>
                <p className="c-gray fsz-def line-height-2">Securely in a self-managed wallet (like Blockchain&lsquo;s) means that no one instance can block your access to your funds.</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="explore-item box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-30">
                  <i className="icon-global-1" />
                </div>
                <h5 className="fw-n">It&lsquo;s inclusive worldwide</h5>
                <p className="c-gray fsz-def line-height-2">Bitcoin enables millions of users around the world to conduct transactions, make savings, and hedge their way to a better financial future.</p>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
    </section>
  </>
);

export default WhatIsBitcoin;
