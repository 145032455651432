import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import IllustrationSlider from './IllustrationSlider';
import WeGotCoveredSlider from '../common/Sliders/WeGotCoveredSlider';
import SectionPartner from '../common/SectionPartner';
import Offer from '../common/LangdingSections/Offer';
import StartUsing from '../common/LangdingSections/StartUsing';
import DownloadApp from '../common/LangdingSections/DownloadApp';
import ProductTabs from '../common/ProductTabs';

const LandingPageIllustration = () => (
  <>
    <Helmet>
      <title>Paybit - Landing Page Illustration</title>
    </Helmet>
    <IllustrationSlider />
    <div className="landing-page-illustration-bg">
      <ReactWOW animation="fadeInLeft">
        <div className="shape shape-1" />
      </ReactWOW>
      <ReactWOW animation="fadeInLeft">
        <div className="icons icons-1" />
      </ReactWOW>
      <ReactWOW animation="fadeInRight">
        <div className="shape shape-2" />
      </ReactWOW>
      <section className="pB-70 section-solution section-solution-2">
        <div className="container">
          <div className="mT-50">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <ReactWOW animation="slideInUp">
                      <div className="mB-50 box-shadow-mb">
                        <p className="c-gray text-uppercase">benefits</p>
                        <h3 className="mB-20"><b className="fw-n">Get everything you need</b></h3>
                        <div className="line-height-2 c-text-gray fsz-def">
                          <p>
                            Shop with peace of mind, knowing we protect your eligible purchases.
                            If an eligible item doesn&lsquo;t show up,
                            or turns out to be different than described,
                            we’ll help sort things out with the seller. Conditions apply.
                          </p>
                          <br />
                          <p>
                            We offer a Buyer and Seller Protection system.
                            Payments can be refund up to 180 days,
                            based on what is sold. Nobody needs to worry to get problems,
                            read our Buyer and Protection details for more information.
                          </p>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <ReactWOW animation="slideInUp" delay="0.1s">
                      <div className="mB-40 box-shadow-mb">
                        <p className="c-gray text-uppercase">simple</p>
                        <h3 className="mB-20"><b className="fw-n">We got you covered</b></h3>
                        <div className="line-height-2 c-text-gray fsz-def">
                          <p>
                          Buyers make purchases on merchants’
                          websites and in merchants’ Apps with Paybit.
                          Paybit will deduct the amount of a payment from the buyer&lsquo;s
                          Paybit account in real-time in CNY
                          and settle the payment to the merchant in a Crypto currency.
                          </p>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-7">
                <div className="row">
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="fas fa-dollar-sign" />
                        </div>
                      </div>
                      <h4><b className="fw-n">Available Currencies</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">We support every crypto currency possible. From BTC to ETH, from EON to NEO. Currencys are converted automaticly.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="icon-transfer-in-second" />
                        </div>
                      </div>
                      <h4><b className="fw-n">Transfer in Secound</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">Payments can&lsquo;t get better. Pay anyone with just one simple click. Everything is done in lightspeed.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="icon-security" />
                        </div>
                      </div>
                      <h4><b className="fw-n">Mighty Protection</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">You dont need to worry. With our PayBit Buyer Protection. you have not to worry about any problems.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="icon-hand-pointer" />
                        </div>
                      </div>
                      <h4><b className="fw-n">Easy to Use</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">PayBit is simple. You can pay with just a bit. After signup, a short verification is needed. After that you can start paying and selling.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="icon-headphone" />
                        </div>
                      </div>
                      <h4><b className="fw-n">24/7 Support</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">Our large staff is always there when needed. No matter how big or small your problem is we will do our best to solve everything.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="icon-organized-payments" />
                        </div>
                      </div>
                      <h4><b className="fw-n">Organized Payments</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">With our simple but yet mighty Dashboard organizing payments has reached a new level Everything needed for both business and normal clients</p>
                    </div>
                  </ReactWOW>
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-gray" />
          <div className="text-center mB-40">
            <h4><b className="fw-n">Paybit Solutions for Online, Mobile and In-Store</b></h4>
            <p className="fsz-def">Get started now with your bitcoin wallet. Monetize your virtual currency payments</p>
          </div>
          <div className="row justify-content-center">
            <ReactWOW animation="bounceInLeft" duration="1s" delay="0.4s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-mobile" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5><b className="fw-n">Mobile</b></h5>
                    <p className="fsz-def">Add Paybit to Your Mobile Site and App</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="bounceInUp" duration="1s" delay="0.6s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-pc" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5><b className="fw-n">PC</b></h5>
                    <p className="fsz-def">Add Paybit to Your Website</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="bounceInRight" duration="1s" delay="0.8s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-shop" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5><b className="fw-n">Store</b></h5>
                    <p className="fsz-def">Accept Paybit in Your Store</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>
    </div>
    <ReactWOW animation="fadeInUp">
      <section className="pT-70 bgc-grey great-success">
        <div className="container">
          <div className="row justify-content-between">
            <ReactWOW animation="slideInUp" duration="0.5s">
              <div className="col-md-12 col-lg-5">
                <div className="mT-50">
                  <p className="c-gray text-uppercase">fast</p>
                  <h3 className="mB-20"><b className="fw-n">Payment in a few clicks</b></h3>
                  <div className="line-height-2 c-text-gray fsz-def mB-40">
                    <p>
                      Millions of customers around the world use us for
                      one simple reason: it’s simple.
                      Just an email address and password will get you through to
                      checkout before you can reach for your wallet.
                    </p>
                    <br />
                    <div>
                      <a href="/" className="c-black">Pay on Websites</a>
                      <a href="/" className="c-black">Pay multiple Clients/Customers</a>
                    </div>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <div className="col-md-12 col-lg-6">
              <div className="row no-gutters">
                <ReactWOW animation="bounceInLeft">
                  <div className="col-4 col-md-4 mT-50">
                    <div className="circle-163 m-auto mB-15">
                      <span className="c-blue">
                        <i className="icon-hand-pointer" />
                      </span>
                    </div>
                  </div>
                </ReactWOW>
                <ReactWOW animation="bounceIn">
                  <div className="col-3 col-md-3 mT-50 text-center">
                    <div className="dot-line">
                      <i className="fas fa-circle" />
                      <i className="fas fa-circle" />
                      <i className="fas fa-circle" />
                      <i className="fas fa-circle" />
                      <i className="fas fa-circle" />
                      <i className="fas fa-circle" />
                    </div>
                  </div>
                </ReactWOW>
                <ReactWOW animation="bounceInRight">
                  <div className="col-4 col-md-5">
                    <div className="circle-163 active m-auto">
                      <span className="c-blue">
                        <i className="icon-paybit" />
                      </span>
                      <i className="fas fa-check-circle c-green" />
                    </div>
                    <p className="text-center mT-20">
    Great sucess you made
                      <br />
    the payment!
                    </p>
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ReactWOW>
    <ProductTabs />
    <WeGotCoveredSlider />
    <section className="pT-70 bgc-grey">
      <div className="container">
        <div className="row">
          <ReactWOW animation="fadeInLeft">
            <div className="col-md-12 col-lg-6 box-hover text-center-md">
              <div className="d-flex d-mb-inline-block mB-40 box-shadow-mb">
                <div className="mR-30 mX-auto-md mB-40">
                  <div className="circle-80 m-auto">
                    <span className="c-blue">
                      <i className="icon-moneys1" />
                    </span>
                  </div>
                </div>
                <div>
                  <h5 className="mB-15"><b className="fw-n">Send money online or with your mobile.</b></h5>
                  <p className="fsz-def c-text-gray">Send money to almost anyone with an email address. You can send all around the world and they&lsquo;ll be delighted to get your message. Currency conversion fees may apply.</p>
                  <br />
                  <a href="/" className="c-black">
                    <b className="fw-n">Start Selling </b>
                    <i className="icon-right-arrow mL-10" />
                  </a>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInRight">
            <div className="col-md-12 col-lg-6 box-hover">
              <div className="d-flex d-mb-inline-block mB-40 box-shadow-mb">
                <div className="mR-30 mX-auto-md mB-40">
                  <div className="circle-80 m-auto">
                    <span className="c-blue">
                      <i className="icon-cart1" />
                    </span>
                  </div>
                </div>
                <div>
                  <h5 className="mB-15"><b className="fw-n">Shop around the world.</b></h5>
                  <p className="fsz-def c-text-gray">With PayBit you can shop at thousands of stores around the world in just a few easy clicks, knowing your card details are never shared with the seller. Currency conversion fees may apply.</p>
                  <br />
                  <a href="/" className="c-black">
                    <b className="fw-n">Start Shopping </b>
                    <i className="icon-right-arrow mL-10" />
                  </a>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
        <div className="text-center mT-30">
          <ReactWOW animation="bounceInUp">
            <div className="m-auto mockup-mobile" style={{ backgroundImage: 'url(/images/mockup-phone-paybit.png)' }} />
          </ReactWOW>
        </div>
      </div>
    </section>
    <section className="pT-60 bgc-dark bg-dark-phone-login">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-11 col-xl-10">
            <div className="d-flex">
              <div className="d-flex d-md-inline-block">
                <div className="mockup-mobile-login" style={{ backgroundImage: 'url(/images/mockup-phone-paybit-login.png)' }} />
                <ReactWOW animation="fadeInRight">
                  <div className="c-white wallet-right pY-70 pL-50">
                    <h1 className="mB-20">#1 Wallet</h1>
                    <p className="fsz-def c-text-gray line-height-2 mB-20">With over 28 million wallets, $200B transacted, and the lowest fees in the industry, it’s no wonder we’re the most trusted cryptocurrency wallet.</p>
                    <ul className="d-flex list-unstyled d-md-inline-block mB-0 w-100">
                      <li className="mR-20 mB-10 d-flex align-items-center float-left">
                        <div className="circle-40 bgc-white mR-15"><i className="c-blue fsz-md icon-security" /></div>
                        Security
                      </li>
                      <li className="mR-20 mB-10 d-flex align-items-center float-left">
                        <div className="circle-40 bgc-white mR-15"><i className="c-blue fsz-sm icon-arrow-double" /></div>
                        Send &amp; Receive
                      </li>
                      <li className="mR-20 mB-10 d-flex align-items-center float-left">
                        <div className="circle-40 bgc-white mR-15"><i className="c-blue fsz-md fas fa-dollar-sign" /></div>
                        Buy, Sell, &amp; Exchange
                      </li>
                    </ul>
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ReactWOW animation="fadeInUp">
      <section className="pY-70 bgc-white">
        <div className="container">
          <div className="row mB-50">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <h4><b className="fw-n">Your money is on its way in a few clicks.</b></h4>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <p className="fsz-def c-text-gray">
                We offer a Buyer and Seller Protection system.
                Payments can be refund up to 180 days,
                based on what is sold. Nobody needs to worry to get problems,
                read our Buyer and Protection details for more information.
              </p>
              <br />
              <a href="/" className="c-black">
                <b className="fw-n">Get Started </b>
                <i className="icon-right-arrow mL-10" />
              </a>
            </div>
          </div>
          <div className="row">
            <ReactWOW animation="slideInUp" duration="0.2s">
              <div className="col-12 col-md-6 col-lg-3 mB-15 text-center hover-opacity box-shadow-mb">
                <div className="img-84x57 m-auto mB-10" style={{ backgroundImage: 'url(/images/millions-of-payments-processed.png)' }} />
                <h6 className="fsz-def mB-15"><b className="fw-n">Millions of payments processed</b></h6>
                <p className="fsz-sm c-gray">Milion of customer around the world use us for one simple reason</p>
              </div>
            </ReactWOW>
            <ReactWOW animation="slideInUp" duration="0.2s" delay="0.1s">
              <div className="col-12 col-md-6 col-lg-3 mB-15 text-center hover-opacity box-shadow-mb">
                <div className="img-84x57 m-auto mB-10" style={{ backgroundImage: 'url(/images/and-even-more-clients.png)' }} />
                <h6 className="fsz-def mB-15"><b className="fw-n">And even more clients</b></h6>
                <p className="fsz-sm c-gray">We’ll help sort things out with the seller. Conditions apply.</p>
              </div>
            </ReactWOW>
            <ReactWOW animation="slideInUp" duration="0.2s" delay="0.2s">
              <div className="col-12 col-md-6 col-lg-3 mB-15 text-center hover-opacity box-shadow-mb">
                <div className="img-84x57 m-auto mB-10" style={{ backgroundImage: 'url(/images/about-5000-api-integ.png)' }} />
                <h6 className="fsz-def mB-15"><b className="fw-n">About 5000 API integrations</b></h6>
                <p className="fsz-sm c-gray">Over 500.000 API Integrations</p>
              </div>
            </ReactWOW>
            <ReactWOW animation="slideInUp" duration="0.2s" delay="0.3s">
              <div className="col-12 col-md-6 col-lg-3 mB-15 text-center hover-opacity box-shadow-mb">
                <div className="img-84x57 m-auto mB-10" style={{ backgroundImage: 'url(/images/monitoring.png)' }} />
                <h6 className="fsz-def mB-15"><b className="fw-n">Monitoring</b></h6>
                <p className="fsz-sm c-gray">With a few clicks you can generate and view hundreads of payments.</p>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>
    </ReactWOW>
    <section className="pB-25 section-features">
      <div className="container">
        <div className="bd bgc-grey p-50">
          <div className="text-center mB-50">
            <h3 className="mB-20"><b className="fw-n">We have more stuff and features to offer for you</b></h3>
            <p className="c-text-gray fsz-def">We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold. Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-multiple-payments" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Send multiple Payments</b></h6>
                  <p className="c-text-gray">
                    Payments can not get simpler! You can pay multiply clients,
                    affiliates or customers at once with one simple click.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-all-money" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Tap into all your money.</b></h6>
                  <p className="c-text-gray">
                    Use our mobile tools and panels to have
                    always a overview of your business and money.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-developers-api" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Multiple developers APIs</b></h6>
                  <p className="c-text-gray">
                    We have solutions for a big variety of
                    ecommerce systems and many developer APIs for each possible solution.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-security" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">You are secured</b></h6>
                  <p className="c-text-gray">
                    We offer a Buyer and Seller Protection system.
                    Payments can be refund up to 180 days,
                    based on what is sold. Nobody needs to worry to get problems,
                    read our Buyer and Protection details.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-overview" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Send money online or with your mobile</b></h6>
                  <p className="c-text-gray">
                    Use our mobile tools and panels to have
                    always a overview of your business and money.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-time" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Transfer in Secounds</b></h6>
                  <p className="c-text-gray">
                    Payments can&lsquo;t get better.
                    Pay anyone with just one simple click.
                    Everything is done in lightspeed.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-money-10 fsz-def" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Send and request money fast.</b></h6>
                  <p className="c-text-gray">
                      It’s free to send money to friends and family in the U.S.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-manage-money-easily" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Manage your money easily.</b></h6>
                  <p className="c-text-gray">
                    The improved Paybit app makes it simple to send and request money,
                    manage credit, pool cash from peers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex mT-40">
                <div className="c-blue fsz-lg mR-20">
                  <i className="icon-collect-money-from-group" />
                </div>
                <div>
                  <h6 className="mB-20 fsz-def"><b className="fw-n">Collect money from groups.</b></h6>
                  <p className="c-text-gray">
                    Whether you&lsquo;re collecting money for group
                    gifts, travel, or special events.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <StartUsing />
    <Offer />
    <DownloadApp />
    <SectionPartner />
    <ReactWOW animation="fadeInUp">
      <section className="section-download-app">
        <div className="container text-center c-white">
          <h2 className="mB-20"><b className="fw-n">Your money is on its way in a few clicks.</b></h2>
          <div className="mB-30"><a href="/" className="btn-light btn-lg bdrs-0 btn-signup"><b className="fw-n">Sign Up</b></a></div>
          <h2 className="fsz-xl mB-30"><b className="fw-n">Our Apps</b></h2>
          <div>
            <a href="/" className="button_appstore m-5">{' '}</a>
            <a href="/" className="button_androidstore m-5">{' '}</a>
          </div>
        </div>
      </section>
    </ReactWOW>
  </>
);

export default LandingPageIllustration;
