import React from 'react';
import { compose } from 'redux';
import { Tab, Nav } from 'react-bootstrap';
import { withAuth, withLogin } from '../../../hocs/withAuth';

export const SignUpTabs = ({ toggle }) => (
  <Tab.Container defaultActiveKey="tab_Personal">
    <Nav className="nav nav-tabs" id="signupTab" role="tablist" navbar={false}>
      <Nav.Item className="nav-item">
        <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_Personal" role="tab" aria-selected="true">
          <i className="icon-user_circle_bold mR-10" />
                    Personal
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className="nav-item">
        <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_Business" role="tab" aria-selected="false">
          <i className="icon-businesses mR-10" />
                    Business
        </Nav.Link>
      </Nav.Item>
    </Nav>
    <div className="tab-content">
      <Tab.Pane className="tab-pane fade" eventKey="tab_Personal">
        <form action="#">
          <div className="sign-up-tab">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                {/* <h5 class="c-grey fsz-def tt-u"><b class="fw-n">JOIN OVER 2,000 PAYBIT USERS</b></h5> */}
                {/* <h1 class="mB-30">Create Your Account</h1> */}
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-user" />
                        <input type="text" className="form-control form_control" placeholder="First Name" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-user" />
                        <input type="text" className="form-control form_control" placeholder="Last Name" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="fas fa-map-marker-alt" />
                        <input type="text" className="form-control form_control" placeholder="Your Street" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-postalcode" />
                        <input type="text" className="form-control form_control" placeholder="Postalcode" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-province" />
                        <input type="text" className="form-control form_control" placeholder="Province" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="fas fa-map-marker-alt" />
                        <input type="text" className="form-control form_control" placeholder="City" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <input type="number" className="form-control form_control" placeholder="dd" />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <input type="number" className="form-control form_control" placeholder="mm" />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <input type="number" className="form-control form_control" placeholder="yyyy" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-global" />
                        <select className="form-control form_control">
                          <option value>Select your country</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-mail" />
                        <input type="email" className="form-control form_control" placeholder="Enter your email address" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-lock" />
                        <input type="password" className="form-control form_control" placeholder="*********" />
                      </div>
                      <small className="form-group-desc fsz-sm c-gray">min 8 characters,at least one number, one lowercase and one uppercase letter</small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group password-strength">
                      <div className="input-has-icon">
                        <i className="icon-lock" />
                        <input type="password" className="form-control form_control password-metter" placeholder="*********" name="password" />
                      </div>
                      <div className="password-strength-bar">
                        <div className="progress progress-striped password-strength-meter">
                          <div className="progress-bar pw-bar" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} style={{ width: '0%' }} />
                        </div>
                        <div className="password-strength-text">Worst</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-telephone" />
                        <input type="text" className="form-control form_control" placeholder="Enter your phone number" />
                      </div>
                      <small className="form-group-desc fsz-sm c-gray">e.g. 998 93 524 95 06Using for notifications only, never for spam</small>
                    </div>
                  </div>
                </div>
                <div className="row mT-15">
                  <div className="col-12">
                    <div className="form-group">
                      <input className="checkbox-around" id="checkbox-1" name="checkbox" type="checkbox" />
                      <label htmlFor="checkbox-1" className="c-gray">
                              By clicking you confirm and agree that you have read,
                              consent and agree to
                        {' '}
                        <a href="/#">PayBits User Agreement</a>
                        {' '}
                                and
                        {' '}
                        <a href="/#">Privacy Policy</a>
                        {' '}
                                , and that you are of legal age.
                                You confirm that you can change your notification settings
                                at any time in your PayBit Account.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mT-10">
              <button type="submit" className="btn btn-lg btn-around btn-primary btn-submit">Create an Account</button>
            </div>
            <div className="text-center mT-10 fsz-def c-grey">
              You already have account ?
              <a href="sign-in" className="td-u c-black">
                <b className="fw-n">Sign In</b>
              </a>
            </div>
          </div>
        </form>
      </Tab.Pane>
      <Tab.Pane className="tab-pane fade" eventKey="tab_Business">
        <form action="#">
          <div className="sign-up-tab">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                {/* <h5 className="c-grey fsz-def tt-u"><b className="fw-n">JOIN OVER 2,000 PAYBIT USERS</b></h5> */}
                {/* <h1 className="mB-30">Create Your Account</h1> */}
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input type="text" className="form-control form_control" placeholder="Company Name" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <select className="form-control form_control">
                        <option value>Choose Business Category</option>
                        <option value>Choose Business Category</option>
                        <option value>Choose Business Category</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input type="text" className="form-control form_control" placeholder="Business Address" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-user" />
                        <input type="text" className="form-control form_control" placeholder="First Name" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-user" />
                        <input type="text" className="form-control form_control" placeholder="Last Name" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="fas fa-map-marker-alt" />
                        <input type="text" className="form-control form_control" placeholder="Your Street" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-postalcode" />
                        <input type="text" className="form-control form_control" placeholder="Postalcode" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-province" />
                        <input type="text" className="form-control form_control" placeholder="Province" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="fas fa-map-marker-alt" />
                        <input type="text" className="form-control form_control" placeholder="City" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-mail" />
                        <input type="email" className="form-control form_control" placeholder="Enter your email address" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-lock" />
                        <input type="password" className="form-control form_control" placeholder="*********" />
                      </div>
                      <small className="form-group-desc fsz-sm c-gray">min 8 characters,at least one number, one lowercase and one uppercase letter</small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group password-strength">
                      <div className="input-has-icon">
                        <i className="icon-lock" />
                        <input type="password" className="form-control form_control password-metter" placeholder="*********" name="password" />
                      </div>
                      <div className="password-strength-bar">
                        <div className="progress progress-striped password-strength-meter">
                          <div className="progress-bar pw-bar" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} style={{ width: '0%' }} />
                        </div>
                        <div className="password-strength-text">Worst</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="input-has-icon">
                        <i className="icon-telephone" />
                        <input type="text" className="form-control form_control" placeholder="Enter your phone number" />
                      </div>
                      <small className="form-group-desc fsz-sm c-gray">e.g. 998 93 524 95 06Using for notifications only, never for spam</small>
                    </div>
                  </div>
                </div>
                <div className="row mT-15">
                  <div className="col-12">
                    <div className="form-group">
                      <input className="checkbox-around" id="checkbox-1" name="checkbox" type="checkbox" />
                      <label htmlFor="checkbox-1" className="c-gray">
                                By clicking you confirm and agree that you have read,
                                consent and agree to

                        <a href="/#">
                          {' '}
PayBits User Agreement
                          {' '}
                        </a>
                                and
                        <a href="/#">
                          {' '}
Privacy Policy
                          {' '}
                        </a>

                              , and that you are of legal age. You confirm that you can change your
                              notification settings at any time in your PayBit Account.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mT-30">
              <button type="submit" className="btn btn-lg btn-around btn-primary btn-submit">Create an Account</button>
            </div>
          </div>
        </form>
      </Tab.Pane>
    </div>
  </Tab.Container>
);
export default compose(
  withAuth,
  withLogin,
)(SignUpTabs);
