import React from 'react';
import ReactWOW from 'react-wow';
import NoneRedirectA from '../NoneRedirectA';

const DownloadApp = () => (
  <section className="container-fluid">
    <div className="row">
      <div className="col-md-12 col-lg-6 bgc-blue">
        <ReactWOW animation="slideInUp">
          <div className="pX-60 pB-40 text-right pX-sm-0">
            <div className="text-center download-app-ios  ml-auto">
              <div className="mockup-ios-mobile mB-50" style={{ backgroundImage: 'url(/images/download-app-mockup.png)' }} />
              <h3 className="c-white mB-20"><b className="fw-n">Download for App Store</b></h3>
              <p className="c-white-60 mB-40">Save unlimited web images, videos, and text from the web with Cloud.co Save</p>
              <NoneRedirectA href="/" className="button_appstore">{' '}</NoneRedirectA>
            </div>
          </div>
        </ReactWOW>
      </div>
      <div className="col-md-12 col-lg-6">
        <ReactWOW animation="slideInUp">
          <div className="pX-60 pT-50 pX-sm-0">
            <div className="text-center download-app-ios  mr-auto">
              <h3 className="mB-30"><b className="fw-n">Download for Google Play</b></h3>
              <p className="fsz-def c-text-gray mB-25">Access all your photos, videos and documents anywhere, at any time, from any device</p>
              <NoneRedirectA href="/" className="button_androidstore">{' '}</NoneRedirectA>
              <div className="mockup-ios-mobile mT-50" style={{ backgroundImage: 'url(/images/download-app-android-mockup.png)' }} />
            </div>
          </div>
        </ReactWOW>
      </div>
    </div>
  </section>
);

export default DownloadApp;
