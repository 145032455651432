import React from 'react';
import classNames from 'classnames';

const Paginate = ({
  pagination: {
    page,
    showingPages,
    totalPages,
  },
  isShowAll,
  changePage,
}) => {
  if (isShowAll || totalPages < 2) return null;
  return (
    <div className="mT-30">
      <nav className="nav-paging">
        <ul className="pagination justify-content-center">
          {page > 1 && (
            <li className={classNames('page-item', {
              disabled: page < 2,
            })}
            >
              <a className="page-link" onClick={() => changePage(0)} tabIndex={-1}><i className="fas fa-chevron-left" /></a>
            </li>
          )}
          {showingPages.map(pageIndex => (
            <li
              className={classNames('page-item', {
                active: pageIndex === page,
              })}
              key={pageIndex}
            >
              <a className="page-link" onClick={() => changePage(pageIndex)}>
                {pageIndex}
              </a>
            </li>
          ))}
          {page < totalPages
          && (
            <li className="page-item">
              <a className="page-link" onClick={() => changePage(totalPages)}><i className="fas fa-chevron-right" /></a>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};
export default Paginate;
