import React from 'react';
import { Tab, Nav, Dropdown } from 'react-bootstrap';
// import PriceGraph from '../common/Charts/PriceGraph';
import {
  compose, lifecycle, withState, mapProps,
} from 'recompose';
import { map, take } from 'lodash';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import CurrentCryptoPriceGrapht from '../common/Charts/CurrentCryptoPriceGrapht';
import { pricesSelector } from '../../selectors/coinsSelector';
import { fetchPrices } from '../../actions/coinsAction';
import { cssURLCoinImg } from '../../utils/apiDataHelper';

const Marketplace = ({
  prices, setSymbol, symbol,
  price: {
    slug,
    coinName,
  } = {},
}) => (
  <section className="bgc-grey pY-30 bdT">
    <Helmet>
      <title>Paybit - Marketplace</title>
    </Helmet>
    <div className="container">
      <ul className="breadcrumb breadcrumbs mB-40">
        <li><a href="/"><i className="icon-house" /></a></li>
        <li>
          <i className="fas fa-chevron-right" />
          <span>Marketplace</span>
        </li>
      </ul>
      <div className="marketplace-content mB-40">
        <div className="marketplace-search d-flex justify-content-between d-md-inline-block w-100 bd bgc-white mB-10">
          <div className="d-flex d-md-inline-block w-100 mB-5">
            <div className="d-flex align-items-center">
              <Dropdown className="dropdown dropdown-coin">
                <div className="coin-header">
                  <span className="circle coin-icon coin-icon-20" style={{ backgroundImage: cssURLCoinImg(slug) }} />
                  <span>{coinName}</span>
                </div>
                <Dropdown.Toggle as="button" className="btn dropdown-toggle no-after" type="button" id="dropdownBTC" data-toggle="dropdown">
                  <i className="fas fa-chevron-down" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu" aria-labelledby="dropdownBTC" x-placement="top-start">
                  {map(take(prices, 7), p => (
                    <Dropdown.Item as="a" className="dropdown-item" onClick={() => setSymbol(p.symbol)}>
                      <span className="circle coin-icon coin-icon-20" style={{ backgroundImage: cssURLCoinImg(p.slug) }} />
                      {p.coinName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <div className="mX-10 c-grey fsz-lg"><i className="icon-exchage" /></div>
              <div className="dropdown dropdown-coin">
                <div className="coin-header">
                  <span className="circle coin-icon-20" style={{ backgroundImage: 'url(/images/circle-dollar.png)' }} />
                  <span>USD</span>
                </div>
                <button className="btn dropdown-toggle no-after" type="button" id="dropdownBTC" data-toggle="dropdown">
                  <i className="fas fa-chevron-down" />
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownBTC" x-placement="top-start">
                  <a href="/" className="dropdown-item">
                    <span className="circle coin-icon-20" style={{ backgroundImage: 'url(/images/circle-dollar.png)' }} />
                    {' '}
USD
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="marketplace-search-input mB-5">
            <div className="input-has-icon mw-300">
              <i className="icon-search" />
              <input type="text" className="form-control form_control" placeholder="Search currencies…" />
            </div>
          </div>
        </div>
        <div className="row row-sm">
          <div className="col-md-12 col-lg-8 col-xl-9">
            <CurrentCryptoPriceGrapht symbol={symbol} prices={prices} />
          </div>
          <div className="col-md-12 col-lg-4 col-xl-3">
            <div className="marketplace-form-convert">
              <div className="form-convert-header">
                <span>Wallet:</span>
                <b className="fw-n fsz-def">$16,222,55</b>
              </div>
              <Tab.Container defaultActiveKey="tab_Converter">
                <div className="form-convert-body">
                  <div className="tab-common">
                    <Nav className="nav nav-tabs tab-sm" id="walletBalanceTab" role="tablist">
                      <Nav.Item className="nav-item">
                        <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_Converter" role="tab" aria-selected="true">Converter</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item">
                        <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_btcHistoricalData" role="tab" aria-selected="false">BTC Historical Data</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content as="div" className="tab-content" id="walletBalanceTabContent">
                      <Tab.Pane className="tab-pane" eventKey="tab_Converter" role="tabpanel">
                        <form action className="mY-20">
                          <div className="form-group">
                            <label className="fsz-sm c-grey">Send</label>
                            <div className="group-input">
                              <input type="text" className="form-control" placeholder="Enter the amount" />
                              <select className="btn-transparent">
                                <option value>USD</option>
                                <option value>EUR</option>
                              </select>
                            </div>
                          </div>
                          <div className="mB-15"><button className="btn btn-md btn-primary w-100">Calculate Exchange</button></div>
                          <div className="form-group">
                            <label className="fsz-sm c-grey">Receive</label>
                            <div className="group-input">
                              <input type="text" className="form-control" placeholder="Amount here.." />
                              <select className="btn-transparent">
                                <option value>BTC</option>
                                <option value>ETH</option>
                              </select>
                            </div>
                          </div>
                        </form>
                      </Tab.Pane>
                      <Tab.Pane className="tab-pane fade" eventKey="tab_btcHistoricalData" role="tabpanel" />
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      <div className="single-blog-related">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="blog-items blog-items-1 mB-25">
              <div className="blog-text p-25 bgc-white">
                <a href="/" className="btn-category btn btn-around bgc-orange1 c-white">Statistics</a>
                <a href="/" className="c-black"><h4 className="fw-n title mY-10">Study: Gen Z prefers shopping over online</h4></a>
                <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
              </div>
              <div className="blog-image">
                <a href="/">
                  <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-12.jpg)' }} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8">
            <div className="blog-items blog-items-2 mB-25">
              <div className="blog-image">
                <a href="/">
                  <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-11.jpg)' }} />
                </a>
              </div>
              <div className="blog-text p-25 bgc-white">
                <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                <a href="/" className="c-black"><h4 className="fw-n title mY-10">PredictSpring intros &lsquo;modern&lsquo;  mobile commerce platform for brick-and-mortar</h4></a>
                <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="blog-items blog-items-3 mB-25">
              <div className="blog-image">
                <a href="/">
                  <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-13.jpg)' }} />
                </a>
              </div>
              <div className="blog-text">
                <div className="d-flex">
                  <div className="mR-25">
                    <div className="circle-136" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                  </div>
                  <div>
                    <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                    <a href="/" className="c-black"><h4 className="fw-n title mY-10">Ripple expands network worldwide for easier transfers</h4></a>
                    <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default compose(
  connect(pricesSelector, { fetchPrices }),
  lifecycle({
    componentDidMount() {
      this.props.fetchPrices();
    },
  }),
  withState('symbol', 'setSymbol', 'BTC'),
  mapProps(props => ({
    ...props,
    price: (props.prices || []).find(price => price.symbol === props.symbol),
  })),
)(Marketplace);
