import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import Account from '../common/FAQS/Account';
import SecurityNPrivacy from '../common/FAQS/SecurityNPrivacy';
import PartnerItems from '../common/PartnerItems';

const IcoLanding = () => (
  <>
    <Helmet>
      <title>Paybit - IcoLanding</title>
    </Helmet>
    <section className="ico-landing-banner">
      <div className="container">
        <div className="pY-30 mB-50">
          <ul className="breadcrumb breadcrumbs">
            <li><a href="/"><i className="icon-house" /></a></li>
            <li>
              <i className="fas fa-chevron-right" />
              ICO Listing Application
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-5 col-xl-4">
            <ReactWOW animation="fadeInLeft">
              <div lassName="ico-landing-banner-left">
                <p className="mB-10 c-gray">Paybit for business</p>
                <h1 className="mB-30 fw-n">Do more with Paybit, join now</h1>
                <div className="mY-30">
                  <a href="/" className="btn btn-lg btn-primary">Open FREE Business Account</a>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row justify-content-center mT-30 ">
          <div className="col-md-12 col-lg-9">
            <h2 className="mY-50 text-center text-before-decor">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</h2>
          </div>
        </div>
        <div className="mT-30">
          <PartnerItems />
        </div>
      </div>
    </section>
    <ReactWOW animation="fadeInUp">
      <section className="pY-50 section-img-text ">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12 col-lg-6 section-img-text-text">
              <div>
                <p className="text-uppercase c-grey mB-5"><b className="fw-n">Crypto Currency</b></p>
                <h3 className="fw-n mB-15">Get started with PBIT</h3>
                <p className="c-text-gray mB-15">
                  PBIT is the native cryptocurrency token of PayBit. It is an ERC-20-compatible token and can be securely stored in a regular Ethereum wallet and managed with MetaMask, Ledger, Trezor and other wallet clients. PBIT can be used to pay fees within the system and will have other applications later as well. PBIT total supply is 1,000,000,000 PBIT.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 section-img-text-img">
              <div className="img-crypto" style={{ backgroundImage: 'url(/images/crypto-1.png)' }} />
            </div>
          </div>
        </div>
      </section>
    </ReactWOW>
    <ReactWOW animation="fadeInUp">
      <section className="pY-50 section-img-text ">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12 col-lg-4 plugin-browser  section-img-text-img">
              <div className="img-crypto" style={{ backgroundImage: 'url(/images/crypto-2.png)' }} />
            </div>
            <div className="col-md-12 col-lg-6  section-img-text-text">
              <div>
                <p className="text-uppercase c-grey mB-5"><b className="fw-n">Get started</b></p>
                <h3 className="fw-n mB-15">Get started with your free wallet</h3>
                <p className="c-text-gray mB-15">
                  Millions of customers around the world
                  use us for one simple reason:
                  it’s simple. Just an email address and
                  password will get you through to checkout before you can reach for your wallet.
                  <br />
                  <br />
                  Expand the Paybit network and earn money!
                  <br />
                  <br />
                  Refer your friends, family, clients and
                  contacts to Paybit and qualify for cash rewards.
                  It’s simple: refer someone to use Paybit,
                  and if they meet the conditions (read more) then you’ll
                  get paid! All you have to do is copy and
                  paste your personalized link or banner code into
                  an email or your website to get started!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ReactWOW>
    <ReactWOW animation="fadeInUp">
      <section className="pY-50 section-img-text ">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12 col-lg-6 section-img-text-text">
              <div>
                <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
                <h3 className="fw-n mB-15">Pay a bit with Paybit</h3>
                <p className="c-text-gray mB-15">Use PayBit and its own currency token PBIT for doing payments of all types. No limits to pay anyone. You can send payments in just a bit. Its that easy and simple. Try it now and join Paybit for free</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 section-img-text-img">
              <div className="img-crypto" style={{ backgroundImage: 'url(/images/crypto-3.png)' }} />
            </div>
          </div>
        </div>
      </section>
    </ReactWOW>
    <section className="bgc-blue section-paybit-trending">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-7">
            <div className="section-paybit-trending-img" style={{ backgroundImage: 'url(/images/mockup-paybit-trending.jpg)' }} />
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="section-paybit-trending-text c-white">
              <p className="text-uppercase">Process of Intesting</p>
              <h3 className="fw-n mB-20">PayBit Trending</h3>
              <p className="c-white-60">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-live-trading c-white">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-12 col-lg-5">
            <p className="text-uppercase c-grey mB-15"><b className="fw-n">Process of Intesting</b></p>
            <h3 className="fw-n mB-15">Live Trading via PayBit</h3>
            <p className="c-grey mB-15">
              Millions of customers around the world
              use us for one simple reason: it’s simple.
              Just an email address and password will get
              you through to checkout before you can reach
              for your wallet.
            </p>
          </div>
          <div className="col-md-12 col-lg-1" />
          <div className="col-md-12 col-lg-5">
            <div className="text-center">
              <div className="img-live-trading-form m-auto" style={{ backgroundImage: 'url(/images/live-trading-form.jpg)' }} />
            </div>
          </div>
        </div>
        <div className="pY-50">
          <div className="img-mockup-more-features" style={{ backgroundImage: 'url(/images/mockup-more-features.jpg)' }} />
        </div>
        <div className="mB-50">
          <div className="d-flex d-md-inline-block w-100 justify-content-between align-items-center">
            <div className="d-flex d-md-inline-block float-left">
              <div className="mR-30">
                <h4 className="fw-n mb-0">$6,524.55</h4>
                <p className="c-green fsz-def">
                  <i className="fas fa-sort-up" />
0.25%
                </p>
              </div>
            </div>
            <div className="d-flex d-md-inline-block float-left">
              <div className="d-flex">
                <div className="mR-40">
                  <p className="c-gray mb-0">Market Cap</p>
                  <h5 className="fw-n">$112.23B</h5>
                </div>
                <div className="mR-40">
                  <p className="c-gray mb-0">Circ Sply</p>
                  <h5 className="fw-n">17,291,150 BTC</h5>
                </div>
                <div className="mR-40">
                  <p className="c-gray mb-0">24H Vol</p>
                  <h5 className="fw-n">$1.19B</h5>
                </div>
                <div>
                  <p className="c-gray mb-0">24h High/Low</p>
                  <h5 className="fw-n">$6,550.91 / $6,429.37</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pY-50">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-6 col-xl-5 text-center">
              <h3 className="fw-n mB-30">Why become Paybit Investitor?</h3>
              <p className="c-gray line-height-2 fsz-def">Bitcoin, the decentralized network, allows users to transact directly, peer to peer, without a middle man to manage the exchange of funds.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-11 col-xl-10">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 box-shadow-mb">
                <div className="ico-items">
                  <div className="ico-items-img" style={{ backgroundImage: 'url(/images/select.png)' }} />
                  <h5 className="fw-n">Select</h5>
                  <p className="c-white-60 line-height-2 mB-30">
                    The are hundra of digital assets that can form
                    various combination of digital ssets arrays and preset

                    he prfect vehicle to acheive your goal
                  </p>
                  <a href="/" className="c-white">More Information</a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 box-shadow-mb">
                <div className="ico-items">
                  <div className="ico-items-img" style={{ backgroundImage: 'url(/images/invest.png)' }} />
                  <h5 className="fw-n">Invest</h5>
                  <p className="c-white-60 line-height-2 mB-30">
                    The digital asset, bitcoin, is used like other
                    assets in exchange for goods and services. Unlike traditional
                    currencies and assets, bitcoin is easily portable, divisible, and irreversible.
                  </p>
                  <a href="/" className="c-white">More Information</a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 box-shadow-mb">
                <div className="ico-items">
                  <div className="ico-items-img" style={{ backgroundImage: 'url(/images/protect.png)' }} />
                  <h5 className="fw-n">Protect</h5>
                  <p className="c-white-60 line-height-2 mB-30">
                    Bitcoin increases system efficiency and enables
                    the provision of financial services at a drastically
                    lower cost, giving users more power and freedom.
                  </p>
                  <a href="/" className="c-white">More Information</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-center mT-50">
            <a href="/" className="btn btn-lg btn-primary">Find out More</a>
          </div>
        </div>
        <div className="pY-50 mY-50">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-6 col-xl-5 text-center">
              <h3 className="fw-n mB-30">Why become Paybitshare Manager?</h3>
              <p className="c-gray line-height-2 fsz-def">Bitcoin, the decentralized network, allows users to transact directly, peer to peer, without a middle man to manage the exchange of funds.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-11 col-xl-10">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 box-shadow-mb">
                <div className="ico-items">
                  <div className="ico-items-img" style={{ backgroundImage: 'url(/images/create.png)' }} />
                  <h5 className="fw-n">Create</h5>
                  <p className="c-white-60 line-height-2 mB-30">
                    The are hundra of digital assets that can form various
                    combination of digital ssets arrays and preset he prfect
                    vehicle to acheive your goal
                  </p>
                  <a href="/" className="c-white">More Information</a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 box-shadow-mb">
                <div className="ico-items">
                  <div className="ico-items-img" style={{ backgroundImage: 'url(/images/manage.png)' }} />
                  <h5 className="fw-n">Manage</h5>
                  <p className="c-white-60 line-height-2 mB-30">
                    The digital asset, bitcoin, is used like other assets in
                    exchange for goods and services. Unlike traditional
                    currencies and assets, bitcoin is easily portable,
                    divisible, and irreversible.
                  </p>
                  <a href="/" className="c-white">More Information</a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 box-shadow-mb">
                <div className="ico-items">
                  <div className="ico-items-img" style={{ backgroundImage: 'url(/images/share.png)' }} />
                  <h5 className="fw-n">Share</h5>
                  <p className="c-white-60 line-height-2 mB-30">
                    Bitcoin increases system efficiency
                    and enables the provision of financial services
                    at a drastically lower cost, giving users more power and freedom.
                  </p>
                  <a href="/" className="c-white">More Information</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-center mT-50">
            <a href="/" className="btn btn-lg btn-primary">Find out More</a>
          </div>
        </div>
      </div>
    </section>
    <section className="bgc-white pY-60">
      <div className="container">
        <div className="text-center mB-50">
          <h4 className="fw-n">Today Statistics</h4>
          <p className="c-gray">Submit the form to get your pre-ICO or ICO published on ICObench for free.</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-6 col-lg-4 bdR bd-md-n text-center">
            <div>
              <p className="c-gray mb-0">Last Price</p>
              <h2 className="fw-n">$0.06600245</h2>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 text-center">
            <div>
              <p className="c-gray mb-0">24 Volume</p>
              <h2 className="fw-n">$157,422</h2>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 bdL bd-md-n text-center">
            <div>
              <p className="c-gray mb-0">Market Cap</p>
              <h2 className="fw-n">$4,495,255,251</h2>
            </div>
          </div>
        </div>
        <div className="title-underline text-center mY-50">
          <h4 className="fw-n">Where to buy Paybit</h4>
        </div>
        <PartnerItems />
        <div className="mT-50">
          <div className="text-center mY-50">
            <h4 className="fw-n">Highlight The Best</h4>
            <p className="c-gray">Submit the form to get your pre-Paybit or Paybit published on Paybitbench for free.</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-4">
              <div className="box-hover-boxshadow box-shadow-mb">
                <div className="text-center">
                  <p className="c-blue fsz-30"><i className="icon-request_money" /></p>
                  <h5 className="fw-n">KWATT Coin</h5>
                  <p className="c-text-gray">Exchange and transact bitcoin, ethereum, and bitcoin cash using the world’s most trusted.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box-hover-boxshadow box-shadow-mb">
                <div className="text-center">
                  <p className="c-blue fsz-30"><i className="icon-request_money" /></p>
                  <h5 className="fw-n">Token Suply</h5>
                  <p className="c-text-gray">Use the first and most popular bitcoin block explorer to search and verify transactions.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box-hover-boxshadow box-shadow-mb">
                <div className="text-center">
                  <p className="c-blue fsz-30"><i className="icon-electric-output" /></p>
                  <h5 className="fw-n">Electric Output</h5>
                  <p className="c-text-gray">Stay on top of bitcoin and other top cryptocurrency prices, news, and market information.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box-hover-boxshadow box-shadow-mb">
                <div className="text-center">
                  <p className="c-blue fsz-30"><i className="icon-inside-token" /></p>
                  <h5 className="fw-n">Inside Tokens Restricted</h5>
                  <p className="c-text-gray">The first and most trusted global cryptocurrency company.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box-hover-boxshadow box-shadow-mb">
                <div className="text-center">
                  <p className="c-blue fsz-30"><i className="icon-mining-farm" /></p>
                  <h5 className="fw-n">Mining Farm</h5>
                  <p className="c-text-gray">Stay on top of bitcoin and other top cryptocurrency prices, news, and market information.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box-hover-boxshadow box-shadow-mb">
                <div className="text-center">
                  <p className="c-blue fsz-30"><i className="icon-mining-capcity" /></p>
                  <h5 className="fw-n">Minig Capacity</h5>
                  <p className="c-text-gray">Stay on top of bitcoin and other top cryptocurrency prices, news, and market information.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bgc-grey pY-70">
      <div className="container">
        <Account className="mB-15" />
        <SecurityNPrivacy className="mB-15" />
        <div className="text-center mT-50">
          <a href="/" className="btn btn-lg btn-primary">Find out More</a>
        </div>
      </div>
    </section>
    <section className="section-imgs section-subscribe" style={{ backgroundImage: 'url(/images/subscribe-bg.jpg)' }}>
      <div className="inner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-6">
              <div className="c-white text-center">
                <h2 className="fsz-sup fw-n mB-10">Subscribe Our Newsletter!</h2>
                <p className="c-white-90 mB-20 fsz-md">For get all of our latest updates and promotion</p>
                <div className="form-subscribe">
                  <div className="input-group">
                    <input type="email" className="form-control form_control" placeholder="Enter your email address" />
                    <div className="input-group-append">
                      <button className="btn-primary btn"><i className="icon-right-arrow" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default IcoLanding;
