import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import { Dropdown } from 'react-bootstrap';
import SectionPartner from '../common/SectionPartner';
import PriceGraph from '../common/Charts/PriceGraph';

const CurrencyComparsion = () => (
  <>
    <Helmet>
      <title>Paybit - Currency Comparsion</title>
    </Helmet>
    <section className="top-banner-medium" style={{ backgroundImage: 'url(/images/business-tour-banner.jpg)' }}>
      <div className="top-banner-common-inner c-white">
        <div className="container">
          <ul className="breadcrumb breadcrumbs breadcrumbs-white mT-40">
            <li><a href="/"><i className="icon-house" /></a></li>
            <li>
              <i className="fas fa-chevron-right" />
              <a href="/">Currency comparsion</a>
            </li>
          </ul>
          <ReactWOW animation="fadeInUp">
            <div className="top-banner-text">
              <h1 className="fsz-50 fw-n mB-20">
                NEM - (XEM) VS TenX -
                <br className="hidden-md" />
                (PAY) Comparison - 1 day
              </h1>
              <p className="c-text-gray fsz-def mB-30">&ldquo;What should I choose?&ldquo; NEM or TenX? Is XEM better than PAY?</p>
              <div className="mT-20">
                <div className="exchange-cryptocurrency-form bgc-dark">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4">
                      <div className="form-group">
                        <label className="c-grey">From</label>
                        <div className="input-group">
                          <input type="number" className="form-control form_control" />
                          <div className="input-group-append">
                            <Dropdown className="dropdown select-coin btn bd d-flex align-items-center">
                              <Dropdown.Toggle as="div" className="btn-transparent dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true">
                                <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                <span className="c-black">BTC</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu">
                                <ul className="lis-n p-0 m-0">
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                    <span className="c-black">BTC</span>
                                  </li>
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                                    <span className="c-black">ETH</span>
                                  </li>
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                    <span className="c-black">BTC</span>
                                  </li>
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-1">
                      <div className="form-group exchange-icon">
                        <label className="c-grey">&nbsp;</label>
                        <div className="text-center c-white"><i className="icon-exchage" /></div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-4">
                      <div className="form-group">
                        <label className="c-grey">To</label>
                        <div className="input-group">
                          <input type="number" className="form-control form_control" />
                          <div className="input-group-append">
                            <Dropdown className="dropdown select-coin btn bd d-flex align-items-center">
                              <Dropdown.Toggle as="div" className="btn-transparent dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true">
                                <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                <span className="c-black">BTC</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu">
                                <ul className="lis-n p-0 m-0">
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                    <span className="c-black">BTC</span>
                                  </li>
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                                    <span className="c-black">ETH</span>
                                  </li>
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                    <span className="c-black">BTC</span>
                                  </li>
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-3">
                      <div className="form-group">
                        <label className="c-grey hidden-md">&nbsp;</label>
                        <button className="btn btn-lg w-100 btn-primary">Compare</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
    <section className="bgc-grey pY-70">
      <div className="container">
        <div className="list-currency-comparsion">
          <div className="item">
            <p className="c-gray mB-25">Price (USD)</p>
            <div className="d-flex justify-content-between">
              <div className="item-currency">
                <h5 className="fw-n">XEM</h5>
                <p className="mb-0 c-red">0.0525</p>
              </div>
              <div className="item-currency">
                <h5 className="fw-n">PAY</h5>
                <p className="mb-0 c-green">0.352</p>
              </div>
            </div>
          </div>
          <div className="item">
            <p className="c-gray mB-25">Market Cap (USD)</p>
            <div className="d-flex justify-content-between">
              <div className="item-currency">
                <h5 className="fw-n">XEM</h5>
                <p className="mb-0 c-red">34,618,879</p>
              </div>
              <div className="item-currency">
                <h5 className="fw-n">PAY</h5>
                <p className="mb-0 c-green">626,910,640</p>
              </div>
            </div>
          </div>
          <div className="item">
            <p className="c-gray mB-25">Change 1h %</p>
            <div className="d-flex justify-content-between">
              <div className="item-currency">
                <h5 className="fw-n">XEM</h5>
                <p className="mb-0 c-red">-0.231142</p>
              </div>
              <div className="item-currency">
                <h5 className="fw-n">PAY</h5>
                <p className="mb-0 c-green">0.15431</p>
              </div>
            </div>
          </div>
          <div className="item">
            <p className="c-gray mB-25">Change 24h %</p>
            <div className="d-flex justify-content-between">
              <div className="item-currency">
                <h5 className="fw-n">XEM</h5>
                <p className="mb-0 c-red">-2.90655</p>
              </div>
              <div className="item-currency">
                <h5 className="fw-n">PAY</h5>
                <p className="mb-0 c-green">18.2421</p>
              </div>
            </div>
          </div>
          <div className="item">
            <p className="c-gray mB-25">Change 7d %</p>
            <div className="d-flex justify-content-between">
              <div className="item-currency">
                <h5 className="fw-n">XEM</h5>
                <p className="mb-0 c-red">-10.491</p>
              </div>
              <div className="item-currency">
                <h5 className="fw-n">PAY</h5>
                <p className="mb-0 c-green">11.014</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mB-20">
          <div className="ad-1200x110" style={{ backgroundImage: 'url(/images/ad-1200x110.png)' }} />
        </div>
        <div className="row no-gutters mB-15">
          <div className="col-md-12 col-lg-6 bd bgc-white mB-15">
            <div className="d-flex p-20 justify-content-between d-md-inline-block w-100 align-items-center">
              <div className="d-flex align-items-center mB-10">
                <div className="mR-10">
                  <div className="img-24" style={{ backgroundImage: 'url(/images/Nem_XEM.png)' }} />
                </div>
                <b className="fw-n fsz-def">
NEM - (XEM)
                  {' '}
                  <span className="c-red">Runner Up</span>
                </b>
              </div>
              <ul className="lis-n p-0 mb-0 list-tab-history">
                <li className="float-left"><a href="/" className="btn btn-white btn-around">Today</a></li>
                <li className="float-left active"><a href="/" className="btn btn-white btn-around">7 Days</a></li>
                <li className="float-left"><a href="/" className="btn btn-white btn-around">14 Days</a></li>
                <li className="float-left"><a href="/" className="btn btn-white btn-around">Last Month</a></li>
              </ul>
            </div>
            <div className="p-10">
              <div className="chart-price-container">
                <PriceGraph />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 bd bgc-white mB-15">
            <div className="d-flex p-20 justify-content-between d-md-inline-block w-100 align-items-center">
              <div className="d-flex align-items-center mB-10">
                <div className="mR-10">
                  <div className="img-24" style={{ backgroundImage: 'url(/images/tenx.png)' }} />
                </div>
                <b className="fw-n fsz-def">
TenX - (PAY)
                  {' '}
                  <span className="c-green">Winner</span>
                </b>
              </div>
              <ul className="lis-n p-0 mb-0 list-tab-history">
                <li className="float-left"><a href="/" className="btn btn-white btn-around">Today</a></li>
                <li className="float-left active"><a href="/" className="btn btn-white btn-around">7 Days</a></li>
                <li className="float-left"><a href="/" className="btn btn-white btn-around">14 Days</a></li>
                <li className="float-left"><a href="/" className="btn btn-white btn-around">Last Month</a></li>
              </ul>
            </div>
            <div className="p-10">
              <div className="chart-price-container">
                <PriceGraph />
              </div>
            </div>
          </div>
        </div>
        <h5 className="fw-n mB-20">Comparison History</h5>
        <div className="list-tags-button mB-20">
          <ul className="list-unstyled">
            <li><a href="/">Pluton vs PIECoin</a></li>
            <li><a href="/">TRON vs Theta Token</a></li>
            <li><a href="/">Pluton vs Wings</a></li>
            <li><a href="/">BitTube vs Maker</a></li>
            <li><a href="/">Blackmoon vs Blocktix</a></li>
            <li><a href="/">iQuant vs Acute Angle Cloud</a></li>
            <li><a href="/">Ulord vs LQD</a></li>
            <li><a href="/">Zcash vs WaBi</a></li>
            <li><a href="/">Eidoo vs Nuls</a></li>
            <li><a href="/">WaykiChain vs Dent</a></li>
            <li><a href="/">Verge vs CloakCoin</a></li>
            <li><a href="/">ICON vs AirSwap</a></li>
            <li><a href="/">Dash vs AdEx</a></li>
            <li><a href="/">Pluton vs PIECoin</a></li>
            <li><a href="/">TRON vs Theta Token</a></li>
            <li><a href="/">Pluton vs Wings</a></li>
            <li><a href="/">BitTube vs Maker</a></li>
            <li><a href="/">Blackmoon vs Blocktix</a></li>
            <li><a href="/">iQuant vs Acute Angle Cloud</a></li>
            <li><a href="/">Ulord vs LQD</a></li>
            <li><a href="/">Zcash vs WaBi</a></li>
            <li><a href="/">Eidoo vs Nuls</a></li>
            <li><a href="/">Verge vs CloakCoin</a></li>
            <li><a href="/">ICON vs AirSwap</a></li>
            <li><a href="/">Dash vs AdEx</a></li>
          </ul>
        </div>
        <h5 className="fw-n mB-20">Featured Comparisons</h5>
        <div className="list-tags-button mB-20">
          <ul className="list-unstyled">
            <li><a href="/">Bitcoin vs Ethereum</a></li>
            <li><a href="/">Bitcoin vs Bitcoin Cash</a></li>
            <li><a href="/">Bitcoin vs Ripple</a></li>
            <li><a href="/">Ethereum vs Bitcoin Cash</a></li>
            <li><a href="/">Bitcoin Cash vs Litecoin</a></li>
            <li><a href="/">Ripple vs Nem</a></li>
          </ul>
        </div>
        <div className="mT-20 text-center">
          <a href="/" className="btn btn-lg btn-primary btn-around">Find out More</a>
        </div>
      </div>
    </section>
    <SectionPartner backgroundImage="/images/testimonials-bg2.jpg" />
  </>
);

export default CurrencyComparsion;
