import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const SingleBlog = () => (
  <section className="bgc-grey pY-30 bdT">
    <Helmet>
      <title>Paybit - Single Blog</title>
    </Helmet>
    <div className="container">
      <ul className="breadcrumb breadcrumbs mB-20">
        <li><a href="/"><i className="icon-house" /></a></li>
        <li>
          <i className="fas fa-chevron-right" />
          <a href="/">Blog</a>
        </li>
        <li>
          <i className="fas fa-chevron-right" />
          <span>PredictSring infos….</span>
        </li>
      </ul>
      <div className="article-container">
        <div className="row row-sm">
          <div className="col-md-12 col-lg-8 col-xl-8 mB-20">
            <div className="bd bgc-white p-35 mB-30">
              <h1 className="article-title mB-30 fw-n">PredictSpring intros &lsquo;modern&lsquo; mobile commerce platform for brick-and-mortar</h1>
              <div className="d-flex mB-30">
                <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                <div>
                  <h5><b className="fw-n">John Smith</b></h5>
                  <p className="c-grey fsz-def">Updated 10 Minutes ago</p>
                </div>
              </div>
              <div className="article-content">
                <div className="mB-20">
                  <iframe
                    title="this is title"
                    width="100%"
                    height={390}
                    src="https://www.youtube.com/embed/3MKj2qVk2yQ?controls=0"
                    frameBorder={0}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
                <p>
                  Times are really exciting at Electrolux.
                  We are busy revolutionizing peoples home, focused in provide
                  effortless and delightful experiences for our
                  consumers. We come to work every day because we
                  are passionate about shape living for the better
                  in the areas of Taste, Care, and Wellbeing.
                </p>
                <br />
                <p>
                  We are currently looking for a talented Design
                  Manager who will be part of the Latin America
                  design team. The chosen candidate will take care
                  of a category of products, accessories, and also
                  supporting connectivity strategy, guiding and inspiring
                  a design group to deliver human-centered products and solutions.
                </p>
                <br />
                <h5 className="fw-n c-black">A regular day at work</h5>
                <p>
                  You will be part of Electrolux Group Design,
                  to drive the design vision and strategy across
                  product solutions and will also lead the multidisciplinary
                  design team to deliver best in class consumer experiences for
                  home solutions to all Latin American countries.
                </p>
                <br />
                <p>The main responsibilities are:</p>
                <br />
                <p>
                  - Build strong and consistent design proposals,
                  aligned with Electrolux Brand design DNA.
                </p>
                <br />
                <p>
                  - Co-create with consumers to design end-to-end experiences
                  using participatory and iterative design techniques including
                  observational studies, customer interviews, usability testing,
                  and other forms of qualitative and quantitative research to
                  uncover insights, learn about user behavior and verify design concepts.
                </p>
                <br />
                <p>
                  - Mentor and develop more junior designers
                  by helping them grow their hard and soft skills.
                </p>
                <br />
                <p>
                  - Guide the team to develop best practices
                  for ensuring a high bar of design quality.
                </p>
                <br />
                <p>
                  - Foster a culture of feedback, continuous improvement and operational excellence.

                </p>
                <br />
                <p>
                  - Set clear goals, manage project loads,
                  ensuring the deadlines are met and set up
                  squad teams for overall success.
                </p>
                <br />
                <p>
                  - Connect product and design strategy with relevant stakeholders,
                  such as R&amp;D, MKT, and product line, also identifying innovation opportunities.
                </p>
                <br />
                <h5 className="fw-n c-black">A regular day at work</h5>
                <p>
                  Agile. You love working according to design
                  thinking and agile principles and have some experience in doing so.
                </p>
                <br />
                <p>
                  - Customer focused.
                  You are passionate about creating excelente consumer
                  experiences and prefer that this drives the
                  way the product is developed.
                </p>
                <br />
                <p>
                  - A leader. You are passionate about people,
                  a great team leader, manager, and design thinker,
                  who will provide leadership and guidance to foster
                  a culture of trust, transparency, and collaboration.
                </p>
                <br />
                <p>
                  - Collaboration. You will also collaborate with other design
                  leaders to develop talented designers, and identify opportunities
                  to establish best practices for a seamless
                  experience across all of our products.
                </p>
                <br />
                <p>
                  - International. We think you’ve been exposed to
                  both large corporations and international environments.
                </p>
                <br />
              </div>
              <div className="social-share">
                <ul>
                  <li><a href="/" className="hover-boxshadow"><i className="fab fa-facebook-f" /></a></li>
                  <li><a href="/" className="hover-boxshadow"><i className="fab fa-twitter" /></a></li>
                  <li><a href="/" className="hover-boxshadow"><i className="fab fa-instagram" /></a></li>
                </ul>
              </div>
            </div>
            <div className="mB-20">
              <h4><b className="fw-n">Comments</b></h4>
              <div className="d-flex d-md-inline-block w-100 justify-content-between">
                <p className="c-text-gray mB-10">There are 33 comments to this article</p>
                <div className="drop-sort mB-10">
                  <span>Sort by: </span>
                  <Dropdown className="dropdown_sort">
                    <Dropdown.Toggle variant="" as="a"> Date</Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu" ariaLabelledby="dropdown_sort">
                      <a href="/" className="dropdown-item action-sort">Date</a>
                      <a href="/" className="dropdown-item action-sort">Month</a>
                      <a href="/" className="dropdown-item action-sort">Years</a>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="bd bdB-n bgc-white mB-30">
              <div className="p-35 bdB">
                <div className="d-flex mB-15">
                  <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                  <div>
                    <h5><b className="fw-n">John Smith</b></h5>
                    <p className="c-grey fsz-def">Updated 10 Minutes ago</p>
                  </div>
                </div>
                <div className="c-grey-6 line-height-2 fsz-def">The estimated interchange fees listed in this report should be used for general reference and identifying trends – not for reconciliation.</div>
              </div>
              <div className="p-35 bdB">
                <div className="d-flex mB-15">
                  <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                  <div>
                    <h5><b className="fw-n">John Smith</b></h5>
                    <p className="c-grey fsz-def">Updated 10 Minutes ago</p>
                  </div>
                </div>
                <div className="c-grey-6 line-height-2 fsz-def">The estimated interchange fees listed in this report should be used for general reference and identifying trends – not for reconciliation.</div>
              </div>
              <div className="p-35 bdB">
                <div className="d-flex mB-15">
                  <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                  <div>
                    <h5><b className="fw-n">John Smith</b></h5>
                    <p className="c-grey fsz-def">Updated 10 Minutes ago</p>
                  </div>
                </div>
                <div className="c-grey-6 line-height-2 fsz-def">The estimated interchange fees listed in this report should be used for general reference and identifying trends – not for reconciliation.</div>
              </div>
              <div className="p-35 bdB">
                <div className="d-flex mB-15">
                  <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                  <div>
                    <h5><b className="fw-n">John Smith</b></h5>
                    <p className="c-grey fsz-def">Updated 10 Minutes ago</p>
                  </div>
                </div>
                <div className="c-grey-6 line-height-2 fsz-def">The estimated interchange fees listed in this report should be used for general reference and identifying trends – not for reconciliation.</div>
              </div>
            </div>
            <div className="d-flex conversation-box mB-30">
              <div className="circle img-42 mR-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              <textarea className="form-control pY-10 pX-25" placeholder="Add to conversation" defaultValue="" />
            </div>
            <nav className="nav-paging">
              <ul className="pagination justify-content-center">
                <li className="page-item disabled">
                  <a className="page-link" href="/" tabIndex={-1}><i className="fas fa-chevron-left" /></a>
                </li>
                <li className="page-item"><a className="page-link" href="/">1</a></li>
                <li className="page-item active"><a className="page-link" href="/">2</a></li>
                <li className="page-item"><a className="page-link" href="/">3</a></li>
                <li className="page-item">
                  <a className="page-link" href="/"><i className="fas fa-chevron-right" /></a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-md-12 col-lg-4 col-xl-4 mB-20">
            <h5 className="mY-15"><b className="fw-n">Recent Post</b></h5>
            <div className="mB-5 bgc-white d-inline-block w-100 bd p-20 post-box-sidebar">
              <div className="circle-50 mR-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
              <div className="post-box-content">
                <h5 className="mB-5"><a href="/" className="c-black"><b className="fw-n">Ripple expands network worldwide for easier transfers.</b></a></h5>
                <p className="mb-0 c-grey">John Smith</p>
              </div>
            </div>
            <div className="mB-5 bgc-white d-inline-block w-100 bd p-20 post-box-sidebar">
              <div className="circle-50 mR-25" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
              <div className="post-box-content">
                <h5 className="mB-5"><a href="/" className="c-black"><b className="fw-n">Study: US consumers prefer cash over plastic for small-ticket items.</b></a></h5>
                <p className="mb-0 c-grey">Smith Cara</p>
              </div>
            </div>
            <div className="mB-5 bgc-white d-inline-block w-100 bd p-20 post-box-sidebar">
              <div className="circle-50 mR-25" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
              <div className="post-box-content">
                <h5 className="mB-5"><a href="/" className="c-black"><b className="fw-n">Hacker gets 14 years in prison for enabling Target breach.</b></a></h5>
                <p className="mb-0 c-grey">Tanja Biberdzic</p>
              </div>
            </div>
            <h5 className="mY-15"><b className="fw-n">Categories</b></h5>
            <div className="mB-5 bgc-white d-inline-block w-100 bd p-20 post-box-sidebar">
              <ul className="list-unstyled mb-0">
                <li>
                  <a href="/" className="c-black"><h6 className="fw-n">Payments</h6></a>
                </li>
                <li>
                  <a href="/" className="c-black"><h6 className="fw-n">Finance</h6></a>
                </li>
                <li>
                  <a href="/" className="c-black"><h6 className="fw-n">Bitcoins</h6></a>
                </li>
              </ul>
            </div>
            <h5 className="mY-15"><b className="fw-n">Years</b></h5>
            <div className="mB-5 bgc-white d-inline-block w-100 bd p-20 post-box-sidebar">
              <ul className="list-unstyled m-0">
                <li>
                  <a href="/" className="c-black"><h6 className="fw-n">January 2018</h6></a>
                </li>
                <li>
                  <a href="/" className="c-black"><h6 className="fw-n">June 2018</h6></a>
                </li>
                <li>
                  <a href="/" className="c-black"><h6 className="fw-n">Novembar 2018</h6></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="single-blog-related">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="blog-items blog-items-1 mB-25">
              <div className="blog-text p-25 bgc-white">
                <a href="/" className="btn-category btn btn-around bgc-orange1 c-white">Statistics</a>
                <a href="/" className="c-black"><h4 className="fw-n title mY-10">Study: Gen Z prefers shopping over online</h4></a>
                <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
              </div>
              <div className="blog-image">
                <a href="/">
                  <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-12.jpg)' }} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8">
            <div className="blog-items blog-items-2 mB-25">
              <div className="blog-image">
                <a href="/">
                  <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-11.jpg)' }} />
                </a>
              </div>
              <div className="blog-text p-25 bgc-white">
                <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                <a href="/" className="c-black"><h4 className="fw-n title mY-10">PredictSpring intros &lsquo;modern&lsquo; mobile commerce platform for brick-and-mortar</h4></a>
                <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="blog-items blog-items-3 mB-25">
              <div className="blog-image">
                <a href="/">
                  <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-13.jpg)' }} />
                </a>
              </div>
              <div className="blog-text">
                <div className="d-flex">
                  <div className="mR-25">
                    <div className="circle-136" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                  </div>
                  <div>
                    <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                    <a href="/" className="c-black"><h4 className="fw-n title mY-10">Ripple expands network worldwide for easier transfers</h4></a>
                    <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default SingleBlog;
