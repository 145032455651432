import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ScrollToTop from '../common/ScrollToTop';
import DashboardHome from './Home';
import MainSettings from './MainSettings';
import MessageCenter from './MessageCenter';
import NotificationMain from './NotificationMain';
import Referral from './Referral';
import SendMoneyDedicated from './SendMoneyDedicated';
import TransactionsDetail from './TransactionsDetail';
import WalletsAddress from './WalletsAddress';
import Reports from './Reports';
import Transactions from './Transactions';
import ActivityHistory from './ActivityHistory';
import Contact from './Contact';
import BusinessMerchant from './BusinessMerchant';
import Overview from './Overview';
import Detail from './WalletsAddress/Detail';
import AuthRoute from '../common/Routes/AuthRoute';

const DashboardRouter = () => (
  <Switch>
    <AuthRoute path="/dashboard_new/" component={DashboardHome} exact />
    <Route path="/dashboard_new/common" component={() => <div />} exact />
    <Route path="/dashboard_new/main-settings" component={MainSettings} exact />
    <Route path="/dashboard_new/message-center" component={MessageCenter} exact />
    <Route path="/dashboard_new/notification-main" component={NotificationMain} exact />
    <Route path="/dashboard_new/referral" component={Referral} exact />
    <Route path="/dashboard_new/send-money-dedicated" component={SendMoneyDedicated} exact />
    <Route path="/dashboard_new/transactions-detail" component={TransactionsDetail} exact />
    <Route path="/dashboard_new/wallets-address" component={WalletsAddress} exact />
    <Route path="/dashboard_new/wallets-addresses-detail" component={Detail} exact />
    <Route path="/dashboard_new/reports" component={Reports} exact />
    <Route path="/dashboard_new/transactions" component={Transactions} exact />
    <Route path="/dashboard_new/activity-history" component={ActivityHistory} exact />
    <Route path="/dashboard_new/contact" component={Contact} exact />
    <Route path="/dashboard_new/business-merchant" component={BusinessMerchant} exact />
    <Route path="/dashboard_new/overview" component={Overview} exact />
  </Switch>
);

export default DashboardRouter;
