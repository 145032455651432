import React from 'react';
import { Helmet } from 'react-helmet';

const MessageCenter = () => (
  <section>
    <Helmet>
      <title>Paybit - Message Center</title>
    </Helmet>
    <div className="message-center-container pT-20">
      <div className="message-center-sidebar bdT bdR bg-white">
        <div className="bdB pY-20 pX-30">
          <button className="btn w-100 btn-xl btn-primary btn-around">Write a Message</button>
        </div>
        <form className="search-contact bdB" id="search-contact">
          <div className="search-input">
            <label htmlFor="search-contact-input">
              <i className="fa fa-search" aria-hidden="true" />
              <span className="sr-only">Search for messages...</span>
            </label>
            <input type="search" id="search-contact-input" className="form-control" placeholder="Search for messages..." />
          </div>
          <select className="select-search">
            <option value={1}>Recent</option>
            <option value={1}>Hot</option>
          </select>
        </form>
        <div className="chat-history-content scrollable">
          <div className="conversationList">
            <div className="item">
              <div className="item-check">
                <div>
                  <input type="checkbox" id="item-check-1" />
                  <label htmlFor="item-check-1" />
                </div>
                <i className="c-yellow fas fa-star" />
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="chat-user-name mB-5">
                    <p className="fsz-sm c-blue">
                      <i className="mR-5 icon-contacts" />
Joshua Vyce
                      {' '}
                      <span className="user-status c-green"><i className="fas fa-circle" /></span>
                    </p>
                    <b className="fw-n fsz-def">Hi Mate, any update on the files?....</b>
                  </div>
                  <div className="c-gray fsz-xs text-right chat-item-time">
                    <div><i className="fas fa-paperclip" /></div>
                    <div>1 Week</div>
                  </div>
                </div>
                <p className="c-gray fsz-sm mb-0">Ah ok, sorry didn&lsquo;t see it. Downloading now. I&lsquo;ll keep in touch about other work.</p>
              </div>
            </div>
            <div className="item active">
              <div className="item-check">
                <div>
                  <input type="checkbox" id="item-check-2" />
                  <label htmlFor="item-check-2" />
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="chat-user-name mB-5">
                    <p className="fsz-sm c-green">
                      <i className="mR-5 icon-contacts" />
Joshua Vyce
                      {' '}
                      <span className="user-status c-green"><i className="fas fa-circle" /></span>
                    </p>
                    <b className="fw-n fsz-def">Hi Mate, any update on the files?....</b>
                  </div>
                  <div className="c-gray fsz-xs text-right chat-item-time">
                    <div>1 Week</div>
                  </div>
                </div>
                <p className="c-gray fsz-sm mb-0">Ah ok, sorry didn&lsquo;t see it. Downloading now. I&lsquo;ll keep in touch about other work.</p>
              </div>
            </div>
            <div className="item">
              <div className="item-check">
                <div>
                  <input type="checkbox" id="item-check-3" />
                  <label htmlFor="item-check-3" />
                </div>
                <i className="c-yellow fas fa-star" />
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="chat-user-name mB-5">
                    <p className="fsz-sm c-blue">
                      <i className="mR-5 icon-contacts" />
Joshua Vyce
                      {' '}
                      <span className="user-status c-red"><i className="fas fa-circle" /></span>
                    </p>
                    <b className="fw-n fsz-def">Hi Mate, any update on the files?....</b>
                  </div>
                  <div className="c-gray fsz-xs text-right chat-item-time">
                    <div><i className="fas fa-paperclip" /></div>
                    <div>1 Week</div>
                  </div>
                </div>
                <p className="c-gray fsz-sm mb-0">Ah ok, sorry didn&lsquo;t see it. Downloading now. I&lsquo;ll keep in touch about other work.</p>
              </div>
            </div>
            <div className="item">
              <div className="item-check">
                <div>
                  <input type="checkbox" id="item-check-4" />
                  <label htmlFor="item-check-4" />
                </div>
                <i className="c-yellow fas fa-star" />
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="chat-user-name mB-5">
                    <p className="fsz-sm c-blue">
                      <i className="mR-5 icon-contacts" />
Joshua Vyce
                      {' '}
                      <span className="user-status c-green"><i className="fas fa-circle" /></span>
                    </p>
                    <b className="fw-n fsz-def">Hi Mate, any update on the files?....</b>
                  </div>
                  <div className="c-gray fsz-xs text-right chat-item-time">
                    <div><i className="fas fa-paperclip" /></div>
                    <div>1 Week</div>
                  </div>
                </div>
                <p className="c-gray fsz-sm mb-0">Ah ok, sorry didn&lsquo;t see it. Downloading now. I&lsquo;ll keep in touch about other work.</p>
              </div>
            </div>
            <div className="item">
              <div className="item-check">
                <div>
                  <input type="checkbox" id="item-check-5" />
                  <label htmlFor="item-check-5" />
                </div>
                <i className="c-yellow fas fa-star" />
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="chat-user-name mB-5">
                    <p className="fsz-sm c-blue">
                      <i className="mR-5 icon-contacts" />
Joshua Vyce
                      {' '}
                      <span className="user-status c-green"><i className="fas fa-circle" /></span>
                    </p>
                    <b className="fw-n fsz-def">Hi Mate, any update on the files?....</b>
                  </div>
                  <div className="c-gray fsz-xs text-right chat-item-time">
                    <div><i className="fas fa-paperclip" /></div>
                    <div>1 Week</div>
                  </div>
                </div>
                <p className="c-gray fsz-sm mb-0">Ah ok, sorry didn&lsquo;t see it. Downloading now. I&lsquo;ll keep in touch about other work.</p>
              </div>
            </div>
            <div className="item">
              <div className="item-check">
                <div>
                  <input type="checkbox" id="item-check-6" />
                  <label htmlFor="item-check-6" />
                </div>
                <i className="c-yellow fas fa-star" />
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="chat-user-name mB-5">
                    <p className="fsz-sm c-blue">
                      <i className="mR-5 icon-contacts" />
Joshua Vyce
                      {' '}
                      <span className="user-status c-green"><i className="fas fa-circle" /></span>
                    </p>
                    <b className="fw-n fsz-def">Hi Mate, any update on the files?....</b>
                  </div>
                  <div className="c-gray fsz-xs text-right chat-item-time">
                    <div><i className="fas fa-paperclip" /></div>
                    <div>1 Week</div>
                  </div>
                </div>
                <p className="c-gray fsz-sm mb-0">Ah ok, sorry didn&lsquo;t see it. Downloading now. I&lsquo;ll keep in touch about other work.</p>
              </div>
            </div>
            <div className="item">
              <div className="item-check">
                <div>
                  <input type="checkbox" id="item-check-7" />
                  <label htmlFor="item-check-7" />
                </div>
                <i className="c-yellow fas fa-star" />
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="chat-user-name mB-5">
                    <p className="fsz-sm c-blue">
                      <i className="mR-5 icon-contacts" />
Joshua Vyce
                      {' '}
                      <span className="user-status c-green"><i className="fas fa-circle" /></span>
                    </p>
                    <b className="fw-n fsz-def">Hi Mate, any update on the files?....</b>
                  </div>
                  <div className="c-gray fsz-xs text-right chat-item-time">
                    <div><i className="fas fa-paperclip" /></div>
                    <div>1 Week</div>
                  </div>
                </div>
                <p className="c-gray fsz-sm mb-0">Ah ok, sorry didn&lsquo;t see it. Downloading now. I&lsquo;ll keep in touch about other work.</p>
              </div>
            </div>
            <div className="item">
              <div className="item-check">
                <div>
                  <input type="checkbox" id="item-check-8" />
                  <label htmlFor="item-check-8" />
                </div>
                <i className="c-yellow fas fa-star" />
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="chat-user-name mB-5">
                    <p className="fsz-sm c-blue">
                      <i className="mR-5 icon-contacts" />
Joshua Vyce
                      {' '}
                      <span className="user-status c-green"><i className="fas fa-circle" /></span>
                    </p>
                    <b className="fw-n fsz-def">Hi Mate, any update on the files?....</b>
                  </div>
                  <div className="c-gray fsz-xs text-right chat-item-time">
                    <div><i className="fas fa-paperclip" /></div>
                    <div>1 Week</div>
                  </div>
                </div>
                <p className="c-gray fsz-sm mb-0">Ah ok, sorry didn&lsquo;t see it. Downloading now. I&lsquo;ll keep in touch about other work.</p>
              </div>
            </div>
            <div className="item">
              <div className="item-check">
                <div>
                  <input type="checkbox" id="item-check-9" />
                  <label htmlFor="item-check-9" />
                </div>
                <i className="c-yellow fas fa-star" />
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="chat-user-name mB-5">
                    <p className="fsz-sm c-blue">
                      <i className="mR-5 icon-contacts" />
Joshua Vyce
                      {' '}
                      <span className="user-status c-green"><i className="fas fa-circle" /></span>
                    </p>
                    <b className="fw-n fsz-def">Hi Mate, any update on the files?....</b>
                  </div>
                  <div className="c-gray fsz-xs text-right chat-item-time">
                    <div><i className="fas fa-paperclip" /></div>
                    <div>1 Week</div>
                  </div>
                </div>
                <p className="c-gray fsz-sm mb-0">Ah ok, sorry didn&lsquo;t see it. Downloading now. I&lsquo;ll keep in touch about other work.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="message-center-content-right bdT">
        <div className="message-content-header bdB d-flex justify-content-between align-items-center bg-white pY-20 pX-30">
          <div>
            <b className="fw-n fsz-md">We can discuss any details over chat.</b>
            <i className="mL-10 fas fa-circle c-green fsz-xxs" />
          </div>
          <div className="text-right">
            <span className="fsz-sm c-gray mR-10">1 October, 2018 - 12:00PM</span>
            <i className="c-yellow fas fa-star" />
          </div>
        </div>
        <div className="message-content-body bgc-grey">
          <div className="message-view scrollable">
            <div className="chat-item">
              <div className="chat-item-avatar">
                <div className="chat-user-avatar">
                  <div className="img-48 bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                  <span className="chat-status online" />
                </div>
                <div className="chat-user-name">
                  <div><b className="fw-n fsz-def">John Carmack</b></div>
                  <span className="c-gray fsz-xs">04:45 pm</span>
                </div>
              </div>
              <div className="chat-item-content">
                <div className="chat-text">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Vero numquam tempore non? Laudantium omnis molestias praesentium.
                    Natus aperiam quas consequuntur ea aliquid ad,
                    dicta architecto recusandae voluptas similique, neque deserunt.
                  </span>
                </div>
              </div>
            </div>
            <div className="chat-item">
              <div className="chat-item-avatar">
                <div className="chat-user-avatar">
                  <div className="img-48 bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                  <span className="chat-status online" />
                </div>
                <div className="chat-user-name">
                  <div><b className="fw-n fsz-def">John Carmack</b></div>
                  <span className="c-gray fsz-xs">04:45 pm</span>
                </div>
              </div>
              <div className="chat-item-content">
                <div className="chat-image">
                  <figure>
                    <img src="/images/refer-friends-bg.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="chat-item chat-item-me">
              <div className="chat-item-avatar">
                <div className="chat-user-avatar">
                  <div className="img-48 bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                  <span className="chat-status online" />
                </div>
                <div className="chat-user-name">
                  <div><b className="fw-n fsz-def">John Carmack</b></div>
                  <span className="c-gray fsz-xs">04:45 pm</span>
                </div>
              </div>
              <div className="chat-item-content">
                <div className="chat-text">
                  <span>
                    We need someone who can take our mock design and
                    add their own ideas and make the Iphone look wonderful
                  </span>
                </div>
              </div>
              <div className="chat-item-content">
                <div className="chat-text">
                  <span>we need an iOS7/8 design concept using flat design concepts</span>
                </div>
              </div>
            </div>
            <div className="chat-item">
              <div className="chat-item-avatar">
                <div className="chat-user-avatar">
                  <div className="img-48 bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                  <span className="chat-status online" />
                </div>
                <div className="chat-user-name">
                  <div><b className="fw-n fsz-def">John Carmack</b></div>
                  <span className="c-gray fsz-xs">04:45 pm</span>
                </div>
              </div>
              <div className="chat-item-content">
                <div className="chat-text">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Vero numquam tempore non? Laudantium omnis molestias praesentium.
                    Natus aperiam quas consequuntur ea aliquid ad,
                    dicta architecto recusandae voluptas similique,
                    neque deserunt.
                  </span>
                </div>
              </div>
            </div>
            <div className="chat-item chat-item-me">
              <div className="chat-item-avatar">
                <div className="chat-user-avatar">
                  <div className="img-48 bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                  <span className="chat-status online" />
                </div>
                <div className="chat-user-name">
                  <div><b className="fw-n fsz-def">John Carmack</b></div>
                  <span className="c-gray fsz-xs">04:45 pm</span>
                </div>
              </div>
              <div className="chat-item-content">
                <div className="chat-text">
                  <span>
                    We need someone who can take our mock design and
                    add their own ideas and make the Iphone look wonderful
                  </span>
                </div>
              </div>
              <div className="chat-item-content">
                <div className="chat-text">
                  <span>we need an iOS7/8 design concept using flat design concepts</span>
                </div>
              </div>
            </div>
          </div>
          <div className="chat-input bg-white bdT">
            <textarea className="text-input" name id cols={30} rows={2} defaultValue="Hi aleksandardesign, I noticed your profile and would like to offer you my project. " />
            <div className="chat-input-action">
              <div className="chat-input-tools">
                <div className="send-file">
                  <input type="file" name="s-file" id="s-file" multiple hidden="true" />
                  <label htmlFor="s-file"><i className="fas fa-paperclip" /></label>
                </div>
                <div className="search-mess">
                  <label><i className="fa fa-search" /></label>
                </div>
                <div className="send-sticker">
                  <label><i className="far fa-smile" /></label>
                </div>
              </div>
              <div><button className="btn btn-primary btn-send-mess">Send</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default MessageCenter;
