import React from 'react';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { withAuth, withLogin } from '../../hocs/withAuth';

const SignIn = ({ account: { email, password }, setAccount, login }) => (
  <section className="section-sign-in">
    <Helmet>
      <title>Paybit - Sign In</title>
    </Helmet>
    <div className="container">
      <div className="sign-up-container">
        <form action="#" onSubmit={login}>
          <h5 className="c-grey fsz-def tt-u"><b className="fw-n">ENTER YOUR WALLET</b></h5>
          <h1 className="mB-30">Login to your account</h1>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <div className="input-has-icon">
                  <i className="icon-mail" />
                  <input type="email" className="form-control form_control" placeholder="Enter your email address" onChange={e => setAccount({ email: e.target.value, password })} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <div className="input-has-icon">
                  <i className="icon-lock" />
                  <input type="password" className="form-control form_control" placeholder="*********" onChange={e => setAccount({ password: e.target.value, email })} />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center"><a href="/forgot-password" className="c-gray fsz-def">Forgot your login or password?</a></div>
          <div className="text-center mT-20">
            <button type="submit" className="btn btn-lg btn-around btn-primary btn-submit">Log in to Account</button>
          </div>
          <div className="text-center mT-30 fsz-def c-grey">
            You don’t have account ?
            <a href="sign-up-personal" className="c-black"><b> Sign Up</b></a>
          </div>
        </form>
      </div>
    </div>
  </section>
);

export default compose(
  withAuth,
  withLogin,
)(SignIn);
