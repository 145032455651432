
// @flow
import React from 'react';
import { compose, defaultProps } from 'recompose';
import { scaleTime } from 'd3-scale';
import { utcHour } from 'd3-time';
import { ChartCanvas, Chart } from 'react-stockcharts';
import { CandlestickSeries } from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { fitWidth } from 'react-stockcharts/lib/helper';
import { last, timeIntervalBarWidth } from 'react-stockcharts/lib/utils';

import mockData from './mockData';

const colorLoader = d => (d.close > d.open && '#00C300') || '#FF3300';

const candlesAppearance = {
  wickStroke: colorLoader,
  fill: colorLoader,
  stroke: colorLoader,
  candleStrokeWidth: 1,
  widthRatio: 0.3,
  opacity: 0.9,
};

const CandleStickChart = (props) => {
  const {
    type, width, data = mockData, ratio,
  } = props;
  const xAccessor = d => d && d.date;
  const xExtents = [
    xAccessor(last(data)),
    xAccessor(data[0]),
  ];
  return (
    <ChartCanvas
      height={400}
      ratio={ratio}
      width={width}
      margin={{
        left: 100, right: 100, top: 20, bottom: 30,
      }}
      type={type}
      data={data}
      xAccessor={xAccessor}
      xScale={scaleTime()}
      xExtents={xExtents}
    >
      <Chart id={1} yExtents={d => [d.high, d.low]}>
        <XAxis axisAt="bottom" orient="bottom" ticks={10} />
        <YAxis axisAt="right" orient="right" ticks={8} tickStrokeOpacity={0} />
        <CandlestickSeries width={timeIntervalBarWidth(utcHour)} {...candlesAppearance} />
      </Chart>
    </ChartCanvas>
  );
};

export default compose(
  fitWidth,
  defaultProps({
    type: 'svg',
  }),
)(CandleStickChart);
