import { handleActions } from 'redux-actions';

import {
  updatePrices,
  updateHistorical,
} from '../actions/coinsAction';

export const initialState = {
  prices: {
    data: [],
    updatedAt: null,
  },
  historicals: {
    updatedAt: null,
  },
};

export default handleActions({
  [updatePrices]: (state, { payload: data }) => ({
    ...state, prices: { data, updatedAt: new Date().getTime() },
  }),
  [updateHistorical]: ({ historicals, ...state }, { payload: { data, symbol } }) => ({
    ...state,
    historicals: { ...historicals, [symbol]: data, updatedAt: new Date().getTime() },
  }),
}, initialState);
