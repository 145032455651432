import React from 'react';
import { compose } from 'recompose';

import Helmet from 'react-helmet';
import ApplyModal from './ApplyModal';
import withModal from '../../hocs/withModal';
import SectionPartner from '../common/SectionPartner';
import NoneRedirectA from '../common/NoneRedirectA';

const CareersSingle = ({ isOpenModal, closeModal, openModal }) => (
  <>
    <Helmet>
      <title>Paybit - Careers Single</title>
    </Helmet>
    <section className="bgc-grey pY-30 bdT">
      <div className="container">
        <ul className="breadcrumb breadcrumbs mB-20">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
            <a href="TODO#">Careers </a>
          </li>
          <li>
            <i className="fas fa-chevron-right" />
            <span> Association of Managemtn Consulting Firm</span>
          </li>
        </ul>
        <div className="article-container">
          <div className="row row-sm">
            <div className="col-md-12 col-lg-8 col-xl-8 mB-20">
              <div className="bd bgc-white p-35 mB-30">
                <h5 className="c-gray"><b className="fw-n">About this position</b></h5>
                <div className="mB-10 d-flex">
                  <div className="mR-25 fsz-50 c-grey">
                    <i className="icon-rocket" />
                  </div>
                  <div>
                    <h1 className="mB-10 fsz-20 fw-n">Association of Management Consulting Firms (Full Time)</h1>
                    <p className="c-text-gray">Paybit.com - San Franscisco , CA  - $34-45,000 + Benefits</p>
                    <div className="mB-20">
                      <span className="c-gray mR-10">
                        <i className="mR-10 c-blue icon-mapline" />
                        San Franscisco, CA
                      </span>
                      <span className="c-gray">
                        <i className="mR-10 c-blue icon-time" />
                        6 Days Left
                      </span>
                    </div>
                  </div>
                </div>
                <div className="article-content">
                  <p>
                    Times are really exciting at Electrolux.
                    We are busy revolutionizing peoples home,
                    focused in provide
                    effortless and delightful experiences for our consumers.
                    We come to work every day because we are passionate about shape living
                    for the better in the areas of Taste, Care, and Wellbeing.
                  </p>
                  <br />
                  <p>
                    We are currently looking for a talented
                    Design Manager
                    who will be part of the Latin America design team.
                    The chosen candidate will take care of a category of products,
                    accessories, and also supporting connectivity strategy,
                    guiding and inspiring a design group to deliver
                    human-centered products and solutions.
                  </p>
                  <br />
                  <h5 className="fw-n c-black">A regular day at work</h5>
                  <p>
                    You will be part of Electrolux Group Design,
                    to drive the design vision and strategy across
                    product solutions and will also lead the multidisciplinary
                    design team to deliver best in class consumer experiences for
                    home solutions to all Latin American countries.
                  </p>
                  <br />
                  <p>The main responsibilities are:</p>
                  <br />
                  <p>
                    - Build strong and consistent design proposals,
                    aligned with Electrolux Brand design DNA.
                  </p>
                  <br />
                  <p>
                    - Co-create with consumers to design
                    end-to-end experiences using
                    participatory and iterative design
                    techniques including observational studies,
                    customer interviews, usability testing, and other
                    forms of qualitative and quantitative research to uncover insights,
                    learn about user behavior and verify design concepts.
                  </p>
                  <br />
                  <p>
                    - Mentor and develop more junior designers by
                    helping them grow their hard and soft skills.
                  </p>
                  <br />
                  <p>
                    - Guide the team to develop best practices
                    for ensuring a high bar of design quality.
                  </p>
                  <br />
                  <p>
                    - Foster a culture of feedback,
                    continuous improvement and operational
                    excellence.
                  </p>
                  <br />
                  <p>
                    - Set clear goals, manage project loads,
                    ensuring the deadlines are met and set up squad
                    teams for overall success.
                  </p>
                  <br />
                  <p>
                    - Connect product and design strategy with
                    relevant stakeholders, such as R&amp;D, MKT,
                    and product line, also identifying
                    innovation opportunities.
                  </p>
                  <br />
                  <h5 className="fw-n c-black">A regular day at work</h5>
                  <p>
                    Agile. You love working according to design
                    thinking and agile principles and
                    have some experience in doing so.
                  </p>
                  <br />
                  <p>
                    - Customer focused. You are passionate
                    about creating excelente consumer experiences and prefer
                    that this drives the way the product is developed.
                  </p>
                  <br />
                  <p>
                    - A leader. You are passionate about people,
                    a great team leader, manager, and design thinker,
                    who will provide leadership and guidance to foster a culture of trust,
                    transparency, and collaboration.
                  </p>
                  <br />
                  <p>
                    - Collaboration. You will also collaborate with other
                    design leaders to develop talented designers,
                    and identify opportunities to establish best practices
                    for a seamless experience across all of our products.
                  </p>
                  <br />
                  <p>
                    - International. We think you’ve been exposed to both
                    large corporations and international environments.
                  </p>
                  <br />
                </div>
                <div className="social-share">
                  <ul>
                    <li><a href="/" className="hover-boxshadow"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="/" className="hover-boxshadow"><i className="fab fa-twitter" /></a></li>
                    <li><a href="/" className="hover-boxshadow"><i className="fab fa-instagram" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 col-xl-4 mB-20">
              <div className="bd bgc-white p-25">
                <h5 className="mB-20 line-height-2">What are you wating, Apply for this job </h5>
                <NoneRedirectA
                  className="btn btn-primary btn-lg w-100"
                  data-toggle="modal"
                  data-target="#applyModal"
                  onClick={openModal}
                  style={{ color: 'white' }}
                >
                  Sumbit your CV
                </NoneRedirectA>
              </div>
              <h5 className="mY-15"><b className="fw-n">More from Paybit.com</b></h5>
              <div className="mB-10 bgc-white d-flex bd p-20 job-box">
                <div className="mR-25 fsz-sup c-grey">
                  <i className="icon-rocket" />
                </div>
                <div>
                  <p className="c-text-gray">Paybit.com</p>
                  <h5 className="mB-10"><a href="/" className="c-black"><b className="fw-n">Association of Management Consulting Firms</b></a></h5>
                  <div className="mB-20">
                    <span className="c-gray mR-10">
                      <i className="mR-10 c-blue icon-mapline" />
                      San Franscisco, CA
                    </span>
                    <span className="c-gray">
                      <i className="mR-10 c-blue icon-time" />
                      6 Days Left
                    </span>
                  </div>
                  <a href="/" className="c-black">
                    <b className="fw-n">
                      View More
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
              <div className="mB-10 bgc-white d-flex bd p-20 job-box">
                <div className="mR-25 fsz-sup c-grey">
                  <i className="icon-message" />
                </div>
                <div>
                  <p className="c-text-gray">Paybit.com</p>
                  <h5 className="mB-10"><a href="/" className="c-black"><b className="fw-n">PR Careers in Consulting</b></a></h5>
                  <div className="mB-20">
                    <span className="c-gray mR-10">
                      <i className="mR-10 c-blue icon-mapline" />
                      San Franscisco, CA
                    </span>
                    <span className="c-gray">
                      <i className="mR-10 c-blue icon-time" />
                      6 Days Left
                    </span>
                  </div>
                  <a href="/" className="c-black">
                    <b className="fw-n">
                      View More
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
              <div className="mB-10 bgc-white d-flex bd p-20 job-box">
                <div className="mR-25 fsz-sup c-grey">
                  <i className="icon-chart-bold" />
                </div>
                <div>
                  <p className="c-text-gray">Paybit.com</p>
                  <h5 className="mB-10"><a href="/" className="c-black"><b className="fw-n">Association for Financial Professionals</b></a></h5>
                  <div className="mB-20">
                    <span className="c-gray mR-10">
                      <i className="mR-10 c-blue icon-mapline" />
                      San Franscisco, CA
                    </span>
                    <span className="c-gray">
                      <i className="mR-10 c-blue icon-time" />
                      6 Days Left
                    </span>
                  </div>
                  <a href="/" className="c-black">
                    <b className="fw-n">
                      View More
                      {' '}
                      <i className="icon-right-arrow" />
                    </b>

                  </a>
                </div>
              </div>
              <h5 className="mY-15"><b className="fw-n">Recent Post</b></h5>
              <div className="mB-5 bgc-white d-inline-block w-100 bd p-20 post-box-sidebar">
                <div className="circle-50 mR-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                <div className="post-box-content">
                  <h5 className="mB-5"><a href="/" className="c-black"><b className="fw-n">Ripple expands network worldwide for easier transfers.</b></a></h5>
                  <p className="mb-0 c-grey">John Smith</p>
                </div>
              </div>
              <div className="mB-5 bgc-white d-inline-block w-100 bd p-20 post-box-sidebar">
                <div className="circle-50 mR-25" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                <div className="post-box-content">
                  <h5 className="mB-5"><a href="/" className="c-black"><b className="fw-n">Study: US consumers prefer cash over plastic for small-ticket items.</b></a></h5>
                  <p className="mb-0 c-grey">Smith Cara</p>
                </div>
              </div>
              <div className="mB-5 bgc-white d-inline-block w-100 bd p-20 post-box-sidebar">
                <div className="circle-50 mR-25" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                <div className="post-box-content">
                  <h5 className="mB-5"><a href="/" className="c-black"><b className="fw-n">Hacker gets 14 years in prison for enabling Target breach.</b></a></h5>
                  <p className="mb-0 c-grey">Tanja Biberdzic</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApplyModal {...{ isOpenModal, closeModal, openModal }} />
    </section>
    <SectionPartner backgroundImage="/images/testimonials-bg-3.jpg" />
  </>
);

const CareersSingleContainer = compose(
  withModal(),
)(CareersSingle);

export default CareersSingleContainer;
