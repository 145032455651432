import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import SliderBlogTop from './SliderBlogTop';

const Blog = () => (
  <>
    <Helmet>
      <title>Paybit - Blog</title>
    </Helmet>
    <section className="section-banner-blog img" style={{ backgroundImage: 'url(/images/img-blog-first.jpg)' }}>
      <div className="container">
        <ul className="breadcrumb breadcrumbs breadcrumbs-white">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
            <span>Blog</span>
          </li>
        </ul>
        <div className="d-flex item-blog-banner mY-50">
          <div className="mR-25">
            <div className="circle-136" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
          </div>
          <div>
            <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
            <a href="/" className="c-black"><h4 className="fw-n title mY-10 fsz-30 c-white">Ripple expands network worldwide for easier transfers</h4></a>
            <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
          </div>
        </div>
      </div>
    </section>
    <SliderBlogTop />
    <ReactWOW animation="fadeInUp">
      <main className="blog-main">
        <div className="container">
          <div className="d-flex justify-content-between mB-50">
            <h4 className="fw-n">Today Posts</h4>
            <a href="/" className="c-gray td-u">View All</a>
          </div>
          <div className="list-blog">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-blog blog-style-1 mB-25">
                  <div className="blog-text bgc-white">
                    <a href="/single-blog" className="btn-category btn btn-around bgc-orange1 c-white">Statistics</a>
                    <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">Study: Gen Z prefers shopping over online</h4></a>
                    <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                  </div>
                  <div className="blog-image">
                    <a href="/single-blog">
                      <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-4.jpg)' }} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-8">
                <div className="box-blog blog-style-2 mB-25">
                  <div className="blog-image">
                    <a href="/single-blog">
                      <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-3.jpg)' }} />
                    </a>
                  </div>
                  <div className="blog-text bgc-white">
                    <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                    <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">PredictSpring intros &lsquo;modern&lsquo; mobile commerce platform for brick-and-mortar</h4></a>
                    <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row align-items-center box-blog blog-style-3 mB-25">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="mB-25">
                      <div className="blog-text bgc-white">
                        <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                        <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">Six Payment Services adds in-app payments feature for cruise operators</h4></a>
                        <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-8">
                    <div className="blog-image">
                      <a href="/single-blog">
                        <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-5.jpg)' }} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-8">
                <div className="box-blog blog-style-2 mB-25">
                  <div className="blog-image">
                    <a href="/single-blog">
                      <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-6.jpg)' }} />
                    </a>
                  </div>
                  <div className="blog-text bgc-white">
                    <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                    <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">PredictSpring intros &lsquo;modern&lsquo; mobile commerce platform for brick-and-mortar</h4></a>
                    <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-blog blog-style-1 mB-25">
                  <div className="blog-text bgc-white">
                    <a href="/" className="btn-category btn btn-around bgc-orange1 c-white">Statistics</a>
                    <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">Study: Gen Z prefers shopping over online</h4></a>
                    <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                  </div>
                  <div className="blog-image">
                    <a href="/single-blog">
                      <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-7.jpg)' }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mY-50">
              <h4 className="fw-n">Popular Story</h4>
              <a href="/blog" className="c-gray td-u">View All</a>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-blog blog-style-1 mB-25">
                  <div className="blog-text bgc-white">
                    <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">Study: Gen Z prefers in-store shopping over online</h4></a>
                    <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                  </div>
                  <div className="blog-image">
                    <a href="/single-blog">
                      <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-8.jpg)' }} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-blog blog-style-1 mB-25">
                  <div className="blog-image">
                    <a href="/single-blog">
                      <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-9.jpg)' }} />
                    </a>
                  </div>
                  <div className="blog-text bgc-white mT-20">
                    <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">Study: Gen Z prefers in-store shopping over online</h4></a>
                    <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-blog blog-style-1 mB-25">
                  <div className="blog-text bgc-white">
                    <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">Study: Gen Z prefers in-store shopping over online</h4></a>
                    <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                  </div>
                  <div className="blog-image">
                    <a href="/single-blog">
                      <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-10.jpg)' }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bgc-grey pY-50">
          <div className="container">
            <div className="d-flex justify-content-between mB-50">
              <h4 className="fw-n">Payment Blogs</h4>
              <a href="/" className="c-gray td-u">View All</a>
            </div>
            <div className="single-blog-related">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="blog-items blog-items-1 mB-25">
                    <div className="blog-text p-25 bgc-white">
                      <a href="/" className="btn-category btn btn-around bgc-orange1 c-white">Statistics</a>
                      <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">Study: Gen Z prefers shopping over online</h4></a>
                      <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                    </div>
                    <div className="blog-image">
                      <a href="/single-blog">
                        <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-12.jpg)' }} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <div className="blog-items blog-items-2 mB-25">
                    <div className="blog-image">
                      <a href="/single-blog">
                        <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-11.jpg)' }} />
                      </a>
                    </div>
                    <div className="blog-text p-25 bgc-white">
                      <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                      <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">PredictSpring intros &lsquo;modern&lsquo; mobile commerce platform for brick-and-mortar</h4></a>
                      <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="blog-items blog-items-3 mB-25">
                    <div className="blog-image">
                      <a href="/single-blog">
                        <div className="blog-img img" style={{ backgroundImage: 'url(/images/img-blog-13.jpg)' }} />
                      </a>
                    </div>
                    <div className="blog-text">
                      <div className="d-flex">
                        <div className="mR-25">
                          <div className="circle-136" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                        </div>
                        <div>
                          <a href="/" className="btn-category btn btn-around bgc-blue c-white">Payment</a>
                          <a href="/single-blog" className="c-black"><h4 className="fw-n title mY-10">Ripple expands network worldwide for easier transfers</h4></a>
                          <p className="c-gray">Jonathan Banks - 8 Minutes Ago</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </ReactWOW>
  </>
);

export default Blog;
