import React, { useRef } from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';
import { Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import withModal from '../../../hocs/withModal';
import { SignUpModal } from './SignUpModal';
// import { SignUpTabs } from './SignUpTabs';
import { LogInModal } from './LogInModal';
import { withAuth } from '../../../hocs/withAuth';
import NoneRedirectA from '../../common/NoneRedirectA';
import useOutsideClick from '../../../utils/useOutsideClick';
// import { LogInTabs } from './LogInTabs';

const HeaderComponent = ({
  active, isOpenModal, setIsOpenModal,
  isOpenModalSignUp, toggleModalSignUp, closeModalSignUp, openModalSignUp, setModalRefSignUp,
  isOpenModalSignIn, toggleModalSignIn, closeModalSignIn, openModalSignIn, setModalRefSignIn,
  isOpenModalProducts, toggleModalProducts,
  isOpenModalServices, toggleModalServices,
  isOpenModalApps, toggleModalApps,
  isAuth,
}) => {
  const { t } = useTranslation('header');
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpenModal(false));
  return (
    <header className={classNames('header', 'fixed-top', { active })}>
      <Navbar
        // variant="false"
        // animate={false}
        className="navbar navbar-header"
        expand="xl"
        as="nav"
        onToggle={setIsOpenModal}
        expanded={isOpenModal}
        ref={wrapperRef}
      >
        <div className="container">
          <div className="navbar-logo">
            <Navbar.Brand as="a" className="navbar-brand" href="/">
              <div className="logo-paybit-white" style={{ backgroundImage: 'url(/images/logo-paybit.svg)' }} />
            </Navbar.Brand>
          </div>
          <Navbar.Toggle
            className={classNames('navbar-toggler', { collapsed: !isOpenModal })}
            type="button"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            label="Toggle navigation"
            onClick={() => setIsOpenModal(!isOpenModal)}
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </Navbar.Toggle>
          <div className={classNames('navbar-right', { active: isOpenModal })}>
            <ul id="main-menu" className="list-unstyled">
              {/* <li><a href="/personal-tour">Wallet</a></li> */}
              <li className="menu-item-product">
                <NoneRedirectA onClick={toggleModalProducts} href="/personal-tour" disableWidth={1025}>{t('Products')}</NoneRedirectA>
                <div className={classNames(
                  'dropdown-content',
                  {
                    'dropdown-content--active': isOpenModalProducts,
                    'dropdown-content--hidden': !isOpenModalProducts,
                  },
                )}
                >
                  <div className="dropdown-content-inner">
                    <div className="dropdown-content-left">
                      <div className="dropdown-header">
                        <h5 className="c-grey fsz-xs tt-u">
                          <b className="fw-n">{t('Paybit Products')}</b>
                        </h5>
                      </div>
                      <div className="dropdown-body">
                        <div className="row">
                          <div className="col-md-6">
                            <a href="/personal-tour" className="products-items">
                              <i className="icon-wallet2" />
                              <div>
                                <b className="fsz-md c-black">{t('Wallet')}</b>
                                <p>{t('Open your personal PayBit Wallet')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="merchant-tour" className="products-items">
                              <i className="icon-search" />
                              <div>
                                <b className="fsz-md c-black">{t('Marketcap')}</b>
                                <p>{t('Live Crypto Exchangerates')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="business-tour" className="products-items">
                              <i className="fas fa-dollar-sign" />
                              <div>
                                <b className="fsz-md c-black">{t('Business')}</b>
                                <p>{t('Earn money with PayBit')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="/landing-page-illustration-2" className="products-items">
                              <i className="icon-detection" />
                              <div>
                                <b className="fsz-md c-black">{t('Fraud Detection')}</b>
                                <p>{t('Payment Protection Tour')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="explorer-search" className="products-items">
                              <i className="icon-explorer" />
                              <div>
                                <b className="fsz-md c-black">{t('Explorer')}</b>
                                <p>{t('Currency Transaction Explorer')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="/careers" className="products-items">
                              <i className="icon-shops" />
                              <div>
                                <b className="fsz-md c-black">{t('Careers')}</b>
                                <p>{t('Start your PayBit Career')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="/crypto-market" className="products-items">
                              <i className="icon-stats" />
                              <div>
                                <b className="fsz-md c-black">{t('Crypto Market')}</b>
                                <p>{t('Latest Market Information')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="marketplace" className="products-items">
                              <i className="icon-marketplace" />
                              <div>
                                <b className="fsz-md c-black">{t('Marketplace')}</b>
                                <p>{t('Sell and buy online')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="/landing-pagebest" className="products-items">
                              <i className="icon-shopping-bag-check" />
                              <div>
                                <b className="fsz-md c-black">{t('Referral')}</b>
                                <p>{t('Earn promoting PayBit')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="personal-tour" className="products-items">
                              <i className="icon-personal" />
                              <div>
                                <b className="fsz-md c-black">{t('Personal Tour')}</b>
                                <p>{t('Get your money faster, easier and cheaper')}</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-content-right">
                      <div className="dropdown-header">
                        <h5 className="c-grey fsz-xs tt-u">
                          <b className="fw-n">{t('Paybit Benefits')}</b>

                        </h5>
                      </div>
                      <div className="navigation__sub-content">
                        <a className="navigation__sub-item-link" href="pricing-fees">
                          <p className="navigation__sub-subtitle navigation__sub-highlight">{t('Pricing')}</p>
                          <p className="navigation__sub-desc">{t('Transparent pricing for all businesses')}</p>
                        </a>
                        <div className="navigation__sub-item-link">
                          <p className="navigation__sub-subtitle submenu-ct">{t('Company')}</p>
                          <a className="navigation__sub-desc navigation__sub-highlight" href="help-center-articles">{t('Support')}</a>
                          <a className="navigation__sub-desc navigation__sub-highlight" href="blog">{t('Blog')}</a>
                          <a className="navigation__sub-desc navigation__sub-highlight" href="help-center">{t('Help & Support')}</a>
                          <a className="navigation__sub-desc navigation__sub-highlight" href="about-us">{t('Check Our Story')}</a>
                          <a className="navigation__sub-desc navigation__sub-highlight" href="/">{t('Common')}</a>
                          <a className="navigation__sub-desc navigation__sub-highlight" href="contact-us">{t('Contact Us')}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-item-product menu-item-product-colum2">
                <NoneRedirectA onClick={toggleModalServices} href="/merchant-tour" disableWidth={1025}>{t('Services')}</NoneRedirectA>
                <div className={classNames(
                  'dropdown-content',
                  {
                    'dropdown-content--active': isOpenModalServices,
                    'dropdown-content--hidden': !isOpenModalServices,
                  },
                )}
                >
                  <div className="dropdown-content-inner">
                    <div className="dropdown-content-left">
                      <div className="dropdown-header">
                        <h5 className="c-grey fsz-xs tt-u">
                          <b className="fw-n">{t('Paybit Services')}</b>

                        </h5>
                      </div>
                      <div className="dropdown-body">
                        <div className="row">
                          <div className="col-md-6">
                            <a href="pricing-fees" className="products-items">
                              <i className="icon-wallet2" />
                              <div>
                                <b className="fsz-md c-black">{t('Pricing & Packaging')}</b>
                                <p>{t('Rates for payments')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="about-us" className="products-items">
                              <i className="icon-search" />
                              <div>
                                <b className="fsz-md c-black">{t('Check Our Story')}</b>
                                <p>{t('Paybit History')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="business-tour" className="products-items">
                              <i className="fas fa-dollar-sign" />
                              <div>
                                <b className="fsz-md c-black">{t('Invoice & Billing')}</b>
                                <p>{t('Pay your Invoices online')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="paybit-status-page" className="products-items">
                              <i className="icon-detection" />
                              <div>
                                <b className="fsz-md c-black">{t('Paybit Status')}</b>
                                <p>{t('Uptime and status')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="personal-tour" className="products-items">
                              <i className="icon-explorer" />
                              <div>
                                <b className="fsz-md c-black">{t('Wallet')}</b>
                                <p>{t('Send, Receive, and Trade')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="merchant-tour" className="products-items">
                              <i className="icon-shops" />
                              <div>
                                <b className="fsz-md c-black">{t('Merchants')}</b>
                                <p>{t('Busines Solutions for you')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="/" className="products-items">
                              <i className="icon-stats" />
                              <div>
                                <b className="fsz-md c-black">{t('Common')}</b>
                                <p>{t('More Paybit Information')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="/paybit-status-page" className="products-items">
                              <i className="icon-marketplace" />
                              <div>
                                <b className="fsz-md c-black">{t('Message Center')}</b>
                                <p>{t('Get Paybit Help online')}</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-item-product menu-item-product-colum2">
                <a href="/what-is-bitcoin">{t('Solutions')}</a>
                <div className="dropdown-content">
                  <div className="dropdown-content-inner">
                    <div className="dropdown-content-left">
                      <div className="dropdown-header">
                        <h5 className="c-grey fsz-xs tt-u"><b className="fw-n">{t('Paybit Solutions')}</b></h5>
                      </div>
                      <div className="dropdown-body">
                        <div className="row">
                          <div className="col-md-6">
                            <a href="toolbar-download" className="products-items">
                              <i className="fas fa-dollar-sign" />
                              <div>
                                <b className="fsz-md c-black">{t('Toolbar Download')}</b>
                                <p>
                                  {t('Get your money faster, easier and cheaper with PayBit.')}
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="what-is-bitcoin" className="products-items">
                              <i className="icon-wallet2" />
                              <div>
                                <b className="fsz-md c-black">{t('Training Portal')}</b>
                                <p>{t('Manage your business on the go.')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="ico-landing" className="products-items">
                              <i className="icon-shops" />
                              <div>
                                <b className="fsz-md c-black">{t('ICO Listing Application')}</b>
                                <p>{t('Manage your business on the go.')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="cryptop-currency-converting" className="products-items">
                              <i className="icon-search" />
                              <div>
                                <b className="fsz-md c-black">{t('Currency Comparsion')}</b>
                                <p>{t('NEM - (XEM) VS TenX - (PAY) Comparison - 1 day')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="currency-comparsion" className="products-items">
                              <i className="icon-stats" />
                              <div>
                                <b className="fsz-md c-black">{t('Exchange Crypto')}</b>
                                <p>{t('Exchange cryptocurrency at the best rate')}</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-item-product menu-item-product-colum2">
                <NoneRedirectA onClick={toggleModalApps} href="download-desktop-linux" disableWidth={1025}>{t('Apps')}</NoneRedirectA>
                <div className={classNames(
                  'dropdown-content',
                  {
                    'dropdown-content--active': isOpenModalApps,
                    'dropdown-content--hidden': !isOpenModalApps,
                  },
                )}
                >
                  <div className="dropdown-content-inner">
                    <div className="dropdown-content-left">
                      <div className="dropdown-header">
                        <h5 className="c-grey fsz-xs tt-u">
                          <b className="fw-n">{t('Paybit APPS')}</b>

                        </h5>
                      </div>
                      <div className="dropdown-body">
                        <div className="row">
                          <div className="col-md-6">
                            <a href="download-desktop-linux" className="products-items">
                              <i className="fas fa-dollar-sign" />
                              <div>
                                <b className="fsz-md c-black">{t('Desktop')}</b>
                                <p>{t('Download the Desktop App')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="download-android-phone" className="products-items">
                              <i className="icon-wallet2" />
                              <div>
                                <b className="fsz-md c-black">{t('Android Phone')}</b>
                                <p>{t('Download the Android Phone App.')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="download-android-tablet" className="products-items">
                              <i className="icon-shops" />
                              <div>
                                <b className="fsz-md c-black">{t('Android Tablet')}</b>
                                <p>{t('Download the Android Tablet App.')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="download-apple-mac" className="products-items">
                              <i className="icon-search" />
                              <div>
                                <b className="fsz-md c-black">{t('Apple Mac')}</b>
                                <p>{t('Download the Mac App.')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="download-ipad" className="products-items">
                              <i className="icon-detection" />
                              <div>
                                <b className="fsz-md c-black">{t('Apple Ipad')}</b>
                                <p>{t('Download the Ipad App.')}</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a href="download-iphone-phone" className="products-items">
                              <i className="icon-explorer" />
                              <div>
                                <b className="fsz-md c-black">{t('Apple Iphone')}</b>
                                <p>{t('Download the Iphone App.')}</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li><a href="/help-center">{t('Help & Support')}</a></li>
              {
                !isAuth && (
                  <>
                    <li className="menu-sign-in">
                      <a
                        href="#modalLogin"
                        className="btn btn-outline-light btn-around"
                        onClick={openModalSignIn}
                        data-toggle="modal"
                        data-target="#modalLogin"
                      >
                        Log In
                      </a>
                      <LogInModal
                        {...{
                          isOpenModalSignIn,
                          toggleModalSignIn,
                          closeModalSignIn,
                          setModalRefSignIn,
                          isOpenModalSignUp,
                          toggleModalSignUp,
                          closeModalSignUp,
                          setModalRefSignUp,
                          openModalSignUp,
                        }}
                      />
                    </li>
                    <li className="menu-sign-in li-sign">
                      <a
                        // role="presentation"
                        href="#modalSignup"
                        className="btn btn-around btn-primary"
                        onClick={openModalSignUp}
                        data-toggle="modal"
                        data-target="#modalSignup"
                      >
                        Sign Up
                      </a>
                      <SignUpModal
                        {...{
                          isOpenModalSignUp,
                          toggleModalSignUp,
                          closeModalSignUp,
                          setModalRefSignUp,
                          isOpenModalSignIn,
                          toggleModalSignIn,
                          closeModalSignIn,
                          setModalRefSignIn,
                          openModalSignIn,
                        }}
                      />
                    </li>
                  </>
                )
              }
            </ul>
          </div>
        </div>
      </Navbar>
    </header>
  );
};

export default compose(
  withModal(),
  withModal('SignUp'),
  withModal('SignIn'),
  withModal('Products', false),
  withModal('Services', false),
  withModal('Apps', false),
  withAuth,
)(HeaderComponent);
