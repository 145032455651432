import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withAuth, withLogin } from '../../../hocs/withAuth';

const UserDropdown = ({ logout, t }) => (
  <Dropdown className="dropdown dropdown-user">
    <Dropdown.Toggle className="dropdown-toggle peers ai-c lh-1" data-toggle="dropdown" as="a">
      <div className="peer mR-10"><div className="user-avatar img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} /></div>
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-menu">
      <Dropdown.Toggle className="btn btn-transparent btn-close-dropdown without-after" as="button">
        <i className="icon-close" />
      </Dropdown.Toggle>
      <li className="user">
        <a href="dashboard_new/main-settings" className="d-flex peers td-n pY-15 pX-10 c-grey-800 cH-blue bgcH-grey-100">
          <div className="peer mR-15">
            <div className="size-40 img circle" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
          </div>
          <div className="peer peer-greed">
            <span className="fw-500">John Carmack</span>
            <p className="c-gray fsz-xs">
              <i className="mR-5 icon-mail" />
              johncarmack@gmail.com
            </p>
          </div>
        </a>
      </li>
      <li className="drop-paybit-balance">
        <a href="/">
          <div className="paybit-balance">
            <i className="icon-wallet1" />
            <div>
              {t('Paybit Balance')}
              {' '}
              <br />
              {' '}
              <span className="black">$4,687.11</span>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a href="/dashboard_new/message-center" className="d-block td-n bgcH-grey-100">
          <span className="icon-holder c-grey-400 mR-10">
            <i className="icon-mail" />
          </span>
          <span className="c-grey-500">{t('Message')}</span>
        </a>
      </li>
      <li>
        <a href="/dashboard_new/main-settings" className="d-block td-n bgcH-grey-100">
          <span className="icon-holder c-grey-400 mR-10">
            <i className="icon-profile" />
          </span>
          <span className="c-grey-500">{t('Profile')}</span>
        </a>
      </li>
      <li>
        <a href="/dashboard_new/main-settings" className="d-block td-n bgcH-grey-100">
          <span className="icon-holder c-grey-400 mR-10">
            <i className="icon-settings" />
          </span>
          <span className="c-grey-500">{t('Account Settings')}</span>
        </a>
      </li>
      <li>
        <a href="/dashboard_new/notification-main" className="d-block td-n bgcH-grey-100">
          <span className="icon-holder c-grey-400 mR-10">
            <i className="icon-notifications" />
          </span>
          <span className="c-grey-500">{t('Notifications')}</span>
        </a>
      </li>
      <li>
        <a href="/dashboard_new/contact" className="d-block td-n bgcH-grey-100">
          <span className="icon-holder c-grey-400 mR-10">
            <i className="icon-contacts" />
          </span>
          <span className="c-grey-500">{t('Contacts')}</span>
        </a>
      </li>
      <li>
        <a href="/dashboard_new/activity-history" className="d-block td-n bgcH-grey-100">
          <span className="icon-holder c-grey-400 mR-10">
            <i className="icon-security" />
          </span>
          <span className="c-grey-500">{t('Security')}</span>
        </a>
      </li>
      <li role="separator" className="divider" />
      <li>
        <a href="/dashboard_new/business-merchant" className="d-block td-n bgcH-grey-100">
          <span className="icon-holder c-grey-400 mR-10">
            <i className="icon-merchant" />
          </span>
          <span className="c-grey-500">{t('Merchants')}</span>
        </a>
      </li>
      <li>
        <a href="detail" className="d-block td-n bgcH-grey-100">
          <span className="icon-holder c-grey-400 mR-10">
            <i className="icon-developers" />
          </span>
          <span className="c-grey-500">{t('Developers')}</span>
        </a>
      </li>
      <li>
        <a href="/dashboard_new/main-settings" className="d-block td-n bgcH-grey-100">
          <span className="icon-holder c-grey-400 mR-10">
            <i className="icon-lock" />
          </span>
          <span className="c-grey-500">{t('Lock Account')}</span>
        </a>
      </li>
      <li>
        <a href="/" className="d-block td-n bgcH-grey-100" onClick={logout}>
          <span className="icon-holder c-grey-400 mR-10">
            <i className="icon-logout" />
          </span>
          <span className="c-grey-500">{t('Sign Out')}</span>
        </a>
      </li>
    </Dropdown.Menu>
  </Dropdown>

);

export default compose(
  withAuth,
  withLogin,
  withTranslation('UserDropdown'),
)(UserDropdown);
