// @flow
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import classNames from 'classnames';

import {
  compose, withState, lifecycle,
} from 'recompose';
import AppRoute from './AppRoute';
import Header from './Header';
import Footer from './Footer';

const fixedHeaderRoute = [
  '/',
  '/landing-pagebest',
  '/landing-page-illustration-2',
  '/about-us',
  '/business-tour',
  '/personal-tour',
  '/cryptocurrency-calculato',
  '/download-android-phone',
  '/download-android-tablet',
  '/download-apple-mac',
  '/download-ipad',
  '/download-iphone-phone',
  '/cryptop-currency-converting',
  '/currency-comparsion',
];

const WrapperContainer = compose(
  withState('fixed', 'setFixed', props => fixedHeaderRoute.includes(props.location.pathname)),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { location: { pathname }, fixed, setFixed } = this.props;
      if (pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
        const isFixed = fixedHeaderRoute.includes(pathname);
        if (isFixed !== fixed) setFixed(isFixed);
      }
    },
  }),
)(({ fixed = false }) => (
  <div className={classNames('wrapper', { 'wrapper-header-notfixed': !fixed })}>
    <Header />
    <AppRoute />
    <Footer />
  </div>
));

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={WrapperContainer} />
    </Switch>
  </BrowserRouter>
);

export default App;
