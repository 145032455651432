import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import PartnerItems from '../common/PartnerItems';

const MerchantTour = () => (
  <>
    <Helmet>
      <title>Paybit - Merchant Tour</title>
    </Helmet>
    <section className="merchant-tour-banner">
      <div className="merchant-tour-header">
        <div className="container">
          <div className="pY-30 mB-50">
            <ul className="breadcrumb breadcrumbs breadcrumbs-white">
              <li><a href="/"><i className="icon-house" /></a></li>
              <li>
                <i className="fas fa-chevron-right" />
                Merchant Tour
              </li>
            </ul>
          </div>
          <div className="row justify-content-between pB-100">
            <div className="col-md-12 col-lg-6 col-xl-5">
              <div className="merchant-tour-banner-left c-white">
                <ReactWOW animation="slideInLeft" duration="0.5s">
                  <p className="mB-30 c-white-60">Paybit for business</p>
                </ReactWOW>
                <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.25s">
                  <h1 className="mB-30 fw-n">
                    Accept Payments
                    <br />
                    within minutes.
                  </h1>
                </ReactWOW>
                <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.5s">
                  <ul className="fsz-def list-unstyled line-height-2">
                    <li>
                      <i className="fas fa-check c-white mR-10" />
                      Fast — Go live in minutes, not days
                    </li>
                    <li>
                      <i className="fas fa-check c-white mR-10" />
                      Free — No fees to accept crypto
                    </li>
                    <li>
                      <i className="fas fa-check c-white mR-10" />
                      Global — Tap into a global customer base
                    </li>
                    <li>
                      <i className="fas fa-check c-white mR-10" />
                      No Middlemen — Be your own bank
                    </li>
                    <li>
                      <i className="fas fa-check c-white mR-10" />
                      Irreversible — Chargebacks are a thing of the past
                    </li>
                  </ul>
                </ReactWOW>
                <div className="mY-30">
                  <ReactWOW animation="slideInRight" duration="0.5s" delay="1.25s">
                    <a href="/" className="btn btn-lg bdrs6 btn-light">Get Started</a>
                  </ReactWOW>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 col-xl-4">
              <div className="bgc-white bd merchant-tour-banner-right">
                <p className="fsz-sm mT-20">To pay send amount to the address:</p>
                <form action="#">
                  <div className="form-group">
                    <label className="c-gray">Amount</label>
                    <div className="input-coppy">
                      <input type="text" className="form-control form_control" defaultValue="0.00804668 ETH ($10.00)" />
                      <button className="btn-transparent"><i className="icon-copy" /></button>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="c-gray">Address</label>
                    <div className="input-coppy">
                      <input type="text" className="form-control form_control" defaultValue="0xd200d0f4d02a980d376b5D" />
                      <button className="btn-transparent"><i className="icon-copy" /></button>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-flex">
                      <div className="cirle-counting mR-10">
                        <div className="circle" />
                        <div className="center" />
                      </div>
                      <b className="mR-10">14:46</b>
                      <span className="c-gray">Awaiting payment....</span>
                    </div>
                  </div>
                  <button className="btn btn-lg btn-primary w-100">Pay with Litecoin</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center mT-50 ">
          <div className="col-md-12 col-lg-9">
            <h2 className="mY-50 text-center text-before-decor">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</h2>
          </div>
        </div>
        <div className="mT-30">
          <PartnerItems />
        </div>
      </div>
    </section>
    <ReactWOW animation="fadeInUp">
      <section className="pY-50 section-img-text">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12 col-lg-6 section-img-text-text">
              <div>
                <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
                <h3 className="fw-n mB-15">Accept payments</h3>
                <p className="c-text-gray mB-15">
                  We&lsquo;ll have you set up with hosted checkout pages,
                  payment buttons, and multiple ecommerce integrations
                  Working on refreshing some of our pages. Idea with the
                  header image is to feature some of our big merchants and
                  theme by their industry. Expedia hotels = beach :)
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 section-img-text-img">
              <div className="img-accept-payments" style={{ backgroundImage: 'url(/images/accept-payments.png)' }} />
            </div>
          </div>
        </div>
      </section>
    </ReactWOW>
    <section className="pY-50">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-6 col-lg-4 box-shadow-mb">
            <div className="main-feature-item-xl feature-item-style-1 box-hover-zoom">
              <div className="main-feature-item-xl feature-item-style-1">
                <div className="main-feature-item-icon">
                  <i className="icon-exchange_money" />
                </div>
                <h5 className="fw-n">
                  Quick Actions:
                  <br />
                  Resend &amp; refund
                </h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                        Allows your customers to pay with their favourite cryptocurrencies
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 box-shadow-mb">
            <div className="main-feature-item-xl feature-item-style-1 box-hover-zoom">
              <div className="main-feature-item-xl feature-item-style-1">
                <div className="main-feature-item-icon">
                  <i className="icon-support-light" />
                </div>
                <h5 className="fw-n">
                  Simplified disputes
                  <br />
                  resolution
                </h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                        No need to worry about market volatility — you always get what you asked for.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 box-shadow-mb">
            <div className="main-feature-item-xl feature-item-style-1 box-hover-zoom">
              <div className="main-feature-item-xl feature-item-style-1">
                <div className="main-feature-item-icon">
                  <i className="icon-infomation" />
                </div>
                <h5 className="fw-n">
                  All information
                  <br />
                  on one screen
                </h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    This makes our integration risk and
                    hassle-free for merchants and their business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="merchant-our-product">
      <ReactWOW animation="fadeInUp">
        <div className="pY-50 section-img-text">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-12 col-lg-6 section-img-text-img">
                <div className="img-easy-api" style={{ backgroundImage: 'url(/images/easy-api.png)' }} />
              </div>
              <div className="col-md-12 col-lg-6 section-img-text-text">
                <div>
                  <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
                  <h3 className="fw-n mB-15">Easy API</h3>
                  <p className="c-text-gray mB-15">
                    Millions of customers around the world use us for one
                    simple reason: it’s simple. Just an email address and
                    password will get you through to checkout before you can
                    reach for your wallet.
                    <br />
                    <br />
                    Expand the Paybit network and earn money!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactWOW>
      <ReactWOW animation="fadeInUp">
        <section className="pY-50 section-img-text">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-12 col-lg-6  section-img-text-text">
                <div>
                  <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
                  <h3 className="fw-n mB-15">Plugins for browsers software to integrate paybit</h3>
                  <p className="c-text-gray mB-15">
                    Millions of customers around the world use us for one
                    simple reason: it’s simple. Just an email address and
                    password will get you through to checkout before you
                    can reach for your wallet.
                    <br />
                    <br />
                      Expand the Paybit network and earn money!
                    <br />
                    <br />
                      Refer your friends, family, clients and
                      contacts to Paybit and qualify for cash rewards.
                      It’s simple: refer someone to use Paybit, and if
                      they meet the conditions (read more) then you’ll
                      get paid! All you have to do is copy and paste
                      your personalized link or banner code into an
                      email or your website to get started!
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 plugin-browser  section-img-text-img">
                <a href="/" className="btn btn-browser btn-chrome">
                Chrome Plugin
                  {' '}
                  <i className="icon-arrow-right-circle" />
                </a>
                <a href="/" className="btn btn-browser btn-firefox">
                  Firefox Plugin
                  <i className="icon-arrow-right-circle" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </ReactWOW>
      <ReactWOW animation="fadeInUp">
        <section className="pY-50 section-img-text">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-12 col-lg-6  section-img-text-img">
                <div className="img-fast-stats" style={{ backgroundImage: 'url(/images/fast-stats.png)' }} />
              </div>
              <div className="col-md-12 col-lg-6  section-img-text-text">
                <div>
                  <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
                  <h3 className="fw-n mB-15">Fast stats analytics process via paybit integration API</h3>
                  <p className="c-text-gray mB-15">
                    Millions of customers around the world use us for one simple
                    reason: it’s simple. Just an email address and password will
                    get you through to checkout before you can reach for your wallet.
                    <br />
                    <br />
                    Expand the Paybit network and earn money!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ReactWOW>
    </section>
    <ReactWOW animation="fadeInUp">
      <section className="pY-50 section-img-text">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12 col-lg-6 section-img-text-text">
              <div>
                <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
                <h3 className="fw-n mB-15">Accept Payment Instantly</h3>
                <p className="c-text-gray mB-15">
                    Millions of customers around the
                    world use us for one simple reason: it’s simple.
                    Just an email address and password will get you through
                    to checkout before you can reach for your wallet.
                  <br />
                  <br />
                    Expand the Paybit network and earn money!
                  <br />
                  <br />
                  Refer your friends, family, clients and contacts to Paybit
                  and qualify for cash rewards. It’s simple: refer someone to use Paybit,
                  and if they meet the conditions (read more) then you’ll get paid!
                  All you have to do is copy and paste your personalized link or
                  banner code into an email or your website to get started!
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 section-img-text-img">
              <div
                className="img-accept-payment-instantly"
                style={{ backgroundImage: 'url(/images/accept-payment-instantly.png)' }}
              />
            </div>
          </div>
        </div>
      </section>
    </ReactWOW>
    <section className="section-imgs section-subscribe" style={{ backgroundImage: 'url(/images/subscribe-bg.jpg)' }}>
      <div className="inner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-6">
              <div className="c-white text-center">
                <h2 className="fsz-sup fw-n mB-10">Subscribe Our Newsletter!</h2>
                <p className="c-white-90 mB-20 fsz-md">For get all of our latest updates and promotion</p>
                <div className="form-subscribe">
                  <div className="input-group">
                    <input type="email" className="form-control form_control" placeholder="Enter your email address" />
                    <div className="input-group-append">
                      <button className="btn-primary btn"><i className="icon-right-arrow" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default MerchantTour;
