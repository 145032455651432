import React from 'react';
import {
  LineChart, Line,
  XAxis, YAxis,
  Tooltip,
  ResponsiveContainer, CartesianGrid,
} from 'recharts';

const mockData = [
  {
    xCategory: '0', value: 116, day: 'Thu',
  },
  { xCategory: '1', value: 180 },
  { xCategory: '2', value: 72 },
  { xCategory: '3', value: 156 },
  { xCategory: '4', value: 2 },
  { xCategory: '5', value: 137 },
  {
    xCategory: '6', value: 51, day: 'Fri',
  },
  { xCategory: '7', value: 119 },
  { xCategory: '8', value: 86 },
  { xCategory: '9', value: 139 },
  {
    xCategory: '10', value: 194, day: 'Sat',
  },
  { xCategory: '11', value: 90 },
  { xCategory: '12', value: 189 },
  { xCategory: '13', value: 59 },
  { xCategory: '14', value: 156 },
  { xCategory: '15', value: 19 },
  {
    xCategory: '16', value: 148, day: 'Sun',
  },
  { xCategory: '17', value: 64 },
  { xCategory: '18', value: 127 },
  { xCategory: '19', value: 4 },
  { xCategory: '20', value: 144 },
  { xCategory: '21', value: 66 },
  {
    xCategory: '22', value: 93, day: 'Mon',
  },
  { xCategory: '23', value: 41 },
  { xCategory: '24', value: 30 },
  { xCategory: '25', value: 179 },
  { xCategory: '26', value: 108 },
  { xCategory: '27', value: 189 },
  {
    xCategory: '28', value: 145, day: 'Tue',
  },
  { xCategory: '29', value: 74 },
  { xCategory: '30', value: 125 },
  { xCategory: '31', value: 35 },
  { xCategory: '32', value: 122 },
  { xCategory: '33', value: 80 },
  {
    xCategory: '34', value: 42, day: 'Today',
  },
  { xCategory: '35', value: 47 },
  { xCategory: '36', value: 53 },
  { xCategory: '37', value: 58 },
  { xCategory: '38', value: 63 },
  { xCategory: '39', value: 68 },
  {
    xCategory: '40', value: 73,
  },
  { xCategory: '39', value: 78 },
  { xCategory: '39', value: 83 },
];

const CustomTick = (props) => {
  const { x, y, payload: { value } } = props;
  const { day } = mockData.find(payload => payload.xCategory === value);
  return day ? (
    <g transform={`translate(${x + 30},${y})`} {...props}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">{day}</text>
    </g>
  ) : null;
};

const SingleLineChart = ({
  data = mockData, height = 400, width = '100%', color = '#FF3300',
}) => (
  <ResponsiveContainer>
    <LineChart data={data} margin={{ bottom: 10, right: 20 }} height={height} width={width}>
      <YAxis dataKey="value" tickLine={false} axisLine={false} tick={{ transform: 'translate(-10, 0)' }} />
      <XAxis dataKey="xCategory" tickLine={false} tick={CustomTick} />
      <CartesianGrid vertical={false} />
      <Tooltip
        viewBox={{
          x: -1,
          y: -1,
          width: 400,
          height: 400,
        }}
      />
      <Line type="linear" dataKey="value" stroke={color} strokeWidth={2} fillOpacity={1} dot={false} />
    </LineChart>
  </ResponsiveContainer>
);

export default SingleLineChart;
