import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

const ToolbarDownload = () => (
  <section className="bdT section-download-toolbar" style={{ backgroundImage: 'url(/images/background-wave-gray.jpg)' }}>
    <Helmet>
      <title>Paybit - Toolbar Download</title>
    </Helmet>
    <div className="container">
      <div className="mB-25">
        <ul className="breadcrumb breadcrumbs">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
            Browser Plugins
          </li>
        </ul>
      </div>
    </div>
    <div className="download-toolbar-item">
      <ReactWOW animation="fadeInLeft">
        <div className="download-toolbar-item-img">
          <div style={{ backgroundImage: 'url(/images/mockups-laptop-dashboard.png)' }} />
        </div>
      </ReactWOW>
      <ReactWOW animation="fadeInRight">
        <div className="download-toolbar-item-text">
          <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
          <h4 className="fw-n mB-15">Download PayBit Toolbar for your browser</h4>
          <p className="c-text-gray mB-15">
            Millions of customers around the
            world use us for one simple reason:
            it’s simple. Just an email address
            and password will get you through to
            checkout before you can reach for your wallet.
            <br />
            <br />
            Expand the Paybit network and earn money!
            <br />
            <br />
            Refer your friends, family, clients and
            contacts to Paybit and qualify for cash rewards.
            It’s simple: refer someone to use Paybit, and if
            they meet the conditions (read more) then you’ll
            get paid! All you have to do is copy and paste your
            personalized link or banner code into an email or your
            website to get started!

          </p>
          <div className="mT-20">
            <div className="row row-sm">
              <div className="col-12 col-sm-6">
                <a href="/" className="btn btn-browser btn-chrome">
                  Chrome Plugin
                  {' '}
                  <i className="icon-arrow-right-circle" />
                </a>
              </div>
              <div className="col-12 col-sm-6">
                <a href="/" className="btn btn-browser btn-firefox">
                  Firefox Plugin
                  {' '}
                  <i className="icon-arrow-right-circle" />
                </a>
              </div>
              <div className="col-12 col-sm-6">
                <a href="/" className="btn btn-browser btn-edge">
                  Edge Plugin
                  {' '}
                  <i className="icon-arrow-right-circle" />
                </a>
              </div>
              <div className="col-12 col-sm-6">
                <a href="/" className="btn btn-browser btn-opera">
                  Opera Plugin
                  {' '}
                  <i className="icon-arrow-right-circle" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </ReactWOW>
    </div>
  </section>
);

export default ToolbarDownload;
