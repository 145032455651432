import React from 'react';
import Slider from 'react-slick';

const settings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
};

const DownloadCustomerSlider = () => (
  <Slider {...settings} className="slider download-customer-slider">
    <div className="item">
      <div className="d-flex">
        <div className="mR-20">
          <div className="circle-50" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
        </div>
        <div>
          <p className="c-gray"><i>“Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</i></p>
          <p className="c-gray">
            <b className="fw-n c-black">Bill Johnson</b>
              - Customer
          </p>
        </div>
      </div>
    </div>
    <div className="item">
      <div className="d-flex">
        <div className="mR-20">
          <div className="circle-50" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
        </div>
        <div>
          <p className="c-gray"><i>“Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</i></p>
          <p className="c-gray">
            <b className="fw-n c-black">Tom Glato</b>
            {' '}
- Customer
          </p>
        </div>
      </div>
    </div>
    <div className="item">
      <div className="d-flex">
        <div className="mR-20">
          <div className="circle-50" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
        </div>
        <div>
          <p className="c-gray"><i>“Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</i></p>
          <p className="c-gray">
            <b className="fw-n c-black">Jonathan Banks</b>
            {' '}
- Customer
          </p>
        </div>
      </div>
    </div>
  </Slider>
);

export default DownloadCustomerSlider;
