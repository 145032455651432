import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import { Dropdown } from 'react-bootstrap';
import { compose } from 'recompose';

import SectionPartner from '../common/SectionPartner';
import Account from '../common/FAQS/Account';
import SecurityNPrivacy from '../common/FAQS/SecurityNPrivacy';
import PartnerItems from '../common/PartnerItems';
import withDropDown from '../../hocs/withDropDown';

const coins = [{ name: 'Bitcoin', image: '/images/bitcoin.png' },
  { name: 'Neo', image: '/images/neo.png' },
  { name: 'Ethereum', image: '/images/ethereum.png' }];

const DropdownCoin = compose(
  withDropDown({ defaultGetter: () => coins[0] }),
)(({ selectedValue: { name, image }, changeValue }) => (
  <Dropdown className="dropdown select-coin btn bd d-flex align-items-center">
    <Dropdown.Toggle as="div" className="btn-transparent dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true">
      <span className="coin" style={{ backgroundImage: `url(${image})` }} />
      <span className="c-black">{name}</span>
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-menu">
      <ul className="lis-n p-0 m-0">
        {coins.map((coin, index) => (
          <Dropdown.Item onClick={() => changeValue(coin)} key={index}>
            <span className="coin" style={{ backgroundImage: `url(${coin.image})` }} />
            <span className="c-black">{coin.name}</span>
          </Dropdown.Item>
        ))}
      </ul>
    </Dropdown.Menu>
  </Dropdown>
));


const CryptopCurrencyConverting = () => (
  <>
    <Helmet>
      <title>Paybit - Cryptop Currency Converting</title>
    </Helmet>
    <section
      className="top-banner-medium"
      style={{ backgroundImage: 'url(/images/crypto-currency-converting-unsplash.jpg)' }}
    >
      <div className="top-banner-common-inner c-white">
        <div className="container">
          <ul className="breadcrumb breadcrumbs breadcrumbs-white mT-40">
            <li><a href="/"><i className="icon-house" /></a></li>
            <li>
              <i className="fas fa-chevron-right" />
              <a href="/">Exchange Crypto</a>
            </li>
          </ul>
          <ReactWOW animation="fadeInUp">
            <div className="top-banner-text">
              <h1 className="fsz-50 fw-n mB-20">
                Exchange cryptocurrency
                <br className="hidden-md" />
                at the best rate
              </h1>
              <p className="c-text-gray fsz-def mB-30">Transfer from one wallet to another within seconds. It&lsquo;s that simple.</p>
              <div className="mT-20">
                <div className="exchange-cryptocurrency-form bgc-dark">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4">
                      <div className="form-group">
                        <label className="c-grey">You Send Bitcoin</label>
                        <div className="input-group">
                          <input type="number" className="form-control form_control" />
                          <div className="input-group-append">
                            <DropdownCoin />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-1">
                      <div className="form-group exchange-icon">
                        <label className="c-grey">&nbsp;</label>
                        <div className="text-center c-white"><i className="icon-exchage" /></div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-4">
                      <div className="form-group">
                        <label className="c-grey">You Get Ethereum</label>
                        <div className="input-group">
                          <input type="number" className="form-control form_control" />
                          <div className="input-group-append">
                            <DropdownCoin />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-3">
                      <div className="form-group">
                        <label className="c-grey">10 More Offers</label>
                        <button className="btn btn-lg w-100 btn-primary">View All Offers</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
    <ReactWOW animation="fadeInUp">
      <div className="bgc-white pT-70 pB-30">
        <div className="container">
          <div className="row justify-content-center">
            <ReactWOW animation="fadeInUp">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="main-feature-item-xl box-hover-zoom box-shadow-mb">
                  <div className="main-feature-item-icon">
                    <i className="icon-user-information" />
                  </div>
                  <h5 className="fw-n">Fair</h5>
                  <div className="main-feature-item-text">
                    <p className="c-text-gray">
                      Other cryptocurrency trading services charge you with withdrawal
                      fees and commissions. We have a reasonable fee of 0.5%.
                    </p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="main-feature-item-xl box-hover-zoom box-shadow-mb">
                  <div className="main-feature-item-icon">
                    <i className="icon-user-information-check" />
                  </div>
                  <h5 className="fw-n">Fast</h5>
                  <div className="main-feature-item-text">
                    <p className="c-text-gray">
                      Enter the amount and the address. Transfer your coins and let the magic happen.
                    </p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="main-feature-item-xl box-hover-zoom box-shadow-mb">
                  <div className="main-feature-item-icon">
                    <i className="icon-user-information-check" />
                  </div>
                  <h5 className="fw-n">Trusty</h5>
                  <div className="main-feature-item-text">
                    <p className="c-text-gray">
                      We are a team with one of the longest track records on crypto market.
                    </p>
                  </div>
                </div>
              </div>
            </ReactWOW>
          </div>
          <ReactWOW animation="fadeInUp">
            <div className="mT-30">
              <PartnerItems />
            </div>
          </ReactWOW>
        </div>
      </div>
    </ReactWOW>
    <section className="bgc-grey pY-70">
      <div className="container">
        <Account className="mB-15" />
        <SecurityNPrivacy className="mB-15" />
        <div className="mT-50 text-center">
          <a href="/" className="btn btn-lg btn-primary btn-around">Find out More</a>
        </div>
      </div>
    </section>
    <SectionPartner backgroundImage="/images/testimonials-bg2.jpg" />
  </>
);

export default CryptopCurrencyConverting;
