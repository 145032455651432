import React from 'react';
import { Helmet } from 'react-helmet';

import SectionPartner from '../common/SectionPartner';

const HelpCenter = () => (
  <>
    <Helmet>
      <title>Paybit - Help Center</title>
    </Helmet>
    <section className="section-help-search bg" style={{ backgroundImage: 'url(/images/help-center.jpg)' }}>
      <div className="section-help-search-container">
        <div className="container">
          <h1 className="c-white text-center fsz-sup">What do you need help with ?</h1>
          <form action>
            <div className="form-search-help">
              <div className="input-group">
                <div className="input-group-input">
                  <input type="text" className="form-control" placeholder="Enter your question…" />
                </div>
                <div className="input-group-append">
                  <button className="btn-primary btn">Search</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section className="bgc-white pY-50">
      <div className="container">
        <div className="row mB-50">
          <div className="col-md-12 col-lg-6">
            <h3><b className="fw-n">Find your category</b></h3>
          </div>
          <div className="col-md-12 col-lg-6">
            <p className="fsz-def c-gray">
              We offer a Buyer and Seller Protection system.
              Payments can be refund up to 180 days,
              based on what is sold. Nobody needs to worry to get problems,
              read our Buyer and Protection details for more information.
            </p>
          </div>
        </div>
        <div className="list-help-box mB-30">
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="bd bgc-white help-box text-center">
                <div className="help-icon"><i className="icon-user_circle_bold" /></div>
                <h5><b className="fw-n">Get Answers From the Community</b></h5>
                <div className="fsz-def c-gray line-height-2">
                  Ask a quesiton in our Community ,
                  where thousands of users may be able to help you.
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="bd bgc-white help-box text-center">
                <div className="help-icon"><i className="icon-frequently-asked-questions" /></div>
                <h5><b className="fw-n">Frequently Asked Questions</b></h5>
                <div className="fsz-def c-gray line-height-2">
                  Find Answers to the most common questions asked by Paybit Clients.
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="bd bgc-white help-box text-center">
                <div className="help-icon"><i className="icon-headphone" /></div>
                <h5><b className="fw-n">Contact Paybit Support Center</b></h5>
                <div className="fsz-def c-gray line-height-2">
                  Can’t Find an answer to your question in our support portal? Contact us directly.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mY-45">
          <div className="col-md-12 col-lg-6">
            <h3><b className="fw-n">Support Categories</b></h3>
          </div>
          <div className="col-md-12 col-lg-6">
            <p className="fsz-def c-gray mb-0">Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="hover-boxshadow box-help-horizon bd bgc-white mB-15 p-50">
              <div className="d-flex align-items-center">
                <div className="fsz-40 mR-40 help-icon">
                  <i className="icon-payment-lock" />
                </div>
                <div>
                  <h5><b className="fw-n">Payments</b></h5>
                  <p className="fsz-def c-gray">
                    <a href="/" className="mR-20">14 Articles</a>
                    {' '}
                    Last Updated: Sep 22, 2018
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="hover-boxshadow box-help-horizon bd bgc-white mB-15 p-50">
              <div className="d-flex align-items-center">
                <div className="fsz-40 mR-40 help-icon">
                  <i className="icon-user_circle_bold" />
                </div>
                <div>
                  <h5><b className="fw-n">Account</b></h5>
                  <p className="fsz-def c-gray">
                    <a href="/" className="mR-20">14 Articles</a>
                    {' '}
                    Last Updated: Sep 22, 2018
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="hover-boxshadow box-help-horizon bd bgc-white mB-15 p-50">
              <div className="d-flex align-items-center">
                <div className="fsz-40 mR-40 help-icon">
                  <i className="icon-upload-download" />
                </div>
                <div>
                  <h5><b className="fw-n">Upload &amp; Download</b></h5>
                  <p className="fsz-def c-gray">
                    <a href="/" className="mR-20">18 Articles</a>
                    {' '}
                    Last Updated: Sep 22, 2018
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="hover-boxshadow box-help-horizon bd bgc-white mB-15 p-50">
              <div className="d-flex align-items-center">
                <div className="fsz-40 mR-40 help-icon">
                  <i className="icon-light" />
                </div>
                <div>
                  <h5><b className="fw-n">Suggestions &amp; Improvements</b></h5>
                  <p className="fsz-def c-gray">
                    <a href="/" className="mR-20">16 Articles</a>
                    {' '}
                    Last Updated: Sep 22, 2018
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="hover-boxshadow box-help-horizon bd bgc-white mB-15 p-50">
              <div className="d-flex align-items-center">
                <div className="fsz-40 mR-40 help-icon">
                  <i className="icon-technical-api" />
                </div>
                <div>
                  <h5><b className="fw-n">Technical / API Support</b></h5>
                  <p className="fsz-def c-gray">
                    <a href="/" className="mR-20">12 Articles</a>
                    {' '}
                    Last Updated: Sep 22, 2018
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="hover-boxshadow box-help-horizon bd bgc-white mB-15 p-50">
              <div className="d-flex align-items-center">
                <div className="fsz-40 mR-40 help-icon">
                  <i className="icon-swimmer" />
                </div>
                <div>
                  <h5><b className="fw-n">Other</b></h5>
                  <p className="fsz-def c-gray">
                    <a href="/" className="mR-20">30 Articles</a>
                    {' '}
                    Last Updated: Sep 22, 2018
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mY-45">
          <div className="col-md-12 col-lg-6">
            <h3><b className="fw-n">Popular Articles</b></h3>
          </div>
          <div className="col-md-12 col-lg-6">
            <p className="fsz-def c-gray mb-0">Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
          </div>
        </div>
        <div className="bd">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="pX-35">
                <div className="pY-25 bdB d-flex justify-content-between align-items-center">
                  <div>
                    <h5><b className="fw-n">Configuring SPF Records</b></h5>
                    <p className="c-grey mT-10 mb-0">Technical Support &gt; Configurations</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="c-yellow fsz-40 mR-20"><i className="icon-hand-like" /></span>
                    <h5 className="mb-0"><b className="fw-n">612</b></h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="pX-35">
                <div className="pY-25 bdB d-flex justify-content-between align-items-center">
                  <div>
                    <h5><b className="fw-n">Configuring SPF Records</b></h5>
                    <p className="c-grey mT-10 mb-0">Technical Support &gt; Configurations</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="c-yellow fsz-40 mR-20"><i className="icon-hand-like" /></span>
                    <h5 className="mb-0"><b className="fw-n">612</b></h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="pX-35">
                <div className="pY-25 bdB d-flex justify-content-between align-items-center">
                  <div>
                    <h5><b className="fw-n">Configuring SPF Records</b></h5>
                    <p className="c-grey mT-10 mb-0">Technical Support &gt; Configurations</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="c-yellow fsz-40 mR-20"><i className="icon-hand-like" /></span>
                    <h5 className="mb-0"><b className="fw-n">612</b></h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="pX-35">
                <div className="pY-25 bdB d-flex justify-content-between align-items-center">
                  <div>
                    <h5><b className="fw-n">Configuring SPF Records</b></h5>
                    <p className="c-grey mT-10 mb-0">Technical Support &gt; Configurations</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="c-yellow fsz-40 mR-20"><i className="icon-hand-like" /></span>
                    <h5 className="mb-0"><b className="fw-n">612</b></h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="pX-35">
                <div className="pY-25 bdB-mb d-flex justify-content-between align-items-center">
                  <div>
                    <h5><b className="fw-n">Configuring SPF Records</b></h5>
                    <p className="c-grey mT-10 mb-0">Technical Support &gt; Configurations</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="c-yellow fsz-40 mR-20"><i className="icon-hand-like" /></span>
                    <h5 className="mb-0"><b className="fw-n">612</b></h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="pX-35">
                <div className="pY-25 d-flex justify-content-between align-items-center">
                  <div>
                    <h5><b className="fw-n">Configuring SPF Records</b></h5>
                    <p className="c-grey mT-10 mb-0">Technical Support &gt; Configurations</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="c-yellow fsz-40 mR-20"><i className="icon-hand-like" /></span>
                    <h5 className="mb-0"><b className="fw-n">612</b></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SectionPartner backgroundImage="/images/testimonials-bg2.jpg" />
  </>
);

export default HelpCenter;
