import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { compose } from 'recompose';
import className from 'classnames';

import { withTranslation } from 'react-i18next';
import withModal from '../../../../hocs/withModal';
import DaskModeToggle from './DaskModeToggle';

const UserStep = ({
  isOpenModal: active, toggleModal, t, ...props
}) => (
  <div className={className('sidebar-step', { active })}>
    <div className="step-title">
      {t('Your next steps')}
      <span className="toggle-step" onClick={toggleModal} role="presentation">
        <i className="fas fa-minus" />
      </span>
    </div>
    <div className="layer-progress d-inline-block w-100 mT-15">
      <div className="progress-step mT-10">
        <div className="progress">
          <div className="progress-bar bgc-green" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '80%' }} />
        </div>
        <span className="progress-number c-gray ta-r">80%</span>
      </div>
      <span className="pull-left c-green">{t('finished', { step: '3/4' })}</span>
    </div>
    {active
    && (

      <ul className="lis-n list-step p-0 mT-20">
        <li className="pY-15 pX-20 bd step-success">
          <div>
            <p className="mgb-5">{t('Address')}</p>
            <span>{t('Fill your address and contact info')}</span>
          </div>
          <span className="icon-holder"><i className="far fa-check-circle" /></span>
        </li>
        <li className="pY-15 pX-20 bd step-success">
          <div>
            <p className="mgb-5">{t('Connect Bank Account')}</p>
            <span>{t('You have connected 2 accounts')}</span>
          </div>
          <span className="icon-holder"><i className="far fa-check-circle" /></span>
        </li>
        <li className="pY-15 pX-20 bd step-success">
          <div>
            <p className="mgb-5">{t('Upload Tax Documents')}</p>
            <span>{t('You uploaded W-2 and 1099')}</span>
          </div>
          <span className="icon-holder"><i className="far fa-check-circle" /></span>
        </li>
        <li className="pY-15 pX-20 bd">
          <div>
            <p className="mgb-5">{t('Deposit Money')}</p>
            <span>{t('You can deposit from your bank')}</span>
          </div>
          <span className="icon-holder"><i className="fas fa-arrow-right" /></span>
        </li>
      </ul>
    )
    }
    <div className="sidebar-foot-more">
      <p className="fsz-xs c-gray">
        <span className="icon-holder c-blue mR-5 pull-left"><i className="icon-time fsz-def" /></span>
        <span>{t('Server time', { time: '29 Dec 2018 17:21 GMT+02:00' })}</span>
      </p>
      <div className="sidebar-foot-menu mB-20 mT-20">
        <ul className="lis-n p-0 d-inline-block w-100">
          <li><a href="/" className="c-black">{t('Security')}</a></li>
          <li><a href="/" className="c-black">{t('Terms & Conditions')}</a></li>
          <Dropdown as="li">
            <Dropdown.Toggle as="a" className="btn-transparent dropdown-toggle" data-toggle="dropdown" aria-haspopup="true">
                      Other
            </Dropdown.Toggle>
            <Dropdown.Menu as="div" className="dropdown-menu" x-placement="bottom-start">
              <Dropdown.Item as="a" className="dropdown-item">{t('Action')}</Dropdown.Item>
              <Dropdown.Item as="a" className="dropdown-item">{t('Another action')}</Dropdown.Item>
              <Dropdown.Item as="a" className="dropdown-item">{t('Something else here')}</Dropdown.Item>
              <Dropdown.Divider className="dropdown-divider" />
              <Dropdown.Item as="a" className="dropdown-item">{t('Separated link')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </div>
      <DaskModeToggle {...props} />
      <p className="c-gray mT-20">{t('PayBit')}</p>
    </div>
  </div>
);

export default compose(
  withModal(),
  withTranslation('UserStep'),
)(UserStep);
