import React from 'react';
import ReactWOW from 'react-wow';
import { Dropdown } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const CryptoCurrencyCalculato = () => (
  <>
    <Helmet>
      <title>Paybit - Crypto Currency Calculato</title>
    </Helmet>
    <section className="top-banner-medium" style={{ backgroundImage: 'url(/images/business-tour-banner.jpg)' }}>
      <div className="top-banner-common-inner c-white">
        <div className="container">
          <ul className="breadcrumb breadcrumbs breadcrumbs-white mT-40">
            <li><a href="/"><i className="icon-house" /></a></li>
            <li>
              <i className="fas fa-chevron-right" />
              <a href="/">Cryptocurrency Calculator</a>
            </li>
          </ul>
          <ReactWOW animation="fadeInUp">
            <div className="top-banner-text">
              <h1 className="fsz-50 fw-n mB-20">
  Cryptocurrency Calculator
                {' '}
                <br className="hidden-md" />
  &amp; Converter
              </h1>
              <p className="c-text-gray fsz-def mB-30">Rates of our CryptoCurrency Converter based on the data provided by cryptocurrency echange APIs. The general rates aren&lsquo;t designed to be used for investment purposes.</p>
              <div className="mT-20">
                <div className="exchange-cryptocurrency-form bgc-dark">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-2">
                      <div className="form-group">
                        <label className="c-grey">Amount</label>
                        <input type="number" className="form-control form_control" />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-3">
                      <div className="form-group">
                        <label className="c-grey">Select Currency</label>
                        <div className="input-group">
                          <input type="number" className="form-control form_control" />
                          <div className="input-group-append">
                            <Dropdown className="dropdown select-coin btn bd d-flex align-items-center">
                              <Dropdown.Toggle as="div" className="btn-transparent dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true">
                                <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                <span className="c-black">BTC</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu">
                                <ul className="lis-n p-0 m-0">
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                    <span className="c-black">BTC</span>
                                  </li>
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                                    <span className="c-black">ETH</span>
                                  </li>
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                    <span className="c-black">BTC</span>
                                  </li>
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-1">
                      <div className="form-group exchange-icon">
                        <label className="c-grey">&nbsp;</label>
                        <div className="text-center c-white"><i className="icon-exchage" /></div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-3">
                      <div className="form-group">
                        <label className="c-grey">Select Currency</label>
                        <div className="input-group">
                          <input type="number" className="form-control form_control" />
                          <div className="input-group-append">
                            <Dropdown className="dropdown select-coin btn bd d-flex align-items-center">
                              <Dropdown.Toggle className="btn-transparent dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true">
                                <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                <span className="c-black">BTC</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu">
                                <ul className="lis-n p-0 m-0">
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                    <span className="c-black">BTC</span>
                                  </li>
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                                    <span className="c-black">ETH</span>
                                  </li>
                                  <li>
                                    <span className="coin" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                                    <span className="c-black">BTC</span>
                                  </li>
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-3">
                      <div className="form-group">
                        <label className="c-grey hidden-sm">&nbsp;</label>
                        <button className="btn btn-lg w-100 btn-primary">Convert</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
    <section className="bgc-grey pY-70">
      <div className="container">
        <h5 className="fw-n mB-20">History</h5>
        <div className="list-tags-button mB-20">
          <ul className="list-unstyled list-cryptocurrency">
            <li><a href="/">1 Bitcoin to Ethereum</a></li>
            <li><a href="/">50 Dogecoin to Indonesian Rupiah</a></li>
            <li><a href="/">1 US Dollar to Bitcoin</a></li>
            <li><a href="/">2000 WePower to TRON</a></li>
            <li><a href="/">375000000 Sprouts to US Dollar</a></li>
            <li><a href="/">125000000 Sprouts to US Dollar</a></li>
            <li><a href="/">13600 Indian Rupee to Japanese Yen</a></li>
            <li><a href="/">1500000000000 Sprouts to US Dollar</a></li>
            <li><a href="/">1 Bitcoin to Ethereum</a></li>
            <li><a href="/">50 Dogecoin to Indonesian Rupiah</a></li>
            <li><a href="/">26 Tether to Universal Currency</a></li>
            <li><a href="/">1 US Dollar to Bitcoin</a></li>
            <li><a href="/">2000 WePower to TRON</a></li>
            <li><a href="/">375000000 Sprouts to US Dollar</a></li>
            <li><a href="/">125000000 Sprouts to US Dollar</a></li>
            <li><a href="/">13600 Indian Rupee to Japanese Yen</a></li>
            <li><a href="/">1500000000000 Sprouts to US Dollar</a></li>
          </ul>
        </div>
      </div>
    </section>
  </>
);

export default CryptoCurrencyCalculato;
