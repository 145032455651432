import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { compose, mapProps, lifecycle } from 'recompose';

import { withAuth } from '../../../hocs/withAuth';

const RedirectToDashboard = compose(
  lifecycle({
    componentDidMount() {
      window.location.reload();
    },
  }),
)(() => (
  <Redirect to={{ pathname: '/dashboard_new' }} />
));

export default compose(
  withAuth,
  mapProps(({ component, isAuth, ...props }) => ({
    isAuth,
    component: isAuth ? RedirectToDashboard : component,
    ...props,
  })),
)(Route);
