import React from 'react';
import classNames from 'classnames';
import ReactWOW from 'react-wow';
import { withTranslation } from 'react-i18next';

import FAQ from './FAQ';
import container from './container';

const faqs = [
  {
    q: 'q1',
    a: 'a1',
  },
  {
    q: 'q2',
    a: 'a2',
  },
  {
    q: 'q3',
    a: 'a3',
  },
];

const Acccount = ({
  setCollapse, collapseIndex, className, t,
}) => (
  <ReactWOW animation="fadeInUp">
    <div className={classNames('bd bgc-white faq-horizonal-box-item', className)}>
      <div className="d-flex align-items-center mB-15 faq-horizonal-box-item-header">
        <div className="mR-30 fsz-40 c-grey">
          <i className="icon-user_circle_bold" />
        </div>
        <div>
          <h5><b className="fw-n">{t('title')}</b></h5>
          <p className="fsz-def c-grey mb-0">{t('sub')}</p>
        </div>
      </div>
      <div className="faq-horizonal-item-body">
        <div id="accordion">
          {faqs.map((faq, index) => (
            <FAQ
              {...faq}
              index={index}
              key={`faq-${index}`}
              setCollapse={setCollapse}
              collapseIndex={collapseIndex}
            />
          ))}
        </div>
      </div>
    </div>
  </ReactWOW>
);

export default withTranslation('Acccount')(container(Acccount));
