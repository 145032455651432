import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const HelpCenterArticles = () => (
  <section className="bgc-white pY-30 bdT">
    <Helmet>
      <title>Paybit - Help Center Articles</title>
    </Helmet>
    <div className="container">
      <div className="mB-25">
        <ul className="breadcrumb breadcrumbs">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
            <a href="/">Help Center </a>
          </li>
          <li>
            <i className="fas fa-chevron-right" />
            <span> Payment Support</span>
          </li>
        </ul>
      </div>
      <div className="d-flex align-items-center justify-content-between d-md-inline-block w-100 pX-30 pY-40 bd">
        <div className="d-flex align-items-center">
          <div className="mR-30 fsz-35 c-grey"><i className="icon-payment-lock" /></div>
          <div>
            <h4 className="mB-10"><b className="fw-n">Payment Support</b></h4>
            <p className="fsz-def c-grey mb-0">Ask a quesiton in our Community , where thousands of users may be able to help you.</p>
          </div>
        </div>
        <div>
          <a href="/" className="btn btn-primary btn-lg button-ct">New Post</a>
        </div>
      </div>
      <div className="mY-20 d-flex d-md-inline-block w-100 justify-content-between align-items-center">
        <div className="d-flex">
          <div className="drop-sort mR-20">
            <span>Show: </span>
            <Dropdown className="dropdown dropdown_sort">
              <Dropdown.Toggle className="dropdown-toggle" role="button" id="dropdown_sort" data-toggle="dropdown" aria-haspopup="true">All</Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu" aria-labelledby="dropdown_sort">
                <a href="/" className="dropdown-item action-sort">All</a>
                <a href="/" className="dropdown-item action-sort">All</a>
                <a href="/" className="dropdown-item action-sort">All</a>
                <a href="/" className="dropdown-item action-sort">All</a>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Dropdown className="drop-sort mR-20">
            <span>Sort by:</span>
            <div className="dropdown dropdown_sort">
              <Dropdown.Toggle className="dropdown-toggle" role="button" id="dropdown_sort" data-toggle="dropdown" aria-haspopup="true">Recent Activity</Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu" aria-labelledby="dropdown_sort">
                <a href="/" className="dropdown-item action-sort">All</a>
                <a href="/" className="dropdown-item action-sort">All</a>
                <a href="/" className="dropdown-item action-sort">All</a>
                <a href="/" className="dropdown-item action-sort">All</a>
              </Dropdown.Menu>
            </div>
          </Dropdown>
        </div>
        <div className="input-has-icon mw-300">
          <i className="icon-search" />
          <input type="text" className="form-control form_control" placeholder="Search Article..." />
        </div>
      </div>
      <div className="bd bdB-n">
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Uses Advanced Search results to analyze decline rates</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Settlement Batch Summary</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">5</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">1</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Uses Advanced Search results to analyze decline rates</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Settlement Batch Summary</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">5</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">1</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Uses Advanced Search results to analyze decline rates</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Settlement Batch Summary</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">5</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">1</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Uses Advanced Search results to analyze decline rates</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Settlement Batch Summary</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">5</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">1</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Uses Advanced Search results to analyze decline rates</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Settlement Batch Summary</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">5</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">1</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Uses Advanced Search results to analyze decline rates</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">0</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
        <div className="bdB pY-25 pX-35 d-flex d-md-inline-block w-100 justify-content-between">
          <div>
            <h5 className="mT-10"><a href="/" className="c-black"><b className="fw-n">Settlement Batch Summary</b></a></h5>
            <p className="mB-10 fsz-def c-grey">Updated 10 Minutes ago ●  Jonathan Banks </p>
          </div>
          <div className="d-flex">
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">5</b></h5>
              <p className="mB-10 fsz-def c-grey">Views</p>
            </div>
            <div className="mR-30 text-center">
              <h5 className="mT-10"><b className="fw-n">1</b></h5>
              <p className="mB-10 fsz-def c-grey">Votes</p>
            </div>
            <div className="text-center">
              <h5 className="mT-10"><b className="fw-n">12</b></h5>
              <p className="mB-10 fsz-def c-grey">Comments</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HelpCenterArticles;
