import React from 'react';
import { withRouter } from 'react-router-dom';
import numeral from 'numeral';

import { map } from 'lodash';
import { compose, mapProps } from 'recompose';
import { significantDigits } from '../../../utils/currencyFormater';
import ChartExpand from './ChartExpand';
import { cssURLCoinImg } from '../../../utils/apiDataHelper';

const RowCoin = compose(
  withRouter,
  mapProps(({ price, ...props }) => {
    const { chartData } = price;
    return {
      ...props,
      price,
      chartData: map(chartData, (value, key) => ({ value, key })),
    };
  }),
)(({
  price: {
    id,
    // rank,
    coinName,
    marketCap,
    price,
    circulatingSupply,
    volume,
    change,
    slug,
    symbol,
  },
  chartData,
  history,
  // index,
}) => (
  <tr>
    <td>
      <b className="fsz-md fw-n">
        {id}
      </b>
    </td>
    <td>
      <span className="coin-currency" onClick={() => history.push(`/crypto-details-page/${symbol}`)} role="presentation">
        <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: cssURLCoinImg(slug) }} />
        <a href={`/crypto-details-page/${symbol}`} className="text-uppercase whs-nw mR-10"><b className="fw-n fsz-xs">{coinName}</b></a>
      </span>
    </td>
    <td>
      $
      {significantDigits(marketCap)}
    </td>
    <td>
      $
      {significantDigits(price)}
    </td>
    <td>
      {circulatingSupply && numeral(circulatingSupply).format('$ 0 a').toUpperCase()}
    </td>
    <td>
      $
      {significantDigits(volume)}
    </td>
    <td style={change < 0 ? { color: 'red' } : {}}>
      {significantDigits(change)}
    </td>
    <ChartExpand chartData={chartData} symbol={symbol} />
  </tr>
));

const TableCryptoMartket = ({
  prices,
}) => (
  <div className="table-responsive">
    <table className="table table-common table-crypto-market">
      <thead>
        <tr className="whs-nw">
          <th>#</th>
          <th>Name</th>
          <th>Market Cap</th>
          <th>Price</th>
          <th>Coins</th>
          <th>Volume</th>
          <th>% 24h</th>
          <th>Price Graph</th>
        </tr>
      </thead>
      <tbody className="pX-20">
        {prices.map((price, index) => <RowCoin price={price} key={price.id} index={index} />)}
      </tbody>
    </table>
  </div>
);

export default TableCryptoMartket;
