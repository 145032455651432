import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const Contact = () => (
  <section>
    <Helmet>
      <title>Paybit - Contact</title>
    </Helmet>
    <Tab.Container defaultActiveKey="tab_seen_people">
      <div className="tab-common pX-25 bgc-white bdB">
        <Nav className="nav nav-tabs tab-lg" id="mainTab" role="tablist">
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_seen_people" role="tab" aria-selected="true">Seen People </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_contacts" role="tab" aria-selected="false">Contacts</Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_frequent_contacts" role="tab" aria-selected="false">Frequent Contacts</Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" data-toggle="tab" eventKey="tab_last_transactions" role="tab" aria-selected="false">Last Transactions</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Tab.Content className="tab-content" id="mainTabContent">
        <Tab.Pane className="tab-pane fade" eventKey="tab_seen_people" role="tabpanel">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="pY-20 d-flex d-md-inline-block w-100 justify-content-between align-items-center">
                <div className="d-flex d-md-inline-block w-100 align-items-center">
                  <h5 className="mB-5"><b className="fw-n mR-20">Seen People</b></h5>
                  <span className="c-gray mR-15 mB-5">152 Total</span>
                  <span className="c-gray mR-15 mB-5">Sorted by:</span>
                  <select className="bdn bgn">
                    <option value>Active</option>
                  </select>
                </div>
                <div className="d-flex d-md-inline-block w-100 align-items-center justify-content-end">
                  <div className="input-has-icon mR-5 mB-5">
                    <i className="icon-search" />
                    <input type="text" className="form-control form_control bdrs-0" placeholder="Search people" />
                  </div>
                  <button className="btn btn-success btn-lg bdrs-0 mR-5 mB-5">
                          Filter
                    {' '}
                    <i className="fas fa-caret-down" />
                  </button>
                  <button className="btn btn-primary btn-lg bdrs-0 mB-5">Search</button>
                </div>
              </div>
              <div className="list-contact-people">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="bd box-contact-people hover">
                      <div className="people-avatar">
                        <div className="img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                        <div className="people-status bg-success"><i className="fas fa-check" /></div>
                      </div>
                      <div className="people-content">
                        <h5><b className="fw-n">Jonathan Banks</b></h5>
                        <p className="c-gray">aleksandardesign@gmail.com</p>
                        <div className="list-people-friend d-flex justify-content-center">
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                        </div>
                      </div>
                      <div className="box-contact-people-footer">
                        <button className="btn btn-profile">
                          <i className="icon-user" />
                          Profile
                        </button>
                        <button className="btn btn-message">
                          <i className="icon-mail" />
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="bd box-contact-people hover">
                      <div className="people-avatar">
                        <div className="img bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                        <div className="people-status bg-secondary"><i className="fas fa-question" /></div>
                      </div>
                      <div className="people-content">
                        <h5><b className="fw-n">Jonathan Banks</b></h5>
                        <p className="c-gray">aleksandardesign@gmail.com</p>
                        <div className="list-people-friend d-flex justify-content-center">
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                        </div>
                      </div>
                      <div className="box-contact-people-footer">
                        <button className="btn btn-profile">
                          <i className="icon-user" />
                          Profile
                        </button>
                        <button className="btn btn-addfriend">Add Friend</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="bd box-contact-people hover">
                      <div className="people-avatar">
                        <div className="img bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                        <div className="people-status bg-success"><i className="fas fa-check" /></div>
                      </div>
                      <div className="people-content">
                        <h5><b className="fw-n">Jonathan Banks</b></h5>
                        <p className="c-gray">aleksandardesign@gmail.com</p>
                        <div className="list-people-friend d-flex justify-content-center">
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                        </div>
                      </div>
                      <div className="box-contact-people-footer">
                        <button className="btn btn-profile">
                          <i className="icon-user" />
                          Profile
                        </button>
                        <button className="btn btn-message">
                          <i className="icon-mail" />
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="bd box-contact-people hover">
                      <div className="people-avatar">
                        <div className="img bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                        <div className="people-status bg-success"><i className="fas fa-check" /></div>
                      </div>
                      <div className="people-content">
                        <h5><b className="fw-n">Jonathan Banks</b></h5>
                        <p className="c-gray">aleksandardesign@gmail.com</p>
                        <div className="list-people-friend d-flex justify-content-center">
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                        </div>
                      </div>
                      <div className="box-contact-people-footer">
                        <button className="btn btn-profile">
                          <i className="icon-user" />
                          Profile
                        </button>
                        <button className="btn btn-message">
                          <i className="icon-mail" />
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="bd box-contact-people hover">
                      <div className="people-avatar">
                        <div className="img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                        <div className="people-status bg-success"><i className="fas fa-check" /></div>
                      </div>
                      <div className="people-content">
                        <h5><b className="fw-n">Jonathan Banks</b></h5>
                        <p className="c-gray">aleksandardesign@gmail.com</p>
                        <div className="list-people-friend d-flex justify-content-center">
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                        </div>
                      </div>
                      <div className="box-contact-people-footer">
                        <button className="btn btn-profile">
                          <i className="icon-user" />
                          Profile
                        </button>
                        <button className="btn btn-message">
                          <i className="icon-mail" />
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="bd box-contact-people hover">
                      <div className="people-avatar">
                        <div className="img bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                        <div className="people-status bg-secondary"><i className="fas fa-question" /></div>
                      </div>
                      <div className="people-content">
                        <h5><b className="fw-n">Jonathan Banks</b></h5>
                        <p className="c-gray">aleksandardesign@gmail.com</p>
                        <div className="list-people-friend d-flex justify-content-center">
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                        </div>
                      </div>
                      <div className="box-contact-people-footer">
                        <button className="btn btn-profile">
                          <i className="icon-user" />
                          Profile
                        </button>
                        <button className="btn btn-addfriend">Add Friend</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="bd box-contact-people hover">
                      <div className="people-avatar">
                        <div className="img bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                        <div className="people-status bg-success"><i className="fas fa-check" /></div>
                      </div>
                      <div className="people-content">
                        <h5><b className="fw-n">Jonathan Banks</b></h5>
                        <p className="c-gray">aleksandardesign@gmail.com</p>
                        <div className="list-people-friend d-flex justify-content-center">
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                        </div>
                      </div>
                      <div className="box-contact-people-footer">
                        <button className="btn btn-profile">
                          <i className="icon-user" />
                          Profile
                        </button>
                        <button className="btn btn-message">
                          <i className="icon-mail" />
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="bd box-contact-people hover">
                      <div className="people-avatar">
                        <div className="img bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                        <div className="people-status bg-success"><i className="fas fa-check" /></div>
                      </div>
                      <div className="people-content">
                        <h5><b className="fw-n">Jonathan Banks</b></h5>
                        <p className="c-gray">aleksandardesign@gmail.com</p>
                        <div className="list-people-friend d-flex justify-content-center">
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                          <div className="size-32 img bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                        </div>
                      </div>
                      <div className="box-contact-people-footer">
                        <button className="btn btn-profile">
                          <i className="icon-user" />
                          Profile
                        </button>
                        <button className="btn btn-message">
                          <i className="icon-mail" />
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mT-40">
                  <span className="d-flex align-items-center">
                    <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
                    {' '}
                    <span className="mL-10 c-gray">Loading more</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane fade" eventKey="tab_contacts" role="tabpanel">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content" />
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane fade" eventKey="tab_frequent_contacts" role="tabpanel">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content" />
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane fade" eventKey="tab_last_transactions" role="tabpanel">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content" />
          </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  </section>
);

export default Contact;
