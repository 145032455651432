import React from 'react';
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const mockData = [
  {
    name: 'some text', value: 8000,
  },
  {
    name: 'some text', value: 2800,
  },
  {
    name: 'some text', value: 2600,
  },
  {
    name: 'some text', value: 2400,
  },
  {
    name: 'some text', value: 2200,
  },
  {
    name: 'some text', value: 2000,
  },
  {
    name: 'some text', value: 2100,
  },
  {
    name: 'some text', value: 2300, keepShowing: true,
  },
  {
    name: 'some text', value: 1800,
  },
  {
    name: 'some text', value: 1300,
  },
  {
    name: 'some text', value: 1200,
  },
  {
    name: 'some text', value: 1000,
  },
];

const ProductSoldChart = () => (
  <ResponsiveContainer
    width="100%"
    height="100%"
  >
    <AreaChart
      width={520}
      height={250}
      data={mockData}
    >
      <Tooltip
        viewBox={{
          x: -1,
          y: -1,
          width: 400,
          height: 400,
        }}
        content={() => null}
      />
      <defs>
        <linearGradient id="customerColor" x1="1" y1="0" x2="1" y2="2">
          <stop offset="5%" stopColor="#FF8E0B" stopOpacity={1} />
          <stop offset="95%" stopColor="#FEC103" stopOpacity={0.7} />
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey="value"
        stroke="#FF910A"
        fill="url(#customerColor)"
        fillOpacity={0.9}
        ffset="5%"
        dot={null}
      />
    </AreaChart>
  </ResponsiveContainer>
);

export default ProductSoldChart;
