import React from 'react';
import { Helmet } from 'react-helmet';

import SectionPartner from '../common/SectionPartner';

const FAQs = () => (
  <>
    <Helmet>
      <title>Paybit - FAQs</title>
    </Helmet>
    <section className="section-faq-search bg" style={{ backgroundImage: 'url(/images/faq-bg.jpg)' }}>
      <div className="section-faq-search-container">
        <div className="container">
          <h1 className="c-white text-center fsz-sup">Find what you are looking for</h1>
          <form>
            <div className="form-search-faq">
              <div className="input-group">
                <div className="input-group-prepend">
                  <select className="form-control">
                    <option value>Category</option>
                    <option value>Category</option>
                    <option value>Category</option>
                  </select>
                </div>
                <input type="text" className="form-control" placeholder="Enter search tearms here..." />
                <div className="input-group-append">
                  <button className="btn-primary btn">Search</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section className="bgc-grey pY-50">
      <div className="container">
        <div className="row mB-50">
          <div className="col-5 col-md-5 col-lg-6">
            <h3><b className="fw-n">Features Questions Paybit</b></h3>
          </div>
          <div className="col-5 col-md-5 col-lg-6">
            <p className="fsz-def c-gray">We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold. Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
          </div>
        </div>
        <div className="list-faq-box mB-30">
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="bd pY-25 pX-30 bgc-white faq-box">
                <div className="faq-icon"><i className="icon-swimmer" /></div>
                <h5><b className="fw-n">Basics</b></h5>
                <ul className="fsz-def">
                  <li><a href="/">What is PayBit?</a></li>
                  <li><a href="/">What can I spend money on?</a></li>
                  <li><a href="/">How do I register a PayBit Account?</a></li>
                  <li><a href="/">What is PayBit Dollar?</a></li>
                  <li><a href="/">I have a business and want to use PayBit Dollars to sell goods and services. How is it possible?</a></li>
                </ul>
                <a href="/" className="c-black fsz-def">View More</a>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="bd pY-25 pX-30 bgc-white faq-box">
                <div className="faq-icon"><i className="icon-security" /></div>
                <h5><b className="fw-n">Security &amp; Privacy</b></h5>
                <ul className="fsz-def">
                  <li><a href="/">I think my account was hacked. What should I do?</a></li>
                  <li><a href="/">I want to change account&lsquo;s password. How do I do this?</a></li>
                  <li><a href="/">I still have not found a solution to my problem. Can you help me?</a></li>
                </ul>
                <a href="/" className="c-black fsz-def">View More</a>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="bd pY-25 pX-30 bgc-white faq-box">
                <div className="faq-icon"><i className="icon-user_circle_bold" /></div>
                <h5><b className="fw-n">Account</b></h5>
                <ul className="fsz-def">
                  <li><a href="/">How do I register a PayBit Account?</a></li>
                  <li><a href="/">I represent a company. Can I register a business account?</a></li>
                  <li><a href="/">I still have not found a solution to my problem. Can you help me?</a></li>
                </ul>
                <a href="/" className="c-black fsz-def">View More</a>
              </div>
            </div>
          </div>
          <hr className="clearfix hr-gray" />
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="bd pY-25 pX-30 bgc-white faq-box">
                <div className="faq-icon"><i className="icon-payment-lock" /></div>
                <h5><b className="fw-n">Payment Operations</b></h5>
                <ul className="fsz-def">
                  <li><a href="/">How do I send PayBit Dollars to another user?</a></li>
                  <li><a href="/">How do I withdraw PayBit Dollars?</a></li>
                  <li><a href="/">I still have not found a solution to my problem. Can you help me?</a></li>
                </ul>
                <a href="/" className="c-black fsz-def">View More</a>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="bd pY-25 pX-30 bgc-white faq-box">
                <div className="faq-icon"><i className="icon-api" /></div>
                <h5><b className="fw-n">Business / API</b></h5>
                <ul className="fsz-def">
                  <li><a href="/">How do I register a PayBit Account?</a></li>
                  <li><a href="/">How do I accept PayBit payments on my website?</a></li>
                  <li><a href="/">Do you have integration modules for popular CMS?</a></li>
                  <li><a href="/">I still have not found a solution to my problem. Can you help me?</a></li>
                </ul>
                <a href="/" className="c-black fsz-def">View More</a>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="bd pY-25 pX-30 bgc-white faq-box">
                <div className="faq-icon"><i className="icon-question-message" /></div>
                <h5><b className="fw-n">General Questions</b></h5>
                <ul className="fsz-def">
                  <li><a href="/">How do I register a PayBit Account?</a></li>
                  <li><a href="/">How do I accept PayBit payments on my website?</a></li>
                  <li><a href="/">Do you have integration modules for popular CMS?</a></li>
                  <li><a href="/">I still have not found a solution to my problem. Can you help me?</a></li>
                </ul>
                <a href="/" className="c-black fsz-def">View More</a>
              </div>
            </div>
          </div>
        </div>
        <div className="bd bgc-white faq-horizonal-box-item mB-15">
          <div className="d-flex align-items-center mB-15 faq-horizonal-box-item-header">
            <div className="mR-30 fsz-40 c-grey">
              <i className="icon-user_circle_bold" />
            </div>
            <div>
              <h5><b className="fw-n">Account</b></h5>
              <p className="fsz-def c-grey mb-0">Get familiar with our account system.</p>
            </div>
          </div>
          <div className="faq-horizonal-item-body">
            <div id="accordion">
              <div className="faq-horizonal-item">
                <div className="faq-horizonal-item-header" id="headingOne">
                  <a
                    href="/"
                    className="btn-toggle-faq"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    How do I register a PayBit Account?
                  </a>
                </div>
                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div className="faq-horizonal-item-body">
                  Its easy as nothing. Just enter your email, signup for free. For real money transaction we need you to do a verification.
                  </div>
                </div>
              </div>
              <div className="faq-horizonal-item">
                <div className="faq-horizonal-item-header" id="headingTwo">
                  <a href="/" className="btn-toggle-faq collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-controls="collapseTwo">
                    I represent a company. Can I register a business account?
                  </a>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="faq-horizonal-item-body">
                  Each Business can signup for a Merchant Account. Its very simple.
                  </div>
                </div>
              </div>
              <div className="faq-horizonal-item">
                <div className="faq-horizonal-item-header" id="headingThree">
                  <a href="/" className="btn-toggle-faq collapsed" data-toggle="collapse" data-target="#collapseThree" aria-controls="collapseThree">
                    I still have not found a solution to my problem. Can you help me?
                  </a>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div className="faq-horizonal-item-body">
                  Check our Help Center or contact our Live Support for more help.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bd bgc-white faq-horizonal-box-item mB-15">
          <div className="d-flex align-items-center mB-15 faq-horizonal-box-item-header">
            <div className="mR-30 fsz-40 c-grey">
              <i className="icon-security" />
            </div>
            <div>
              <h5><b className="fw-n">Security &amp; Privacy</b></h5>
              <p className="fsz-def c-grey mb-0">We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold.</p>
            </div>
          </div>
          <div className="faq-horizonal-item-body">
            <div id="accordion2">
              <div className="faq-horizonal-item">
                <div className="faq-horizonal-item-header" id="heading1">
                  <a href="/" className="btn-toggle-faq collapsed" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    I think my account was hacked. What should I do?
                  </a>
                </div>
                <div id="collapse1" className="collapse " aria-labelledby="heading1" data-parent="#accordion2">
                  <div className="faq-horizonal-item-body">
                  Security is one of our priorities. If you think your security was breached, please contact our support.
                  </div>
                </div>
              </div>
              <div className="faq-horizonal-item">
                <div className="faq-horizonal-item-header" id="heading2">
                  <a href="/" className="btn-toggle-faq" data-toggle="collapse" data-target="#collapse2" aria-controls="collapse2">
                    I want to change account&lsquo;s password. How do I do this?
                  </a>
                </div>
                <div id="collapse2" className="collapse show" aria-labelledby="heading2" data-parent="#accordion2">
                  <div className="faq-horizonal-item-body">
                  Please follow the change password procedure, after submitting your old password you can change your password to a new one.
                  </div>
                </div>
              </div>
              <div className="faq-horizonal-item">
                <div className="faq-horizonal-item-header" id="heading3">
                  <a
                    href="/"
                    className="btn-toggle-faq collapsed"
                    data-toggle="collapse"
                    data-target="#collapse3"

                    aria-controls="collapse3"
                  >
                    I have even more questions, what can i do?
                  </a>
                </div>
                <div id="collapse3" className="collapse" aria-labelledby="heading3" data-parent="#accordion2">
                  <div className="faq-horizonal-item-body">
                  Please check our Help & Support Center to search for more articles. Additional to that you can contact our Live Support.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SectionPartner backgroundImage="/images/testimonials-bg2.jpg" />
  </>
);

export default FAQs;
