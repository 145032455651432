import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const HelpCenterSingleArticle = () => (
  <section className="bgc-grey pY-30 bdT">
    <Helmet>
      <title>Paybit - Help Center Single Article</title>
    </Helmet>
    <div className="container">
      <div className="mB-20 d-flex d-md-inline-block w-100 align-items-center justify-content-between">
        <ul className="breadcrumb breadcrumbs mB-10">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
            <a href="/">Help Center </a>
          </li>
          <li>
            <i className="fas fa-chevron-right" />
            <span> Payment Support</span>
          </li>
        </ul>
        <div className="input-has-icon mw-300 mB-10">
          <i className="icon-search" />
          <input type="text" className="form-control form_control" placeholder="Search Article..." />
        </div>
      </div>
      <div className="article-container">
        <div className="row row-sm">
          <div className="col-md-12 col-lg-8 col-xl-8 mB-20">
            <div className="bd bgc-white p-35 mB-30">
              <h1 className="article-title mB-30">Running a Transaction-Level Fee report Transaction-Level Fee Report</h1>
              <div className="d-flex mB-30">
                <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                <div>
                  <h5><b className="fw-n">John Smith</b></h5>
                  <p className="c-grey fsz-def">Updated 10 Minutes ago</p>
                </div>
              </div>
              <div className="article-content">
                <p>
                  The Transaction-Level Fee report provides detailed information about your
                  transactions and the fees associated with them. The details provided in the
                  report depend on whether you have an IC+ pricing model,
                  or a flat rate or blended model.
                  {' '}
                  <a href="/">If you&lsquo;re unsure of your pricing model</a>
                  , contact our Accounts team.
                </p>
                <br />
                <p>
                  The Transaction-Level
                  {' '}
                  <a href="/">Fee report for flat rate and blended merchants</a>
                  contains the actual Braintree fees charged
                  for each transaction as defined by your pricing agreement.
                  This report can give you deeper insight into the following:
                  {' '}
                </p>
                <br />
                <p>
                  The Transaction-Level Fee report includes estimated
                  interchange fee rates for each transaction.
                  {' '}
                  <a href="/">After a transaction is processed</a>
                  , these estimated values are often adjusted
                  through a process known as reclassification,
                  and the actual per-transaction interchange
                  fees are passed on to you in a consolidated sum
                  at the end of the month. As a result, exact
                  transaction-level fee assessments for interchange are not available.
                </p>
              </div>
              <div className="social-share">
                <ul>
                  <li><a href="/" className="hover-boxshadow"><i className="fab fa-facebook-f" /></a></li>
                  <li><a href="/" className="hover-boxshadow"><i className="fab fa-twitter" /></a></li>
                  <li><a href="/" className="hover-boxshadow"><i className="fab fa-instagram" /></a></li>
                </ul>
              </div>
            </div>
            <div className="mB-20">
              <h4><b className="fw-n">Comments</b></h4>
              <div className="d-flex d-md-inline-block w-100 justify-content-between">
                <p className="c-text-gray mB-10">There are 33 comments to this article</p>
                <div className="drop-sort mB-10">
                  <span>Sort by: </span>
                  <Dropdown className="dropdown dropdown_sort">
                    <Dropdown.Toggle className="dropdown-toggle" role="button" id="dropdown_sort" data-toggle="dropdown" aria-haspopup="true">Date</Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu" aria-labelledby="dropdown_sort">
                      <a href="/" className="dropdown-item action-sort">Date</a>
                      <a href="/" className="dropdown-item action-sort">Month</a>
                      <a href="/" className="dropdown-item action-sort">Years</a>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="bd bdB-n bgc-white mB-30">
              <div className="p-35 bdB">
                <div className="d-flex mB-15">
                  <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                  <div>
                    <h5><b className="fw-n">John Smith</b></h5>
                    <p className="c-grey fsz-def">Updated 10 Minutes ago</p>
                  </div>
                </div>
                <div className="c-grey-6 line-height-2 fsz-def">The estimated interchange fees listed in this report should be used for general reference and identifying trends – not for reconciliation.</div>
              </div>
              <div className="p-35 bdB">
                <div className="d-flex mB-15">
                  <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                  <div>
                    <h5><b className="fw-n">John Smith</b></h5>
                    <p className="c-grey fsz-def">Updated 10 Minutes ago</p>
                  </div>
                </div>
                <div className="c-grey-6 line-height-2 fsz-def">The estimated interchange fees listed in this report should be used for general reference and identifying trends – not for reconciliation.</div>
              </div>
              <div className="p-35 bdB">
                <div className="d-flex mB-15">
                  <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                  <div>
                    <h5><b className="fw-n">John Smith</b></h5>
                    <p className="c-grey fsz-def">Updated 10 Minutes ago</p>
                  </div>
                </div>
                <div className="c-grey-6 line-height-2 fsz-def">The estimated interchange fees listed in this report should be used for general reference and identifying trends – not for reconciliation.</div>
              </div>
              <div className="p-35 bdB">
                <div className="d-flex mB-15">
                  <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                  <div>
                    <h5><b className="fw-n">John Smith</b></h5>
                    <p className="c-grey fsz-def">Updated 10 Minutes ago</p>
                  </div>
                </div>
                <div className="c-grey-6 line-height-2 fsz-def">The estimated interchange fees listed in this report should be used for general reference and identifying trends – not for reconciliation.</div>
              </div>
            </div>
            <div className="d-flex conversation-box mB-30">
              <div className="circle img-42 mR-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
              <textarea className="form-control pY-10 pX-25" placeholder="Add to conversation" defaultValue="" />
            </div>
            <nav className="nav-paging">
              <ul className="pagination justify-content-center">
                <li className="page-item disabled">
                  <a className="page-link" href="/" tabIndex={-1}><i className="fas fa-chevron-left" /></a>
                </li>
                <li className="page-item"><a className="page-link" href="/">1</a></li>
                <li className="page-item active"><a className="page-link" href="/">2</a></li>
                <li className="page-item"><a className="page-link" href="/">3</a></li>
                <li className="page-item">
                  <a className="page-link" href="/"><i className="fas fa-chevron-right" /></a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-md-12 col-lg-4 col-xl-4 mB-20">
            <div className="bd bgc-white p-25">
              <h5 className="mB-20 line-height-2">Can’t find what you are looking for?</h5>
              <a href="/" className="btn btn-primary btn-lg w-100">New Post</a>
            </div>
            <h5 className="mY-15"><b className="fw-n">Related Posts</b></h5>
            <div className="bd bgc-white p-20 mB-10">
              <h5 className="mB-20"><a href="/" className="c-black"><b className="fw-n">Uses Advanced Search results to analyze decline rates</b></a></h5>
              <div className="d-flex">
                <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                <div>
                  <h5><b className="fw-n">John Smith</b></h5>
                  <p className="mb-0 c-grey">Updated 10 Minutes ago</p>
                </div>
              </div>
            </div>
            <div className="bd bgc-white p-20 mB-10">
              <h5 className="mB-20"><a href="/" className="c-black"><b className="fw-n">Uses Advanced Search results to analyze decline rates</b></a></h5>
              <div className="d-flex">
                <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                <div>
                  <h5><b className="fw-n">John Smith</b></h5>
                  <p className="mb-0 c-grey">Updated 10 Minutes ago</p>
                </div>
              </div>
            </div>
            <div className="bd bgc-white p-20 mB-10">
              <h5 className="mB-20"><a href="/" className="c-black"><b className="fw-n">Uses Advanced Search results to analyze decline rates</b></a></h5>
              <div className="d-flex">
                <div className="circle img-42 mR-15" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                <div>
                  <h5><b className="fw-n">John Smith</b></h5>
                  <p className="mb-0 c-grey">Updated 10 Minutes ago</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HelpCenterSingleArticle;
