import {
  takeLatest, all,
  call, put,
} from 'redux-saga/effects';

import { coinsApi } from '../services/restClient/coinsApi';

import {
  fetchPrices,
  fetchHistorical,
  updatePrices,
  updateHistorical,
} from '../actions/coinsAction';

export function* fetchPricesSaga() {
  try {
    const response = yield call([coinsApi, coinsApi.getPrices]);
    yield put(updatePrices(response.data));
  } catch (e) {
    // do nothing at the moment
  }
}

export function* fetchHistoricalSaga(actions) {
  try {
    const { payload: { symbol } = {} } = actions;
    const response = yield call([coinsApi, coinsApi.getHistorical], symbol);
    yield put(updateHistorical({ data: response.data, symbol }));
  } catch (e) {
    // do nothing at the moment
  }
}

export default function* () {
  yield all([
    takeLatest(fetchPrices, fetchPricesSaga),
    takeLatest(fetchHistorical, fetchHistoricalSaga),
  ]);
}
