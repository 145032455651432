import React from 'react';
import { Helmet } from 'react-helmet';

import CurrentPortfolioChart from './CurrentPortfolioChart';
import IncomeChart from './IncomeChart';

const Reports = () => (
  <section>
    <Helmet>
      <title>Paybit - Reports</title>
    </Helmet>
    <div className="p-20 bgc-grey main-content-container">
      <div className="main-content">
        <div className="box-style bd bgc-white mB-20">
          <div className="box-header box-last-activity-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center box-last-activity-header-left">
                <span className="fsz-sm c-gray mR-30">
Transactions :
                  <b className="c-black">23944</b>
                </span>
                <span className="fsz-sm c-gray mR-10">
Today :
                  <b className="c-black">October 3 , 2018</b>
                </span>
              </div>
              <div className="d-flex align-items-center">
                <div className="mR-15">
                  <div className="input_group">
                    <i className="mL-10 far fa-calendar-check" />
                    <input type="date" placeholder="Select Date" className="form-control bdn" />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
                <button className="btn btn-lg btn-warning">Create Report</button>
              </div>
            </div>
            <hr />
          </div>
          <div className="box-body pY-20 pX-30">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <div className="pR-25 mR-25 bdR">
                  <h4>
                    <b className="fw-n">$152.522</b>
                    {' '}
                    <span className="c-green fsz-sm">
                      <i className="icon-arrow_up" />
                      {' '}
+5.2%
                    </span>
                  </h4>
                  <small className="c-gray">Total Income</small>
                </div>
                <div className="pR-25 mR-25 bdR">
                  <h4>
                    <b className="fw-n">$82.555</b>
                    {' '}
                    <span className="c-red fsz-sm">
                      <i className="icon-arrow_down" />
                      {' '}
-5.2%
                    </span>
                  </h4>
                  <small className="c-gray">Spent</small>
                </div>
                <div className="mR-25">
                  <h4>
                    <b className="fw-n">$25.521</b>
                    {' '}
                    <span className="c-green fsz-sm">
                      <i className="icon-arrow_up" />
                      {' '}
+5.2%
                    </span>
                  </h4>
                  <small className="c-gray">Profit</small>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="d-flex align-items-center btn btn-gray btn-around">
                  <i className="fas fa-circle mR-10 c-yellow fsz-xxs" />
Actually Spent
                </span>
                <span className="d-flex align-items-center btn btn-gray btn-around mL-5">
                  <i className="fas fa-circle mR-10 c-grey fsz-xxs" />
Planed
                </span>
              </div>
            </div>
            <div className="pY-20">
              <IncomeChart />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-8">
            <div className="box-style bd bgc-white mB-20 bdrs-6">
              <div className="box-header box-last-activity-header">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center box-last-activity-header-left">
                    <h5><b className="fw-n mR-10">Last Reports</b></h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="mR-15">
                      <select className="form-control form_control form_control_sm">
                        <option>Status</option>
                        <option>Status</option>
                        <option>Status</option>
                      </select>
                    </div>
                    <div>
                      <div className="input_group">
                        <i className="mL-10 far fa-calendar-check" />
                        <input type="date" placeholder="Select Date" className="form-control bdn" />
                        <button className="btn btn-primary">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-body pY-20">
                <div className="table-responsive">
                  <table className="table table-common table-hover">
                    <thead>
                      <tr>
                        <th>Report Name </th>
                        <th>Date</th>
                        <th>Report Status</th>
                        <th>Action Toolbar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><span className="c-gray">Somethink here</span></td>
                        <td><span className="c-gray">23.08.2018</span></td>
                        <td><span className="c-gray">Pending</span></td>
                        <td><button className="btn-transparent c-gray fsz-md"><i className="icon-undo" /></button></td>
                      </tr>
                      <tr>
                        <td><span className="c-gray">Somethink here</span></td>
                        <td><span className="c-gray">23.08.2018</span></td>
                        <td><span className="c-gray">Pending</span></td>
                        <td><button className="btn-transparent c-gray fsz-md"><i className="icon-download1" /></button></td>
                      </tr>
                      <tr>
                        <td><span className="c-gray">Somethink here</span></td>
                        <td><span className="c-gray">23.08.2018</span></td>
                        <td><span className="c-gray">Pending</span></td>
                        <td><button className="btn-transparent c-gray fsz-md"><i className="icon-trash" /></button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-4">
            <div className="bd bgc-white">
              <div className="p-30 bdB">
                <h6><b className="fw-n">Current Portfolio</b></h6>
              </div>
              <div className="pY-30 pX-40 d-flex align-items-center justify-content-between">
                <div>
                  <span className="d-flex align-items-center btn btn-gray btn-around mB-10">
                    <i className="fas fa-circle mR-10 c-yellow fsz-xxs" />
Bitcoin
                  </span>
                  <span className="d-flex align-items-center btn btn-gray btn-around mB-10">
                    <i className="fas fa-circle mR-10 c-red fsz-xxs" />
Monero
                  </span>
                  <span className="d-flex align-items-center btn btn-gray btn-around mB-10">
                    <i className="fas fa-circle mR-10 c-green fsz-xxs" />
Litecoin
                  </span>
                  <span className="d-flex align-items-center btn btn-gray btn-around mB-10">
                    <i className="fas fa-circle mR-10 c-blue fsz-xxs" />
Ethereum
                  </span>
                </div>
                <div>
                  <CurrentPortfolioChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Reports;
