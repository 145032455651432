import React from 'react';
import { compose } from 'recompose';
import Countup from 'react-countup';
import { Helmet } from 'react-helmet';

import CurrentPortfolioChart from '../Reports/CurrentPortfolioChart';
import YourbalanceChart from './YourbalanceChart';
import ChartTotalEarnings from './ChartTotalEarnings';
import NewWalletModal from './NewWalletModal';
import StatsChart from '../components/StatsChart';
import TinyLineChart from '../../common/Charts/TinyLineChart';
import withModal from '../../../hocs/withModal';

const WalletsAddress = ({
  isOpenModal, closeModal, openModal, history,
}) => {
  const redirectToDetailPage = () => history.push('/dashboard_new/wallets-addresses-detail');
  return (
    <section>
      <Helmet>
        <title>Paybit - Wallets Address</title>
      </Helmet>
      <div className="p-20 bgc-grey main-content-container">
        <div className="main-content">
          <div className="row">
            <div className="col-12">
              <div className="pY-15">
                <h5>
                  <b className="fw-n">Wallet Coins</b>
                  <span className="fsz-xs c-gray mL-15">See the list of your wallets</span>
                </h5>
              </div>
            </div>
          </div>
          <div className="list-wallet-coin mB-15">
            <div className="wallet-coin-item">
              <div className="bd bgc-white bdrs-3">
                <div className="pY-10 pL-25 d-flex align-items-center justify-content-between">
                  <div>
                    <div className="d-flex mB-10">
                      <span className="coin-icon coin-icon-38 mR-20" style={{ backgroundImage: 'url(/images/coins/bitcoin-38.png)' }} />
                      <span>
                        <b className="fw-n">BTC</b>
                        <br />
                        <span className="c-gray"><Countup end={5255.15} prefix="$ " separator="," decimal="." duration={2.75} decimals={2} /></span>
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="coin-icon-38 text-center c-green fsz-xl mR-20"><i className="icon-arrow_up" /></span>
                      <span>
                        <b className="fw-n"><Countup end={2624.9} prefix="$ " separator="," decimal="." duration={2.75} decimals={1} /></b>
                        <br />
                        <span className="c-gray"><Countup end={0.567} suffix=" coin" separator="," decimal="." duration={3} decimals={3} /></span>
                      </span>
                    </div>
                  </div>
                  <div className="chart-line">
                    <TinyLineChart color="#00C300" />
                  </div>
                </div>
                <div className="pX-20 pY-10 d-flex justify-content-between">
                  <div>
                    <p className="c-gray mb-0">Wallet Addresses</p>
                    <small><b>12 (24254 PBT - 7442424 USD)</b></small>
                  </div>
                  <div>
                    <p className="c-gray mb-0">Market Cap</p>
                    <small><b>$6,2321,232,2323</b></small>
                  </div>
                </div>
              </div>
            </div>
            <div className="wallet-coin-item">
              <div className="bd bgc-white bdrs-3">
                <div className="pY-10 pL-25 d-flex align-items-center justify-content-between">
                  <div>
                    <div className="d-flex mB-10">
                      <span className="coin-icon coin-icon-38 mR-20" style={{ backgroundImage: 'url(/images/coins/ripple-38.png)' }} />
                      <span>
                        <b className="fw-n">XRP</b>
                        <br />
                        <span className="c-gray"><Countup end={5255.15} prefix="$ " separator="," decimal="." duration={2.75} decimals={2} /></span>
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="coin-icon-38 text-center c-red fsz-xl mR-20"><i className="icon-arrow_down" /></span>
                      <span>
                        <b className="fw-n"><Countup end={2624.9} prefix="$ " separator="," decimal="." duration={2.75} decimals={1} /></b>
                        <br />
                        <span className="c-gray"><Countup end={0.567} suffix=" coin" separator="," decimal="." duration={3} decimals={3} /></span>
                      </span>
                    </div>
                  </div>
                  <div className="chart-line">
                    <TinyLineChart color="#FF000A" />
                  </div>
                </div>
                <div className="pX-20 pY-10 d-flex justify-content-between">
                  <div>
                    <p className="c-gray mb-0">Wallet Addresses</p>
                    <small><b>12 (24254 PBT - 7442424 USD)</b></small>
                  </div>
                  <div>
                    <p className="c-gray mb-0">Market Cap</p>
                    <small><b>$6,2321,232,2323</b></small>
                  </div>
                </div>
              </div>
            </div>
            <div className="wallet-coin-item">
              <div className="bd bgc-white bdrs-3">
                <div className="pY-10 pL-25 d-flex align-items-center justify-content-between">
                  <div>
                    <div className="d-flex mB-10">
                      <span className="coin-icon coin-icon-38 mR-20" style={{ backgroundImage: 'url(/images/coins/eth-38.png)' }} />
                      <span>
                        <b className="fw-n">ETH</b>
                        <br />
                        <span className="c-gray"><Countup end={5255.15} prefix="$ " separator="," decimal="." duration={2.75} decimals={2} /></span>
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="coin-icon-38 text-center c-green fsz-xl mR-20"><i className="icon-arrow_up" /></span>
                      <span>
                        <b className="fw-n"><Countup end={2624.9} prefix="$ " separator="," decimal="." duration={2.75} decimals={1} /></b>
                        <br />
                        <span className="c-gray"><Countup end={0.567} suffix=" coin" separator="," decimal="." duration={3} decimals={3} /></span>
                      </span>
                    </div>
                  </div>
                  <div className="chart-line">
                    <TinyLineChart color="#00C300" />
                  </div>
                </div>
                <div className="pX-20 pY-10 d-flex justify-content-between">
                  <div>
                    <p className="c-gray mb-0">Wallet Addresses</p>
                    <small><b>12 (24254 PBT - 7442424 USD)</b></small>
                  </div>
                  <div>
                    <p className="c-gray mb-0">Market Cap</p>
                    <small><b>$6,2321,232,2323</b></small>
                  </div>
                </div>
              </div>
            </div>
            <div className="wallet-coin-item">
              <div className="bd bgc-white bdrs-3">
                <div className="pY-10 pL-25 d-flex align-items-center justify-content-between">
                  <div>
                    <div className="d-flex mB-10">
                      <span className="coin-icon coin-icon-38 mR-20" style={{ backgroundImage: 'url(/images/coins/paybit-38.png)' }} />
                      <span>
                        <b className="fw-n">PBT</b>
                        <br />
                        <span className="c-gray"><Countup end={5255.15} prefix="$ " separator="," decimal="." duration={2.75} decimals={2} /></span>
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="coin-icon-38 text-center c-red fsz-xl mR-20"><i className="icon-arrow_down" /></span>
                      <span>
                        <b className="fw-n"><Countup end={2624.9} prefix="$ " separator="," decimal="." duration={2.75} decimals={1} /></b>
                        <br />
                        <span className="c-gray"><Countup end={0.567} suffix=" coin" separator="," decimal="." duration={3} decimals={3} /></span>
                      </span>
                    </div>
                  </div>
                  <div className="chart-line">
                    <TinyLineChart color="#FF000A" />
                  </div>
                </div>
                <div className="pX-20 pY-10 d-flex justify-content-between">
                  <div>
                    <p className="c-gray mb-0">Wallet Addresses</p>
                    <small><b>12 (24254 PBT - 7442424 USD)</b></small>
                  </div>
                  <div>
                    <p className="c-gray mb-0">Market Cap</p>
                    <small><b>$6,2321,232,2323</b></small>
                  </div>
                </div>
              </div>
            </div>
            <div className="add-new-wallet">
              <div><a href="/" className="c-black">+ Add new wallet</a></div>
            </div>
          </div>
          <div className="box-style bd bgc-white mB-20">
            <div className="box-header d-flex d-md-inline-block justify-content-between w-100">
              <div className="mR-10 d-flex d-md-inline-block w-100">
                <select className="form-control form_control mR-10 mB-5">
                  <option value>All</option>
                </select>
                <select className="form-control form_control mR-10 mB-5">
                  <option value>Coins</option>
                </select>
                <select className="form-control form_control mR-10 mB-5">
                  <option value>Tokens</option>
                </select>
                <select className="form-control form_control mR-10 mB-5">
                  <option value>Groups</option>
                </select>
              </div>
              <div className="d-flex d-md-inline-block w-100 justify-content-end">
                <a href="/" className="btn btn-lg btn-outline-light bd c-black mR-10 mB-5">
              Next 100
                  <i className="fas fa-chevron-right mL-20" />
                </a>
                <a href="/" className="btn btn-lg btn-outline-light bd c-black mR-10 mB-5">
              View All
                </a>
                <a href="/" className="btn btn-lg btn-primary mR-10 mB-5">New Group</a>
                <a href="/" className="btn btn-lg btn-warning mB-5" data-toggle="modal" onClick={openModal}>Create New</a>
              </div>
            </div>
            <div className="box-body">
              <div className="table-responsive">
                <table className="table table-common table-hover table-crypto-market">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Funds</th>
                      <th>Wallet</th>
                      <th>Coins</th>
                      <th>Circulating Supply</th>
                      <th>Last Transaction</th>
                      <th>Change(24h)</th>
                    </tr>
                  </thead>
                  <tbody className="pX-20">
                    <tr onClick={redirectToDetailPage}>
                      <td><b className="fsz-md fw-n">1</b></td>
                      <td>
                        <span className="coin-currency">
                          <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                          <b className="fw-b fsz-md">Bitcoin</b>
                        </span>
                        <div className="mT-10">
                          <a href="/" className="text-uppercase mR-10">wallet details</a>
                          <a href="/" className="text-uppercase">Create new address</a>
                        </div>
                      </td>
                      <td>1MTSD2sdaxxz22DSDAVS123sa</td>
                      <td>3</td>
                      <td>$252</td>
                      <td>$9,4342.55</td>
                      <td>16,624,245 BTC</td>
                      <td>22.08.2018</td>
                      <td>
                        <div className="chart-line">
                          <TinyLineChart color="#FF000A" />
                        </div>
                      </td>
                    </tr>
                    <tr onClick={redirectToDetailPage}>
                      <td><b className="fsz-md fw-n">2</b></td>
                      <td>
                        <span className="coin-currency">
                          <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/eth-38.png)' }} />
                          <b className="fw-b fsz-md">Ethereum</b>
                        </span>
                        <div className="mT-10">
                          <a href="/" className="text-uppercase mR-10">wallet details</a>
                          <a href="/" className="text-uppercase">Create new address</a>
                        </div>
                      </td>
                      <td>1MTSD2sdaxxz22DSDAVS123sa</td>
                      <td>3</td>
                      <td>$252</td>
                      <td>$9,4342.55</td>
                      <td>16,624,245 BTC</td>
                      <td>22.08.2018</td>
                      <td>
                        <div className="chart-line">
                          <TinyLineChart color="#00C300" />
                        </div>
                      </td>
                    </tr>
                    <tr onClick={redirectToDetailPage}>
                      <td><b className="fsz-md fw-n">3</b></td>
                      <td>
                        <span className="coin-currency">
                          <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                          <b className="fw-b fsz-md">Bitcoin</b>
                        </span>
                        <div className="mT-10">
                          <a href="/" className="text-uppercase mR-10">wallet details</a>
                          <a href="/" className="text-uppercase">Create new address</a>
                        </div>
                      </td>
                      <td>1MTSD2sdaxxz22DSDAVS123sa</td>
                      <td>3</td>
                      <td>$252</td>
                      <td>$9,4342.55</td>
                      <td>16,624,245 BTC</td>
                      <td>22.08.2018</td>
                      <td>
                        <div className="chart-line">
                          <TinyLineChart color="#FF000A" />
                        </div>
                      </td>
                    </tr>
                    <tr onClick={redirectToDetailPage}>
                      <td><b className="fsz-md fw-n">4</b></td>
                      <td>
                        <span className="coin-currency">
                          <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                          <b className="fw-b fsz-md">Bitcoin</b>
                        </span>
                        <div className="mT-10">
                          <a href="/" className="text-uppercase mR-10">wallet details</a>
                          <a href="/" className="text-uppercase">Create new address</a>
                        </div>
                      </td>
                      <td>1MTSD2sdaxxz22DSDAVS123sa</td>
                      <td>3</td>
                      <td>$252</td>
                      <td>$9,4342.55</td>
                      <td>16,624,245 BTC</td>
                      <td>22.08.2018</td>
                      <td>
                        <div className="chart-line">
                          <TinyLineChart color="#00C300" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="bd bdrs-3 p-25 mB-20 bg-white">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <span className="fsz-sup c-grey"><i className="icon-exchange_money" /></span>
                    <div className="mL-20">
                      <span className="fsz-xs c-grey">Your Balance</span>
                      <div className="pos-r pR-10">
                        <b className="fsz-sup fw-n ">
  4,687.11
                          {' '}
                          <sup className="c-gray fsz-sm pos-a r-0 t-15">$</sup>
                        </b>
                      </div>
                    </div>
                  </div>
                  <div>
                    <select className="bdn c-gray">
                      <option value>Week</option>
                      <option value>Month</option>
                    </select>
                  </div>
                </div>
                <div className="pT-10 text-center flex-center">
                  <div className="yourbalance-chart-wrapper">
                    <YourbalanceChart />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="bd bdrs-3 p-25 mB-20 bg-white">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <span className="fsz-sup c-grey"><i className="icon-exchange_money" /></span>
                    <div className="mL-20">
                      <span className="fsz-xs c-grey">Total Earnings</span>
                      <div className="pos-r pR-10">
                        <b className="fsz-sup fw-n ">
  10,252
                          {' '}
                          <sup className="c-gray fsz-sm pos-a r-0 t-15">$</sup>
                        </b>
                      </div>
                    </div>
                  </div>
                  <div>
                    <select className="bdn c-gray">
                      <option value>Week</option>
                      <option value>Month</option>
                    </select>
                  </div>
                </div>
                <div className="pT-10 text-center flex-center">
                  <div className="chart-total-earnings-wrapper">
                    <ChartTotalEarnings />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="bd bdrs-3 p-25 mB-20 bg-white">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <span className="fsz-sup c-grey"><i className="icon-exchange_money" /></span>
                    <div className="mL-20">
                      <span className="fsz-xs c-grey">Account 1</span>
                      <div className="pos-r">
                        <b className="fsz-sup fw-n ">230,10</b>
                        {' '}
                        <span className="c-gray">Adst</span>
                        {' '}
                        <i className="c-green icon-stats" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <select className="bdn c-gray">
                      <option value>Week</option>
                      <option value>Month</option>
                    </select>
                  </div>
                </div>
                <div className="pY-20">
                  <div className="pY-20 mB-25">
                    <a href="/" className="c-gray fsz-md td-u">0x08df466b5f3561b5b5b2b5b2f36f36</a>
                  </div>
                  <div>
                    <a href="/" className="btn btn-primary btn-lg btn-around w-100">New Transactions</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-9">
              <div className="box-style bd bdrs-3 bgc-white mB-20">
                <span className="box-close-btn"><i className="icon-drag" /></span>
                <div className="box-header chart-stats-header">
                  <div className="chart-stats-header-inner d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center chart-stats-header-left">
                      <h5>
                        <b className="fw-n mR-10">Stats </b>
                        {' '}
                        <span className="fsz-sm c-gray">Year 2018</span>
                      </h5>
                      <div className="mL-30">
                        <span className="c-gray dropdown dropdown-monthly" role="group">
                          <span id="dropdown-dropdown-monthly" className="btn-transparent dropdown-toggle" data-toggle="dropdown" aria-haspopup="true">
                            Monthly
                          </span>
                          <div className="dropdown-menu" aria-labelledby="dropdown-dropdown-monthly">
                            <a className="dropdown-item" href="/">Monthly</a>
                            <a className="dropdown-item" href="/">Monthly</a>
                          </div>
                        </span>
                      </div>
                      <ul className="lis-n p-0 mL-10 mb-0">
                        <li className="float-left ml-4">
                          <i className="c-red fas fa-circle" />
                          <span className="pL-5">Monero</span>
                        </li>
                        <li className="float-left ml-4">
                          <i className="c-orange fas fa-circle" />
                          <span className="pL-5">Bitcoin</span>
                        </li>
                        <li className="float-left ml-4">
                          <i className="c-green fas fa-circle" />
                          <span className="pL-5">Riplle</span>
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex align-items-center chart-stats-header-right">
                      <div className="mr-5">
                        <span className="c-grey fsz-xs">Total</span>
                        <br />
                        {' '}
                        <span>
                          <Countup end={81000} prefix="$" separator="" decimal="." duration={3} />
                        </span>
                      </div>
                      <div className="mr-5">
                        <span className="c-grey fsz-xs">Monthly</span>
                        <br />
                        {' '}
                        <span><Countup end={95500} prefix="$" separator="" decimal="." duration={3} /></span>
                      </div>
                      <div className="mr-5">
                        <span className="c-grey fsz-xs">Summart</span>
                        <br />
                        {' '}
                        <span><Countup end={93528} prefix="$" separator="" decimal="." duration={3} /></span>
                      </div>
                      <div className="mr-5">
                        <span className="c-grey fsz-xs">Cashflow</span>
                        <br />
                        {' '}
                        <span><Countup end={74583} prefix="$" separator="" decimal="." duration={3} /></span>
                      </div>
                      <div className="mR-15">
                        <button className="btn-gray-gradient"><i className="fas fa-chevron-left" /></button>
                      </div>
                      <div className="mR-20">
                        <button className="btn-gray-gradient"><i className="fas fa-chevron-right" /></button>
                      </div>
                      <div>
                        <button className="btn-transparent">
                          <i className="c-gray fas fa-ellipsis-h" />
                        </button>
                      </div>
                      <div className="mL-10">
                        <button className="btn-transparent">
                          <i className="c-gray fas fa-times" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-body pY-20 start-chart-wrapper">
                  <StatsChart />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-3">
              <div className="box-style bd bgc-white mB-20">
                <div className="box-header">
                  <h5><b className="fw-n mR-10">Token Distribution</b></h5>
                </div>
                <div className="box-body pX-20 pB-20">
                  <div className="current-portfolio-chart-wrapper">
                    <CurrentPortfolioChart />
                  </div>
                  <div className="chart-token-distribution-desc">
                    <ul>
                      <li>
                        <b className="fw-n">
                          <i className="fas fa-circle c-blue" />
  Coins
                        </b>
                        <span>41%</span>
                      </li>
                      <li>
                        <b className="fw-n">
                          <i className="fas fa-circle c-red" />
  Currencies
                        </b>
                        <span>10%</span>
                      </li>
                      <li>
                        <b className="fw-n">
                          <i className="fas fa-circle c-violet" />
  USD
                        </b>
                        <span>41%</span>
                      </li>
                      <li>
                        <b className="fw-n">
                          <i className="fas fa-circle c-pink" />
  Master Nodes
                        </b>
                        <span>8%</span>
                      </li>
                      <li>
                        <b className="fw-n">
                          <i className="fas fa-circle c-green" />
                          Wallets
                        </b>
                        <span>15%</span>
                      </li>
                      <li>
                        <b className="fw-n">
                          <i className="fas fa-circle c-green-2" />
                          Program
                        </b>
                        <span>8%</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <NewWalletModal show={isOpenModal} onHide={closeModal} />
    </section>
  );
};

const container = compose(
  withModal(),
);


export default container(WalletsAddress);
