import React from 'react';
import {
  LineChart, Line, XAxis, ResponsiveContainer,
} from 'recharts';
import { currencyFormater } from '../../../utils/currencyFormater';

const mockData = [
  {
    name: 'Mon', value: 580,
  },
  {
    name: 'Tue', value: 139,
  },
  {
    name: 'Wed', value: 390,
  },
  {
    name: 'Thu', value: 250,
  },
  {
    name: 'Fri', value: 900,
  },
  {
    name: 'Sat', value: 380.2, keepShowing: true,
  },
  {
    name: 'Sun', value: 590,
  },
];

const CustomizedDot = (props) => {
  const { cx, cy, payload: { value, keepShowing = false } } = props;
  const radius = 15;
  const diameter = radius * 2;
  return keepShowing && (
    <svg width={diameter} height={diameter} preserveAspectRatio="xMidYMid" className="lds-ripple" style={{ background: 'none', overflow: 'visible' }}>
      <circle cx={cx} cy={cy} r="15" fill="none" ng-attr-stroke="{{config.c1}}" ng-attr-stroke-width="{{config.width}}" stroke="#0084FF" strokeWidth={3}>
        <animate attributeName="r" calcMode="spline" values="0;25" keyTimes="0;1" dur={2.5} keySplines="0 0.2 0.8 1" begin="-2.0s" repeatCount="indefinite" />
        <animate attributeName="opacity" calcMode="spline" values="1;0" keyTimes="0;1" dur={2.5} keySplines="0.2 0 0.8 1" begin="-2.0s" repeatCount="indefinite" />
      </circle>
      <circle cx={cx} cy={cy} fill="none" ng-attr-stroke="{{config.c2}}" ng-attr-stroke-width="{{config.width}}" stroke="#0084FF" strokeWidth={3}>
        <animate attributeName="r" calcMode="spline" values="0;15" keyTimes="0;1" dur={2.5} keySplines="0 0.2 0.8 1" begin="0s" repeatCount="indefinite" />
        <animate attributeName="opacity" calcMode="spline" values="1;0" keyTimes="0;1" dur={2.5} keySplines="0.2 0 0.8 1" begin="0s" repeatCount="indefinite" />
      </circle>
      <circle cx={cx} cy={cy} r={6} rstrokeWidth="0" fill="#0084FF" stroke="#FFF" strokeWidth="3" />
      <text x={cx - 5} y={cy - 30} fill="#212529" fontSize="12" fontWeight="bold">
        +
        {' '}
        {currencyFormater(value)}
      </text>
    </svg>
  );
};
const YourbalanceChart = ({ data = mockData }) => (
  <ResponsiveContainer
    width="100%"
    height="100%"
  >
    <LineChart
      data={data}
      margin={{
        top: 5, right: 30, left: 20, bottom: 5,
      }}
    >
      <XAxis dataKey="name" tickLine={false} axisLine={false} />
      <defs>
        <linearGradient id="customerColor" x1="0" y1="1" x2="1" y2="1">
          <stop offset="5%" stopColor="#0084FF" stopOpacity={0.2} />
          <stop offset="30%" stopColor="#0084FF" stopOpacity={0.5} />
          <stop offset="50%" stopColor="#0084FF" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Line type="monotone" dataKey="value" dot={<CustomizedDot />} strokeWidth={3} stroke="url(#customerColor)" />
    </LineChart>
  </ResponsiveContainer>
);

export default YourbalanceChart;
