import React from 'react';
import Countup from 'react-countup';
import { Helmet } from 'react-helmet';

import Account from '../common/FAQS/Account';
import SecurityNPrivacy from '../common/FAQS/SecurityNPrivacy';

const PricingFees = () => (
  <>
    <Helmet>
      <title>Paybit - Pricing Fees</title>
    </Helmet>
    <section className="bdT bgc-white pY-30">
      <div className="container">
        <ul className="breadcrumb breadcrumbs">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
            Fees
          </li>
        </ul>
      </div>
    </section>
    <section className="pY-50 bgc-grey">
      <div className="text-center mB-30 padding-L-R">
        <h4 className="fw-n">With PayBit, sending money online is always free. There are no activation costs and no yearly fees.</h4>
        <p className="c-gray fsz-def">Submit the form to get your pre-ICO or ICO published on ICObench for free.</p>
      </div>
      <div className="container">
        <div className="row justify-content-end mB-30">
          <div className="col-sm-12 col-md-6 col-lg-5 bd bgc-white pY-35">
            <div className="text-center">
              <div className="fsz-50 c-blue mB-20">
                <i className="icon-personal" />
              </div>
              <h5 className="fw-n">Personal</h5>
              <p className="fsz-def c-grey">
Advance features &amp;
                {' '}
                <br />
control of your wallet
              </p>
              <a href="/" className="btn btn-lg btn-primary btn-around">Contact Us</a>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-5 bd bgc-white pY-35">
            <div className="text-center">
              <div className="fsz-50 c-yellow mB-20">
                <i className="icon-business" />
              </div>
              <h5 className="fw-n">Business</h5>
              <p className="fsz-def c-grey">
                Advance Merchant and Business features
              </p>
              <a href="/" className="btn btn-lg btn-outline-secondary bd btn-around">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="mB-20 padding-L-R">
          <h5 className="row fw-n mB-20">Send or Receive Funds</h5>
          <div className="fsz-def">
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-2 bd pY-20 bgc-white "><span className="c-grey">Open an Account</span></div>
              <div className="col-12 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="c-green fw-n">Free</b></div>
              <div className="col-6 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="c-green fw-n">Free</b></div>
            </div>
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-2 bd pY-20 bgc-white "><span className="c-grey">Receive Funds*</span></div>
              <div className="col-12 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="fw-n">3.50 % + $0.25 USD </b></div>
              <div className="col-6 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="fw-n">2.50 % + $0.7 USD </b></div>
            </div>
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-2 bd pY-20 bgc-white "><span className="c-grey">Send Funds</span></div>
              <div className="col-12 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="c-green fw-n">Free</b></div>
              <div className="col-6 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="c-green fw-n">Free</b></div>
            </div>
          </div>
        </div>
        <div className="mB-20 padding-L-R">
          <h5 className="row fw-n mB-20">Add Funds</h5>
          <div className="fsz-def">
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-2 bd pY-20 bgc-white "><span className="c-grey">Bank Wire</span></div>
              <div className="col-12 col-sm-6 col-md-5 bd pY-20 bgc-white text-center">
                <b className="fw-n">
                  <Countup end={20.00} prefix="$" suffix=" BTC" separator="," decimal="." duration={3} decimals={2} delay={1} />
                </b>
              </div>
              <div className="col-6 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="fw-n">3.50 %  </b></div>
            </div>
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-2 bd pY-20 bgc-white "><span className="c-grey">Credit Card</span></div>
              <div className="col-12 col-sm-6 col-md-5 bd pY-20 bgc-white text-center">
                <b className="fw-n">
                  <Countup end={40.00} prefix="$" suffix=" BTC" separator="," decimal="." duration={3} decimals={2} delay={1} />
                </b>
              </div>
              <div className="col-6 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="fw-n">5.50 %  </b></div>
            </div>
          </div>
        </div>
        <div className="mB-20 padding-L-R">
          <h5 className="row fw-n mB-20">Withdraw Funds</h5>
          <div className="fsz-def">
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-2 bd pY-20 bgc-white "><span className="c-grey">Bank Wire</span></div>
              <div className="col-12 col-sm-6 col-md-5 bd pY-20 bgc-white text-center">
                <b className="fw-n">
                  <Countup end={30.00} prefix="$" suffix=" BTC" separator="," decimal="." duration={3} decimals={2} delay={1} />
                </b>
              </div>
              <div className="col-6 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="fw-n">3.00 %  </b></div>
            </div>
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-2 bd pY-20 bgc-white "><span className="c-grey">Credit Card</span></div>
              <div className="col-12 col-sm-6 col-md-5 bd pY-20 bgc-white text-center">
                <b className="fw-n">
                  <Countup end={20.00} prefix="$" suffix=" BTC" separator="," decimal="." duration={3} decimals={2} delay={1} />
                </b>
              </div>
              <div className="col-6 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="fw-n">2.00 %</b></div>
            </div>
          </div>
        </div>
        <div className="mB-20 padding-L-R">
          <h5 className="row fw-n mB-20">Currency Conversion</h5>
          <div className="fsz-def">
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-2 bd pY-20 bgc-white "><span className="c-grey">Foreign Exchange**</span></div>
              <div className="col-12 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="fw-n">2.500 %</b></div>
              <div className="col-6 col-sm-6 col-md-5 bd pY-20 bgc-white text-center"><b className="fw-n">4.500 %</b></div>
            </div>
          </div>
        </div>
        <div className="mT-30 row">
          <Account className="mB-15" />
          <SecurityNPrivacy className="mB-15" />
        </div>
      </div>
    </section>
    <section
      className="section-imgs section-subscribe"
      style={{ backgroundImage: 'url(/images/personal-tour-banner.jpg)' }}
    >
      <div className="inner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-6">
              <div className="c-white text-center">
                <h2 className="fsz-sup fw-n mB-10">Subscribe Our Newsletter!</h2>
                <p className="c-white-90 mB-20 fsz-md">For get all of our latest updates and promotion</p>
                <div className="form-subscribe">
                  <div className="input-group">
                    <input type="email" className="form-control form_control" placeholder="Enter your email address" />
                    <div className="input-group-append">
                      <button className="btn-primary btn"><i className="icon-right-arrow" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default PricingFees;
