import React from 'react';
import { Helmet } from 'react-helmet';

const Referral = () => (
  <section className="p-20 bgc-grey main-content-container">
    <Helmet>
      <title>Paybit - Referral</title>
    </Helmet>
    <div className="box-style bd bgc-white mB-20 pY-20 pX-30">
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <div className="bd mB-20 p-20 text-center referral-box active">
            <p className="mT-10 mB-20">
              <i className="icon-referals" />
            </p>
            <b className="fsz-def">Referral Code</b>
            <p className="fsz-sm c-gray">Lorem Ipsum is simply</p>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="bd mB-20 p-20 text-center referral-box">
            <p className="mT-10 mB-20">
              <i className="icon-gift-box" />
            </p>
            <b className="fsz-def">Promotion Tools</b>
            <p className="fsz-sm c-gray">Let me know if you need anything</p>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="bd mB-20 p-20 text-center referral-box">
            <p className="mT-10 mB-20">
              <i className="icon-leaderboard" />
            </p>
            <b className="fsz-def">Leaderboard</b>
            <p className="fsz-sm c-gray">Check your status on the board</p>
          </div>
        </div>
      </div>
      <div className="mT-30 mB-40 text-center">
        <h4><b className="fw-n">Referrals</b></h4>
        <p className="c-gray fsz-def">Submit your promo code, send points with your friend or donate to chat pool</p>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-8 col-lg-6">
          <div className="input-group mb-3">
            <input type="text" className="form-control" defaultValue=" https://paybit.com/signup?ref=ref24rzh921" />
            <div className="input-group-append">
              <button className="btn btn-lg btn-primary" type="button">Copy</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="mB-20">
      <div className="row">
        <div className="mB-20 col-md-6 col-lg-3">
          <div className="bd bdrs-6p p-30 text-center">
            <div className="fsz-xl c-gray">21</div>
            <div className="mT-20 mB-20">
              <i className="c-blue fsz-50 icon-referral-signups" />
            </div>
            <h4><b className="fw-n">Referral Signups</b></h4>
          </div>
        </div>
        <div className="mB-20 col-md-6 col-lg-3">
          <div className="bd bdrs-6p p-30 text-center">
            <div className="fsz-xl c-gray">12</div>
            <div className="mT-20 mB-20">
              <i className="c-blue fsz-50 icon-successfull-referrals" />
            </div>
            <h4><b className="fw-n">Seccessfull Referrals</b></h4>
          </div>
        </div>
        <div className="mB-20 col-md-6 col-lg-3">
          <div className="bd bdrs-6p p-30 text-center">
            <div className="fsz-xl c-gray">10</div>
            <div className="mT-20 mB-20">
              <i className="c-blue fsz-50 icon-exchage" />
            </div>
            <h4><b className="fw-n">Exchanged Crypto</b></h4>
          </div>
        </div>
        <div className="mB-20 col-md-6 col-lg-3">
          <div className="bd bdrs-6p p-30 text-center">
            <div className="fsz-xl c-gray">5</div>
            <div className="mT-20 mB-20">
              <i className="c-blue fsz-50 icon-bank-money" />
            </div>
            <h4><b className="fw-n">Credits Earned</b></h4>
          </div>
        </div>
      </div>
    </div>
    <div className="box-style bd bgc-white mB-20">
      <div className="box-header box-last-activity-header">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center box-last-activity-header-left">
            <h5><b className="fw-n mR-10">Refferal Users</b></h5>
            <div className="mL-30">
              <a className="c-gray" href="/">324 Contacts</a>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="mR-15">
              <div className="input_group">
                <i className="mL-10 icon-search" />
                <input type="text" placeholder="Search  Contact" className="form-control bdn" />
                <button className="btn btn-primary">Search</button>
              </div>
            </div>
            <div className="pL-20 pT-5 float-left">
              <button className="btn-transparent">
                <i className="c-gray icon-drag" />
              </button>
            </div>
            <div className="pL-20 pT-5 float-left">
              <button className="btn-transparent">
                <i className="c-gray fas fa-times" />
              </button>
            </div>
            <div className="pL-20 pT-5 float-left">
              <button className="btn-transparent">
                <i className="c-gray fas fa-ellipsis-h" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="box-body">
        <div className="table-responsive">
          <table className="table table-common table-hover table-reffera">
            <thead>
              <tr>
                <th>Name / Email </th>
                <th>Signup Date</th>
                <th>Earned</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="pX-20">
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="img-48 mR-15 bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                    <div className>
                      <b className="fw-n">Jonathan Smith</b>
                      <p className="fsz-sm c-gray mb-0">tamaranikcevic@yahoo.com</p>
                    </div>
                  </div>
                </td>
                <td className="c-gray">25.2.2018</td>
                <td><span className="c-green">$5,241</span></td>
                <td><button className="btn btn-lg btn-danger">Block Contact</button></td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="img-48 mR-15 bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                    <div className>
                      <b className="fw-n">Jonathan Smith</b>
                      <p className="fsz-sm c-gray mb-0">tamaranikcevic@yahoo.com</p>
                    </div>
                  </div>
                </td>
                <td className="c-gray">25.2.2018</td>
                <td><span className="c-green">$5,241</span></td>
                <td><button className="btn btn-lg btn-outline-light">Invite Contact</button></td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="img-48 mR-15 bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                    <div className>
                      <b className="fw-n">Jonathan Smith</b>
                      <p className="fsz-sm c-gray mb-0">tamaranikcevic@yahoo.com</p>
                    </div>
                  </div>
                </td>
                <td className="c-gray">25.2.2018</td>
                <td><span className="c-green">$5,241</span></td>
                <td><button className="btn btn-lg btn-outline-light">Invite Contact</button></td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="img-48 mR-15 bdrs-50p" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                    <div className>
                      <b className="fw-n">Jonathan Smith</b>
                      <p className="fsz-sm c-gray mb-0">tamaranikcevic@yahoo.com</p>
                    </div>
                  </div>
                </td>
                <td className="c-gray" />
                <td><span className="c-green" /></td>
                <td><button className="btn btn-lg btn-outline-light">Invite Contact</button></td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="img-48 mR-15 bdrs-50p" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                    <div className>
                      <b className="fw-n">Jonathan Smith</b>
                      <p className="fsz-sm c-gray mb-0">tamaranikcevic@yahoo.com</p>
                    </div>
                  </div>
                </td>
                <td className="c-gray" />
                <td><span className="c-green" /></td>
                <td><button className="btn btn-lg btn-outline-light">Invite Contact</button></td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="img-48 mR-15 bdrs-50p" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                    <div className>
                      <b className="fw-n">Jonathan Smith</b>
                      <p className="fsz-sm c-gray mb-0">tamaranikcevic@yahoo.com</p>
                    </div>
                  </div>
                </td>
                <td className="c-gray" />
                <td><span className="c-green" /></td>
                <td><button className="btn btn-lg btn-outline-light">Invite Contact</button></td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="img-48 mR-15 bdrs-50p" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                    <div className>
                      <b className="fw-n">Jonathan Smith</b>
                      <p className="fsz-sm c-gray mb-0">tamaranikcevic@yahoo.com</p>
                    </div>
                  </div>
                </td>
                <td className="c-gray" />
                <td><span className="c-green" /></td>
                <td><button className="btn btn-lg btn-outline-light">Invite Contact</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
);

export default Referral;
