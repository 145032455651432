
import React from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';

import { store } from '../store/paybitAppStore';
import PaybitAppRouter from '../components/PaybitAppRouter';
import i18n from '../locales/PaybitAppI18n';

const PaybitApp = () => (
  <Provider store={store}>
    <CookiesProvider>
      <I18nextProvider i18n={i18n}>
        <PaybitAppRouter />
      </I18nextProvider>
    </CookiesProvider>
  </Provider>
);

export default PaybitApp;
