import React from 'react';
import { compose } from 'recompose';
import { map } from 'lodash';
import classNames from 'classnames';
import { withDetailCryto } from '../containers/CryptoDetailContainer';
import CryptoPriceGrapht from '../../common/Charts/CryptoPriceGrapht';

export const DetailCryptoChart = ({
  durationTypes, chartDuration, setChartDuration, chartData, historical, durationSettings,
}) => (
  <div className="pL-10">
    <div className="mY-20">
      <ul className="lis-n p-0 mb-0 list-tab-history d-inline-block w-100">
        {
          map(durationTypes, ({ displayName }, type) => (
            <li className={classNames('float-left', { active: chartDuration === type })} key={type}>
              <a className="btn btn-white btn-around" style={{ cursor: 'pointer' }} onClick={() => setChartDuration(type)}>{displayName}</a>
            </li>
          ))
        }
      </ul>
    </div>
    <div className="chart-price-container">
      <CryptoPriceGrapht chartData={chartData} historical={historical} chartDuration={chartDuration} durationSettings={durationSettings} />
    </div>
  </div>

);

export default compose(
  withDetailCryto,
)(DetailCryptoChart);
