import React from 'react';
import ReactWOW from 'react-wow';

const PartnerItems = () => (
  <ReactWOW animation="fadeInUp">
    <div className="partner-list-img">
      <div className="partner-item" style={{ backgroundImage: 'url(/images/partner-item1.png)' }} />
      <div className="partner-item" style={{ backgroundImage: 'url(/images/partner-item2.png)' }} />
      <div className="partner-item" style={{ backgroundImage: 'url(/images/partner-item3.png)' }} />
      <div className="partner-item" style={{ backgroundImage: 'url(/images/partner-item4.png)' }} />
      <div className="partner-item" style={{ backgroundImage: 'url(/images/partner-item1.png)' }} />
      <div className="partner-item" style={{ backgroundImage: 'url(/images/partner-item2.png)' }} />
      <div className="partner-item" style={{ backgroundImage: 'url(/images/partner-item3.png)' }} />
      <div className="partner-item" style={{ backgroundImage: 'url(/images/partner-item4.png)' }} />
    </div>
  </ReactWOW>
);

export default PartnerItems;
