import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import TopSlider from './TopSlider';
import YourMoney from './YourMoney';
import WeGotCoveredSlider from '../common/Sliders/WeGotCoveredSlider';
import CurrencySlider from '../common/Sliders/CurrencySlider';
import SectionPartner from '../common/SectionPartner';
import StartUsing from '../common/LangdingSections/StartUsing';
import Offer from '../common/LangdingSections/Offer';
import DownloadApp from '../common/LangdingSections/DownloadApp';
import ProductTabs from '../common/ProductTabs';

const LandingPageBest = () => (
  <>
    <Helmet>
      <title>Paybit - Landing Page Best</title>
    </Helmet>
    <TopSlider />
    <CurrencySlider />
    <div className="landing-pagebest-bg">
      <ReactWOW animation="fadeInLeft">
        <div className="shape shape-1" />
      </ReactWOW>
      <ReactWOW animation="fadeInLeft">
        <div className="icons icons-1" />
      </ReactWOW>
      <ReactWOW animation="fadeInRight">
        <div className="shape shape-2" />
      </ReactWOW>
      <ReactWOW animation="fadeInUp" delay="0.5s">
        <div className="icons icons-2" />
      </ReactWOW>
      <ReactWOW animation="fadeInUp" delay="0.5s">
        <div className="icons icons-3" />
      </ReactWOW>
      <ReactWOW animation="fadeInUp" delay="1s">
        <div className="icons icons-4" />
      </ReactWOW>
      <ReactWOW animation="fadeInUp" delay="0.5s">
        <div className="icons icons-5" />
      </ReactWOW>
      <ReactWOW animation="fadeInUp" delay="0.5s">
        <div className="icons icons-6" />
      </ReactWOW>
      <ReactWOW animation="fadeInUp" delay="1s">
        <div className="icons icons-7" />
      </ReactWOW>
      <section className="pY-70 section-solution">
        <div className="container">
          <div className="text-center mB-40">
            <h4><b className="fw-n">Paybit Solutions for Online, Mobile and In-Store</b></h4>
            <p className="fsz-def">Get started now with your bitcoin wallet. Monetize your virtual currency payments</p>
          </div>
          <div className="row">
            <ReactWOW animation="bounceInLeft" duration="1s" delay="0.2s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-mobile" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5><b className="fw-n">Mobile</b></h5>
                    <p className="fsz-def">Add Paybit to Your Mobile Site and App</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="bounceInUp" duration="1s" delay="0.4s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-pc" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5><b className="fw-n">PC</b></h5>
                    <p className="fsz-def">Add Paybit to Your Website</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="bounceInRight" duration="1s" delay="0.6s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-shop" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5><b className="fw-n">Store</b></h5>
                    <p className="fsz-def">Accept Paybit in Your Store</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="bounceInLeft" duration="1s" delay="0.8s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-individuals" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5><b className="fw-n">Individuals</b></h5>
                    <p className="fsz-def">Paybit is the easiest way to transact at a very low cost.</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="bounceInUp" duration="1s" delay="1s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-businesses" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5><b className="fw-n">Businesses</b></h5>
                    <p className="fsz-def">Paybit is a very secure and inexpensive way to handle payments.</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="bounceInRight" duration="1s" delay="1.2s">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="paybit-solution-item box-shadow-mb">
                  <div className="circle-133 m-auto">
                    <span>
                      <i className="icon-developers1" />
                      <i className="fas fa-check-circle c-blue" />
                    </span>
                  </div>
                  <div className="mT-25">
                    <h5><b className="fw-n">Developers</b></h5>
                    <p className="fsz-def">Paybit can be used to build amazing things and provide solutions to common problems.</p>
                  </div>
                </div>
              </div>
            </ReactWOW>
          </div>
          <hr className="hr-gray" />
          <div className="mT-50">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <ReactWOW animation="slideInUp">
                      <div className="mB-50 box-shadow-mb">
                        <p className="c-gray text-uppercase">benefits</p>
                        <h3 className="mB-20"><b className="fw-n">Get everything you need</b></h3>
                        <div className="line-height-2 c-text-gray fsz-def">
                          <p>Shop with peace of mind, knowing we protect your eligible purchases. If an eligible item doesn’t show up, or turns out to be different than described, we’ll help sort things out with the seller. Conditions apply.</p>
                          <br className="pc" />
                          <p className="pc">We offer a Buyer and Seller Protection system. Payments can be refund up to 180 days, based on what is sold. Nobody needs to worry to get problems, read our Buyer and Protection details for more information.</p>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <ReactWOW animation="slideInUp" delay="0.1s">
                      <div className="mB-40 box-shadow-mb">
                        <p className="c-gray text-uppercase">simple</p>
                        <h3 className="mB-20"><b className="fw-n">We got you covered</b></h3>
                        <div className="line-height-2 c-text-gray fsz-def">
                          <p>Buyers make purchases on merchants’ websites and in merchants’ Apps with Paybit. Paybit will deduct the amount of a payment from the buyer&apos;s Paybit account in real-time in CNY and settle the payment to the merchant in a Crypto currency.</p>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-7">
                <div className="row">
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="fas fa-dollar-sign" />
                        </div>
                      </div>
                      <h4><b className="fw-n">Available Currencies</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">We support every crypto currency possible. From BTC to ETH, from EON to NEO. Currencys are converted automaticly.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="icon-transfer-in-second" />
                        </div>
                      </div>
                      <h4><b className="fw-n">Transfer in Secound</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">Payments can&apos;t get better. Pay anyone with just one simple click. Everything is done in lightspeed.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="icon-security" />
                        </div>
                      </div>
                      <h4><b className="fw-n">Mighty Protection</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">You dont need to worry. With our PayBit Buyer Protection. you have not to worry about any problems.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="icon-hand-pointer" />
                        </div>
                      </div>
                      <h4><b className="fw-n">Easy to Use</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">PayBit is simple. You can pay with just a bit. After signup, a short verification is needed. After that you can start paying and selling.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="icon-headphone" />
                        </div>
                      </div>
                      <h4><b className="fw-n">24/7 Support</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">Our large staff is always there when needed. No matter how big or small your problem is we will do our best to solve everything.</p>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="slideInUp">
                    <div className="col-12 col-md-6 mB-30 box-hover box-shadow-mb">
                      <div className="mB-25">
                        <div className="circle-blue-o-80">
                          <i className="icon-organized-payments" />
                        </div>
                      </div>
                      <h4><b className="fw-n">Organized Payments</b></h4>
                      <p className="fsz-def c-text-gray line-height-2">With our simple but yet mighty Dashboard organizing payments has reached a new level Everything needed for both business and normal clients</p>
                    </div>
                  </ReactWOW>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <ReactWOW animation="fadeInUp">
      <section className="pT-70 bgc-grey great-success">
        <div className="container">
          <div className="row justify-content-between">
            <ReactWOW animation="slideInUp">
              <div className="col-md-12 col-lg-5">
                <div className="mT-50">
                  <p className="c-gray text-uppercase">fast</p>
                  <h3 className="mB-20"><b className="fw-n">Payment in a few clicks</b></h3>
                  <div className="line-height-2 c-text-gray fsz-def mB-40">
                    <p>
                      Millions of customers around the world use us for one simple reason:
                      it’s simple. Just an email address and password will get you through
                      to checkout before you can reach for your wallet.
                    </p>
                    <br />
                    <div>
                      <a href="/" className="c-black">Pay on Websites</a>
                      {' '}
                      |
                      <a href="/" className="c-black">Pay multiple Clients/Customers</a>
                    </div>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <div className="col-md-12 col-lg-6">
              <div className="row no-gutters">
                <ReactWOW animation="bounceInLeft">
                  <div className="col-4 col-md-4 mT-50">
                    <div className="circle-163 m-auto mB-15">
                      <span className="c-blue">
                        <i className="icon-hand-pointer" />
                      </span>
                    </div>
                  </div>
                </ReactWOW>
                <ReactWOW animation="bounceIn">
                  <div className="col-3 col-md-3 mT-50 text-center">
                    <div className="dot-line">
                      <i className="fas fa-circle" />
                      <i className="fas fa-circle" />
                      <i className="fas fa-circle" />
                      <i className="fas fa-circle" />
                      <i className="fas fa-circle" />
                      <i className="fas fa-circle" />
                    </div>
                  </div>
                </ReactWOW>
                <ReactWOW animation="bounceInRight">
                  <div className="col-4 col-md-5">
                    <div className="circle-163 active m-auto">
                      <span className="c-blue">
                        <i className="icon-paybit" />
                      </span>
                      <i className="fas fa-check-circle c-green" />
                    </div>
                    <p className="text-center mT-20">
    Great sucess you made
                      {' '}
                      <br />
    the payment!
                    </p>
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ReactWOW>
    <ProductTabs />
    <WeGotCoveredSlider />
    <section className="pT-70 bgc-grey">
      <div className="container">
        <div className="row">
          <ReactWOW animation="fadeInLeft">
            <div className="col-md-12 col-lg-6 box-hover text-center-md">
              <div className="d-flex d-mb-inline-block mB-40 box-shadow-mb">
                <div className="mR-30 mX-auto-md mB-40">
                  <div className="circle-80 m-auto">
                    <span className="c-blue">
                      <i className="icon-moneys1" />
                    </span>
                  </div>
                </div>
                <div>
                  <h5 className="mB-15"><b className="fw-n">Send money online or with your mobile.</b></h5>
                  <p className="fsz-def c-text-gray">Send money to almost anyone with an email address. You can send all around the world and they&lsquo;ll be delighted to get your message. Currency conversion fees may apply.</p>
                  <br />
                  <a href="/" className="c-black">
                    <b className="fw-n">Start Selling </b>
                    <i className="icon-right-arrow mL-10" />
                  </a>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInRight">
            <div className="col-md-12 col-lg-6 box-hover">
              <div className="d-flex d-mb-inline-block mB-40 box-shadow-mb">
                <div className="mR-30 mX-auto-md mB-40">
                  <div className="circle-80 m-auto">
                    <span className="c-blue">
                      <i className="icon-cart1" />
                    </span>
                  </div>
                </div>
                <div>
                  <h5 className="mB-15"><b className="fw-n">Shop around the world.</b></h5>
                  <p className="fsz-def c-text-gray">With PayBit you can shop at thousands of stores around the world in just a few easy clicks, knowing your card details are never shared with the seller. Currency conversion fees may apply.</p>
                  <br />
                  <a href="/" className="c-black">
                    <b className="fw-n">Start Shopping </b>
                    <i className="icon-right-arrow mL-10" />
                  </a>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
        <div className="text-center mT-30">
          <ReactWOW animation="bounceInUp">
            <div className="m-auto mockup-mobile" style={{ backgroundImage: 'url(/images/mockup-mobile.png)' }} />
          </ReactWOW>
        </div>
      </div>
    </section>
    <YourMoney />
    <Offer />
    <StartUsing />
    <DownloadApp />
    <SectionPartner />
  </>
);

export default LandingPageBest;
