import React from 'react';
import {
  PieChart, Pie, Cell, ResponsiveContainer, Tooltip,
} from 'recharts';

const data = [
  { name: 'Ethereum Coin', value: 300 },
  { name: 'Bitcoin Product', value: 550 },
  { name: 'Moreno Products', value: 150 },
];
const COLORS = [
  '#0084FF',
  '#FAAB1A',
  '#FF000A',
];

const BestSellingChart = () => (
  <ResponsiveContainer>
    <PieChart width="100%" height={255}>
      <Pie
        data={data}
        innerRadius={110}
        outerRadius={120}
        startAngle={180}
        endAngle={-180}
        cornerRadius={5}
        fill="#0084FF"
      >
        {
          data.map((_, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
        }
      </Pie>
      <Tooltip />
    </PieChart>
  </ResponsiveContainer>
);

export default BestSellingChart;
