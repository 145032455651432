import React from 'react';
import {
  PieChart, Pie, Sector, Cell, Tooltip,
} from 'recharts';
import { withDaskMode } from '../../../hocs/withDashMode';

const mockData = [
  { value: 800, name: 'Coins' },
  { value: 300, name: 'Currencies' },
  { value: 300, name: 'Wallets' },
  { value: 263, name: 'Program' },
  { value: 200, name: 'USD' },
];
const COLORS = [
  '#4780FB',
  '#ED575E',
  '#43C538',
  '#FDC321',
  '#6952FE',
];

const renderActiveShape = withDaskMode((props) => {
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle, daskMode,
    fill, payload,
  } = props;
  const textFill = daskMode ? '#fff' : '';
  return (
    <g>
      <text x={cx} y={cy} dy={0} textAnchor="middle" fontSize="20" fontWeight="bold" fill={textFill}>
        {payload.value}
      </text>
      <text x={cx} y={cy} dy={15} textAnchor="middle" fontSize="10" fill={textFill}>OCNG</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
});


const WalletPortfolio = () => (
  <PieChart width={132} height={126}>
    <Pie
      data={mockData}
      innerRadius={50}
      outerRadius={60}
      fill="#8884d8"
      paddingAngle={5}
      activeIndex={3}
      activeShape={renderActiveShape}
    >
      {
        mockData.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} key={index} />)
      }
    </Pie>
    <Tooltip />
  </PieChart>
);

export default WalletPortfolio;
