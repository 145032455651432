import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
  compose, withState, withHandlers, lifecycle,
} from 'recompose';
import { debounce } from 'lodash';

import Dashboard from '../Dashboard';
import EmailInvoice from '../EmailInvoice';
import App from '../App';
import { AuthWrapper } from '../../hocs/withAuth';
import AuthRoute from '../common/Routes/AuthRoute';

const PaybitAppRouter = ({ isAuth, setIsAuth }) => (
  <BrowserRouter>
    <AuthWrapper {...{ isAuth, setIsAuth }}>
      <Switch>
        <Route path="/email-invoice" component={EmailInvoice} />
        <AuthRoute path="/dashboard_new" component={Dashboard} />
        <Route path="/" component={App} />
      </Switch>
    </AuthWrapper>
  </BrowserRouter>
);

const detectMob = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem));
};

export default compose(
  withState('isAuth', 'setIsAuth', localStorage.getItem('isAuth') === 'true'),
  withState('size', 'setSize', window.innerWidth),
  withHandlers({
    setIsAuth: ({ setIsAuth }) => (isAuth) => {
      localStorage.setItem('isAuth', isAuth);
      setIsAuth(isAuth);
    },
    handleResize: ({ size }) => () => {
      if (size !== window.innerWidth && !detectMob()) {
        window.location.reload();
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { handleResize } = this.props;
      window.addEventListener('resize', debounce(handleResize, 500));
    },
  }),
)(PaybitAppRouter);
