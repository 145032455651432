import React from 'react';
import { Modal } from 'react-bootstrap';

const ApplyModal = ({ isOpenModal, closeModal }) => (
  <Modal show={isOpenModal} onHide={closeModal} className="modal fade modal-applyjob" id="applyModal" as="div">
    <div className="modal-dialog" role="document">
      <div className="modal-content bd bdrs-0">
        <Modal.Body className="p-40" style={{ overflow: 'hidden' }}>
          <p className="c-grey mB-5"><b className="fw-n">Fill the fields</b></p>
          <h2 className="mB-30">Apply for job</h2>
          <form action>
            <div className="form-group">
              <label className="c-text-gray required">Name</label>
              <div className="input-has-icon">
                <i className="icon-user_circle_bold" />
                <input type="text" className="form-control form_control" placeholder="Enter your full name" />
              </div>
            </div>
            <div className="form-group">
              <label className="c-text-gray required">Enter your email</label>
              <div className="input-has-icon">
                <i className="icon-mail" />
                <input type="email" className="form-control form_control" placeholder="Enter your email" />
              </div>
            </div>
            <div className="form-group">
              <label className="c-text-gray required">Phone Number</label>
              <div className="input-has-icon">
                <i className="icon-telephone" />
                <input type="text" className="form-control form_control" placeholder="+381 (     )" />
              </div>
            </div>
            <div className="form-group">
              <label className="c-text-gray required">Covering Letter</label>
              <textarea className="form-control form_control" cols={30} rows={5} defaultValue="" />
            </div>
            <div className="form-group">
              <div className="d-flex pB-15 bdB justify-content-between">
                <div className="c-black">
                  <i className="far fa-file-alt c-grey mR-10" />
                  <span id="file-upload-filename" />
                </div>
                <div className="c-black">
                1,6 MB
                  <i className="icon-trash c-grey mL-10" />
                </div>
              </div>
            </div>
            <div className="attach-document">
              <label htmlFor="document-input">
                <input type="file" id="document-input" style={{ opacity: 0 }} />
                <h5 className="fw-n">Attach Document</h5>
                <p className="fsz-sm mb-0 c-text-gray">
The file size is not more that 10 MB.
                  <br />
JPGE, PDF, DOC
                </p>
              </label>
            </div>
            <div className="text-center mT-30 mB-20">
              <button className="btn btn-primary btn-lg">Send your CV</button>
            </div>
            <div className="fsz-sm c-text-gray text-center">
By clicking on the Send button
              {' '}
              <br />
you agree to the processing or personal data.
              <br />
In accordance with the
              {' '}
              <a href="/">Privacy Policy.</a>
            </div>
          </form>
        </Modal.Body>
      </div>
    </div>
  </Modal>
);

export default ApplyModal;
