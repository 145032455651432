import React from 'react';
import { Dropdown } from 'react-bootstrap';
import {
  compose, withHandlers, withState, lifecycle,
} from 'recompose';
import { withRouter, Link } from 'react-router-dom';
import Slider from 'react-slick';
import Countup from 'react-countup';
import { debounce } from 'lodash';

import Notifications from '../Notifications';
import Messages from '../Messages';
import UserDropdown from '../UserDropdown';
import HeaderSearchForm from './HeaderSearchForm';
import ModalTransactionDetail from './ModalTransactionDetail';
import withModal from '../../../../hocs/withModal';
import { withAuth } from '../../../../hocs/withAuth';

const settings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: false,
  arrows: true,
  dots: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 1680,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const FullViewWrapper = ({ children }) => (
  <div className="header-coin">
    {children}
  </div>
);

const SlickWrapper = ({ children }) => (
  <Slider {...settings} className="header-coin">
    {children}
  </Slider>
);

const Header = ({
  location: { pathname }, withSlicker,
  isOpenModalCurrentcy, openModalCurrentcy, closeModalCurrentcy,
}) => {
  const IconsWrapper = withSlicker ? SlickWrapper : FullViewWrapper;
  return (
    <div className="dashboard-header">
      <div className="header-container">
        <div className="header-left">
          <ul className="breadcrumb">
            <li>
              <Link to="/dashboard_new">
                <i className="icon-home" />
                {pathname === '/dashboard_new' ? (<span>Dashboard</span>) : 'Dashboard'}
              </Link>
            </li>
            <li>
              <Link to="/dashboard_new/main-settings">
                {pathname.includes('/dashboard_new/main-settings') ? (<span>Settings</span>) : 'Settings'}
              </Link>
            </li>
            <li>Devices</li>
          </ul>
        </div>
        <div className="header-right">
          <IconsWrapper>
            <Dropdown className="dropdown dropdown-coin">
              <div className="coin-header" onClick={openModalCurrentcy} role="presentation">
                <span className="coin-icon coin-icon-20" style={{ backgroundImage: 'url(/images/bitcoin.png)' }} />
                <span>Bitcoin = </span>
                <span className="coin-value"><Countup end={1.1549} prefix="" suffix=" USD" separator="," decimal="." duration={3} decimals={4} /></span>
              </div>
              <Dropdown.Toggle id="dropdownBTC" className="dropdown-toggle btn no-after" variant="">
                <i className="fas fa-chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="dropdownBTC">
                <Dropdown.Item hred="#/action-1">Bitcoin</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="dropdown dropdown-coin">
              <div className="coin-header" onClick={openModalCurrentcy} role="presentation">
                <span className="coin-icon coin-icon-20" style={{ backgroundImage: 'url(/images/neo.png)' }} />
                <span>Neo = </span>
                <span className="coin-value"><Countup end={1.1549} prefix="" suffix=" USD" separator="," decimal="." duration={3} decimals={4} /></span>
              </div>
              <Dropdown.Toggle id="dropdownNeo" className="dropdown-toggle btn no-after" variant="">
                <i className="fas fa-chevron-down" div />
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="dropdownNeo">
                <Dropdown.Item hred="#/action-1">Neo</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="dropdown dropdown-coin">
              <div className="coin-header" onClick={openModalCurrentcy} role="presentation">
                <span className="coin-icon coin-icon-20" style={{ backgroundImage: 'url(/images/ethereum.png)' }} />
                <span>Ethereum = </span>
                <span className="coin-value"><Countup end={1.1549} prefix="" suffix=" USD" separator="," decimal="." duration={3} decimals={4} /></span>
              </div>
              <Dropdown.Toggle id="dropdownETH" className="dropdown-toggle btn no-after" variant="">
                <i className="fas fa-chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="dropdownETH">
                <Dropdown.Item hred="#/action-1">Ethereum</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="dropdown dropdown-coin">
              <div className="coin-header" onClick={openModalCurrentcy} role="presentation">
                <span className="coin-icon coin-icon-20" style={{ backgroundImage: 'url(/images/paybit.png)' }} />
                <span>PayBit = </span>
                <span className="coin-value"><Countup end={1.1549} prefix="" suffix=" USD" separator="," decimal="." duration={3} decimals={4} /></span>
              </div>
              <Dropdown.Toggle id="dropdownPB" className="dropdown-toggle btn no-after" variant="">
                <i className="fas fa-chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="dropdownPB">
                <Dropdown.Item hred="#/action-1">PayBit</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </IconsWrapper>
          <div className="header-group-right">
            <HeaderSearchForm />
            <div className="paybit-balance">
              <i className="icon-wallet1" />
              <div>
                Paybit Balance
                <br />
                <span className="black">$4,687.11</span>
              </div>
            </div>
            <ul className="nav-right">
              <Notifications />
              <Messages />
              <UserDropdown />
            </ul>
          </div>
        </div>
      </div>
      <ModalTransactionDetail
        isOpenModal={isOpenModalCurrentcy}
        openModal={openModalCurrentcy}
        closeModal={closeModalCurrentcy}
      />
    </div>
  );
};

export default compose(
  withRouter,
  withModal('currentcy'),
  withState('withSlicker', 'setSlickerView', false),
  withHandlers({
    updateDimensions: ({ setSlickerView }) => () => {
      const width = window.innerWidth;
      if (width < 1680) {
        setSlickerView(true);
      } else {
        setSlickerView(false);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { updateDimensions } = this.props;
      updateDimensions();
      window.addEventListener('resize', debounce(updateDimensions, 1000));
    },
  }),
  withAuth,
)(Header);
