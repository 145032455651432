import React from 'react';

import EarningsChart from './EarningsChart';
import BestSellingChart from './BestSellingChart';
import LastActivityTable from '../Home/components/Overview/LastActivityTable';

const BusinessMerchant = () => (
  <section>
    <div className="p-20 bgc-grey main-content-container">
      <div className="main-content">
        <div className="row">
          <div className="col-md-12 col-lg-9">
            <div className="row">
              <div className="col-md-12 col-lg-6 col-xl-3 mB-10">
                <div className="box-style bd bgc-white bdrs-3">
                  <div className="d-flex align-items-center p-30">
                    <span className="fsz-sup c-blue"><i className="icon-last_transfer" /></span>
                    <div className="mL-30">
                      <span className="c-grey">Last Transfer</span>
                      <div>
                        <b className="fsz-lg fw-n">$145.00</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-xl-3 mB-10">
                <div className="box-style bd bgc-white bdrs-3">
                  <div className="d-flex align-items-center p-30">
                    <span className="fsz-sup c-orange"><i className="icon-next_transfer" /></span>
                    <div className="mL-30">
                      <span className="c-grey">Next Transfer</span>
                      <div>
                        <b className="fsz-lg fw-n">$785.00</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-xl-3 mB-10">
                <div className="box-style bd bgc-white bdrs-3">
                  <div className="d-flex align-items-center p-30">
                    <span className="fsz-sup c-red"><i className="icon-user_bold" /></span>
                    <div className="mL-30">
                      <span className="c-grey">Total Customers</span>
                      <div>
                        <b className="fsz-lg fw-n">36</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-xl-3 mB-10">
                <div className="box-style bd bgc-white bdrs-3">
                  <div className="d-flex align-items-center p-30">
                    <span className="fsz-sup c-green"><i className="icon-request_money" /></span>
                    <div className="mL-30">
                      <span className="c-grey">Total Volume</span>
                      <div>
                        <b className="fsz-lg fw-n">$23.00</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-style bd bgc-white mB-20 bdrs-3">
              <span className="box-close-btn"><i className="icon-drag" /></span>
              <div className="box-header d-flex align-items-center">
                <h5 className="mR-20">
                  <b className="fw-n mR-10">Earnings </b>
                </h5>
                <select className="bdn bgn">
                  <option value>January 2018</option>
                  <option value>February 2018</option>
                </select>
              </div>
              <div className="earnings-chart-wrappper">
                <EarningsChart />
              </div>
              <div className="box-footer p-20">
                <div className="row pX-20">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                    <div className="d-flex align-items-center mB-10">
                      <div className="mR-30">
                        <p className="c-gray fsz-def mB-5">Income</p>
                        <h3><b className="fw-n">$2.78 M</b></h3>
                      </div>
                      <div>
                        <a href="/" className="btn btn-o-green btn-around">
                          <i className="fas fa-caret-up mR-10" />
                          15%
                        </a>

                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                    <div className="d-flex align-items-center mB-10">
                      <div className="mR-30">
                        <p className="c-gray fsz-def mB-5">Expenses</p>
                        <h3><b className="fw-n">$1.952</b></h3>
                      </div>
                      <div>
                        <a href="/" className="btn btn-o-red btn-around">
                          <i className="fas fa-caret-down mR-10" />
                          22%
                        </a>

                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                    <div className="d-flex align-items-center mB-10">
                      <div className="mR-20">
                        <p className="c-gray fsz-def mB-5">Available</p>
                        <h3><b className="fw-n">$0.0</b></h3>
                      </div>
                      <div>
                        <a href="/" className="btn btn-o-red btn-around">
                          <i className="fas fa-caret-down mR-10" />
                          22%
                        </a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-style bd bgc-white mB-20">
              <div className="box-header box-last-activity-header">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center box-last-activity-header-left">
                    <h5><b className="fw-n mR-10">Top Products Sales</b></h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="mR-15">
                      <div className="input_group">
                        <i className="mL-10 icon-search" />
                        <input type="text" placeholder="Search now" className="form-control bdn" />
                        <button className="btn btn-primary">Search</button>
                      </div>
                    </div>
                    <div>
                      <div className="input_group">
                        <i className="mL-10 far fa-calendar-check" />
                        <input type="date" placeholder="Select Date" className="form-control bdn" />
                        <button className="btn btn-primary">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive scrollable max-h-730">
                  <table className="table table-common table-common-ct table-hover">
                    <thead>
                      <tr>
                        <th>Customer</th>
                        <th>Sales</th>
                        <th>Price</th>
                        <th>Views</th>
                        <th>Total Sales</th>
                      </tr>
                    </thead>
                    <tbody className="pX-20">
                      <tr>
                        <td>
                          <div className="name-email d-flex align-items-center">
                            <div className="circle img size-48" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                            <div className="mL-20">
                              <p className="m-0 c-gray">Name of the customer</p>
                            </div>
                          </div>
                        </td>
                        <td className="c-grey">
                          55
                        </td>
                        <td className="c-grey">
                          $235
                        </td>
                        <td className="c-grey">
                          21,345
                        </td>
                        <td>
                          <a href="/" className="c-yellow">$24,251</a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="name-email d-flex align-items-center">
                            <div className="circle img size-48" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                            <div className="mL-20">
                              <p className="m-0 c-gray">Name of the customer</p>
                            </div>
                          </div>
                        </td>
                        <td className="c-grey">
                          55
                        </td>
                        <td className="c-grey">
                          $235
                        </td>
                        <td className="c-grey">
                          21,345
                        </td>
                        <td>
                          <a href="/" className="c-yellow">$24,251</a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="name-email d-flex align-items-center">
                            <div className="circle img size-48" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                            <div className="mL-20">
                              <p className="m-0 c-gray">Name of the customer</p>
                            </div>
                          </div>
                        </td>
                        <td className="c-grey">
                          55
                        </td>
                        <td className="c-grey">
                          $235
                        </td>
                        <td className="c-grey">
                          21,345
                        </td>
                        <td>
                          <a href="/" className="c-yellow">$24,251</a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="name-email d-flex align-items-center">
                            <div className="circle img size-48" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                            <div className="mL-20">
                              <p className="m-0 c-gray">Name of the customer</p>
                            </div>
                          </div>
                        </td>
                        <td className="c-grey">
                          55
                        </td>
                        <td className="c-grey">
                          $235
                        </td>
                        <td className="c-grey">
                          21,345
                        </td>
                        <td>
                          <a href="/" className="c-yellow">$24,251</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <div className="box-style bd bgc-white mB-20 p-20">
              <h5 className="mB-20"><b className="fw-n">Activity</b></h5>
              <div className="list-activity scrollable">
                <div className="activity-item">
                  <div className="activity-item-header d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="size-36 circle d-flex justify-content-center align-items-center bgc-blue c-white">
                        <i className="icon-cart" />
                      </div>
                      <b className="fw-n mL-10">New Sale</b>
                    </div>
                    <span className="c-gray fsz-xs">Now</span>
                  </div>
                  <div className="activity-item-content">
                    <div className="fsz-xs c-grey-700">Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehicula ut id elit.</div>
                  </div>
                </div>
                <div className="activity-item">
                  <div className="activity-item-header d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="size-36 circle d-flex justify-content-center align-items-center bgc-blue c-white">
                        <i className="fas fa-star" />
                      </div>
                      <b className="fw-n mL-10">New Review</b>
                    </div>
                    <span className="c-gray fsz-xs">10 min ago</span>
                  </div>
                  <div className="activity-item-content">
                    <div className="fsz-xs c-grey-700">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate.</div>
                  </div>
                </div>
                <div className="activity-item">
                  <div className="activity-item-header d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="size-36 circle d-flex justify-content-center align-items-center bgc-blue c-white">
                        <i className="icon-mail" />
                      </div>
                      <b className="fw-n mL-10">New Message</b>
                    </div>
                    <span className="c-gray fsz-xs">10 min ago</span>
                  </div>
                  <div className="activity-item-content">
                    <div className="fsz-xs c-grey-700">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.</div>
                  </div>
                </div>
                <div className="activity-item">
                  <div className="activity-item-header d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="size-36 circle d-flex justify-content-center align-items-center bgc-blue c-white">
                        <i className="icon-increase" />
                      </div>
                      <b className="fw-n mL-10">New Report</b>
                    </div>
                    <span className="c-gray fsz-xs">10 min ago</span>
                  </div>
                  <div className="activity-item-content">
                    <div className="fsz-xs c-grey-700">Lorem ipsum dolor sit.</div>
                  </div>
                </div>
                <div className="activity-item">
                  <div className="activity-item-header d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="size-36 circle d-flex justify-content-center align-items-center bgc-blue c-white">
                        <i className="icon-cart" />
                      </div>
                      <b className="fw-n mL-10">New Sale</b>
                    </div>
                    <span className="c-gray fsz-xs">Now</span>
                  </div>
                  <div className="activity-item-content">
                    <div className="fsz-xs c-grey-700">Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehicula ut id elit.</div>
                  </div>
                </div>
                <div className="activity-item">
                  <div className="activity-item-header d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="size-36 circle d-flex justify-content-center align-items-center bgc-blue c-white">
                        <i className="fas fa-star" />
                      </div>
                      <b className="fw-n mL-10">New Review</b>
                    </div>
                    <span className="c-gray fsz-xs">10 min ago</span>
                  </div>
                  <div className="activity-item-content">
                    <div className="fsz-xs c-grey-700">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate.</div>
                  </div>
                </div>
                <div className="activity-item">
                  <div className="activity-item-header d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="size-36 circle d-flex justify-content-center align-items-center bgc-blue c-white">
                        <i className="icon-mail" />
                      </div>
                      <b className="fw-n mL-10">New Message</b>
                    </div>
                    <span className="c-gray fsz-xs">10 min ago</span>
                  </div>
                  <div className="activity-item-content">
                    <div className="fsz-xs c-grey-700">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-style bd bgc-white mB-20">
              <div className="box-header">
                <h5><b className="fw-n mR-10">Best Selling</b></h5>
              </div>
              <div className="box-body pX-20 pB-20">
                <div className="best-selling-chart-wrapper">
                  <BestSellingChart />
                </div>
                <div className="chart-best-selling-desc mT-20">
                  <ul className="list-unstyled mB-0">
                    <li className="p-5">
                      <i className="fas fa-circle c-blue mR-10" />
Ethereum Coin
                    </li>
                    <li className="p-5">
                      <i className="fas fa-circle c-yellow mR-10" />
Bitcoin Product
                    </li>
                    <li className="p-5">
                      <i className="fas fa-circle c-red mR-10" />
Moreno Products
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-style bd bgc-white mB-20 bdrs-6">
          <span className="box-close-btn"><i className="icon-drag" /></span>
          <div className="box-header box-last-activity-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center box-last-activity-header-left">
                <h5>
                  <b className="fw-n mR-10">Last activity </b>
                  {' '}
                  <span className="fsz-sm c-gray">Year 2018</span>
                </h5>
                <div className="mL-30">
                  <a className="c-gray" href="/">(Show All)</a>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="mR-15">
                  <div className="input_group">
                    <i className="mL-10 icon-search" />
                    <input type="text" placeholder="Search now" className="form-control bdn" />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
                <div>
                  <div className="input_group">
                    <i className="mL-10 far fa-calendar-check" />
                    <input type="date" placeholder="Select Date" className="form-control bdn" />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-body pY-20">
            <LastActivityTable />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default BusinessMerchant;
