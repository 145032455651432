import React from 'react';

const DaskModeToggle = ({ daskMode, toggleDaskMode }) => (
  <div className="dark-version">
    <span className="gray-dark txt-light-switch">Light</span>
    <div className="switch__container">
      <input id="active-dark-version" className="switch switch--flat" type="checkbox" checked={daskMode} onChange={toggleDaskMode} />
      <label htmlFor="active-dark-version" />
    </div>
    <span className="color-orange txt-dark-switch">Dark</span>
  </div>
);

export default DaskModeToggle;
