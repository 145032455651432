import React from 'react';
import { Dropdown, Tab, Nav } from 'react-bootstrap';


const Messages = () => (
  <Dropdown className="notifications dropdown dropdown-chat">
    <Dropdown.Toggle href className="dropdown-toggle no-after" data-toggle="dropdown" aria-expanded="true" as="a">
      <span className="counter">3</span>
      <i className="icon-chat" />
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-menu bd ">
      <Dropdown.Toggle as="button" className="close-dropdown btn-transparent"><i className="icon-close" /></Dropdown.Toggle>
      <li className="pY-30 pL-20">
        <h3 className="fsz-lg">Chat</h3>
        <p className="mB-0 c-gray">You can chat with your family and friends in this space.</p>
      </li>
      <li className="pL-20">
        <Tab.Container as="li" defaultActiveKey="chat-tabContent">
          <div className="tab-common bgc-white bdB">
            <Nav as="ul" className="nav nav-tabs tab-sm" id="chatTab" role="tablist">
              <Nav.Item as="li" className="nav-item">
                <Nav.Link className="nav-link" id="notifications-tab" data-toggle="tab" eventKey="notifications-tabContent" role="tab" aria-selected="false">Notifications</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link className="nav-link" id="chat-tab" data-toggle="tab" eventKey="chat-tabContent" role="tab" aria-selected="true">Chat</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link className="nav-link" id="history-tab" data-toggle="tab" eventKey="history-tabContent" role="tab" aria-selected="false">History</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="tab-content pT-20" id="chatTabContent">
            <Tab.Pane className="tab-pane fade" eventKey="notifications-tabContent" role="tabpanel">
              Tab Notifications
            </Tab.Pane>
            <Tab.Pane className="tab-pane fade" eventKey="chat-tabContent" role="tabpanel">
              <label className="c-gray tt-u mB-20">Online</label>
              <ul className="ovY-a pos-r scrollable lis-n p-0 m-0 fsz-sm ps">
                <li>
                  <a href="/" className="peers td-n pY-15 c-grey-800">
                    <div className="peer mR-15 pos-r">
                      <div className="size-42 img circle" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                      <span className="user-status bgc-green" />
                    </div>
                    <div className="peer peer-greed">
                      <span>
                        <span className="fw-500">John Doe</span>
                      </span>
                      <p className="m-0"><small className="fsz-xs c-gray">@starcevicd</small></p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/" className="peers td-n pY-15 c-grey-800">
                    <div className="peer mR-15 pos-r">
                      <div className="size-42 img circle" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                      <span className="user-status bgc-green" />
                    </div>
                    <div className="peer peer-greed">
                      <span>
                        <span className="fw-500">John Doe</span>
                      </span>
                      <p className="m-0"><small className="fsz-xs c-gray">@starcevicd</small></p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/" className="peers td-n pY-15 c-grey-800">
                    <div className="peer mR-15 pos-r">
                      <div className="size-42 img circle" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                      <span className="user-status bgc-green" />
                    </div>
                    <div className="peer peer-greed">
                      <span>
                        <span className="fw-500">John Doe</span>
                      </span>
                      <p className="m-0"><small className="fsz-xs c-gray">@starcevicd</small></p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/" className="peers td-n pY-15 c-grey-800">
                    <div className="peer mR-15 pos-r">
                      <div className="size-42 img circle" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                      <span className="user-status bgc-orange" />
                    </div>
                    <div className="peer peer-greed">
                      <span>
                        <span className="fw-500">John Doe</span>
                      </span>
                      <p className="m-0"><small className="fsz-xs c-gray">@starcevicd</small></p>
                    </div>
                  </a>
                </li>
              </ul>
              <label className="c-gray tt-u mT-20 mB-20">Offline</label>
              <ul className="ovY-a pos-r scrollable lis-n p-0 m-0 fsz-sm ps">
                <li>
                  <a href="/" className="peers td-n pY-15 c-grey-800">
                    <div className="peer mR-15 pos-r">
                      <div className="size-42 img circle" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                      <span className="user-status bgc-red" />
                    </div>
                    <div className="peer peer-greed">
                      <span>
                        <span className="fw-500">John Doe</span>
                      </span>
                      <p className="m-0"><small className="fsz-xs c-gray">@starcevicd</small></p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/" className="peers td-n pY-15 c-grey-800">
                    <div className="peer mR-15 pos-r">
                      <div className="size-42 img circle" style={{ backgroundImage: 'url(/images/user2.jpg)' }} />
                      <span className="user-status bgc-red" />
                    </div>
                    <div className="peer peer-greed">
                      <span>
                        <span className="fw-500">John Doe</span>
                      </span>
                      <p className="m-0"><small className="fsz-xs c-gray">@starcevicd</small></p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/" className="peers td-n pY-15 c-grey-800">
                    <div className="peer mR-15 pos-r">
                      <div className="size-42 img circle" style={{ backgroundImage: 'url(/images/user3.jpg)' }} />
                      <span className="user-status bgc-red" />
                    </div>
                    <div className="peer peer-greed">
                      <span>
                        <span className="fw-500">John Doe</span>
                      </span>
                      <p className="m-0"><small className="fsz-xs c-gray">@starcevicd</small></p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/" className="peers td-n pY-15 c-grey-800">
                    <div className="peer mR-15 pos-r">
                      <div className="size-42 img circle" style={{ backgroundImage: 'url(/images/user4.jpg)' }} />
                      <span className="user-status bgc-red" />
                    </div>
                    <div className="peer peer-greed">
                      <span>
                        <span className="fw-500">John Doe</span>
                      </span>
                      <p className="m-0"><small className="fsz-xs c-gray">@starcevicd</small></p>
                    </div>
                  </a>
                </li>
              </ul>
            </Tab.Pane>
            <Tab.Pane className="tab-pane fade" eventKey="history-tabContent" role="tabpanel">Tab history</Tab.Pane>
          </div>
        </Tab.Container>
      </li>
    </Dropdown.Menu>
  </Dropdown>

);

export default Messages;
