import React from 'react';
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { currencyFormater } from '../../../../../utils/currencyFormater';

const mockData = [
  {
    name: 'some text', value: 50,
  },
  {
    name: 'some text', value: 45,
  },
  {
    name: 'some text', value: 40,
  },
  {
    name: 'some text', value: 33,
  },
  {
    name: 'some text', value: 26, keepShowing: true,
  },
  {
    name: 'some text', value: 33,
  },
  {
    name: 'some text', value: 45,
  },
  {
    name: 'some text', value: 40,
  },
  {
    name: 'some text', value: 47,
  },
  {
    name: 'some text', value: 60,
  },
  {
    name: 'some text', value: 65,
  },
  {
    name: 'some text', value: 44,
  },
];

const CustomDot = (props) => {
  const { cx, cy, payload: { keepShowing = false, value } } = props;
  const radius = 8;
  const diameter = radius * 2;
  return keepShowing && (
    <svg width={diameter} height={diameter} style={{ overflow: 'visible' }}>
      <circle cx={cx} cy={cy} r={radius} fill="#0084FF" stroke="#FFF" strokeWidth="3" />
      <text x={cx - 10} y={cy - 10} fill="#0084FF" fontSize="16" fontWeight="bold">{currencyFormater(value)}</text>
    </svg>
  );
};

const NewCustomer = () => (
  <div className="new-customer-chart">
    <ResponsiveContainer
      width="100%"
      height="100%"
    >
      <AreaChart
        data={mockData}
      >
        <Tooltip
          viewBox={{
            x: -1,
            y: -1,
            width: 400,
            height: 400,
          }}
          content={() => null}
        />
        <defs>
          <pattern id="custumerBackground" width="300%" height="150%">
            <image x="-10" y="0" xlinkHref="/images/refer-friends-bg.jpg" />
          </pattern>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          stroke="#0084FF"
          fill="url(#custumerBackground)"
          fillOpacity={0.9}
          ffset="5%"
          dot={<CustomDot />}
        />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

export default NewCustomer;
