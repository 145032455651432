import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import LandingPageBest from '../LandingPageBest';
import LandingPageIllustration from '../LandingPageIllustration';
import LandingPage from '../LandingPage';
import SignUpPersonal from '../SignUpPersonal';
import SignIn from '../SignIn';
import ForgotPassword from '../ForgotPassword';
import FAQs from '../FAQs';
import HelpCenter from '../HelpCenter';
import HelpCenterArticles from '../HelpCenterArticles';
import HelpCenterSingleArticle from '../HelpCenterSingleArticle';
import Careers from '../Careers';
import CareersSingle from '../CareersSingle';
import AboutUs from '../AboutUs';
import DownloadDesktopLinux from '../DownloadDesktopLinux';
import ContactUs from '../ContactUs';
import SingleBlog from '../SingleBlog';
import BusinessTour from '../BusinessTour';
import PersonalTour from '../PersonalTour';
import CryptoMarket from '../CryptoMarket';
import ICOListingApplication from '../ICOListingApplication';
import PricingFees from '../PricingFees';
import ToolbarDownload from '../ToolbarDownload';
import Blog from '../Blog';
import Marketplace from '../Marketplace';
import CryptoDetailsPage from '../CryptoMarket/CryptoDetailsPage';
import CryptoCurrencyCalculato from '../CryptoCurrencyCalculato';
import CryptopCurrencyConverting from '../CryptopCurrencyConverting';
import CurrencyComparsion from '../CurrencyComparsion';
import ExplorerSearch from '../ExplorerSearch';
import IcoLanding from '../IcoLanding';
import MerchantTour from '../MerchantTour';
import DownloadAndroidPhone from '../Download/DownloadAndroidPhone';
import DownloadAndroidTablet from '../Download/DownloadAndroidTablet';
import DownloadAppleMac from '../Download/DownloadAppleMac';
import DownloadIpad from '../Download/DownloadIpad';
import DownloadIphonePhone from '../Download/DownloadIphonePhone';
import PaybitStatusPage from '../PaybitStatusPage';
import WhatIsBitcoin from '../WhatIsBitcoin';
import UnauthRoute from '../common/Routes/UnauthRoute';
import ApiDocumentationContainer from '../ApiDocumentation';

const PaybitAppRouter = () => (
  <>
    <Route path="/" component={LandingPage} exact />
    <Route path="/landing-page" component={LandingPage} exact />
    <Route path="/landing-pagebest" component={LandingPageBest} exact />
    <Route path="/landing-page-illustration-2" component={LandingPageIllustration} exact />
    <UnauthRoute path="/sign-up-personal" component={SignUpPersonal} exact />
    <UnauthRoute path="/sign-up-business" component={SignUpPersonal} exact />
    <UnauthRoute path="/sign-in" component={SignIn} exact />
    <UnauthRoute path="/forgot-password" component={ForgotPassword} exact />
    <Route path="/faqs" component={FAQs} exact />
    <Route path="/help-center" component={HelpCenter} exact />
    <Route path="/help-center-articles" component={HelpCenterArticles} exact />
    <Route path="/help-center-single-article" component={HelpCenterSingleArticle} exact />
    <Route path="/careers" component={Careers} exact />
    <Route path="/careers-single" component={CareersSingle} exact />
    <Route path="/about-us" component={AboutUs} exact />
    <Route path="/download-desktop-linux" component={DownloadDesktopLinux} exact />
    <Route path="/contact-us" component={ContactUs} exact />
    <Route path="/single-blog" component={SingleBlog} exact />
    <Route path="/business-tour" component={BusinessTour} exact />
    <Route path="/personal-tour" component={PersonalTour} exact />
    <Route path="/crypto-market" component={CryptoMarket} exact />
    <Route path="/market-cap" component={CryptoMarket} exact />
    <Route path="/ico-listing-application" component={ICOListingApplication} exact />
    <Route path="/pricing-fees" component={PricingFees} exact />
    <Route path="/toolbar-download" component={ToolbarDownload} exact />
    <Route path="/blog" component={Blog} exact />
    <Route path="/marketplace" component={Marketplace} exact />
    <Route path="/crypto-details-page/:symbol" component={CryptoDetailsPage} exact />
    <Route path="/cryptocurrency-calculato" component={CryptoCurrencyCalculato} exact />
    <Route path="/cryptop-currency-converting" component={CryptopCurrencyConverting} exact />
    <Route path="/currency-comparsion" component={CurrencyComparsion} exact />
    <Route path="/explorer-search" component={ExplorerSearch} exact />
    <Route path="/ico-landing" component={IcoLanding} exact />
    <Route path="/merchant-tour" component={MerchantTour} exact />
    <Route path="/download-android-phone" component={DownloadAndroidPhone} exact />
    <Route path="/download-android-tablet" component={DownloadAndroidTablet} exact />
    <Route path="/download-apple-mac" component={DownloadAppleMac} exact />
    <Route path="/download-ipad" component={DownloadIpad} exact />
    <Route path="/download-iphone-phone" component={DownloadIphonePhone} exact />
    <Route path="/paybit-status-page" component={PaybitStatusPage} exact />
    <Route path="/what-is-bitcoin" component={WhatIsBitcoin} exact />
    <Route path="/api-documentation" component={ApiDocumentationContainer} exact />
  </>
);

export default PaybitAppRouter;
