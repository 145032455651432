import React from 'react';
import { Helmet } from 'react-helmet';

const ICOListingApplication = () => (
  <section className="bdT bgc-grey pY-30">
    <Helmet>
      <title>Paybit - ICO Listing Application</title>
    </Helmet>
    <div className="container">
      <div className="mB-25">
        <ul className="breadcrumb breadcrumbs">
          <li><a href="/"><i className="icon-house" /></a></li>
          <li>
            <i className="fas fa-chevron-right" />
ICO Listing Application
          </li>
        </ul>
      </div>
      <div className="mT-80">
        <div className="text-center mB-50">
          <h4 className="fw-n">Publish your ICO</h4>
          <p className="c-gray fsz-def">Submit the form to get your pre-ICO or ICO published on ICObench for free.</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-9">
            <div className="bd p-50 bgc-white">
              <div className="form-group">
                <label className="c-grey mB-10">ICO Name</label>
                <input type="text" className="form-control form_control" />
              </div>
              <div className="row row-sm">
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Category</label>
                    <select name className="form-control form_control">
                      <option value>Select Category</option>
                      <option value={1}>1</option>
                      <option value={1}>2</option>
                      <option value={1}>3</option>
                      <option value={1}>4</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Website URL</label>
                    <input type="text" className="form-control form_control" placeholder="Enter Website URL" />
                  </div>
                </div>
              </div>
              <div className="row row-sm">
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Choose Logo (300x170)</label>
                    <input type="file" className="form-control form_control" />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Choose Icon (85x85)</label>
                    <input type="file" className="form-control form_control" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="c-grey mB-10">About</label>
                <textarea className="form-control form_control" rows={8} placeholder="Tell us more about the ICO" defaultValue="" />
              </div>
              <div className="form-group">
                <label className="c-grey mB-10">Social media links</label>
                <textarea className="form-control form_control" rows={8} placeholder="URLs to Twitter, Facebook,Bitcointalk and more…" defaultValue="" />
              </div>
              <div className="row row-sm">
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Country of operation</label>
                    <select name className="form-control form_control">
                      <option value>Select country</option>
                      <option value={1}>1</option>
                      <option value={1}>2</option>
                      <option value={1}>3</option>
                      <option value={1}>4</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">(pre)ICO start and end date</label>
                    <select name className="form-control form_control">
                      <option value>Select Date</option>
                      <option value={1}>1</option>
                      <option value={1}>2</option>
                      <option value={1}>3</option>
                      <option value={1}>4</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row row-sm">
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Link to whitepaper</label>
                    <input type="text" className="form-control form_control" placeholder="Enter Link" />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Link to bounty</label>
                    <input type="text" className="form-control form_control" placeholder="Enter Link" />
                  </div>
                </div>
              </div>
              <div className="row row-sm">
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Link to MVP/Prototype</label>
                    <input type="text" className="form-control form_control" placeholder="Enter Link" />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Token name / Ticker</label>
                    <input type="text" className="form-control form_control" placeholder="Enter Token Name" />
                  </div>
                </div>
              </div>
              <div className="row row-sm">
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Platform and Token Type (e.g. Ethereum, ERC20)</label>
                    <input type="text" className="form-control form_control" placeholder="Enter Type" />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Price per token (e.g. 1 IBC = 0.01 ETH)</label>
                    <input type="text" className="form-control form_control" placeholder="Enter the Price" />
                  </div>
                </div>
              </div>
              <div className="row row-sm">
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Whitelist/KYC?</label>
                    <select name className="form-control form_control">
                      <option value>None</option>
                      <option value={1}>1</option>
                      <option value={1}>2</option>
                      <option value={1}>3</option>
                      <option value={1}>4</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="c-grey mB-10">Any restrictions in who can participate?</label>
                    <input type="text" className="form-control form_control" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="c-grey mB-10">Contact e-mail</label>
                <input type="email" className="form-control form_control" placeholder="Enter Email Address" />
                <p className="fsz-sm c-gray">We will notify you to your e-mail address if your application was accepted.</p>
              </div>
              <div className="mY-30">
                <div className="form-group">
                  <input className="checkbox-around" id="checkbox-1" name="checkbox" type="checkbox" />
                  <label htmlFor="checkbox-1" className="c-gray">
                  I am the official representative of this ICO and I give full
                  permission to use all the needed data from all
                  of our resources (website, social networks, white paper, etc.)
                  for the listing on ICObench and the ICObench API.
                  I have read and I agree with ICObench publishing policy presented in the
                  site&lsquo;s FAQ and terms. I am aware that
                  priority application and the payment
                  for it does not mean that my ICO will be automatically accepted.
                  I am also aware that if my ICO is declined I will not get a refund.
                  </label>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-9">
                  <button type="submit" className="btn btn-lg btn-around btn-primary w-100">Sumbit Application</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ICOListingApplication;
