
import RestClient from './RestClient';

class Coins extends RestClient {
  getPrices() {
    return this.client.get('api/prices.json');
  }

  getHistorical(symbol) {
    return this.client.get(`api/historical/${symbol}.json`);
  }
}

export const coinsApi = new Coins();
export default coinsApi;
