import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import { compose } from 'recompose';
import SectionPartner from '../common/SectionPartner';
import Account from '../common/FAQS/Account';
import PartnerItems from '../common/PartnerItems';
import { withAuth } from '../../hocs/withAuth';

const PersonalTour = ({ isAuth }) => (
  <>
    <Helmet>
      <title>Paybit - Personal Tour</title>
    </Helmet>
    <section className="top-banner-common" style={{ backgroundImage: 'url(/images/personal-tour-banner.jpg)' }}>
      <div className="top-banner-common-inner c-white">
        <div className="container">
          <ul className="breadcrumb breadcrumbs breadcrumbs-white">
            <li><a href="/"><i className="icon-house" /></a></li>
            <li>
              <i className="fas fa-chevron-right" />
              <a href="/">Personal Tour</a>
            </li>
          </ul>
          <ReactWOW animation="fadeInUp">
            <div className="top-banner-text">
              <ReactWOW animation="slideInUp" duration="0.5s">
                <p className="fsz-def c-white-60">Paybit for Personal</p>
              </ReactWOW>
              <ReactWOW animation="slideInLeft" duration="0.5s">
                <h1 className="fsz-50 fw-n">
                  Get your money faster,
                  <br />
                  {' '}
                  easier and cheaper
                  <br />
                  with PayBit.
                </h1>
              </ReactWOW>
              <div className="mT-20">
                {!isAuth && (
                <>
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.8s">
                    <a href="/sign-up-personal" className="btn btn-lg btn-primary mR-10">Join now</a>
                  </ReactWOW>
                  <ReactWOW animation="slideInLeft" duration="0.5s" delay="0.5s">
                    <a href="/" className="btn btn-lg btn-light">More Information</a>
                  </ReactWOW>
                </>
                )}
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
    <section className="pY-30 bgc-white">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-12 col-lg-9">
            <h2 className="fw-n fsz-lg">Why Paybit?</h2>
            <h2 className="mT-50 text-before-decor">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</h2>
          </div>
        </div>
        <PartnerItems />
      </div>
    </section>
    <ReactWOW animation="fadeInUp">
      <section className="section-img" style={{ backgroundImage: 'url(/images/exclusive-paybit-offers.jpg)' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8" />
            <div className="col-md-12 col-lg-4">
              <div className="c-white">
                <ReactWOW animation="sideRight">
                  <h1 className="fsz-50 fw-n mB-20">
                    Exclusive
                    <br />
                    PayBit Offers
                  </h1>
                </ReactWOW>
                <ReactWOW animation="sideRight">
                  <p className="c-white-90 mB-30">
                    Enjoy easy access to a range of the latest merchant offers, deals and bonuses.
                    Updated every month.
                  </p>
                </ReactWOW>
                <ReactWOW animation="sideRight">
                  <a href="/" className="btn btn-lg btn-primary">
                    <b className="fw-n mR-30">View Offers</b>
                    <i className="icon-arrow-right-circle" />
                  </a>
                </ReactWOW>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ReactWOW>
    <section className="pY-70 img" style={{ backgroundImage: 'url(/images/background-wave-gray.jpg)' }}>
      <div className="container">
        <div className="text-center">
          <h4 className="fw-n">Get Started</h4>
          <p className="c-text-gray">Looking for help or consulting? Fill the form below or email us. We will respond you as soon as possible.</p>
        </div>
        <div className="row mY-50">
          <ReactWOW animation="fadeInUp" delay="0.2s">
            <div className="col-md-12 col-lg-4">
              <div className="p-30 bd bgc-white mB-15 hover-boxshadow box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-40 mB-15">
                  <i className="icon-lock" />
                </div>
                <h5 className="fw-n">Safer and faster</h5>
                <p className="c-text-gray">Pay online with your PayBit password and skip entering your financial information. Plus, rest assured knowing Buyer Protection is available for your eligible purchases. </p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  More about security
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" delay="0.4s">
            <div className="col-md-12 col-lg-4">
              <div className="p-30 bd bgc-white mB-15 hover-boxshadow box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-40 mB-15">
                  <i className="icon-tag-free" />
                </div>
                <h5 className="fw-n">Mostly free, always upfront</h5>
                <p className="c-text-gray">It’s free to open a PayBit account and buy something using PayBit unless it involves a currency conversion. There are no PayBit fees if you use your PayBit balance to send money </p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  More about fees
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" delay="0.6s">
            <div className="col-md-12 col-lg-4">
              <div className="p-30 bd bgc-white mB-15 hover-boxshadow box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-40 mB-15">
                  <i className="icon-money-double" />
                </div>
                <h5 className="fw-n">Simple and convenient</h5>
                <p className="c-text-gray">Pay online with your PayBit password and skip entering your financial info. Or pay even faster with One Touch™, stay logged in and check out without . </p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  More about One Touch™
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
      <div className="pT-80">
        <div className="our-product-item our-product-item-2">
          <ReactWOW animation="fadeInLeft">
            <div className="our-product-item-img">
              <div style={{ backgroundImage: 'url(/images/connectedness.png)' }} />
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInRight">
            <div className="our-product-item-text">
              <p className="text-uppercase c-grey mB-5"><b className="fw-n">our product</b></p>
              <h4 className="fw-n mB-15">A New Vision of Connectedness</h4>
              <p className="c-text-gray mB-15">
                Millions of customers around the
                world use us for one simple reason:
                it’s simple. Just an email address and
                password will get you through to checkout
                before you can reach for your wallet.
                <br />
                <br />
                  Expand the Paybit network and earn money!
                <br />
                <br />
                  Refer your friends, family,
                  clients and contacts to Paybit and
                  qualify for cash rewards. It’s simple:
                  refer someone to use Paybit, and if they
                  meet the conditions (read more) then you’ll
                  get paid! All you have to do is copy and paste
                  your personalized link or banner code into an
                  email or your website to get started!
                <br />
                <br />
              </p>
              <div className="bd bgc-white p-30 hover-boxshadow box-hover-zoom box-shadow-mb">
                <div className="d-flex">
                  <div className="mR-25">
                    <div className="circle-80 hover-boxshadow">
                      <span className="c-blue">
                        <i className="icon-headphone" />
                      </span>
                    </div>
                  </div>
                  <div>
                    <h5 className="fw-n mb-10">We offer the best support 24/7</h5>
                    <p className="c-text-gray">
                      Send money to almost anyone with an email address.
                      You can send all around the world and they&lsquo;ll
                      be delighted to get your message. Currency conversion fees may apply.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
    <section className="bgc-grey pY-50">
      <div className="container">
        <div className="text-center">
          <h4 className="fw-n">Get Started</h4>
          <p className="c-text-gray">Looking for help or consulting? Fill the form below or email us. We will respond you as soon as possible.</p>
        </div>
        <div className="row mY-50 justify-content-center">
          <ReactWOW animation="fadeInUp" deplay="0.2s">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item-xl box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-user-information" />
                </div>
                <h5 className="fw-n">Online Application</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                        Fill in the form on the site to apply.
                        It will take just a 3 minutes of your time
                  </p>
                  <a href="/" className="c-black d-flex align-items-center justify-content-center">
                    <b className="fw-n mR-10">Apply </b>
                    {' '}
                    <i className="fsz-lg c-gray icon-arrow-right-circle" />
                  </a>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" deplay="0.4s">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item-xl box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-user-information-check" />
                </div>
                <h5 className="fw-n">Get an Approval</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    Bank will inform you immediately.
                    If you have any questions,
                    you could contact the bank’s employee right away
                  </p>
                  <a href="/" className="c-black d-flex align-items-center justify-content-center">
                    <b className="fw-n mR-10">Check application status </b>
                    {' '}
                    <i className="fsz-lg c-gray icon-arrow-right-circle" />
                  </a>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" deplay="0.6s">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="main-feature-item-xl box-hover-zoom box-shadow-mb">
                <div className="main-feature-item-icon">
                  <i className="icon-user-information-check" />
                </div>
                <h5 className="fw-n">Card Delivery</h5>
                <div className="main-feature-item-text">
                  <p className="c-text-gray">
                    Bank employee delivers your card and
                    documents to your home or work at a chosen time
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
        <div className="mT-30 mB-15">
          <Account />
        </div>
      </div>
    </section>
    <ReactWOW animation="fadeInUp">
      <section className="pT-60 pB-50 bgc-blue c-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 mB-30">
              <div className="d-flex">
                <div className="cirlce-50-border-white mR-15">
                      1
                </div>
                <div className="w-100-65 fsz-def">Complete the short online application form.</div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mB-30">
              <div className="d-flex">
                <div className="cirlce-50-border-white mR-15">
                      2
                </div>
                <div className="w-100-65 fsz-def">If approved and you accpet, you’ll have a credit limit attached to your PayBit account almost immediately. </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mB-30">
              <div className="d-flex">
                <div className="cirlce-50-border-white mR-15">
                      3
                </div>
                <div className="w-100-65 fsz-def">Start spending straigtaway at thousands of online stores with PayBit Credit.</div>
              </div>
            </div>
          </div>
          {!isAuth && (
            <div className="text-center">
              <a href="/sign-up-personal" className="btn btn-lg btn-light btn-light--light-hover">
                <span className="d-flex align-items-center">
                  <b className="fw-n mR-40">Apply Now</b>
                  <i className="fsz-md icon-arrow-right-circle" />
                </span>
              </a>
            </div>
          )}
        </div>
      </section>
    </ReactWOW>
    <ReactWOW animation="fadeInUp">
      <section className="pY-60 bgc-dark2 c-white">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-6">
              <h2 className="fsz-30 mB-20">No hidden costs, no surprises.</h2>
              <p className="fsz-def c-text-gray line-height-2">When you purchase with PayBit there are no hidden costs or processing fees, so you can enjoy your shopping. Currency conversion fees may apply.</p>
              <a href="/" className="c-white fsz-def"><b className="fw-n">More about fees</b></a>
            </div>
          </div>
        </div>
      </section>
    </ReactWOW>
    <SectionPartner backgroundImage="/images/testimonials-bg2.jpg" />
  </>
);

export default compose(
  withAuth,
)(PersonalTour);
