import React from 'react';
import {
  PieChart, Pie, Sector, Cell, Tooltip,
} from 'recharts';
import { withDaskMode } from '../../../hocs/withDashMode';

const mockData = [
  { value: 200, name: 'Coins' },
  { value: 300, name: 'Currencies' },
  { value: 700, name: 'Wallets' },
  { value: 263, name: 'Program' },
  { value: 110, name: 'USD' },
];
const COLORS = [
  '#4780FB',
  '#ED575E',
  '#43C538',
  '#5dd1ce',
  '#be83e1',
];

const renderActiveShape = withDaskMode((props) => {
  const {
    cx, cy, innerRadius, outerRadius,
    startAngle, endAngle, fill, daskMode,
  } = props;
  const textFill = daskMode ? '#fff' : '';
  return (
    <g>
      <text x={cx} y={cy - 45} dy={0} textAnchor="middle" fontSize="14" fontWeight="bold" fill={textFill}>
        <tspan x={cx} dy="20">
          2728 coins
        </tspan>
        <tspan x={cx} dy="20">
          USD 24242
        </tspan>
        <tspan x={cx} dy="20">
          24242 Wallets
        </tspan>
        <tspan x={cx} dy="20">
          24 currencies
        </tspan>
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
});

const CurrentPortfolioChart = () => (
  <PieChart width={200} height={195}>
    <Pie
      data={mockData}
      innerRadius={80}
      outerRadius={95}
      fill="#8884d8"
      paddingAngle={5}
      activeIndex={3}
      activeShape={renderActiveShape}
    >
      {
        mockData.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} key={index} />)
      }
    </Pie>
    <Tooltip />
  </PieChart>
);

export default CurrentPortfolioChart;
