import React from 'react';
import {
  AreaChart, Area,
  XAxis, YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { currencyFormater } from '../../../utils/currencyFormater';

const mockData = [
  {
    xCategory: '0', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 116, time: '11:20', day: 'Jan 17',
  },
  {
    xCategory: '1', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 180,
  },
  {
    xCategory: '2', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 72,
  },
  {
    xCategory: '3', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 51, time: '11:25', day: 'Feb 17',
  },
  {
    xCategory: '4', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 119,
  },
  {
    xCategory: '5', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 86,
  },
  {
    xCategory: '6', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 194, time: '11:30', day: 'Mar 17',
  },
  {
    xCategory: '7', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 90,
  },
  {
    xCategory: '8', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 189,
  },
  {
    xCategory: '9', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 148, time: '11:35', day: 'Apr 17',
  },
  {
    xCategory: '10', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 64,
  },
  {
    xCategory: '11', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 127,
  },
  {
    xCategory: '12', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 93, time: '11:40', day: 'May 17',
  },
  {
    xCategory: '13', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 41,
  },
  {
    xCategory: '14', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 30,
  },
  {
    xCategory: '15', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 145, time: '11:45', day: 'Jun 17',
  },
  {
    xCategory: '16', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 74,
  },
  {
    xCategory: '17', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 125,
  },
  {
    xCategory: '18', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 42, time: '11:50', day: 'Jul 17',
  },
  {
    xCategory: '19', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 178,
  },
  {
    xCategory: '20', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 113,
  },
  {
    xCategory: '21', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 185, time: '12:00', day: 'Aug 17',
  },
  {
    xCategory: '22', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 162,
  },
  {
    xCategory: '23', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 162,
  },
  {
    xCategory: '24', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 162, time: '12:05', day: 'Aug 17',
  },
  {
    xCategory: '25', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 35,
  },
  {
    xCategory: '26', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 74,
  },
  {
    xCategory: '27', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 179, time: '12:10', day: 'Aug 17',
  },
  {
    xCategory: '28', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 108,
  },
  {
    xCategory: '29', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 189,
  },
  {
    xCategory: '30', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 35, time: '12:15', day: 'Aug 17',
  },
  {
    xCategory: '31', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 122,
  },
  {
    xCategory: '32', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 80,
  },
  {
    xCategory: '33', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 122, time: '12:20', day: 'Aug 17',
  },
  {
    xCategory: '34', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 80,
  },
  {
    xCategory: '32', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 80,
  },
  {
    xCategory: '32', up: '0.25%', coin: 'Bitcoin', high: 6342322.22, low: 6521.66, value: 80,
  },
];

const CustomTick = (props) => {
  const { x, y, payload: { value } } = props;
  const { time } = mockData.find(payload => payload.xCategory === value);
  return (
    <g transform={`translate(${x + 30},${y})`} {...props}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">{time}</text>
    </g>
  );
};

const CustomDot = (props) => {
  const { cx, cy, payload: { keepShowing = false, value } } = props;
  const radius = 8;
  const diameter = radius * 2;
  return keepShowing && (
    <svg width={diameter} height={diameter} style={{ overflow: 'visible' }}>
      <circle cx={cx} cy={cy} r={radius} fill="#3486C2" stroke="#FFF" strokeWidth="3" />
      <text x={cx - 10} y={cy - 10} fill="#3486C2" fontSize="16" fontWeight="bold">{value}</text>
    </svg>
  );
};

const CustomTooltip = (props) => {
  const {
    payload,
  } = props;
  const {
    payload: {
      high, low, coin, up,
    },
  } = payload[0] || { payload: {} };
  return (
    <div className="chart-price--tooltip chart-tooltip">
      <div className="chart-tooltip__header">
        <span>{coin}</span>
        <p className="c-green fsz-def pL-15 mB-0">
          <i className="fas fa-sort-up" />
          {up}
          %
        </p>
      </div>
      <div className="chart-tooltip__break-line mB-5 mT-5" />
      <div className="chart-tooltip__body">
        <div className="chart-tooltip--value">
          <span className="c-gray mb-0">High: </span>
          <span>
            {currencyFormater(high)}
          </span>
        </div>
        <div className="chart-tooltip--value">
          <span className="c-gray mb-0">Low: </span>
          <span>
            {currencyFormater(low)}
          </span>
        </div>
      </div>
    </div>
  );
};

const PriceGraph = () => (
  <div className="chart-price">
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart width={1534} height={370} data={mockData}>
        <defs>
          <linearGradient id="statsColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3486C2" stopOpacity={0.4} />
            <stop offset="50%" stopColor="#3486C2" stopOpacity={0.1} />
            <stop offset="90%" stopColor="#3486C2" stopOpacity={0.01} />
          </linearGradient>
        </defs>
        <Tooltip
          viewBox={{
            x: -1,
            y: -1,
            width: 400,
            height: 400,
          }}
          content={<CustomTooltip />}
        />
        <XAxis dataKey="xCategory" tickLine={false} tick={CustomTick} />
        <YAxis tickLine={false} axisLine={false} />
        <Area type="linear" dataKey="value" stroke="#0084FF" fill="url(#statsColor)" fillOpacity={1} dot={CustomDot} />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

export default PriceGraph;
