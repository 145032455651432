import React from 'react';
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

import OurProduct from '../OurProduct';
import DownloadFooter from '../DownloadFooter';
import PartnerItems from '../../common/PartnerItems';
import BannerText from '../BannerText';

const DownloadAndroidTablet = () => (
  <>
    <Helmet>
      <title>Paybit - Download Android Tablet</title>
    </Helmet>
    <section className="top-banner-download top-banner-download-tablet" style={{ backgroundImage: 'url(/images/download-android-tablet-banner-unsplash.jpg)' }}>
      <div className="top-banner-download-outer">
        <div className="top-banner-download-inner c-white">
          <div className="container">
            <ul className="breadcrumb breadcrumbs breadcrumbs-white">
              <li><a href="/"><i className="icon-house" /></a></li>
              <li>
                <i className="fas fa-chevron-right" />
                <a href="download-iphone-phone">Apps</a>
              </li>
              <li>
                <i className="fas fa-chevron-right" />
                {' '}
                <span>Android App</span>
              </li>
            </ul>
            <div className="top-banner-text">
              <div className="row justify-content-between">
                <BannerText />
              </div>
            </div>
          </div>
          <ReactWOW animation="slideInRight" duration="0.5s" delay="1.5s">
            <div
              className="img-android-tablet-mockup hidden-md"
              style={{ backgroundImage: 'url(/images/mockup-android-tablet.png)' }}
            />
          </ReactWOW>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row justify-content-center mB-50 ">
          <div className="col-md-12 col-lg-9">
            <h2 className="mY-50 text-center text-before-decor">Millions of customers around the world use us for one simple reason: it’s simple. Just an email address and password will get you through to checkout before you can reach for your wallet.</h2>
          </div>
        </div>
        <div className="row mY-50">
          <ReactWOW animation="fadeInUp" deplay="0.2s">
            <div className="col-md-12 col-lg-4">
              <div className="pR-30 mB-30 box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-40 mB-25">
                  <i className="icon-money-10" />
                </div>
                <h5 className="fw-n">Send and request money fast.</h5>
                <p className="c-gray">It’s free to send money to friends and family in the U.S. using your bank account or Paybit balance. Pay your share of the bill or request money from friends with ease.</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  Find our more
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" deplay="0.4s">
            <div className="col-md-12 col-lg-4">
              <div className="pR-30 mB-30 box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-15">
                  <i className="icon-manage-easy" />
                </div>
                <h5 className="fw-n">Manage your money easily.</h5>
                <p className="c-gray">The improved Paybit app makes it simple to send and request money, manage credit, pool cash from peers, and create savings goals. Plus, you can easily track and monitor every transaction you make.</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
                    Find our more
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" deplay="0.6s">
            <div className="col-md-12 col-lg-4">
              <div className="pR-30 mB-30 box-hover-zoom box-shadow-mb">
                <div className="c-blue fsz-50 mB-15">
                  <i className="icon-collect-money-from-group" />
                </div>
                <h5 className="fw-n">Collect money from groups.</h5>
                <p className="c-gray">Whether you&lsquo;re collecting money for group gifts, travel, or special events, Money Pools make it easy to create a custom page that allows family, friends, and coworkers to chip in for big-ticket items.</p>
                <a href="/" className="c-black">
                  <b className="fw-n">
  Find our more
                    {' '}
                    <i className="icon-right-arrow" />
                  </b>

                </a>
              </div>
            </div>
          </ReactWOW>
        </div>
        <div className="clearfix" />
        <div className="mT-30">
          <PartnerItems />
        </div>
      </div>
    </section>
    <div className="bgi-gray">
      <section className="pB-70">
        <div className="container">
          <ReactWOW animation="fadeInUp">
            <div className="row align-items-center justify-content-center download-android-tablet">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-shadow download-box">
                  <div className="circle-gray-70">
                    <i className="icon-money-bag" />
                  </div>
                  <div className="mT-25">
                    <h5 className="fw-n">Get up to speed on getting paid.</h5>
                    <p className="c-text-gray line-height mb-0">Create and send invoices in the moment, so customers can pay right away.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-shadow download-box">
                  <div className="circle-gray-70">
                    <i className="icon-money-dollar" />
                  </div>
                  <div className="mT-25">
                    <h5 className="fw-n">Stay on top of your business</h5>
                    <p className="c-text-gray line-height mb-0">Whether you need to transfer funds, view transaction details.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box-shadow download-box">
                  <div className="circle-gray-70">
                    <i className="icon-flag" />
                  </div>
                  <div className="mT-25">
                    <h5 className="fw-n">Mobilize how you do business.</h5>
                    <p className="c-text-gray line-height mb-0">Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-11">
                <div className="img-mockup-dashboard-pc" style={{ backgroundImage: 'url(/images/mockup-dashboard-pc.png)' }} />
              </div>
            </div>
          </ReactWOW>
          <div className="text-center mT-20">
            <a href="/" className="button_androidstore186x54">{' '}</a>
          </div>
        </div>
      </section>
      <OurProduct />
    </div>
    <DownloadFooter />
  </>
);

export default DownloadAndroidTablet;
