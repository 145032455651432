import React from 'react';

import WalletBalance from './WalletBalance';
import WalletCoins from './WalletCoins';
import Stats from './Stats';

import {
  History, ProductSold, GrossProfit,
  NewCustomerBox, RefundsProcessed,
  ReferFriends, UniqueVisitors,
  Contact, ChatBox, RecentMessages,
} from './Boxes';
import SortableArea from '../../../../common/SortableArea';
import LastActivity from './LastActivity';

const TabOverview = () => (
  <div className="bgc-grey">
    <div className="main-content">
      <div className="row">
        <SortableArea
          components={[
            WalletBalance,
            History,
            ProductSold,
            GrossProfit,
            NewCustomerBox,
            RefundsProcessed,
            WalletCoins,
            Stats,
            LastActivity,
            ReferFriends,
            UniqueVisitors,
            Contact,
            ChatBox,
            RecentMessages,
          ]}
        />
      </div>
    </div>
  </div>
);

export default TabOverview;
