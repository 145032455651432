import React from 'react';
import { Helmet } from 'react-helmet';

const ForgotPassword = () => (
  <section className="section-sign-in">
    <Helmet>
      <title>Paybit - Forgot Password</title>
    </Helmet>
    <div className="container">
      <div className="sign-up-container">
        <form action="#">
          <h5 className="c-grey fsz-def tt-u"><b className="fw-n">You cant rembember?</b></h5>
          <h1 className="mB-30">Forgot Your Password</h1>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <div className="input-has-icon">
                  <i className="icon-mail" />
                  <input type="email" className="form-control form_control" placeholder="Enter your email address" id="faq-search" />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mT-20">
            <button type="submit" className="btn btn-lg btn-around btn-primary btn-submit">Reset your password</button>
          </div>
          <div className="text-center mT-30 fsz-def c-grey">
            You don’t have account ?
            {' '}
            <a href="/sign-up-personal" className="c-black"><b> Sign Up</b></a>
          </div>
        </form>
      </div>
    </div>
  </section>
);

export default ForgotPassword;
