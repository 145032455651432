import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import { Helmet } from 'react-helmet';

import YourbalanceChart from '../YourbalanceChart';
import ChartTotalEarnings from '../ChartTotalEarnings';
import NewWalletModal from '../NewWalletModal';
import CandleStickChart from './CandleStickChart';
import TinyLineChart from '../../../common/Charts/TinyLineChart';

const WalletsAddress = ({ isOpenModal, closeModal, openModal }) => (
  <section>
    <Helmet>
      <title>Paybit - Wallets Address Detail</title>
    </Helmet>
    <div className="p-20 bgc-grey main-content-container">
      <div className="main-content">
        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className="bd bdrs-3 p-25 mB-20 bg-white">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <span className="fsz-sup c-grey"><i className="icon-exchange_money" /></span>
                  <div className="mL-20">
                    <span className="fsz-xs c-grey">Your Balance</span>
                    <div className="pos-r pR-10">
                      <b className="fsz-sup fw-n ">
                        4,687.11
                        {' '}
                        <sup className="c-gray fsz-sm pos-a r-0 t-15">$</sup>
                      </b>
                    </div>
                  </div>
                </div>
                <div>
                  <select className="bdn c-gray">
                    <option value>Week</option>
                    <option value>Month</option>
                  </select>
                </div>
              </div>
              <div className="pT-10 text-center flex-center">
                <div className="yourbalance-chart-wrapper">
                  <YourbalanceChart />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4">
            <div className="bd bdrs-3 p-25 mB-20 bg-white">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <span className="fsz-sup c-grey"><i className="icon-exchange_money" /></span>
                  <div className="mL-20">
                    <span className="fsz-xs c-grey">Total Earnings</span>
                    <div className="pos-r pR-10">
                      <b className="fsz-sup fw-n ">
10,252
                        {' '}
                        <sup className="c-gray fsz-sm pos-a r-0 t-15">$</sup>
                      </b>
                    </div>
                  </div>
                </div>
                <div>
                  <select className="bdn c-gray">
                    <option value>Week</option>
                    <option value>Month</option>
                  </select>
                </div>
              </div>
              <div className="pT-10 text-center flex-center">
                <div className="chart-total-earnings-wrapper">
                  <ChartTotalEarnings />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4">
            <div className="bd bdrs-3 p-25 mB-20 bg-white">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <span className="fsz-sup c-grey"><i className="icon-exchange_money" /></span>
                  <div className="mL-20">
                    <span className="fsz-xs c-grey">Account 1</span>
                    <div className="pos-r">
                      <b className="fsz-sup fw-n ">230,10</b>
                      {' '}
                      <span className="c-gray">Adst</span>
                      {' '}
                      <i className="c-green icon-stats" />
                    </div>
                  </div>
                </div>
                <div>
                  <select className="bdn c-gray">
                    <option value>Week</option>
                    <option value>Month</option>
                  </select>
                </div>
              </div>
              <div className="pY-20">
                <div className="pY-20 mB-25">
                  <a href="/" className="c-gray fsz-md td-u">0x08df466b5f3561b5b5b2b5b2f36f36</a>
                </div>
                <div>
                  <a href="/" className="btn btn-primary btn-lg btn-around w-100">New Transactions</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-style bd bdrs-3 bg-white mB-20">
          <div className="pX-20 pT-20">
            <div className="d-flex">
              <h5>
                <b className="fw-n ">Bitcoin Chart</b>
                {' '}
              </h5>
            </div>
          </div>
          <div className="box-body p-20">
            <div className="candle-stick-chart-wrapper">
              <CandleStickChart />
            </div>
          </div>
        </div>
        <div className="box-style bd bgc-white mB-20">
          <div className="box-header d-flex d-md-inline-block justify-content-between w-100">
            <div className="mR-10 d-flex d-md-inline-block w-100">
              <select className="form-control form_control mR-10 mB-5">
                <option value>All</option>
              </select>
              <select className="form-control form_control mR-10 mB-5">
                <option value>Coins</option>
              </select>
              <select className="form-control form_control mR-10 mB-5">
                <option value>Tokens</option>
              </select>
              <select className="form-control form_control mR-10 mB-5">
                <option value>Groups</option>
              </select>
            </div>
            <div className="d-flex d-md-inline-block w-100 justify-content-end">
              <a href="/" className="btn btn-lg btn-outline-light bd c-black mR-10 mB-5">
            Next 100
                <i className="fas fa-chevron-right mL-20" />
              </a>
              <a href="/" className="btn btn-lg btn-outline-light bd c-black mR-10 mB-5">
            View All
              </a>
              <a href="/" className="btn btn-lg btn-primary mR-10 mB-5">New Group</a>
              <a href="/" className="btn btn-lg btn-warning mB-5" data-toggle="modal" onClick={openModal}>Create New</a>
            </div>
          </div>
          <div className="box-body">
            <div className="table-responsive">
              <table className="table table-common table-hover table-crypto-market">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Funds</th>
                    <th>Wallet</th>
                    <th>Coins</th>
                    <th>Circulating Supply</th>
                    <th>Last Transaction</th>
                    <th>Change(24h)</th>
                  </tr>
                </thead>
                <tbody className="pX-20">
                  <tr>
                    <td><b className="fsz-md fw-n">1</b></td>
                    <td>
                      <span className="coin-currency">
                        <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                        <b className="fw-b fsz-md">Bitcoin</b>
                      </span>
                      <div className="mT-10">
                        <a href="/" className="text-uppercase mR-10">wallet details</a>
                        <a href="/" className="text-uppercase">Create new address</a>
                      </div>
                    </td>
                    <td>1MTSD2sdaxxz22DSDAVS123sa</td>
                    <td>3</td>
                    <td>$252</td>
                    <td>$9,4342.55</td>
                    <td>16,624,245 BTC</td>
                    <td>22.08.2018</td>
                    <td>
                      <div className="chart-line">
                        <TinyLineChart color="#FF000A" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><b className="fsz-md fw-n">2</b></td>
                    <td>
                      <span className="coin-currency">
                        <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/eth-38.png)' }} />
                        <b className="fw-b fsz-md">Ethereum</b>
                      </span>
                      <div className="mT-10">
                        <a href="/" className="text-uppercase mR-10">wallet details</a>
                        <a href="/" className="text-uppercase">Create new address</a>
                      </div>
                    </td>
                    <td>1MTSD2sdaxxz22DSDAVS123sa</td>
                    <td>3</td>
                    <td>$252</td>
                    <td>$9,4342.55</td>
                    <td>16,624,245 BTC</td>
                    <td>22.08.2018</td>
                    <td>
                      <div className="chart-line">
                        <TinyLineChart color="#00C300" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><b className="fsz-md fw-n">3</b></td>
                    <td>
                      <span className="coin-currency">
                        <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                        <b className="fw-b fsz-md">Bitcoin</b>
                      </span>
                      <div className="mT-10">
                        <a href="/" className="text-uppercase mR-10">wallet details</a>
                        <a href="/" className="text-uppercase">Create new address</a>
                      </div>
                    </td>
                    <td>1MTSD2sdaxxz22DSDAVS123sa</td>
                    <td>3</td>
                    <td>$252</td>
                    <td>$9,4342.55</td>
                    <td>16,624,245 BTC</td>
                    <td>22.08.2018</td>
                    <td>
                      <div className="chart-line">
                        <TinyLineChart color="#FF000A" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><b className="fsz-md fw-n">4</b></td>
                    <td>
                      <span className="coin-currency">
                        <span className="coin-icon coin-icon-27 mR-10" style={{ backgroundImage: 'url(/images/coins/bitcoin.png)' }} />
                        <b className="fw-b fsz-md">Bitcoin</b>
                      </span>
                      <div className="mT-10">
                        <a href="/" className="text-uppercase mR-10">wallet details</a>
                        <a href="/" className="text-uppercase">Create new address</a>
                      </div>
                    </td>
                    <td>1MTSD2sdaxxz22DSDAVS123sa</td>
                    <td>3</td>
                    <td>$252</td>
                    <td>$9,4342.55</td>
                    <td>16,624,245 BTC</td>
                    <td>22.08.2018</td>
                    <td>
                      <div className="chart-line">
                        <TinyLineChart color="#00C300" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewWalletModal show={isOpenModal} onHide={closeModal} />
  </section>
);

const container = compose(
  withState('isOpenModal', 'setIsShow', false),
  withHandlers({
    openModal: ({ setIsShow }) => () => setIsShow(true),
    closeModal: ({ setIsShow }) => () => setIsShow(false),
  }),
);


export default container(WalletsAddress);
