import { createSelector } from 'reselect';

export const coinsStateSelector = state => state.coins;

export const pricesSelector = createSelector(
  coinsStateSelector,
  ({ prices: { data = [] } }) => ({ prices: data }),
);

export const historicalsSelector = createSelector(
  coinsStateSelector,
  (state, { symbol }) => symbol,
  ({ historicals }, symbol) => ({
    historical: historicals[symbol] || {},
  }),
);
