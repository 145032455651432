import React from 'react';

import Slider from 'react-slick';


const settings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  arrows: true,
};

const IntroSlider = () => (
  <div className="col-12 col-sm-12 col-md-12 col-lg-4 bdL bdn-md">
    <div className="slider-intro-app">
      <div id="sliderIntroApp">
        <Slider {...settings} className="slider">
          <div className="slider-item">
            <p className="c-gray"><b>WHAT ARE YOU WAITING?</b></p>
            <h3 className="fsz-xl">
              <b>
                Download our app on
                <br />
                Google Play Store
              </b>
            </h3>
            <div className="button-download">
              <a href="/" className="btn-download-appstore">{}</a>
              <a href="/" className="btn-download-googleplay">{}</a>
            </div>
            <div className="/images-slider-image" style={{ backgroundImage: 'url(/images/iphone.png)' }} />
          </div>
          <div className="slider-item">
            <p className="c-gray"><b>WHAT ARE YOU WAITING?</b></p>
            <h3 className="fsz-xl">
              <b>
                Download our app on
                <br />
                App Store
              </b>
            </h3>
            <div className="button-download">
              <a href="/" className="btn-download-appstore">{}</a>
              <a href="/" className="btn-download-googleplay">{}</a>
            </div>
            <div className="/images-slider-image" style={{ backgroundImage: 'url(/images/iphone.png)' }} />
          </div>
        </Slider>
      </div>
    </div>
  </div>
);

export default IntroSlider;
