import { compose, withState, withHandlers } from 'recompose';
import upperFirst from 'lodash/upperFirst';

const stopElementEvent = handler => (e) => {
  if (e && e.preventdefault) {
    e.preventdefault();
  }
  if (e && e.stoppropagation) {
    e.stoppropagation();
  }
  handler(e);
};

const withModal = (modalName = '', defaultValue = false) => {
  const name = upperFirst(modalName);
  const setterName = `setIsOpenModal${name}`;
  return compose(
    withState(`isOpenModal${name}`, `setIsOpenModal${name}`, defaultValue),
    withHandlers({
      [`openModal${name}`]: ({ [setterName]: setIsOpenModal }) => stopElementEvent(() => setIsOpenModal(true)),
      [`closeModal${name}`]: ({ [setterName]: setIsOpenModal }) => stopElementEvent(() => setIsOpenModal(false)),
      [`toggleModal${name}`]: ({
        [setterName]: setIsOpenModal,
        [`isOpenModal${name}`]: isOpenModal,
      }) => stopElementEvent(() => setIsOpenModal(!isOpenModal)),
    }),
  );
};

export default withModal;
