import React from 'react';
import { compose } from 'redux';
import { Modal } from 'react-bootstrap';
import LogInTabs from './LogInTabs';

export const LogInModal = ({
  isOpenModalSignIn, closeModalSignIn,
  toggleModalSignIn, openModalSignIn, openModalSignUp,
}) => (
  <Modal id="modalLogin" tabIndex={-1} role="dialog" aria-hidden="true" show={isOpenModalSignIn} onShow={openModalSignIn} onHide={closeModalSignIn} toggle={toggleModalSignIn}>
    <Modal.Header closeButton className="react-custom-modal-header" />
    <div className="modal-signin">
      <Modal.Body className="sign-up-container">
        <LogInTabs toggle={(event) => {
          event.persist();
          event.nativeEvent.stopImmediatePropagation();
          event.stopPropagation();
          event.preventDefault();
          closeModalSignIn();
          openModalSignUp();
        }}
        />
      </Modal.Body>
    </div>
  </Modal>
);
export default compose(
)(LogInModal);
