import React from 'react';
import Countup from 'react-countup';
import { Helmet } from 'react-helmet';

const EmailInvoice = () => (
  <div className="email-invoice">
    <Helmet>
      <title>Paybit - Email Invoice</title>
    </Helmet>
    <div className="p-40">
      <div className="p-30">
        <p className="c-gray">Payment Receipt</p>
        <div className="d-flex justify-content-between">
          <div>
            <h3 className="mB-40"><b className="fw-n">Paybit user Membership</b></h3>
            <p className="c-gray">
              Hello Ennio, you sent a payment of
              {' '}
              <b className="c-black">
$2
                <Countup end={0.00} prefix="$" suffix=" BTC" separator="," decimal="." duration={3} decimals={2} delay={1} />
                {' '}
to Paybit
              </b>
              {' '}
              (payments@paybit.com)
            </p>
            <p className="c-gray">
              <i className="c-blue icon-time mR-10" />
              Oct 10, 2018 - 08:30:57 PDT
            </p>
          </div>
          <div className="mL-15">
            <div className="logo-paybit" style={{ backgroundImage: 'url(/images/icon-paybit-big.png)' }} />
          </div>
        </div>
      </div>
      <div className="email-invoice-box mB-20">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 mY-5">
            <div className="d-flex align-items-center">
              <i className="icon-wallet c-grey fsz-lg" />
              <div className="mL-25">
                <p className="fsz-sm c-grey mB-5">Amount</p>
                <h3>
                  <b className="fw-n">
$2
                    <Countup end={0.00} prefix="$" suffix=" BTC" separator="," decimal="." duration={3} decimals={2} delay={1} />
                  </b>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 mY-5">
            <div className="d-flex align-items-center">
              <i className="icon-time c-grey fsz-lg" />
              <div className="mL-25">
                <p className="fsz-sm c-grey mB-5">Date</p>
                <h3><b className="fw-n">Oct 10</b></h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 mY-5">
            <div className="d-flex align-items-center">
              <i className="icon-paybit c-grey fsz-lg" />
              <div className="mL-25">
                <p className="fsz-sm c-grey mB-5">Issuer</p>
                <h3><b className="fw-n">Paybit</b></h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 mY-5">
            <div className="d-flex align-items-center">
              <i className="icon-confirmation_number c-grey fsz-lg" />
              <div className="mL-25">
                <p className="fsz-sm c-grey mB-5">Confirmation Number</p>
                <h3><b className="fw-n">9YSCB24151XX</b></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pX-15 fsz-def">
        <div className="row">
          <div className="col-12 col-sm-4 mB-10"><a href="/help-center" className="c-gray">www.paybit.com/help</a></div>
          <div className="col-12 col-sm-4 mB-10"><span className="c-gray">Thank You for using PayBit</span></div>
          <div className="col-12 col-sm-4 mB-10"><span className="c-gray">Invoice ID : 241431233</span></div>
        </div>
      </div>
    </div>
  </div>
);

export default EmailInvoice;
