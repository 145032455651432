import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';

import VerificationsForm from './VerificationsForm';
import withModal from '../../../hocs/withModal';


const MainSettings = ({ isOpenModal, closeModal, openModal }) => (
  <section>
    <Helmet>
      <title>Paybit - Main Settings</title>
    </Helmet>
    <Tab.Container defaultActiveKey="tab_setting">
      <div className="tab-common pX-25 bgc-white bdB">
        <Nav className="nav nav-tabs tab-lg" id="mainsettingTab" role="tablist">
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="tab_setting">Settings</Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="tab_business">Business</Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="tab_security">Security</Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="tab_devices">Devices</Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link
              className="nav-link"
              eventKey="tab_verification"
              onClick={openModal}
            >
              Verification
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="tab_notifications">Notifications</Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="tab_api">API</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Tab.Content className="tab-content" id="mainsettingTabContent">
        <Tab.Pane className="tab-pane" eventKey="tab_setting">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="box-style bd bgc-white mB-5">
                <div className="pX-30 pY-20">
                  <form action="#">
                    <div className="mB-20">
                      <h4><b className="fw-n">Profile Picture</b></h4>
                      <p className="c-gray fsz-def">This is the avatar which you can change</p>
                    </div>
                    <div className="d-flex align-items-center mB-30 d-sm-inline-block">
                      <div className="size-104 img bdrs-50p d-block mR-25" style={{ backgroundImage: 'url(/images/user.jpg)' }} />
                      <div>
                        <button className="btn-transparent c-blue p-0">Upload a new image</button>
                        <p className="mt-10 mb-0 c-gray">Maximum size allowed is 500kb of PNG,JPEG,JPG</p>
                      </div>
                    </div>
                    <div className="mB-20">
                      <h4><b className="fw-n">Personal Info</b></h4>
                      <p className="c-gray fsz-def">By leeting us know your name , we can make our suppport experince much better</p>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">First Name</label>
                          <input type="text" className="form-control form_control" defaultValue="Jason Straigtnam" />
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Last Name</label>
                          <input type="text" className="form-control form_control" defaultValue="Straigtnam" />
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Email Address</label>
                          <input type="email" className="form-control form_control" defaultValue="jasonstraigtnam@gmail.com" />
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Phone Number</label>
                          <div className="phone-country">
                            <div className="dropdown select-flag">
                              <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true">
                                <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                              </button>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                                <ul className="lis-n p-0 m-0">
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <input type="text" className="form-control form_control" defaultValue="98 93 794 95 06" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                        <div className="form-group">
                          <label className="c-grey">Living Address</label>
                          <input type="text" className="form-control form_control" defaultValue="Khamza dist., Dorian Grey st., 42/7/3" />
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                        <div className="form-group">
                          <label className="c-grey">Town</label>
                          <select className="form-control form_control">
                            <option>Select Town</option>
                            <option>Newyork</option>
                            <option>Califonia</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                        <div className="form-group">
                          <label className="c-grey">Country</label>
                          <select className="form-control form_control">
                            <option>Select your Country</option>
                            <option>America</option>
                            <option>Argentina</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mT-20 mB-20">
                      <h4><b className="fw-n">Account Settings</b></h4>
                      <p className="c-gray fsz-def">Select your language</p>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Username</label>
                          <input type="text" className="form-control form_control" defaultValue="jasonstragtnam" />
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Language</label>
                          <div className="phone-country-dropdown">
                            <div className="dropdown select-flag">
                              <button className="btn-transparent dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true">
                                <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                              </button>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                                <ul className="lis-n p-0 m-0">
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Account Status</label>
                          <div>
                            <div className="account-status mR-15">
                              <i className="fas fa-circle c-green" />
                              {' '}
                              Active
                            </div>
                            <a href="/" className="c-red td-u">Delete Account</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="box-style bd bgc-white mB-5">
                <div className="pX-30 pY-20">
                  <form action="#">
                    <div className="mB-30">
                      <h4><b className="fw-n">Privacy Settings</b></h4>
                      <p className="c-gray fsz-def">This includes Messages, Offers , etc</p>
                    </div>
                    <div className="list-setting">
                      <div className="setting-item bdB">
                        <div>Show my email address on my profile</div>
                        <div className="switch__container">
                          <input id="switch-setting-1" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="switch-setting-1" />
                        </div>
                      </div>
                      <div className="setting-item bdB">
                        <div>Show my age + birthday on my profile</div>
                        <div className="switch__container">
                          <input id="switch-setting-2" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="switch-setting-2" />
                        </div>
                      </div>
                      <div className="setting-item bdB">
                        <div>Allow users to message your directly</div>
                        <div className="switch__container">
                          <input id="switch-setting-3" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="switch-setting-3" />
                        </div>
                      </div>
                      <div className="setting-item bdB">
                        <div>Show the world my active status</div>
                        <div className="switch__container">
                          <input id="switch-setting-4" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="switch-setting-4" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="box-style bd bgc-white mB-5">
                <div className="pX-30 pY-20">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4><b className="fw-n">Auto Logout</b></h4>
                      <p className="c-gray fsz-def">After a certain period of inactivity, you will be automatically logged out of your wallet.</p>
                    </div>
                    <div className="text-right">
                      <span className="c-grey mR-15">10 Minutes</span>
                      <button type="button" className="btn btn-lg btn-primary">Change</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-style bd bgc-white mB-5">
                <div className="pX-30 pY-20">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4><b className="fw-n">Bitcoin Link Handling</b></h4>
                      <p className="c-gray fsz-def">Enable this to allow your Wallet to handle bitcoin payment links in the web browser.This will make your experience more convenient when transacting online.</p>
                    </div>
                    <div>
                      <button type="button" className="btn btn-lg btn-primary">Enable</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-style bd bgc-white mB-5">
                <div className="pX-30 pY-20">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4><b className="fw-n">Local Currency</b></h4>
                      <p className="c-gray fsz-def">Select your local currency.</p>
                    </div>
                    <div>
                      <input type="text" className="form-control form_control" defaultValue="U.S. Dollar" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane" eventKey="tab_business" role="tabpanel">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="box-style bd bgc-white mB-5">
                <div className="pX-30 pY-20">
                  <form action="#">
                    <div className="mB-20">
                      <h4><b className="fw-n">Business Information</b></h4>
                      <p className="c-gray fsz-def">By leeting us know your name , we can make our suppport experince much better</p>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Business Name</label>
                          <input type="text" className="form-control form_control" placeholder="Enter your business name" defaultValue="SparksFly" />
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Business Type</label>
                          <select className="form-control form_control">
                            <option value>Food and Beverage</option>
                            <option value>Food and Beverage</option>
                            <option value>Food and Beverage</option>
                            <option value>Food and Beverage</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Phone Number</label>
                          <div className="phone-country">
                            <div className="dropdown select-flag">
                              <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true">
                                <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                +1
                              </button>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                                <ul className="lis-n p-0 m-0">
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                    +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                    +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                    +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                    +1
                                  </li>
                                  <li>
                                    <span className="size-20 bdrs-50p flag" style={{ backgroundImage: 'url(/images/united-states-of-america.png)' }} />
                                    +1
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <input type="text" className="form-control form_control" defaultValue="98 93 794 95 06" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Business Address</label>
                          <input type="text" className="form-control form_control" placeholder="Enter your business address" />
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Website Business Information</label>
                          <input type="text" className="form-control form_control" placeholder="Enter Information" />
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Support Number</label>
                          <input type="text" className="form-control form_control" placeholder="Phone Number" />
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3">
                        <div className="form-group">
                          <label className="c-grey">Business Display Email</label>
                          <input type="email" className="form-control form_control" placeholder="Enter email address" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label className="c-grey">Business Description</label>
                          <textarea className="form-control form_control" rows={5} placeholder="Enter Description" defaultValue="" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane" eventKey="tab_security" role="tabpanel">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="box-style bd bgc-white mB-25">
                <div className="p-30">
                  <div className="d-flex align-items-center justify-content-between mB-20">
                    <div className="d-flex align-items-center">
                      <span className="c-grey fsz-lg mR-20"><i className="icon-lock" /></span>
                      <div>
                        <h4><b className="fw-n">Change Password</b></h4>
                        <p className="c-gray fsz-def">By leeting us know your name , we can make our suppport experince much better</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <i className="icon-time c-grey fsz-md mR-10" />
                      <span>Last Changed 2 years ago</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                      <div className="form-group">
                        <label className="c-grey">Enter Old Password</label>
                        <div className="input-password">
                          <input type="password" className="form-control form_control" placeholder="Enter Password" />
                          <span className="show-password"><i className="fa fa-eye" /></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                      <div className="form-group">
                        <label className="c-grey">Enter new Password</label>
                        <div className="input-password">
                          <input type="password" className="form-control form_control" placeholder="New Password" />
                          <span className="show-password"><i className="fa fa-eye" /></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                      <div className="form-group">
                        <label className="c-grey">Confirm new Password</label>
                        <div className="input-password">
                          <input type="password" className="form-control form_control" placeholder="Confirm Password" />
                          <span className="show-password"><i className="fa fa-eye" /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-style bd bgc-white mB-25">
                <div className="p-30">
                  <div className="d-flex align-items-center mB-20">
                    <span className="c-grey fsz-lg mR-20"><i className="icon-refresh" /></span>
                    <div>
                      <h4><b className="fw-n">Account Recovery Options</b></h4>
                      <p className="c-gray fsz-def">If you forget you password or cannot acces your account, we willl use this information to help you get back in.</p>
                    </div>
                  </div>
                  <div className="bd">
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/recovery-by-phone.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Recovery by Phone</b></h4>
                          <p className="c-gray fsz-def">Add a recovery phone to help secure your account ,You can enable 2FA for your  account on single settings page, so your new </p>
                          <a href="/" className="c-blue tt-u">132***** - Change?</a>
                        </div>
                      </div>
                      <div>
                        <a href="/" className="btn-transparent c-4d"><i className="fas fa-chevron-right" /></a>
                      </div>
                    </div>
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/security-question.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Security Question</b></h4>
                          <p className="c-gray fsz-def">What is the name of your best friend from childhood?</p>
                          <a href="/" className="c-blue tt-u">Hidden - Change?</a>
                        </div>
                      </div>
                      <div />
                    </div>
                    <div className="p-30 d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/secutity-email.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Security Email Verifications</b></h4>
                          <p className="c-gray fsz-def">ledolomaccs@gmail.com</p>
                          <a href="/" className="c-blue tt-u">Verified - Remove?</a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fsz-lg fas fa-check-circle c-green mR-10" />
                        <b className="fw-n fsz-def">Verified</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-style bd bgc-white mB-25">
                <div className="box-header box-last-activity-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center box-last-activity-header-left">
                      <h5><b className="fw-n mR-10">Devices</b></h5>
                      <div className="mL-30">
                        <a className="c-gray" href="/">(Show All)</a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="input_group">
                          <i className="mL-10 far fa-calendar-check" />
                          <input type="date" placeholder="Select Date" className="form-control bdn" />
                          <button className="btn btn-primary">Search</button>
                        </div>
                      </div>
                      <div className="pL-20 pT-5 float-left">
                        <button className="btn-transparent">
                          <i className="c-gray icon-drag" />
                        </button>
                      </div>
                      <div className="pL-20 pT-5 float-left">
                        <button className="btn-transparent">
                          <i className="c-gray fas fa-times" />
                        </button>
                      </div>
                      <div className="pL-20 pT-5 float-left">
                        <button className="btn-transparent">
                          <i className="c-gray fas fa-ellipsis-h" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-body pY-20">
                  <div className="table-responsive">
                    <table className="table table-common table-hover">
                      <thead>
                        <tr>
                          <th>Devices</th>
                          <th>SSID</th>
                          <th>IP</th>
                          <th>Protocol</th>
                          <th>Browser</th>
                          <th>User Agent</th>
                          <th>Location</th>
                          <th>Last Seen</th>
                        </tr>
                      </thead>
                      <tbody className="pX-20">
                        <tr>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-iphone-device" />
                              +352399428443i
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">123.14.14.22</td>
                          <td className="c-gray">Wifi</td>
                          <td className="c-gray">Chrome</td>
                          <td className="c-gray">HTTP</td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">12:50</td>
                        </tr>
                        <tr>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-pc-computer-device" />
                              PC Computer
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">123.14.14.22</td>
                          <td className="c-gray">Wifi</td>
                          <td className="c-gray">Chrome</td>
                          <td className="c-gray">HTTP</td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">12:50</td>
                        </tr>
                        <tr>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md far fa-question-circle" />
                              +214949843834924
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">123.14.14.22</td>
                          <td className="c-gray">Wifi</td>
                          <td className="c-gray">Chrome</td>
                          <td className="c-gray">HTTP</td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">12:50</td>
                        </tr>
                        <tr>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-samsung-device" />
                              +3342249428484
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">123.14.14.22</td>
                          <td className="c-gray">Wifi</td>
                          <td className="c-gray">Chrome</td>
                          <td className="c-gray">HTTP</td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">12:50</td>
                        </tr>
                        <tr>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-iphone-device" />
                              IphoneXS
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">123.14.14.22</td>
                          <td className="c-gray">Wifi</td>
                          <td className="c-gray">Chrome</td>
                          <td className="c-gray">HTTP</td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">12:50</td>
                        </tr>
                        <tr>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-pc-computer-device" />
                              PC Computer
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">123.14.14.22</td>
                          <td className="c-gray">Wifi</td>
                          <td className="c-gray">Chrome</td>
                          <td className="c-gray">HTTP</td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">12:50</td>
                        </tr>
                        <tr>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md far fa-question-circle" />
                              Unassigned
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">123.14.14.22</td>
                          <td className="c-gray">Wifi</td>
                          <td className="c-gray">Chrome</td>
                          <td className="c-gray">HTTP</td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">12:50</td>
                        </tr>
                        <tr>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-iphone-device" />
                              IphoneXS
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">123.14.14.22</td>
                          <td className="c-gray">Wifi</td>
                          <td className="c-gray">Chrome</td>
                          <td className="c-gray">HTTP</td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">12:50</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane" eventKey="tab_devices" role="tabpanel">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="box-style bd bgc-white mB-5">
                <div className="box-header box-last-activity-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center box-last-activity-header-left">
                      <h5><b className="fw-n mR-10">Devices</b></h5>
                      <div className="mL-30">
                        <a className="c-gray" href="/">(Show All)</a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="input_group">
                          <i className="mL-10 far fa-calendar-check" />
                          <input type="date" placeholder="Select Date" className="form-control bdn" />
                          <button className="btn btn-primary">Search</button>
                        </div>
                      </div>
                      <div className="pL-20 pT-5 float-left">
                        <button className="btn-transparent">
                          <i className="c-gray icon-drag" />
                        </button>
                      </div>
                      <div className="pL-20 pT-5 float-left">
                        <button className="btn-transparent">
                          <i className="c-gray fas fa-times" />
                        </button>
                      </div>
                      <div className="pL-20 pT-5 float-left">
                        <button className="btn-transparent">
                          <i className="c-gray fas fa-ellipsis-h" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-body pY-20">
                  <div className="table-responsive">
                    <table className="table table-common table-hover">
                      <thead>
                        <tr>
                          <th />
                          <th>Devices</th>
                          <th>SSID</th>
                          <th>Protocol</th>
                          <th>User Agent</th>
                          <th>Location</th>
                          <th>Manufacturer</th>
                          <th>Last Seen</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody className="pX-20">
                        <tr>
                          <td>
                            <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                            <label htmlFor="checkbox-1" />
                          </td>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-iphone-device" />
                              IphoneXS
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">Wifi</td>
                          <td>
                            <span className="browser-icon">
                              <span className="img size-20" style={{ backgroundImage: 'url(/images/firefox.png)' }} />
                              <span className="mL-10 c-gray">Firefox</span>
                            </span>
                          </td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">Apple, Inc</td>
                          <td className="c-gray"> Octobar 1 , 2018</td>
                          <td className="c-gray">
                            <button className="btn-transparent">
                              <i className="c-gray fas fa-ellipsis-h" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                            <label htmlFor="checkbox-1" />
                          </td>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-pc-computer-device" />
                              PC Computer
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">Wifi</td>
                          <td>
                            <span className="browser-icon">
                              <span className="img size-20" style={{ backgroundImage: 'url(/images/chrome.png)' }} />
                              <span className="mL-10 c-gray">Chrome</span>
                            </span>
                          </td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">Apple, Inc</td>
                          <td className="c-gray"> Octobar 1 , 2018</td>
                          <td className="c-gray">
                            <button className="btn-transparent">
                              <i className="c-gray fas fa-ellipsis-h" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                            <label htmlFor="checkbox-1" />
                          </td>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md far fa-question-circle" />
                              Unassigned
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">Wifi</td>
                          <td>
                            <span className="browser-icon">
                              <span className="img size-20" style={{ backgroundImage: 'url(/images/chrome.png)' }} />
                              <span className="mL-10 c-gray">Chrome</span>
                            </span>
                          </td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">Apple, Inc</td>
                          <td className="c-gray"> Octobar 1 , 2018</td>
                          <td className="c-gray">
                            <button className="btn-transparent">
                              <i className="c-gray fas fa-ellipsis-h" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                            <label htmlFor="checkbox-1" />
                          </td>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-samsung-device" />
                              Samsung Galaxy
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">Wifi</td>
                          <td>
                            <span className="browser-icon">
                              <span className="img size-20" style={{ backgroundImage: 'url(/images/firefox.png)' }} />
                              <span className="mL-10 c-gray">Firefox</span>
                            </span>
                          </td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">Apple, Inc</td>
                          <td className="c-gray"> Octobar 1 , 2018</td>
                          <td className="c-gray">
                            <button className="btn-transparent">
                              <i className="c-gray fas fa-ellipsis-h" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                            <label htmlFor="checkbox-1" />
                          </td>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-iphone-device" />
                              IphoneXS
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">Wifi</td>
                          <td>
                            <span className="browser-icon">
                              <span className="img size-20" style={{ backgroundImage: 'url(/images/firefox.png)' }} />
                              <span className="mL-10 c-gray">Firefox</span>
                            </span>
                          </td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">Apple, Inc</td>
                          <td className="c-gray"> Octobar 1 , 2018</td>
                          <td className="c-gray">
                            <button className="btn-transparent">
                              <i className="c-gray fas fa-ellipsis-h" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                            <label htmlFor="checkbox-1" />
                          </td>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-pc-computer-device" />
                              PC Computer
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">Wifi</td>
                          <td>
                            <span className="browser-icon">
                              <span className="img size-20" style={{ backgroundImage: 'url(/images/chrome.png)' }} />
                              <span className="mL-10 c-gray">Chrome</span>
                            </span>
                          </td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">Apple, Inc</td>
                          <td className="c-gray"> Octobar 1 , 2018</td>
                          <td className="c-gray">
                            <button className="btn-transparent">
                              <i className="c-gray fas fa-ellipsis-h" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                            <label htmlFor="checkbox-1" />
                          </td>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md far fa-question-circle" />
                              Unassigned
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">Wifi</td>
                          <td>
                            <span className="browser-icon">
                              <span className="img size-20" style={{ backgroundImage: 'url(/images/chrome.png)' }} />
                              <span className="mL-10 c-gray">Chrome</span>
                            </span>
                          </td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">Apple, Inc</td>
                          <td className="c-gray"> Octobar 1 , 2018</td>
                          <td className="c-gray">
                            <button className="btn-transparent">
                              <i className="c-gray fas fa-ellipsis-h" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input className="checkbox-circle" id="checkbox-1" name="checkbox" type="checkbox" />
                            <label htmlFor="checkbox-1" />
                          </td>
                          <td>
                            <b className="fw-n d-flex align-items-center devices-icon">
                              <i className="c-blue mR-10 fsz-md icon-iphone-device" />
                              IphoneXS
                            </b>
                          </td>
                          <td className="c-gray">f8:g2:d2:d6:55</td>
                          <td className="c-gray">Wifi</td>
                          <td>
                            <span className="browser-icon">
                              <span className="img size-20" style={{ backgroundImage: 'url(/images/firefox.png)' }} />
                              <span className="mL-10 c-gray">Firefox</span>
                            </span>
                          </td>
                          <td className="c-gray">Novi Sad,Serbia</td>
                          <td className="c-gray">Apple, Inc</td>
                          <td className="c-gray"> Octobar 1 , 2018</td>
                          <td className="c-gray">
                            <button className="btn-transparent">
                              <i className="c-gray fas fa-ellipsis-h" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane" eventKey="tab_verification" role="tabpanel">
          <VerificationsForm show={isOpenModal} onHide={closeModal} />
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="box-style bd bgc-white mB-25">
                <div className="p-30">
                  <div className="d-flex align-items-center mB-20">
                    <span className="c-grey fsz-lg mR-20"><i className="icon-refresh" /></span>
                    <div>
                      <h4><b className="fw-n">Account Verification</b></h4>
                      <p className="c-gray fsz-def">If you forget you password or cannot acces your account, we willl use this information to help you get back in.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&lsquo;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                    </div>
                  </div>
                  <div className="bd">
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/Verify-your-Tax-Documents.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Verify your Tax Documents</b></h4>
                          <p className="c-gray fsz-def">Add a recovery phone to help secure your account ,You can enable 2FA for your  account on single settings page, so your new </p>
                          <a href="/" className="c-blue tt-u">GET STARTED</a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fsz-lg c-yellow fas fa-exclamation-circle mR-10" />
                        <b className="fw-n fsz-def">Pending</b>
                      </div>
                    </div>
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/Verify-your-Tax-Return-Report.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Verify your Tax Return Report</b></h4>
                          <p className="c-gray fsz-def">Add a recovery phone to help secure your account ,You can enable 2FA for your  account on single settings page, so your new </p>
                          <a href="/" className="c-blue tt-u">GET STARTED</a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fsz-lg c-yellow fas fa-exclamation-circle mR-10" />
                        <b className="fw-n fsz-def">Pending</b>
                      </div>
                    </div>
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/Other-Financial.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Other Financial Document</b></h4>
                          <p className="c-gray fsz-def">Add a recovery phone to help secure your account ,You can enable 2FA for your  account on single settings page, so your new </p>
                          <a href="/" className="c-blue tt-u">GET STARTED</a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fsz-lg c-yellow fas fa-exclamation-circle mR-10" />
                        <b className="fw-n fsz-def">Pending</b>
                      </div>
                    </div>
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/Verify-your-Tax-Return-Report.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Verify your Personal Documents</b></h4>
                          <p className="c-gray fsz-def">What is the name of your best friend from childhood?</p>
                          <a href="/" className="c-blue tt-u">UPLOAD YOUR DOCUMENT</a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fsz-lg c-green fas fa-check-circle mR-10" />
                        <b className="fw-n fsz-def">Vertifed</b>
                      </div>
                    </div>
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/Verify-your-Address.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Verify your Address</b></h4>
                          <p className="c-gray fsz-def">What is the name of your best friend from childhood?</p>
                          <a href="/" className="c-blue tt-u">UPLOAD YOUR DOCUMENT</a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fsz-lg c-green fas fa-check-circle mR-10" />
                        <b className="fw-n fsz-def">Vertifed</b>
                      </div>
                    </div>
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/Verify-your-ID-Drivers-License.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Verify your ID/Drivers License</b></h4>
                          <p className="c-gray fsz-def">What is the name of your best friend from childhood?</p>
                          <a href="/" className="c-blue tt-u">UPLOAD YOUR DOCUMENT</a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fsz-lg c-green fas fa-check-circle mR-10" />
                        <b className="fw-n fsz-def">Vertifed</b>
                      </div>
                    </div>
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/business.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Verify your Business Documents</b></h4>
                          <p className="c-gray fsz-def">What is the name of your best friend from childhood?</p>
                          <a href="/" className="c-blue tt-u">GET STARTED</a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fsz-lg c-yellow fas fa-exclamation-circle mR-10" />
                        <b className="fw-n fsz-def">Pending</b>
                      </div>
                    </div>
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/Business-Certification.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Business Certification</b></h4>
                          <p className="c-gray fsz-def">What is the name of your best friend from childhood?</p>
                          <a href="/" className="c-blue tt-u">GET STARTED</a>
                        </div>
                      </div>
                      <div>
                        <i className="fsz-lg c-yellow fas fa-exclamation-circle mR-10" />
                        <b className="fw-n fsz-def">Pending</b>
                      </div>
                    </div>
                    <div className="p-30 bdB d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/Bank-Statement.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Bank Statement</b></h4>
                          <p className="c-gray fsz-def">What is the name of your best friend from childhood?</p>
                          <a href="/" className="c-blue tt-u">GET STARTED</a>
                        </div>
                      </div>
                      <div>
                        <i className="fsz-lg c-yellow fas fa-exclamation-circle mR-10" />
                        <b className="fw-n fsz-def">Pending</b>
                      </div>
                    </div>
                    <div className="p-30 d-flex justify-content-between align-items-center verify-box">
                      <div className="d-flex align-items-center">
                        <div className="img-50 mR-40" style={{ backgroundImage: 'url(/images/recovery-by-phone.png)' }} />
                        <div className>
                          <h4><b className="fw-n">Verify your Phone</b></h4>
                          <p className="c-gray fsz-def">What is the name of your best friend from childhood?</p>
                          <a href="/" className="c-blue tt-u">GET STARTED</a>
                        </div>
                      </div>
                      <div>
                        <i className="fsz-lg c-yellow fas fa-exclamation-circle mR-10" />
                        <b className="fw-n fsz-def">Pending</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-style bd bgc-white mB-25">
                <div className="box-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center box-last-activity-header-left">
                      <h5><b className="fw-n">List</b></h5>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table table-common table-hover">
                      <thead>
                        <tr>
                          <th>Document Name </th>
                          <th>Upload Date</th>
                          <th>Status</th>
                          <th>Answer</th>
                        </tr>
                      </thead>
                      <tbody className="pX-20">
                        <tr>
                          <td className="c-gray">Businesss Cerfication</td>
                          <td className="c-gray">12.08.2018</td>
                          <td>
                            <span className="d-flex align-items-center">
                              <i className="fsz-lg c-yellow fas fa-exclamation-circle mR-10 mR-10" />
                              <b className="fw-n fsz-def">Pending</b>
                            </span>
                          </td>
                          <td className="c-gray">3 Days left</td>
                        </tr>
                        <tr>
                          <td className="c-gray">Verify your Business Documents</td>
                          <td className="c-gray">12.08.2018</td>
                          <td>
                            <span className="d-flex align-items-center">
                              <i className="fsz-lg c-green fas fa-check-circle mR-10" />
                              <b className="fw-n fsz-def">Vertified</b>
                            </span>
                          </td>
                          <td className="c-gray">3 Days left</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane" eventKey="tab_notifications" role="tabpanel">
          <div className="p-20 bgc-grey main-content-container">
            <div className="main-content">
              <div className="box-style bd bgc-white mB-10">
                <div className="pX-30 pY-20">
                  <form action="#">
                    <div className="mB-30">
                      <h4><b className="fw-n">Swap Activity</b></h4>
                      <p className="c-gray fsz-def">This includes Messages, Offers , etc</p>
                    </div>
                    <div className="list-setting">
                      <div className="setting-item bdB">
                        <div>SMS Text Notifications</div>
                        <div className="switch__container">
                          <input id="swap-activity-1" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="swap-activity-1" />
                        </div>
                      </div>
                      <div className="setting-item bdB">
                        <div>Mobile Push Notifications</div>
                        <div className="switch__container">
                          <input id="swap-activity-2" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="swap-activity-2" />
                        </div>
                      </div>
                      <div className="setting-item bdB">
                        <div>Email Notifications</div>
                        <div className="switch__container">
                          <input id="swap-activity-3" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="swap-activity-3" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="box-style bd bgc-white mB-10">
                <div className="pX-30 pY-20">
                  <form action="#">
                    <div className="mB-30">
                      <h4><b className="fw-n">Email</b></h4>
                      <p className="c-gray fsz-def">This includes Messages, Offers , etc</p>
                    </div>
                    <div className="list-setting">
                      <div className="setting-item bdB">
                        <div>SMS Text Notifications</div>
                        <div className="switch__container">
                          <input id="notification-email-1" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="notification-email-1" />
                        </div>
                      </div>
                      <div className="setting-item bdB">
                        <div>Mobile Push Notifications</div>
                        <div className="switch__container">
                          <input id="notification-email-2" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="notification-email-2" />
                        </div>
                      </div>
                      <div className="setting-item bdB">
                        <div>Email Notifications</div>
                        <div className="switch__container">
                          <input id="notification-email-3" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="notification-email-3" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="box-style bd bgc-white mB-10">
                <div className="pX-30 pY-20">
                  <form action="#">
                    <div className="mB-30">
                      <h4><b className="fw-n">Transactions Activity</b></h4>
                      <p className="c-gray fsz-def">This includes Messages, Offers , etc</p>
                    </div>
                    <div className="list-setting">
                      <div className="setting-item bdB">
                        <div>SMS Text Notifications</div>
                        <div className="switch__container">
                          <input id="transactions-activity-1" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="transactions-activity-1" />
                        </div>
                      </div>
                      <div className="setting-item bdB">
                        <div>Mobile Push Notifications</div>
                        <div className="switch__container">
                          <input id="transactions-activity-2" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="transactions-activity-2" />
                        </div>
                      </div>
                      <div className="setting-item bdB">
                        <div>Email Notifications</div>
                        <div className="switch__container">
                          <input id="transactions-activitysetting-3" className="switch switch--flat" type="checkbox" />
                          <label htmlFor="transactions-activity-3" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane className="tab-pane" eventKey="tab_api" role="tabpanel">.5..</Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  </section>
);

const container = compose(
  withModal(),
);

export default container(MainSettings);
