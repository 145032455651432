export default [
  {
    date: new Date('2010-02-03T00:00:00.000Z'), open: 1925.436282332605284, high: 1925.835021381744056, low: 1925.411360259406774, close: 1925.710416, volume: 38409100, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T01:00:00.000Z'), open: 1925.627344939513726, high: 1925.83502196495549, low: 1925.452895407434543, close: 1925.718722, volume: 49749600, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T02:00:00.000Z'), open: 1925.65226505944465, high: 1925.81840750861228, low: 1925.353210976925574, close: 1925.560888, volume: 58182400, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T03:00:00.000Z'), open: 1925.444587793771767, high: 1925.502739021094353, low: 1925.079077898061875, close: 1925.295062, volume: 50559700, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T04:00:00.000Z'), open: 1925.153841756996414, high: 1925.6522649488092, low: 1925.120612602739726, close: 1925.46951, volume: 51197400, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T05:00:00.000Z'), open: 1925.511044730573705, high: 1925.55258096597291, low: 1925.02092861663475, close: 1925.145534, volume: 68754700, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T06:00:00.000Z'), open: 1925.045848646491518, high: 1925.253525666777517, low: 1924.84647870701696, close: 1924.979392, volume: 65912100, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T07:00:00.000Z'), open: 1925.13722727051071, high: 1925.353211377924218, low: 1924.929550244151567, close: 1925.211991, volume: 51863500, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T08:00:00.000Z'), open: 1925.178761733851413, high: 1925.83502196495549, low: 1925.137227159471163, close: 1925.718722, volume: 63228100, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T09:00:00.000Z'), open: 1925.818406945612217, high: 1925.95132023748152, low: 1925.51104412745638, close: 1925.635652, volume: 79913200, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T10:00:00.000Z'), open: 1925.544274163987136, high: 1925.95132113440514, low: 1925.486124596784563, close: 1925.835022, volume: 46575700, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T11:00:00.000Z'), open: 1925.59411494568944, high: 1925.702108656795026, low: 1925.17876090842236, close: 1925.41136, volume: 54849500, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T12:00:00.000Z'), open: 1925.427975689088637, high: 1925.51935191837554, low: 1924.92124291902699, close: 1924.92955, volume: 73086700, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T13:00:00.000Z'), open: 1924.921242227943445, high: 1925.087384673504477, low: 1923.9576208617963, close: 1924.057305, volume: 102004600, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T14:00:00.000Z'), open: 1924.289904353342425, high: 1924.63880174829468, low: 1924.17360522169168, close: 1924.356361, volume: 63373000, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T15:00:00.000Z'), open: 1924.256677400199628, high: 1924.796636835593223, low: 1924.165298678305085, close: 1924.505889, volume: 66639900, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T16:00:00.000Z'), open: 1924.381282411526794, high: 1924.771715213346813, low: 1924.107148742163798, close: 1924.647109, volume: 63949500, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T17:00:00.000Z'), open: 1924.788329503429356, high: 1924.813251576935805, low: 1923.999155984106725, close: 1924.223448, volume: 117513700, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T18:00:00.000Z'), open: 1924.838171916252662, high: 1924.854786078069555, low: 1922.977385792760824, close: 1923.409354, volume: 193888500, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T19:00:00.000Z'), open: 1923.583802007377084, high: 1923.658566566701865, low: 1923.193370033086943, close: 1923.600417, volume: 85931100, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T20:00:00.000Z'), open: 1923.567188934614894, high: 1923.675180153730853, low: 1923.376124415817756, close: 1923.641951, volume: 54413700, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T21:00:00.000Z'), open: 1923.47581083464236, high: 1923.916086957012187, low: 1923.359512531703963, close: 1923.783172, volume: 61397900, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T22:00:00.000Z'), open: 1923.575494533516057, high: 1923.67518033405172, low: 1923.101990926835022, close: 1923.126913, volume: 77850000, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-03T23:00:00.000Z'), open: 1923.259826837972874, high: 1923.492425937060705, low: 1922.90262235438972, close: 1923.276441, volume: 80960100, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T00:00:00.000Z'), open: 1923.268134182833126, high: 1923.326283750587436, low: 1922.902622614091726, close: 1923.027228, volume: 52820600, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T01:00:00.000Z'), open: 1923.234904845121957, high: 1923.542267674401998, low: 1923.052149892895393, close: 1923.268134, volume: 59195800, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T02:00:00.000Z'), open: 1923.284748153680564, high: 1923.459196018578062, low: 1923.12691278885316, close: 1923.251519, volume: 48591300, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T03:00:00.000Z'), open: 1923.201676634364272, high: 1923.59210943129056, low: 1923.01061460700204, close: 1923.359512, volume: 65993700, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T04:00:00.000Z'), open: 1923.101991198292986, high: 1923.309668221207414, low: 1922.9109291679198, close: 1923.201677, volume: 81117200, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T05:00:00.000Z'), open: 1923.47709177175651, high: 1923.677395688560953, low: 1923.38528740952381, close: 1923.660703, volume: 51935600, split: '', dividend: '$0.130',
  },
  {
    date: new Date('2010-02-04T06:00:00.000Z'), open: 1923.810930273207585, high: 1923.911080561385095, low: 1923.669049515949805, close: 1923.861005, volume: 45882900, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T07:00:00.000Z'), open: 1923.861005604453073, high: 1924.228227231839046, low: 1923.794238187581573, close: 1924.17815, volume: 42856500, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T08:00:00.000Z'), open: 1924.0279246886073, high: 1924.13642090510949, low: 1923.944465418534303, close: 1924.011232, volume: 44451800, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T09:00:00.000Z'), open: 1924.06965319596241, high: 1924.15311329961183, low: 1923.91108058475461, close: 1923.977848, volume: 36707100, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T10:00:00.000Z'), open: 1923.93611844264031, high: 1924.061307346629015, low: 1923.443708753618075, close: 1923.644011, volume: 52266200, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T11:00:00.000Z'), open: 1923.80258363823205, high: 1924.027924500255413, low: 1923.685739826453087, close: 1923.894388, volume: 43165900, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T12:00:00.000Z'), open: 1923.593936399999997, high: 1923.911081636363633, low: 1923.385288218181813, close: 1923.869352, volume: 48735300, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T13:00:00.000Z'), open: 1923.911081145797, high: 1924.077999687826996, low: 1923.794238166376, close: 1923.927773, volume: 40370600, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T14:00:00.000Z'), open: 1924.01123281771192, high: 1924.24491794728184, low: 1923.810931397308096, close: 1924.219881, volume: 43805400, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T15:00:00.000Z'), open: 1924.26995529971733, high: 1924.45356485597533, low: 1923.568897443742003, close: 1923.752507, volume: 93123900, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T16:00:00.000Z'), open: 1923.794237468876933, high: 1923.87769757203811, low: 1923.660702639167347, close: 1923.752507, volume: 48442100, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T17:00:00.000Z'), open: 1923.7525072419881, high: 1923.911080688476446, low: 1923.593935464685135, close: 1923.894388, volume: 42890600, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T18:00:00.000Z'), open: 1923.919426488282614, high: 1923.93611834207765, low: 1923.719124242742218, close: 1923.861005, volume: 56001800, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T19:00:00.000Z'), open: 1923.80258363823205, high: 1924.144766642848992, low: 1923.785891784348298, close: 1923.894388, volume: 39414500, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T20:00:00.000Z'), open: 1923.835966751378393, high: 1924.294994406988312, low: 1923.827620824352493, close: 1924.036269, volume: 50271600, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T21:00:00.000Z'), open: 1924.086346470987106, high: 1924.29499464871055, low: 1924.036269239148055, close: 1924.17815, volume: 44891400, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T22:00:00.000Z'), open: 1924.11138228226816, high: 1924.361761761597496, low: 1924.077999408841674, close: 1924.353415, volume: 35349700, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-04T23:00:00.000Z'), open: 1924.470258636146227, high: 1924.520333364928977, low: 1924.23657350832009, close: 1924.428529, volume: 31700200, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-05T00:00:00.000Z'), open: 1924.35341496847337, high: 1924.511988415951947, low: 1924.21153420957548, close: 1924.445221, volume: 37512000, split: '', dividend: '',
  },
  {
    date: new Date('2010-02-05T01:00:00.000Z'), open: 1924.55371679966916, high: 1924.61213828763574, low: 1924.370107243509732, close: 1924.511988, volume: 36723500, split: '', dividend: '',
  },
];
