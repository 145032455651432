import React from 'react';
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { currencyFormater } from '../../../../../utils/currencyFormater';

const mockData = [
  {
    name: 'some text', value: 1500,
  },
  {
    name: 'some text', value: 3000,
  },
  {
    name: 'some text', value: 2300,
  },
  {
    name: 'some text', value: 3300,
  },
  {
    name: 'some text', value: 2300,
  },
  {
    name: 'some text', value: 3200,
  },
  {
    name: 'some text', value: 1100,
  },
  {
    name: 'some text', value: 4200, keepShowing: true,
  },
  {
    name: 'some text', value: 3000,
  },
  {
    name: 'some text', value: 4800,
  },
  {
    name: 'some text', value: 2900,
  },
  {
    name: 'some text', value: 1500,
  },
];

const CustomDot = (props) => {
  const { cx, cy, payload: { keepShowing = false, value } } = props;
  const radius = 8;
  const diameter = radius * 2;
  return keepShowing && (
    <svg width={diameter} height={diameter} style={{ overflow: 'visible' }}>
      <circle cx={cx} cy={cy} r={radius} rstrokeWidth="0" fill="#FF910A" stroke="#FFF" strokeWidth="3" />
      <text x={cx - 10} y={cy - 10} fill="#FF910A" fontSize="16" fontWeight="bold">{currencyFormater(value)}</text>
    </svg>
  );
};

const ProductSoldChart = () => (
  <ResponsiveContainer
    width="100%"
    height="100%"
  >
    <AreaChart
      width={520}
      height={250}
      data={mockData}
    >
      <Tooltip
        viewBox={{
          x: -1,
          y: -1,
          width: 400,
          height: 400,
        }}
        content={() => null}
      />
      <defs>
        <linearGradient id="customerColor" x1="1" y1="0" x2="1" y2="2">
          <stop offset="5%" stopColor="#FF8E0B" stopOpacity={1} />
          <stop offset="95%" stopColor="#FEC103" stopOpacity={0.7} />
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey="value"
        stroke="#FF910A"
        fill="url(#customerColor)"
        fillOpacity={0.9}
        ffset="5%"
        dot={<CustomDot />}
      />
    </AreaChart>
  </ResponsiveContainer>
);

export default ProductSoldChart;
