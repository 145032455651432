import React from 'react';
import ReactWOW from 'react-wow';
import { Tab, Nav } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

function ProductTabs({ t }) {
  return (
    <ReactWOW animation="fadeInUp">
      <section>
        <Tab.Container defaultActiveKey="tab_merchants">
          <div className="bgc-grey tab-personal-merchants-partners">
            <div className="container">
              <Nav as="ul" className="nav nav-tabs" id="mainTab" role="tablist">
                <Nav.Item as="li" className="nav-item" eventKey="tab_personal">
                  <Nav.Link
                    as="a"
                    className="nav-link"
                    data-toggle="tab"
                    eventKey="tab_personal"
                    role="tab"
                    aria-selected="false"
                  >
                    {t('Personal Product')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item" eventKey="tab_merchants">
                  <Nav.Link
                    as="a"
                    className="nav-link"
                    data-toggle="tab"
                    eventKey="tab_merchants"
                    role="tab"
                  >
                    {t('Merchants')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item" eventKey="tab_partners">
                  <Nav.Link
                    as="a"
                    className="nav-link"
                    data-toggle="tab"
                    eventKey="tab_partners"
                    role="tab"
                    aria-selected="false"
                  >
                    {t('Partners')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className="pY-35 bgc-white tab-content">
            <Tab.Pane className="tab-pane" eventKey="tab_personal">
              <div className="container">
                <div className="row no-gutters">
                  <div className="col-12 col-md-12 col-lg-4 bd bdR-n bdR-md bdb-md-n">
                    <div className="d-flex p-25 hover-opacity">
                      <div className="c-blue fsz-xl mR-30">
                        <i className="icon-global" />
                      </div>
                      <div>
                        <h5>
                          <b className="fw-n">{t('Cross-border Online Payment')}</b>
                        </h5>
                        <p className="c-gray">
                          {t('Collect payments')}
                        </p>
                        <div className="mY-20">
                          <a href="/" className="btn btn-around btn-border mR-10">{t('Mobile')}</a>
                          <a href="/" className="btn btn-around btn-border">PC</a>
                        </div>
                        <a href="/" className="c-black td-u">{t('Go see more')}</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4 bd bdR-n bdR-md bdb-md-n">
                    <div className="d-flex p-25 hover-opacity">
                      <div className="c-blue fsz-xl mR-30">
                        <i className="icon-planes" />
                      </div>
                      <div>
                        <h5>
                          <b className="fw-n">{t('Customs Declaration')}</b>
                        </h5>
                        <p className="c-gray">
                          {t('Paybit transaction')}
                        </p>
                        <div className="mY-20">
                          <a href="/" className="btn btn-around btn-border">{t('In - Store')}</a>
                        </div>
                        <a href="/" className="c-black td-u">{t('Go see more')}</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4 bd mB-15">
                    <div className="d-flex p-25 hover-opacity">
                      <div className="c-blue fsz-xl mR-30">
                        <i className="icon-anchor" />
                      </div>
                      <div>
                        <h5>
                          <b className="fw-n">{t('Cross-border In-Store Payment In-Store')}</b>
                        </h5>
                        <p className="c-gray">
                          {t('Paybit transaction')}
                        </p>
                        <div className="mY-15">&nbsp;</div>
                        <a href="/" className="c-black td-u">{t('Go see more')}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane className="tab-pane" eventKey="tab_merchants">
              <div className="container">
                <div className="row no-gutters">
                  <div className="col-12 col-md-12 col-lg-4 bd bdR-n bdR-md bdb-md-n">
                    <div className="d-flex p-25 hover-opacity">
                      <div className="c-blue fsz-xl mR-30">
                        <i className="icon-global" />
                      </div>
                      <div>
                        <h5>
                          <b className="fw-n">{t('Cross-border Online Payment')}</b>
                        </h5>
                        <p className="c-gray">
                          {t('Collect payments')}
                        </p>
                        <div className="mY-20">
                          <a href="/" className="btn btn-around btn-border mR-10">{t('Mobile')}</a>
                          <a href="/" className="btn btn-around btn-border">PC</a>
                        </div>
                        <a href="/" className="c-black td-u">{t('Go see more')}</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4 bd bdR-n bdR-md bdb-md-n">
                    <div className="d-flex p-25 hover-opacity">
                      <div className="c-blue fsz-xl mR-30">
                        <i className="icon-planes" />
                      </div>
                      <div>
                        <h5>
                          <b className="fw-n">{t('Customs Declaration')}</b>
                        </h5>
                        <p className="c-gray">
                          {t('Paybit transaction')}
                        </p>
                        <div className="mY-20">
                          <a href="/" className="btn btn-around btn-border">{t('In - Store')}</a>
                        </div>
                        <a href="/" className="c-black td-u">{t('Go see more')}</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4 bd mB-15">
                    <div className="d-flex p-25 hover-opacity">
                      <div className="c-blue fsz-xl mR-30">
                        <i className="icon-anchor" />
                      </div>
                      <div>
                        <h5>
                          <b className="fw-n">{t('Cross-border In-Store Payment In-Store')}</b>
                        </h5>
                        <p className="c-gray">
                          {t('Paybit transaction')}
                        </p>
                        <div className="mY-15">&nbsp;</div>
                        <a href="/" className="c-black td-u">{t('Go see more')}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane className="tab-pane" eventKey="tab_partners">
              <div className="container">
                <div className="row no-gutters">
                  <div className="col-12 col-md-12 col-lg-4 bd bdR-n bdR-md bdb-md-n">
                    <div className="d-flex p-25 hover-opacity">
                      <div className="c-blue fsz-xl mR-30">
                        <i className="icon-global" />
                      </div>
                      <div>
                        <h5>
                          <b className="fw-n">{t('Cross-border Online Payment')}</b>
                        </h5>
                        <p className="c-gray">
                          {t('Collect payments')}
                        </p>
                        <div className="mY-20">
                          <a href="/" className="btn btn-around btn-border mR-10">{t('Mobile')}</a>
                          <a href="/" className="btn btn-around btn-border">PC</a>
                        </div>
                        <a href="/" className="c-black td-u">{t('Go see more')}</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4 bd bdR-n bdR-md bdb-md-n">
                    <div className="d-flex p-25 hover-opacity">
                      <div className="c-blue fsz-xl mR-30">
                        <i className="icon-planes" />
                      </div>
                      <div>
                        <h5>
                          <b className="fw-n">{t('Customs Declaration')}</b>
                        </h5>
                        <p className="c-gray">
                          {t('Paybit transaction')}
                        </p>
                        <div className="mY-20">
                          <a href="/" className="btn btn-around btn-border">{t('In - Store')}</a>
                        </div>
                        <a href="/" className="c-black td-u">{t('Go see more')}</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4 bd mB-15">
                    <div className="d-flex p-25 hover-opacity">
                      <div className="c-blue fsz-xl mR-30">
                        <i className="icon-anchor" />
                      </div>
                      <div>
                        <h5>
                          <b className="fw-n">{t('Cross-border In-Store Payment In-Store')}</b>
                        </h5>
                        <p className="c-gray">
                          {t('Paybit transaction')}
                        </p>
                        <div className="mY-15">&nbsp;</div>
                        <a href="/" className="c-black td-u">{t('Go see more')}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Pane>
          </div>
        </Tab.Container>
      </section>
    </ReactWOW>
  );
}

export default withTranslation('ProductTabs')(ProductTabs);
