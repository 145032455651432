import React from 'react';
import ReactWOW from 'react-wow';

const StartUsing = () => (
  <section className="bdT bdB section-download-browser">
    <div className="container">
      <div className="row">
        <ReactWOW animation="fadeInLeft">
          <div className="col-sm-12 col-md-6 col-lg-6 bdR bdR-sm-n">
            <div className="pY-60">
              <p className="c-text-gray">Click on the link bellow and start using</p>
              <h3 className="mB-30"><b className="fw-n">Download Toolsbars for browser</b></h3>
              <div className="d-flex">
                <div className="img size-32 mR-20 hover-opacity-onhover" style={{ backgroundImage: 'url(/images/firefox_64.png)' }} />
                <div className="img size-32 mR-20 hover-opacity-onhover" style={{ backgroundImage: 'url(/images/chrome_64.png)' }} />
                <div className="img size-32 mR-20 hover-opacity-onhover" style={{ backgroundImage: 'url(/images/safari_64.png)' }} />
                <div className="img size-32 mR-20 hover-opacity-onhover" style={{ backgroundImage: 'url(/images/edge_64.png)' }} />
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeInRight">
          <div className="col-sm-12 col-md-6 col-lg-6 bdT-sm box-hover">
            <div className="pY-60 pL-50 pL-md-0">
              <p className="c-text-gray">Click on the link bellow and start using</p>
              <h3 className="mB-30"><b className="fw-n">Download our Tools</b></h3>
              <div className="mT-60">
                <a href="/" className="fsz-def c-black">
                  <b className="fw-n">Download our Tools</b>
                  {' '}
                  <i className="icon-right-arrow mL-10" />
                </a>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
    </div>
  </section>
);

export default StartUsing;
